import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import { useEffect } from "react";

const validationSchema = Yup.object().shape({
  situation_description: Yup.string().label("This"),
});

const SituationDescription = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;
  let situDesc;
  const selfConnectData = JSON.parse(sessionStorage.getItem("selfConnectData"));
  situDesc = selfConnectData?.situation_desc;

  const navigate = useNavigate();
  const initialValues = {
    situation_description: situDesc || "",
  };

  const handleSubmit = (values) => {
    const data = { situation_desc: values.situation_description };
    sessionStorage.setItem("selfConnectData", JSON.stringify(data));
    navigate("/bad-feeling");
  };

  return (
    <>
      <div className="bg-light_e pb-10">
        <Header />

        <div className="e2_content pt-100">
          <div className="container px-4">
            <p className="e2_content_disc">
              אם תרצ
              {userGender === "female" ? "י" : "ה"},
              {userGender === "male" ? " כתוב " : " כתבי "}
              בכמה שורות, או בהרבה שורות :) מה עובר עליך כרגע ללא סינון, כל מה
              שעובר בראש.
            </p>

            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                handleSubmit,
                errors,
                setFieldTouched,
                touched,
                values,
              }) => (
                <>
                  <div className="input-group">
                    <textarea
                      className="form-control input_shadow custom_textarea"
                      rows={13}
                      value={values.situation_description}
                      name="situation_description"
                      onChange={handleChange("situation_description")}
                      onBlur={() => setFieldTouched("situation_description")}
                    />
                  </div>
                  {touched.situation_description && (
                    <p style={{ color: "red" }}>
                      {errors.situation_description}
                    </p>
                  )}
                  <div className="line">
                    <RoundBtn
                      extraClass={`mt-5 mb-2 text-regular bg-primary-button`}
                      text="להמשיך"
                      onClick={handleSubmit}
                    />
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default SituationDescription;
