import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import final_shape from "../img/final_shape.png";

const ProcessText = () => {
  const navigate = useNavigate();
  const userRequest = JSON.parse(sessionStorage.getItem("request"));

  useEffect(() => {
    if (userRequest?.ask_from_partner_name === undefined) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;
  const [showSlide, setshowSlide] = useState(false);

  useEffect(() => {
    var popupModal = document.getElementById("popup456");
    popupModal?.addEventListener("hidden.bs.modal", () => {
      setshowSlide(false);
    });
    popupModal?.addEventListener("shown.bs.modal", () => {
      setshowSlide(true);
    });
  }, [showSlide]);

  return (
    <>
      <div className="bg-primary_e">
        <Header extraClass="border-bottom" />
        <div className="a10_content text-center px-3 pt-80">
          <div className="container pt-5">
            <h5>יאללה, יוצאים לדרך!</h5>
            <h6>לפניך תהליך עם 4 תחנות.</h6>
            {/* Outline Button Design */}
            <a
              href="#"
              className="line"
              data-bs-toggle="modal"
              data-bs-target="#popup456"
            >
              <button className="btn bg-outline-button custom_button text-regular mt-4 fs-20">
                איך זה עובד?
              </button>
            </a>
            <h6 className="mt-5 py-1">
              לאחר המעבר בכל התחנות, <br />
              תקבל
              {userGender === "male" ? " " : "י "}
              בקשה מקרבת שתוכל
              {userGender === "male" ? " " : "י "}
              <br />
              לשתף עם {userRequest?.ask_from_partner_name}.
            </h6>

            <Link to="/situation" className="line">
              <RoundBtn
                extraClass="mt-5 text-regular bg-secondary-button"
                text="להמשיך"
              />
            </Link>

            <div className="footer custom_final_footer">
              <img src={final_shape} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="popup456"
        data-toggle="modal"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content custom-content">
            <div className="modal-header border-0"></div>
            <div className="modal-body p-0">
              {showSlide ? (
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  className="custom-swiper"
                  initialSlide="0"
                >
                  {[1, 2, 3].map((el, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="ovelay"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></div>
                      <div className="img-support">
                        <img
                          src={`assets/images/popup-${el}.svg`}
                          className="img-fluid"
                          alt=""
                        />
                        <button
                          type="button"
                          className="btn-close custom-btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                ""
              )}
              {/* Swiper */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcessText;
