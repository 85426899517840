const SisterBlue = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    fill="none"
    viewBox="0 0 100 100"
  >
    <g clipPath="url(#clip0_4680_6259)">
      <path d="M0 0H100V100H0z"></path>
      <g filter="url(#filter0_dd_4680_6259)">
        <g filter="url(#filter1_ii_4680_6259)">
          <circle cx="49.5" cy="49.5" r="42.5" fill="#fff"></circle>
        </g>
        <g>
          <g>
            <mask
              id="mask0_4680_6259"
              style={{ maskType: "alpha" }}
              width="85"
              height="85"
              x="7"
              y="7"
              maskUnits="userSpaceOnUse"
            >
              <g>
                <mask
                  id="mask1_4680_6259"
                  style={{ maskType: "alpha" }}
                  width="85"
                  height="85"
                  x="7"
                  y="7"
                  maskUnits="userSpaceOnUse"
                >
                  <g>
                    <circle
                      cx="49.5"
                      cy="49.5"
                      r="42.5"
                      fill="#DDE1F0"
                    ></circle>
                    <circle
                      cx="49.5"
                      cy="49.5"
                      r="42"
                      stroke="#000"
                      strokeOpacity="0.5"
                    ></circle>
                  </g>
                </mask>
                <g mask="url(#mask1_4680_6259)">
                  <circle
                    cx="49.5"
                    cy="49.5"
                    r="42.5"
                    fill="#DDE1F0"
                  ></circle>
                </g>
              </g>
            </mask>
            <g mask="url(#mask0_4680_6259)">
              <g>
                <g>
                  <g>
                    <g fill="#3869E5">
                      <path d="M62.337 27.102c-11.874 1.731-9.291 11.382-6.916 23.226 1.063 5.254-2.861 11.77 1.495 14.86 4.357 3.092 16.663 2.524 21.933 1.763-2.408-5.808-3.787-9.64-6.105-25.54-2.318-15.9-11.195-8.843-10.407-14.309zM34.46 27.102c11.873 1.731 9.29 11.382 6.916 23.226-1.063 5.254 2.86 11.77-1.496 14.86-4.356 3.092-16.662 2.524-21.932 1.764 2.408-5.809 3.786-9.64 6.105-25.54 2.318-15.9 11.195-8.844 10.407-14.31z"></path>
                    </g>
                    <path
                      fill="#3869E5"
                      d="M42.238 78.76H55.04c.79 0 1.549.313 2.108.868a2.95 2.95 0 01.872 2.092v14.543H39.258V81.72c0-.785.314-1.537.873-2.092a2.99 2.99 0 012.107-.866z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M42.634 84.809h-3.388V97.23h3.388V84.81z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M54.722 97.228h3.3v-12.42h-3.3v12.42z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M44.55 72.647h8.234v7.923a4.064 4.064 0 01-1.203 2.892 4.117 4.117 0 01-2.91 1.2 4.13 4.13 0 01-2.91-1.197 4.074 4.074 0 01-1.206-2.891v-7.923l-.005-.004z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M65.728 59.628c9.437-9.39 9.437-24.612 0-34.002-9.438-9.389-24.74-9.389-34.177 0-9.438 9.39-9.438 24.612 0 34.002 9.437 9.389 24.739 9.389 34.177 0z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M68.046 47.778c0-10.63-8.677-19.248-19.38-19.248-10.702 0-19.379 8.618-19.379 19.248v9.615c0 10.63 8.677 19.247 19.38 19.247 10.702 0 19.379-8.617 19.379-19.247v-9.615z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M69.627 48.168c0-12-10.03-21.82-22.093-21.18a21.05 21.05 0 00-14.076 6.477 20.77 20.77 0 00-5.754 14.308c6.095-1.545 4.894-2.079 20.962-2.079 16.069 0 15.666.906 20.961 2.474z"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <path
            fill="#3869E5"
            d="M54.223 52c.21 0 .411.083.56.23a.783.783 0 01.231.555c.014.43.196.838.507 1.137a1.674 1.674 0 002.32 0c.312-.3.493-.707.508-1.137 0-.208.083-.408.231-.555a.793.793 0 011.35.555 3.208 3.208 0 01-.924 2.33 3.252 3.252 0 01-2.325.973 3.269 3.269 0 01-2.324-.972 3.226 3.226 0 01-.924-2.33c0-.209.083-.409.231-.556a.793.793 0 01.56-.23z"
          ></path>
          <path
            fill="#3869E5"
            d="M37.118 52c.21 0 .41.083.559.23a.782.782 0 01.232.555c.014.43.196.838.507 1.137a1.674 1.674 0 002.32 0c.311-.3.493-.707.507-1.137 0-.208.083-.408.232-.555a.793.793 0 011.35.555 3.208 3.208 0 01-.925 2.33 3.25 3.25 0 01-2.324.973 3.27 3.27 0 01-2.325-.972 3.225 3.225 0 01-.924-2.33.779.779 0 01.488-.727.792.792 0 01.303-.059z"
          ></path>
          <path
            fill="#3869E5"
            d="M40 61c5.515 12.374 10.938 12.804 16.268 0H40z"
          ></path>
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_4680_6259"
        width="103"
        height="103"
        x="-2"
        y="-2"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="5" dy="5"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4680_6259"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-5" dy="-5"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.92549 0 0 0 0 0.937255 0 0 0 0 0.941176 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="effect1_dropShadow_4680_6259"
          result="effect2_dropShadow_4680_6259"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_4680_6259"
          result="shape"
        ></feBlend>
      </filter>
      <filter
        id="filter1_ii_4680_6259"
        width="87"
        height="88"
        x="6"
        y="4"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-1" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="13"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
        <feBlend in2="shape" result="effect1_innerShadow_4680_6259"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="1" dy="-3"></feOffset>
        <feGaussianBlur stdDeviation="13.5"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
        <feBlend
          in2="effect1_innerShadow_4680_6259"
          result="effect2_innerShadow_4680_6259"
        ></feBlend>
      </filter>
      <clipPath id="clip0_4680_6259">
        <path fill="#fff" d="M0 0H100V100H0z"></path>
      </clipPath>
    </defs>
  </svg>
  )
}

export default SisterBlue
