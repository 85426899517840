const NoticeMsg = () => {
  let mobileDevice = /Mobi/.test(navigator.userAgent); // if mobile devices

  return (
    <>
      {mobileDevice ? (
        <img
          src="assets/images/rotate.svg"
          style={{ height: "100vh", display: "block", margin: "0 auto" }}
        />
      ) : (
        <div className="full-page-responsive bg-default">
          <div className="container">
            <h1 className="full-page-responsive-title text-primary text-bold">
              ברוכות הבאות וברוכים הבאים!
            </h1>
            <div className="full-page-responsive-disc">
              <p className="m-0">הממשק בשלב הפיילוט,</p>
              <p>בשלב זה ניתן להיכנס מהסמארטפון בלבד</p>
            </div>
            <p className="full-page-responsive-disc">
              נתראה במסך הסמארטפון שלך :){" "}
            </p>
            <img
              src="assets/images/account-shape.svg"
              alt=""
              className="banner-full-img"
            />
            <img
              src="assets/images/logo.svg"
              alt=""
              className="banner-full-img"
            />
            <h2 className="logo_banner_disc">
              פשוט לדבר במשפחה
              <div className="banner-footer_disc">
                <p className="m-0 text-regular">
                  ליצירת קשר, שאלות ופרטים נוספים
                </p>
                <p className="text-regular">
                  {" "}
                  mpathic.nvc@gmail.com  | 054-7654868 |  www.mpathicfamily.com
                </p>
                <p>© כל הזכויות שמורות לאמפתיק (טניה אנגרט) </p>
              </div>
            </h2>
          </div>
        </div>
      )}
    </>
  );
};

export default NoticeMsg;
