import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRemoveGoodFeel } from "../../redux/goodFeelSlice";

const GoodTabE = React.memo(({ data, index, blockId }) => {
  const dispatch = useDispatch();
  const selectedGFTabIDs = useSelector((state) =>
    state.goodFeel.map((x) => x.id)
  );
  const saveData = (data) => {
    dispatch(addRemoveGoodFeel(data));
  };

  return (
    <button
      className={`btn e7_custom_btn shadow_button mb-2 
        ${
          selectedGFTabIDs.includes(data.id)
            ? "bg_secondary_btn text-white"
            : ""
        } ${index === 0 ? "btn_text_dark" : ""}`}
      onClick={() => {
        saveData({ id: data.id, name: data.subCateName, blockId, index });
      }}
    >
      {data.subCateName}
    </button>
  );
});

export default GoodTabE;
