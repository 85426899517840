export const signupNum = [
  "999999",
  "888888",
  "11111a",
  "2222bb",
  "333ccc",
  "aa1000",
  "ay1000",
  "ac1000",
  "ad1000",
  "ae1000",
  "af1000",
  "ag1000",
  "za1000",
  "zu1000",
  "kt1000",
  "pu1000",
  "mi1000",
  "tu1000",
  "tz1000",
  "is1000",
  "vb1000",
  "gf1000",
  "xn1000",
  "mo1000",
  "br1000",
  "aa2000",
  "ay2000",
  "ac2000",
  "ad2000",
  "ae2000",
  "af2000",
  "ag2000",
  "za2000",
  "zu2000",
  "kt2000",
  "pu2000",
  "mi2000",
  "tu2000",
  "tz2000",
  "is2000",
  "vb2000",
  "gf2000",
  "xn2000",
  "mo2000",
  "br2000",
  "aa3300",
  "ay3300",
  "ac3300",
  "ad3300",
  "ae3300",
  "af3300",
  "ag3300",
  "za3300",
  "zu3300",
  "kt3300",
  "pu3300",
  "mi3300",
  "tu3300",
  "tz3300",
  "is3300",
  "vb3300",
  "gf3300",
  "xn3300",
  "mo3300",
  "br3300",
  "vb3100",
  "gf3100",
  "xn3100",
  "mo3100",
  "br3100",
  "vb0100",
  "gf0100",
  "xn0100",
  "mm0100",
  "br0100",
  "vb0220",
  "gf0220",
  "xn0220",
  "mm0220",
  "br0220",
  "vb4100",
  "gf4100",
  "xn4100",
  "mm4100",
  "br4100",
  "vb5100",
  "gf5100",
  "xn5100",
  "mm5100",
  "br5100",
  "bb3300",
  "yy3300",
  "bc3300",
  "bd3300",
  "be3300",
  "bb0500",
  "yy0500",
  "bc0500",
  "bd0500",
  "be0500",
  "bb0600",
  "yy0600",
  "bc0600",
  "bd0600",
  "be0600",
  "bb7700",
  "yy7700",
  "bc7700",
  "bd7700",
  "be7700",
  "cc7700",
  "cy7700",
  "cb7700",
  "cd7700",
  "ce7700",
  "cc7000",
  "cy7000",
  "cb7000",
  "cd7000",
  "ce7000",
  "ccv000",
  "cyv000",
  "cbv000",
  "cdv000",
  "cev000",
  "aa1001",
  "ay1001",
  "ac1001",
  "ad1001",
  "ae1001",
  "af1001",
  "ag1001",
  "za1001",
  "zu1001",
  "kt1001",
  "pu1001",
  "mi1001",
  "tu1001",
  "tz1001",
  "is1001",
  "vb1001",
  "gf1001",
  "xn1001",
  "mo1001",
  "br1001",
  "aa2001",
  "ay2001",
  "ac2001",
  "ad2001",
  "ae2001",
  "af2001",
  "ag2001",
  "za2001",
  "zu2001",
  "kt2001",
  "pu2001",
  "mi2001",
  "tu2001",
  "tz2001",
  "is2001",
  "vb2001",
  "gf2001",
  "xn2001",
  "mo2001",
  "br2001",
  "aa3301",
  "ay3301",
  "ac3301",
  "ad3301",
  "ae3301",
  "af3301",
  "ag3301",
  "za3301",
  "zu3301",
  "kt3301",
  "pu3301",
  "mi3301",
  "tu3301",
  "tz3301",
  "is3301",
  "vb3301",
  "gf3301",
  "xn3301",
  "mo3301",
  "br3301",
  "vb3101",
  "gf3101",
  "xn3101",
  "mo3101",
  "br3101",
  "vb0101",
  "gf0101",
  "xn0101",
  "mm0101",
  "br0101",
  "vb0221",
  "gf0221",
  "xn0221",
  "mm0221",
  "br0221",
  "vb4101",
  "gf4101",
  "xn4101",
  "mm4101",
  "br4101",
  "vb5101",
  "gf5101",
  "xn5101",
  "mm5101",
  "br5101",
  "bb3301",
  "yy3301",
  "bc3301",
  "bd3301",
  "be3301",
  "bb0501",
  "yy0501",
  "bc0501",
  "bd0501",
  "be0501",
  "bb0601",
  "yy0601",
  "bc0601",
  "bd0601",
  "be0601",
  "bb7701",
  "yy7701",
  "bc7701",
  "bd7701",
  "be7701",
  "cc7701",
  "cy7701",
  "cb7701",
  "cd7701",
  "ce7701",
  "cc7001",
  "cy7001",
  "cb7001",
  "cd7001",
  "ce7001",
  "ccv001",
  "cyv001",
  "cbv001",
  "cdv001",
  "cev001",
  "aa1002",
  "ay1002",
  "ac1002",
  "ad1002",
  "ae1002",
  "af1002",
  "ag1002",
  "za1002",
  "zu1002",
  "kt1002",
  "pu1002",
  "mi1002",
  "tu1002",
  "tz1002",
  "is1002",
  "vb1002",
  "gf1002",
  "xn1002",
  "mo1002",
  "br1002",
  "aa2002",
  "ab2002",
  "ac2002",
  "ad2002",
  "ae2002",
  "af2002",
  "ag2002",
  "za2002",
  "zu2002",
  "kt2002",
  "pu2002",
  "mi2002",
  "tu2002",
  "tz2002",
  "is2002",
  "vb2002",
  "gf2002",
  "xn2002",
  "mo2002",
  "br2002",
  "aa3302",
  "ab3302",
  "ac3302",
  "ad3302",
  "ae3302",
  "af3302",
  "ag3302",
  "za3302",
  "zu3302",
  "kt3302",
  "pu3302",
  "mi3302",
  "tu3302",
  "tz3302",
  "is3302",
  "vb3302",
  "gf3302",
  "xn3302",
  "mo3302",
  "br3302",
  "vb3102",
  "gf3102",
  "xn3102",
  "mo3102",
  "br3102",
  "vb0102",
  "gf0102",
  "xn0102",
  "mm0102",
  "br0102",
  "vb0222",
  "gf0222",
  "xn0222",
  "mm0222",
  "br0222",
  "vb4102",
  "gf4102",
  "xn4102",
  "mm4102",
  "br4102",
  "vb5102",
  "gf5102",
  "xn5102",
  "mm5102",
  "br5102",
  "bb3302",
  "yy3302",
  "bc3302",
  "bd3302",
  "be3302",
  "bb0502",
  "yy0502",
  "bc0502",
  "bd0502",
  "be0502",
  "bb0602",
  "yy0602",
  "bc0602",
  "bd0602",
  "be0602",
  "bb7702",
  "yy7702",
  "bc7702",
  "bd7702",
  "be7702",
  "cc7702",
  "cy7702",
  "cb7702",
  "cd7702",
  "ce7702",
  "cc7002",
  "cy7002",
  "cb7002",
  "cd7002",
  "ce7002",
  "ccv002",
  "cyv002",
  "cbv002",
  "cdv002",
  "cev002",
  "aa8888",
  "bu8008",
  "im7007",
  "gg7000",
  "ur7000",
  "0101vw",
  "0202xw",
  "0303mw",
];
