export const badfeelingInstances = [
  {
    id: 1,
    name: "כעס",
    emotion_category_id: 1,
    female_first_instance: "אני כועסת",
    female_second_instance: "ואני כועסת",
    male_first_instance: "אני כועס",
    male_second_instance: "ואני כועס",
  },
  {
    id: 2,
    name: "עצבנות",
    emotion_category_id: 1,
    female_first_instance: "אני עצבנית",
    female_second_instance: "ואני עצבנית",
    male_first_instance: "אני עצבני",
    male_second_instance: "ואני עצבני",
  },
  {
    id: 3,
    name: "עוינות",
    emotion_category_id: 1,
    female_first_instance: "אני עוינת",
    female_second_instance: "ואני עוינת",
    male_first_instance: "אני עוין",
    male_second_instance: "ואני עוין",
  },
  {
    id: 4,
    name: "עצב",
    emotion_category_id: 2,
    female_first_instance: "אני עצובה",
    female_second_instance: "ואני עצובה",
    male_first_instance: "אני עצוב",
    male_second_instance: "ואני עצוב",
  },
  {
    id: 5,
    name: "באסה",
    emotion_category_id: 2,
    female_first_instance: "אני מבואסת",
    female_second_instance: "ואני מבואסת",
    male_first_instance: "אני מבואס",
    male_second_instance: "ואני מבואס",
  },
  {
    id: 6,
    name: "כיווץ בנפש",
    emotion_category_id: 2,
    female_first_instance: "אני מרגישה כיווץ בנפש",
    female_second_instance: "ואני מרגישה כיווץ בנפש",
    male_first_instance: "אני מרגיש כיווץ בנפש",
    male_second_instance: "ואני מרגיש כיווץ בנפש",
  },
  {
    id: 7,
    name: "דכדוך",
    emotion_category_id: 2,
    female_first_instance: "אני מדוכדכת",
    female_second_instance: "ואני מדוכדכת",
    male_first_instance: "אני מדוכדך",
    male_second_instance: "ואני מדוכדך",
  },
  {
    id: 8,
    name: "מועקה",
    emotion_category_id: 2,
    female_first_instance: "אני מרגישה מועקה",
    female_second_instance: "ואני מרגישה מועקה",
    male_first_instance: "אני מרגיש מועקה",
    male_second_instance: "ואני מרגיש מועקה",
  },
  {
    id: 9,
    name: "דכאון",
    emotion_category_id: 2,
    female_first_instance: "אני מדוכאת",
    female_second_instance: "ואני מדוכאת",
    male_first_instance: "אני מדוכא",
    male_second_instance: "ואני מדוכא",
  },
  {
    id: 10,
    name: "צער",
    emotion_category_id: 2,
    female_first_instance: "אני מרגישה צער",
    female_second_instance: "ואני מרגישה צער",
    male_first_instance: "אני מרגיש צער",
    male_second_instance: "ואני מרגיש צער",
  },
  {
    id: 11,
    name: "אבל",
    emotion_category_id: 2,
    female_first_instance: "אני מתאבלת",
    female_second_instance: "ואני מתאבלת",
    male_first_instance: "אני מתאבל",
    male_second_instance: "ואני מתאבל",
  },
  {
    id: 12,
    name: "תסכול",
    emotion_category_id: 3,
    female_first_instance: "אני מתוסכלת",
    female_second_instance: "ואני מתוסכלת",
    male_first_instance: "אני מתוסכל",
    male_second_instance: "ואני מתוסכל",
  },
  {
    id: 13,
    name: "אכזבה",
    emotion_category_id: 3,
    female_first_instance: "אני מאוכזבת",
    female_second_instance: "ואני מאוכזבת",
    male_first_instance: "אני מאוכזב",
    male_second_instance: "ואני מאוכזב",
  },
  {
    id: 14,
    name: "מרמור",
    emotion_category_id: 3,
    female_first_instance: "אני ממורמרת",
    female_second_instance: "ואני ממורמרת",
    male_first_instance: "אני ממורמר",
    male_second_instance: "ואני ממורמר",
  },
  {
    id: 15,
    name: "כאב",
    emotion_category_id: 4,
    female_first_instance: "כואב לי",
    female_second_instance: "וכואב לי",
    male_first_instance: "כואב לי",
    male_second_instance: "וכואב לי",
  },
  {
    id: 16,
    name: "עלבון",
    emotion_category_id: 4,
    female_first_instance: "אני נעלבתי",
    female_second_instance: "ואני נעלבתי",
    male_first_instance: "אני נעלבתי",
    male_second_instance: "ואני נעלבתי",
  },
  {
    id: 17,
    name: "פגיעה",
    emotion_category_id: 4,
    female_first_instance: "אני פגועה",
    female_second_instance: "ואני פגועה",
    male_first_instance: "אני פגוע",
    male_second_instance: "ואני פגוע",
  },
  {
    id: 18,
    name: "מתח",
    emotion_category_id: 5,
    female_first_instance: "אני במתח",
    female_second_instance: "ואני במתח",
    male_first_instance: "אני במתח",
    male_second_instance: "ואני במתח",
  },
  {
    id: 19,
    name: "לחץ",
    emotion_category_id: 5,
    female_first_instance: "אני בלחץ",
    female_second_instance: "ואני בלחץ",
    male_first_instance: "אני בלחץ",
    male_second_instance: "ואני בלחץ",
  },
  {
    id: 20,
    name: "חוסר סבלנות",
    emotion_category_id: 5,
    female_first_instance: "אני חסרת סבלנות",
    female_second_instance: "ואני חסרת סבלנות",
    male_first_instance: "אני חסר סבלנות",
    male_second_instance: "ואני חסר סבלנות",
  },
  {
    id: 21,
    name: "חוסר שקט",
    emotion_category_id: 5,
    female_first_instance: "אני מרגישה חוסר שקט",
    female_second_instance: "ואני מרגישה חוסר שקט",
    male_first_instance: "אני מרגיש חוסר שקט",
    male_second_instance: "ואני מרגיש חוסר שקט",
  },
  {
    id: 22,
    name: "חוסר נוחות",
    emotion_category_id: 5,
    female_first_instance: "אני מרגישה לא בנוח",
    female_second_instance: "ואני מרגישה לא בנוח",
    male_first_instance: "אני מרגיש לא בנוח",
    male_second_instance: "ואני מרגיש לא בנוח",
  },
  {
    id: 23,
    name: "חרדה",
    emotion_category_id: 6,
    female_first_instance: "אני בחרדה",
    female_second_instance: "ואני בחרדה",
    male_first_instance: "אני בחרדה",
    male_second_instance: "ואני בחרדה",
  },
  {
    id: 24,
    name: "טירדה",
    emotion_category_id: 6,
    female_first_instance: "אני מוטרדת",
    female_second_instance: "ואני מוטרדת",
    male_first_instance: "אני מוטרד",
    male_second_instance: "ואני מוטרד",
  },
  {
    id: 25,
    name: "הצפה",
    emotion_category_id: 6,
    female_first_instance: "אני מוצפת",
    female_second_instance: "ואני מוצפת",
    male_first_instance: "אני מוצף",
    male_second_instance: "ואני מוצף",
  },
  {
    id: 26,
    name: "מצוקה",
    emotion_category_id: 6,
    female_first_instance: "אני במצוקה",
    female_second_instance: "ואני במצוקה",
    male_first_instance: "אני במצוקה",
    male_second_instance: "ואני במצוקה",
  },
  {
    id: 27,
    name: "פחד",
    emotion_category_id: 7,
    female_first_instance: "אני מפחדת",
    female_second_instance: "ואני מפחדת",
    male_first_instance: "אני מפחד",
    male_second_instance: "ואני מפחד",
  },
  {
    id: 28,
    name: "דאגה",
    emotion_category_id: 7,
    female_first_instance: "אני מודאגת",
    female_second_instance: "ואני מודאגת",
    male_first_instance: "אני מודאג",
    male_second_instance: "ואני מודאג",
  },
  {
    id: 29,
    name: "חשש",
    emotion_category_id: 7,
    female_first_instance: "אני חוששת",
    female_second_instance: "ואני חוששת",
    male_first_instance: "אני חושש",
    male_second_instance: "ואני חושש",
  },
  {
    id: 30,
    name: "בהלה",
    emotion_category_id: 7,
    female_first_instance: "אני מבוהלת",
    female_second_instance: "ואני מבוהלת",
    male_first_instance: "אני מבוהל",
    male_second_instance: "ואני מבוהל",
  },
  {
    id: 31,
    name: "אשמה",
    emotion_category_id: 8,
    female_first_instance: "אני מרגישה אשמה",
    female_second_instance: "ואני מרגישה אשמה",
    male_first_instance: "אני מרגיש אשם",
    male_second_instance: "ואני מרגיש אשם",
  },
  {
    id: 32,
    name: "בושה",
    emotion_category_id: 8,
    female_first_instance: "אני מתביישת",
    female_second_instance: "ואני מתביישת",
    male_first_instance: "אני מתבייש",
    male_second_instance: "ואני מתבייש",
  },
  {
    id: 33,
    name: "חרטה",
    emotion_category_id: 8,
    female_first_instance: "אני מתחרטת",
    female_second_instance: "ואני מתחרטת",
    male_first_instance: "אני מתחרט",
    male_second_instance: "ואני מתחרט",
  },
  {
    id: 34,
    name: "מבוכה",
    emotion_category_id: 8,
    female_first_instance: "אני נבוכה",
    female_second_instance: "ואני נבוכה",
    male_first_instance: "אני נבוך",
    male_second_instance: "ואני נבוך",
  },
  {
    id: 35,
    name: "ריחוק/הסתייגות",
    emotion_category_id: 9,
    female_first_instance: "אני מרוחקת",
    female_second_instance: "ואני מרוחקת",
    male_first_instance: "אני מרוחק",
    male_second_instance: "ואני מרוחק",
  },
  {
    id: 36,
    name: "דחייה",
    emotion_category_id: 9,
    female_first_instance: "אני מרגישה דחייה",
    female_second_instance: "ואני מרגישה דחייה",
    male_first_instance: "אני מרגיש דחייה",
    male_second_instance: "ואני מרגיש דחייה",
  },
  {
    id: 37,
    name: "סגירות",
    emotion_category_id: 9,
    female_first_instance: "אני מרגישה שאני סגורה",
    female_second_instance: "ואני מרגיש שאני סגורה",
    male_first_instance: "אני מרגיש שאני סגור",
    male_second_instance: "ואני מרגיש שאני סגור",
  },
  {
    id: 38,
    name: "נתק",
    emotion_category_id: 9,
    female_first_instance: "אני מנותקת",
    female_second_instance: "ואני מנותקת",
    male_first_instance: "אני מנותק",
    male_second_instance: "ואני מנותק",
  },
  {
    id: 39,
    name: "גועל",
    emotion_category_id: 9,
    female_first_instance: "אני נגעלת",
    female_second_instance: "ואני נגעלת",
    male_first_instance: "אני נגעל",
    male_second_instance: "ואני נגעל",
  },
  {
    id: 40,
    name: "פגיעות",
    emotion_category_id: 9,
    female_first_instance: "אני פגיעה",
    female_second_instance: "ואני פגיעה",
    male_first_instance: "אני פגיע",
    male_second_instance: "ואני פגיע",
  },
  {
    id: 41,
    name: "עייפות",
    emotion_category_id: 10,
    female_first_instance: "אני עייפה",
    female_second_instance: "ואני עייפה",
    male_first_instance: "אני עייף",
    male_second_instance: "ואני עייף",
  },
  {
    id: 42,
    name: "כבדות",
    emotion_category_id: 10,
    female_first_instance: "אני מרגישה כבדות",
    female_second_instance: "ואני מרגישה כבדות",
    male_first_instance: "אני מרגיש כבדות",
    male_second_instance: "ואני מרגיש כבדות",
  },
  {
    id: 43,
    name: "אדישות",
    emotion_category_id: 10,
    female_first_instance: "אני אדישה",
    female_second_instance: "ואני אדישה",
    male_first_instance: "אני אדיש",
    male_second_instance: "ואני אדיש",
  },
  {
    id: 44,
    name: "שעמום",
    emotion_category_id: 10,
    female_first_instance: "אני משועממת",
    female_second_instance: "ואני משועממת",
    male_first_instance: "אני משועמם",
    male_second_instance: "ואני משועמם",
  },
  {
    id: 45,
    name: "חולשה",
    emotion_category_id: 10,
    female_first_instance: "אני מרגישה חולשה",
    female_second_instance: "ואני מרגישה חולשה",
    male_first_instance: "אני מרגיש חולשה",
    male_second_instance: "ואני מרגיש חולשה",
  },
  {
    id: 46,
    name: "ריקנות",
    emotion_category_id: 10,
    female_first_instance: "אני מרגישה ריקנות",
    female_second_instance: "ואני מרגישה ריקנות",
    male_first_instance: "אני מרגיש ריקנות",
    male_second_instance: "ואני מרגיש ריקנות",
  },
  {
    id: 47,
    name: "בלבול",
    emotion_category_id: 11,
    female_first_instance: "אני מבולבלת",
    female_second_instance: "ואני מבולבלת",
    male_first_instance: "אני מבולבל",
    male_second_instance: "ואני מבולבל",
  },
  {
    id: 48,
    name: "זעזוע",
    emotion_category_id: 11,
    female_first_instance: "אני מזועזעת",
    female_second_instance: "ואני מזועזעת",
    male_first_instance: "אני מזועזע",
    male_second_instance: "ואני מזועזע",
  },
  {
    id: 49,
    name: "חוסר נוחות",
    emotion_category_id: 11,
    female_first_instance: "אני מרגישה לא בנוח",
    female_second_instance: "ואני מרגישה לא בנוח",
    male_first_instance: "אני מרגיש לא בנוח",
    male_second_instance: "ואני מרגיש לא בנוח",
  },
  {
    id: 50,
    name: "הפתעה",
    emotion_category_id: 12,
    female_first_instance: "אני מופתעת",
    female_second_instance: "ואני מופתעת",
    male_first_instance: "אני מופתע",
    male_second_instance: "ואני מופתע",
  },
  {
    id: 51,
    name: "הלם",
    emotion_category_id: 12,
    female_first_instance: "אני בהלם",
    female_second_instance: "ואני בהלם",
    male_first_instance: "אני בהלם",
    male_second_instance: "ואני בהלם",
  },
  {
    id: 52,
    name: "סערת רגשות",
    emotion_category_id: 12,
    female_first_instance: "אני בסערת רגשות",
    female_second_instance: "ואני בסערת רגשות",
    male_first_instance: "אני בסערת רגשות",
    male_second_instance: "ואני בסערת רגשות",
  },
  {
    id: 53,
    name: "ציפיה",
    emotion_category_id: 13,
    female_first_instance: "אני בתחושת ציפיה",
    female_second_instance: "ואני בתחושת ציפיה",
    male_first_instance: "אני בתחושת ציפיה",
    male_second_instance: "ואני בתחושת ציפיה",
  },
  {
    id: 54,
    name: "רצון",
    emotion_category_id: 13,
    female_first_instance: "יש לי איזשהו רצון",
    female_second_instance: "ויש לי איזשהו רצון",
    male_first_instance: "יש לי איזשהו רצון",
    male_second_instance: "ויש לי איזשהו רצון",
  },
  {
    id: 55,
    name: "חשק",
    emotion_category_id: 13,
    female_first_instance: "יש לי איזשהו חשק",
    female_second_instance: "ויש לי איזשהו חשק",
    male_first_instance: "יש לי איזשהו חשק",
    male_second_instance: "ויש לי איזשהו חשק",
  },
  {
    id: 56,
    name: "ספק",
    emotion_category_id: 14,
    female_first_instance: "יש לי ספקות",
    female_second_instance: "ויש לי ספקות",
    male_first_instance: "יש לי ספקות",
    male_second_instance: "ויש לי ספקות",
  },
  {
    id: 57,
    name: "חוסר ודאות",
    emotion_category_id: 14,
    female_first_instance: "אני בתחושת חוסר ודאות",
    female_second_instance: "ואני בתחושת חוסר ודאות",
    male_first_instance: "אני בתחושת חוסר ודאות",
    male_second_instance: "ואני בתחושת חוסר ודאות",
  },
  {
    id: 58,
    name: "חשדנות",
    emotion_category_id: 14,
    female_first_instance: "אני חשדנית",
    female_second_instance: "ואני חשדנית",
    male_first_instance: "אני חשדן",
    male_second_instance: "ואני חשדן",
  },
  {
    id: 59,
    name: "היסוס",
    emotion_category_id: 14,
    female_first_instance: "אני מהוססת",
    female_second_instance: "ואני מהוססת",
    male_first_instance: "אני מהוסס",
    male_second_instance: "ואני מהוסס",
  },
  {
    id: 60,
    name: "פסימיות",
    emotion_category_id: 15,
    female_first_instance: "אני פסימית",
    female_second_instance: "ואני פסימית",
    male_first_instance: "אני פסימי",
    male_second_instance: "ואני פסימי",
  },
  {
    id: 61,
    name: "ייאוש",
    emotion_category_id: 15,
    female_first_instance: "אני מיואשת",
    female_second_instance: "ואני מיואשת",
    male_first_instance: "אני מיואש",
    male_second_instance: "ואני מיואש",
  },
  {
    id: 62,
    name: "חוסר אונים",
    emotion_category_id: 15,
    female_first_instance: "אני מרגישה חסרת אונים",
    female_second_instance: "ואני מרגישה חסרת אונים",
    male_first_instance: "אני מרגיש חסר אונים",
    male_second_instance: "ואני מרגיש חסר אונים",
  },
  {
    id: 63,
    name: "בדידות",
    emotion_category_id: 16,
    female_first_instance: "אני מרגישה בדידות",
    female_second_instance: "ואני בודדה",
    male_first_instance: "אני מרגיש בדידות",
    male_second_instance: "ואני בודד",
  },
  {
    id: 64,
    name: "געגוע",
    emotion_category_id: 16,
    female_first_instance: "אני מתגעגעת",
    female_second_instance: "ואני מתגעגעת",
    male_first_instance: "אני מתגעגע",
    male_second_instance: "ואני מתגעגע",
  },
  {
    id: 65,
    name: "חוסר שייכות",
    emotion_category_id: 16,
    female_first_instance: "אני מרגישה לא שייכת",
    female_second_instance: "ואני מרגישה לא שייכת",
    male_first_instance: "אני מרגיש לא שייך",
    male_second_instance: "ואני מרגיש לא שייך",
  },
  {
    id: 66,
    name: "סבל",
    emotion_category_id: 17,
    female_first_instance: "אני סובלת",
    female_second_instance: "ואני סובלת",
    male_first_instance: "אני סובל",
    male_second_instance: "ואני סובל",
  },
  {
    id: 67,
    name: "קנאה",
    emotion_category_id: 17,
    female_first_instance: "אני מקנאה",
    female_second_instance: "ואני מקנאה",
    male_first_instance: "אני מקנא",
    male_second_instance: "ואני מקנא",
  },
  {
    id: 68,
    name: "בוז",
    emotion_category_id: 17,
    female_first_instance: "אני מרגישה בוז",
    female_second_instance: "ואני מרגישה בוז",
    male_first_instance: "אני מרגיש בוז",
    male_second_instance: "ואני מרגיש בוז",
  },
];
