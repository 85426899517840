import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BadFeelings from "../../components/feeling/BadFeelings";
import FeelingModal from "../../components/feeling/FeelingModal";
import Header from "../../components/layout/Header";

const BadFeeling = () => {
  const navigate = useNavigate();
  const selfConnData = JSON.parse(sessionStorage.getItem("selfConnectData"));
  useEffect(() => {
    if (selfConnData?.situation_desc === undefined) {
      navigate("/home");
    }

    // Update the content attribute to set initial scale to 1.0
    var metaViewport = document.querySelector('meta[name="viewport"]');
    metaViewport.setAttribute(
      "content",
      "width=device-width, initial-scale=1, maximum-scale=1"
    );
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const resetScrollPosition = () => {
      if (document.documentElement.scrollTop > 5) {
        document.documentElement.scrollTop = 0;
      }
    };

    setTimeout(() => {
      scrollToTop();
      resetScrollPosition();
    }, 10);
  }, []);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  return (
    <>
      <div className="bg-light_e">
        <Header text="רגשות לא נעימים" />

        <ul className="nav nav-pills mt-2 mb-3 justify-content-center pt-90 nav-fix">
          <li className="nav-item">
            <Link to="/good-feeling" className="line">
              <button
                className="nav-link custom_tab_button active"
                type="button"
              >
                <img src="assets/images/good_feel_white.svg" alt="Feeling" />{" "}
                רגשות <br />
                נעימים
              </button>
            </Link>
          </li>

          <li className="nav-item">
            <button className="nav-link custom_tab_button" type="button">
              <img src="assets/images/bad_feel_blue.svg" alt="Feeling" /> רגשות
              לא נעימים
            </button>
          </li>
        </ul>

        <div className="e3_header_content" style={{ paddingTop: 200 }}>
          <div className="container px-4">
            <h5>
              כעת נתמקד ברגשות שלך.
              <br />
              בחר
              {userGender === "male" ? " " : "י "}
              את כל הרגשות שאת
              {userGender === "female" ? " " : "ה "}
              מרגיש
              {userGender === "male" ? " " : "ה "}
              עכשיו (ללא הגבלה),
              {userGender === "male" ? " עבור " : " עברי "}
              בין רגשות נעימים ולא נעימים באמצעות הכפתורים מעלה.
            </h5>
            <a
              href="#"
              className="line"
              data-bs-toggle="modal"
              data-bs-target="#custom_modal"
            >
              <button className="btn e3_custom_btn">
                {userGender === "male" ? "לחץ " : "לחצי "}
                לעזרה בהבנת הרגשות
              </button>
            </a>
          </div>
        </div>

        <BadFeelings />
      </div>

      {/* Modal Popup */}
      <FeelingModal />
      {/* Modal Popup */}
    </>
  );
};

export default BadFeeling;
