import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import Eye from "../../components/svg/Eye";

const TryPartnerDeepNeeds = () => {
  const navigate = useNavigate();
  const judgementData = JSON.parse(sessionStorage.getItem("judgement"));
  useEffect(() => {
    if (
      judgementData?.partner_needs === undefined ||
      judgementData?.partner_needs === ""
    ) {
      navigate("/home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const name = judgementData?.ask_from_partner_name;

  const handleSubmit = (bool) => {
    if (bool === false) {
      delete judgementData?.partner_deeper_needs;
    }

    const newData = {
      ...judgementData,
      does_want_to_check_partner_deeper_needs: bool,
    };
    sessionStorage.setItem("judgement", JSON.stringify(newData));
    if (bool) {
      navigate("/partner-deep-needs");
    } else {
      navigate("/partner-needs-summary");
    }
  };

  return (
    <>
      <div className="bg-primary_e">
        <Header extraClass="border-bottom" />
        <div className="c4b_5_content px-3 text-center pt-80">
          <div className="container pt-5">
            <Eye />
            <h5 className="c4b_5_title">
              רוצה לנסות לבדוק
              <br />
              אם יש ל{name} צרכים יותר
              <br />
              עמוקים שלא מקבלים מענה
              <br />
              במקרה הזה?
            </h5>
            {/* Secondary Button Design */}
            <div className="line">
              <RoundBtn
                extraClass="mt-4 text-regular bg-secondary-button fs-18"
                text="כן, רוצה להתעמק"
                onClick={() => handleSubmit(true)}
              />
            </div>
            {/* Secondary Button Design */}
            <div className="line">
              <RoundBtn
                extraClass="mt-4 text-regular bg-secondary-button fs-18"
                text="לא, רוצה להמשיך"
                onClick={() => handleSubmit(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TryPartnerDeepNeeds;
