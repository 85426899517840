import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";

const GuessFeelingsOf = () => {
  const userRequest = JSON.parse(sessionStorage.getItem("request"));
  const [feelingOf, setFeelingOf] = useState(
    userRequest?.partner_needs_and_feelings || ""
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (userRequest?.needs === undefined) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  let partnerGender;
  if (
    ["kid_m", "dad", "adult_m", "other_m", "brother"].includes(
      userRequest?.ask_from_partner_roll
    )
  ) {
    partnerGender = "male";
  } else {
    partnerGender = "female";
  }

  const submitNeedFeelings = () => {
    const newData = { ...userRequest, partner_needs_and_feelings: feelingOf };
    sessionStorage.setItem("request", JSON.stringify(newData));
    if (feelingOf.length) {
      navigate("/drafting-request");
    }
  };

  return (
    <>
      <div className="bg-light_e">
        <Header
          extraClass="bg-red"
          text={
            <>
              הצרכים והרגשות של
              {partnerGender === "male" ? "ו" : "ה"}
            </>
          }
        />

        <div className="a19_content px-3 pt-100">
          <div className="container">
            <p className="a11_disc_para_content">
              נס
              {userGender === "male" ? "ה " : "י "}
              בבקשה לנחש מה {userRequest?.ask_from_partner_name} מרגיש
              {partnerGender === "male" ? " " : "ה "}
              וצרי
              {partnerGender === "male" ? "ך " : "כה "}
              בסיטואציה הזאת:
            </p>
            {/* Textarea Input Design */}
            <div className="input-group">
              <textarea
                className="form-control input_shadow custom_textarea"
                aria-label="With textarea"
                rows={12}
                value={feelingOf}
                onChange={(e) => setFeelingOf(e.target.value)}
              />
            </div>

            <div className="line">
              <RoundBtn
                extraClass={`mt-5 mb-2 text-regular 
                  ${feelingOf.length ? "bg-primary-button" : "primary-disable"}
                `}
                text="להמשיך"
                onClick={submitNeedFeelings}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuessFeelingsOf;
