import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";

const MyNeedSitu = () => {
  const judgementData = JSON.parse(sessionStorage.getItem("judgement"));
  const [feelingDesc, setFeelingDesc] = useState(judgementData?.feelings_after_needs || "");
  const navigate = useNavigate();

  useEffect(() => {
    if (judgementData?.does_want_to_check_deeper_needs === undefined) {
      navigate("/home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  
  const needsStr = judgementData?.needs?.replace(":", ", ");

  let deepNeeds;
  deepNeeds = judgementData?.deeper_needs?.replaceAll(":", ", ");
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  const submitNeedFeelings = () => {
    let newData;
    if (feelingDesc.length) {
      newData = { ...judgementData, feelings_after_needs: feelingDesc };
    } else {
      newData = { ...judgementData };
    }
    sessionStorage.setItem("judgement", JSON.stringify(newData));
    navigate("/my-need-submit");
  };

  return (
    <>
      <div className="bg-light_e">
        <Header />

        <div className="c3-content px-3 pt-90">
          <div className="container">
            <div className="c4b_8_details">
              <h6>אלה הצרכים שלך בסיטואציה</h6>
              <p>{needsStr}</p>
            </div>

            {deepNeeds && deepNeeds.length > 0 && (
              <div className="c4b_8_details mt-4">
                <h6>ואלה הצרכים שיושבים עמוק יותר</h6>
                <p>{deepNeeds}</p>
              </div>
            )}

            <div className="c4b_8_details mt-4">
              <h6>
                תרצ
                {userGender === "male" ? "ה" : "י"} לכתוב בכמה מילים מה את
                {userGender === "male" ? "ה " : " "}
                מרגיש
                {userGender === "male" ? " " : "ה "}
                עכשיו?
              </h6>
              <h6>האם משהו בהרגשה השתנה?</h6>
            </div>
            {/* Textarea Input Design */}
            <div className="input-group mt-2">
              <textarea
                className="form-control input_shadow custom_textarea"
                aria-label="With textarea"
                rows={12}
                value={feelingDesc}
                onChange={(e) => setFeelingDesc(e.target.value)}
              />
            </div>

            <div className="pb-2">
              <RoundBtn
                extraClass={`mt-5 text-regular bg-primary-button`}
                text="להמשיך"
                onClick={submitNeedFeelings}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyNeedSitu;
