import { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/layout/Header";

const FeelNeedText = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="bg-primary_e">
        <Header extraClass="border-bottom" />
        {/* content section start */}
        <div className="b1_content pt-100">
          <div className="container px-5 pt-100">
            <h6 className="b1_content_disc">
              לפניך רשימות של רגשות וצרכים אנושיים.
            </h6>
            <br />
            <h6 className="b1_content_disc">
              הכרות עם הרגשות והצרכים עשויה לעזור לנו להתחבר לעצמנו ולאנשים
              נוספים בחיינו.
            </h6>
          </div>

          {/* Tab section design start */}
          <ul
            className="nav nav-pills mt-5 d-flex justify-content-center px-1"
          >
            <Link
              to="/all-feelings-needs"
              state={{ data: "good_feel", text: "רגשות נעימים" }}
              className="line"
            >
              <li className="nav-item">
                <button
                  className={`nav-link custom_tab_button active shadow-none`}
                  type="button"
                >
                  <img src={`assets/images/good_feel_white.svg`} alt="Smile" />
                  <p className="px-0 nav_text mt-1">רגשות נעימים</p>
                </button>
              </li>
            </Link>

            <Link
              to="/all-feelings-needs"
              state={{ data: "bad_feel", text: "רגשות לא נעימים" }}
              className="line"
            >
              <li className="nav-item">
                <button
                  className={`nav-link custom_tab_button active shadow-none`}
                  type="button"
                >
                  <img src={`assets/images/bad_feel_white.svg`} alt="Sad" />
                  <p className="px-0 nav_text mt-1"> רגשות לא נעימים</p>
                </button>
              </li>
            </Link>
            <Link
              to="/all-feelings-needs"
              state={{ data: "need", text: "צרכים" }}
              className="line"
            >
              <li className="nav-item">
                <button
                  className={`nav-link custom_tab_button active shadow-none`}
                  type="button"
                >
                  <img src={`assets/images/neutral_white.svg`} alt="Neutral" style={{marginTop:10}} />
                  <p className="mt-2"> צרכים</p>
                </button>
              </li>
            </Link>
          </ul>
          {/* Tab section design end */}
        </div>
        {/* content section end */}
      </div>
    </>
  );
};

export default FeelNeedText;
