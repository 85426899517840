const KidFL = (props) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <g clipPath="url(#clip0_4680_6644)">
        <path d="M0 0H100V100H0z"></path>
        <g>
          <g>
            <mask
              id="mask0_4680_6644"
              style={{ maskType: "alpha" }}
              width="100"
              height="100"
              x="0"
              y="0"
              maskUnits="userSpaceOnUse"
            >
              <g>
                <circle cx="50" cy="50" r="50" fill="#DDE1F0"></circle>
                <mask
                  style={{ maskType: "alpha" }}
                  width="100"
                  height="100"
                  x="0"
                  y="0"
                  maskUnits="userSpaceOnUse"
                >
                  <g>
                    <circle cx="50" cy="50" r="50" fill="#DDE1F0"></circle>
                    <circle
                      cx="50"
                      cy="50"
                      r="49.5"
                      stroke="#000"
                      strokeOpacity="0.5"
                    ></circle>
                  </g>
                </mask>
              </g>
            </mask>
            <g mask="url(#mask0_4680_6644)">
              <g>
                <g>
                  <g>
                    <path
                      fill="#3869E5"
                      d="M41.457 84.423h15.062c.93 0 1.821.367 2.479 1.02a3.47 3.47 0 011.027 2.462v17.109H37.95V87.903c0-.923.37-1.809 1.028-2.461a3.518 3.518 0 012.478-1.02z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M42.235 84.198h1.615a.326.326 0 01.325.323v20.271h-2.263v-20.27a.322.322 0 01.323-.324z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M54.176 84.198h1.614a.327.327 0 01.325.323v20.271h-2.263v-20.27a.322.322 0 01.324-.324z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M41.922 91.537h-3.985v14.614h3.985V91.537z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M56.144 106.148h3.881V91.535h-3.88v14.613z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M44.177 77.23h9.687v9.32a4.781 4.781 0 01-1.415 3.403 4.845 4.845 0 01-3.423 1.412 4.86 4.86 0 01-3.425-1.41 4.793 4.793 0 01-1.418-3.4v-9.321l-.006-.004z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M28.72 65.777a4.061 4.061 0 000-5.77 4.128 4.128 0 00-5.81 0 4.06 4.06 0 000 5.77 4.128 4.128 0 005.81 0z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M72.723 66.95c2.252-.274 3.855-2.31 3.579-4.547-.276-2.237-2.325-3.828-4.577-3.554-2.253.274-3.855 2.31-3.58 4.546.276 2.237 2.326 3.828 4.578 3.554z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M20.282 26.687c6.427 0 11.637-5.175 11.637-11.559 0-6.383-5.21-11.558-11.637-11.558-6.427 0-11.637 5.175-11.637 11.559 0 6.383 5.21 11.558 11.637 11.558z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M27.515 30.436l-2.392-2.374a5.74 5.74 0 01-1.687-4.06 5.74 5.74 0 011.687-4.061 5.82 5.82 0 014.09-1.676 5.82 5.82 0 014.09 1.676l2.39 2.373a5.74 5.74 0 011.686 4.061 5.74 5.74 0 01-1.686 4.061 5.817 5.817 0 01-4.09 1.677 5.816 5.816 0 01-4.088-1.677z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M78.06 26.687c6.427 0 11.638-5.175 11.638-11.559 0-6.383-5.21-11.558-11.638-11.558-6.427 0-11.637 5.175-11.637 11.559 0 6.383 5.21 11.558 11.637 11.558z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M70.825 30.436l2.392-2.374a5.74 5.74 0 001.686-4.06 5.74 5.74 0 00-1.686-4.061 5.82 5.82 0 00-4.09-1.676 5.82 5.82 0 00-4.09 1.676l-2.39 2.373a5.736 5.736 0 00-1.688 4.061c0 1.522.607 2.982 1.688 4.061a5.816 5.816 0 004.089 1.677 5.816 5.816 0 004.088-1.677z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M69.092 61.912c11.103-11.046 11.103-28.956 0-40.002-11.103-11.046-29.105-11.046-40.209 0-11.103 11.046-11.103 28.956 0 40.002 11.104 11.046 29.106 11.046 40.209 0z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M71.82 47.972c0-12.506-10.208-22.644-22.8-22.644s-22.8 10.138-22.8 22.644v11.312c0 12.506 10.208 22.644 22.8 22.644s22.8-10.138 22.8-22.644V47.972z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M73.679 48.43c0-14.117-11.8-25.67-25.993-24.917a24.765 24.765 0 00-16.56 7.62 24.436 24.436 0 00-6.769 16.833c10.89-2.519 19.515-7.123 24.662-14.935 4.901 8.899 13.387 13.73 24.66 15.399z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M35.139 60.013c0 .283-.417.513-.94.513-.524 0-.94-.23-.94-.513 0-.284.418-.512.94-.512.52 0 .94.23.94.512z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M38.081 60.013c0 .283-.417.513-.94.513-.524 0-.94-.23-.94-.513 0-.284.416-.512.94-.512.523 0 .94.23.94.512z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M36.72 61.363c0 .284-.417.514-.94.514-.524 0-.937-.238-.937-.514 0-.275.417-.513.938-.513.522 0 .939.23.939.513z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M39.693 61.363c0 .284-.418.514-.941.514-.524 0-.939-.23-.939-.514 0-.283.418-.513.939-.513.521 0 .94.23.94.513z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M41.023 60.013c0 .283-.418.513-.939.513-.521 0-.94-.23-.94-.513 0-.284.417-.512.94-.512.524 0 .939.23.939.512z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M59.347 60.013c0 .283-.417.513-.94.513-.524 0-.94-.23-.94-.513 0-.284.418-.512.94-.512.52 0 .94.23.94.512z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M62.292 60.013c0 .283-.417.513-.94.513-.524 0-.939-.23-.939-.513 0-.284.417-.512.939-.512.521 0 .94.23.94.512z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M60.928 61.363c0 .284-.417.514-.939.514-.521 0-.94-.23-.94-.514 0-.283.417-.513.94-.513.524 0 .939.23.939.513z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M63.901 61.363c0 .284-.417.514-.94.514-.523 0-.938-.23-.938-.514 0-.283.417-.513.938-.513.521 0 .94.23.94.513z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M65.233 60.013c0 .283-.417.513-.94.513-.524 0-.941-.23-.941-.513 0-.284.417-.512.94-.512.524 0 .94.23.94.512z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M53.451 63.986c.4-2.43-1.26-4.723-3.707-5.12-2.448-.397-4.756 1.251-5.156 3.682-.4 2.43 1.26 4.723 3.708 5.12 2.447.398 4.755-1.251 5.155-3.682z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M41.215 55.858a.933.933 0 01-.657-.27.921.921 0 01-.273-.654 1.943 1.943 0 00-.596-1.337 1.97 1.97 0 00-2.73 0c-.366.352-.58.832-.597 1.337 0 .245-.098.48-.272.654a.933.933 0 01-1.316 0 .92.92 0 01-.272-.654 3.774 3.774 0 011.087-2.742 3.823 3.823 0 012.735-1.143 3.845 3.845 0 012.734 1.143 3.794 3.794 0 011.088 2.742.92.92 0 01-.273.654.933.933 0 01-.658.27z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M61.34 55.858a.933.933 0 01-.657-.27.921.921 0 01-.273-.654 1.943 1.943 0 00-.596-1.337 1.97 1.97 0 00-2.73 0c-.366.352-.58.832-.597 1.337 0 .245-.098.48-.272.654a.933.933 0 01-1.316 0 .92.92 0 01-.272-.654 3.774 3.774 0 011.087-2.742 3.823 3.823 0 012.735-1.143 3.845 3.845 0 012.734 1.143 3.794 3.794 0 011.088 2.742.915.915 0 01-.272.654.93.93 0 01-.659.27z"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4680_6644">
          <path fill="#fff" d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
    </svg>
);

export default KidFL;
