const KidMBlue = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="100"
  height="100"
  fill="none"
  viewBox="0 0 100 100"
>
  <g clipPath="url(#clip0_4680_6216)">
    <path d="M0 0H100V100H0z"></path>
    <g filter="url(#filter0_ddii_4680_6216)">
      <g>
        <ellipse
          cx="49.552"
          cy="49.5"
          fill="#fff"
          rx="42.448"
          ry="42.5"
        ></ellipse>
        <g>
          <mask
            id="mask0_4680_6216"
            style={{ maskType: "alpha" }}
            width="84"
            height="85"
            x="7"
            y="7"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#C4C4C4"
              stroke="#000"
              d="M89.87 49.5c0 23.22-17.279 42-40.422 42C26.282 91.5 7.5 72.697 7.5 49.5c0-23.22 17.278-42 40.421-42 23.167 0 41.948 18.803 41.948 42z"
            ></path>
          </mask>
          <g mask="url(#mask0_4680_6216)">
            <g>
              <g>
                <g>
                  <path
                    fill="#3869E5"
                    d="M35.145 21.673h-.002c-4.206 0-7.616 3.39-7.616 7.573v29.35c0 4.182 3.41 7.573 7.616 7.573h.002c4.206 0 7.615-3.391 7.615-7.573v-29.35c0-4.183-3.41-7.573-7.615-7.573z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M70.644 24.874c0-8.92-7.272-16.152-16.243-16.152-8.97 0-16.243 7.231-16.243 16.152v29.76c0 8.92 7.272 16.151 16.243 16.151 8.97 0 16.243-7.231 16.243-16.152v-29.76z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M26.413 65.067c2.99 0 5.413-2.41 5.413-5.382 0-2.973-2.423-5.383-5.413-5.383S21 56.712 21 59.685c0 2.972 2.424 5.382 5.413 5.382z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M71.662 65.067c2.99 0 5.413-2.41 5.413-5.382 0-2.973-2.423-5.383-5.413-5.383s-5.413 2.41-5.413 5.383c0 2.972 2.424 5.382 5.413 5.382z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M53.718 72.928h-8.936v16.33h8.936v-16.33z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M45.636 82.041h7.14c1.28 0 2.506.505 3.41 1.405.905.9 1.413 2.119 1.413 3.39v10.882H40.814V86.837c0-1.272.508-2.492 1.412-3.391a4.837 4.837 0 013.41-1.405z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M49.038 76.758c11.938 0 21.616-10.81 21.616-24.146 0-13.335-9.678-24.145-21.616-24.145-11.938 0-21.615 10.81-21.615 24.145 0 13.336 9.677 24.146 21.615 24.146z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M48.831 62.965c-2.01 0-3.984-.527-5.723-1.528a2.909 2.909 0 01-1.376-1.77 2.882 2.882 0 01.3-2.215 2.903 2.903 0 011.798-1.344 2.93 2.93 0 012.224.328c1.866 1.086 3.996.818 4.818.207a2.921 2.921 0 013.233-.175 2.907 2.907 0 011.338 1.775 2.883 2.883 0 01-1.075 3.038c-1.504 1.118-3.482 1.684-5.537 1.684z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M45.066 88.394h-4.252v23.61h4.252v-23.61z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M53.349 112.003H57.6v-23.61h-4.252v23.61z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M42.261 41.062l-3.022 1.106a1.428 1.428 0 01-1.082-.044 1.416 1.416 0 01-.736-.79 1.405 1.405 0 01.047-1.08c.159-.339.447-.602.8-.73l3.02-1.107a1.428 1.428 0 011.083.044c.34.157.605.441.736.791.129.352.112.74-.046 1.08-.159.339-.447.602-.8.73z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M56.206 41.062l3.02 1.106a1.43 1.43 0 001.083-.044c.341-.157.606-.44.738-.79.128-.351.111-.738-.046-1.077a1.419 1.419 0 00-.795-.732l-3.02-1.107a1.43 1.43 0 00-1.085.042 1.417 1.417 0 00-.739.792c-.129.351-.112.74.046 1.078.158.34.445.603.798.732z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M45.715 51.49a.646.646 0 01-.595-.395.634.634 0 01-.049-.245 4.768 4.768 0 00-1.408-3.38 4.822 4.822 0 00-3.4-1.401 4.822 4.822 0 00-3.4 1.4 4.768 4.768 0 00-1.409 3.381.64.64 0 01-.644.608.647.647 0 01-.644-.608 6.035 6.035 0 011.748-4.353 6.125 6.125 0 018.695 0 6.06 6.06 0 011.749 4.353.634.634 0 01-.397.592.647.647 0 01-.246.048z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M62.585 51.49a.646.646 0 01-.595-.395.636.636 0 01-.049-.245 4.76 4.76 0 00-1.408-3.38 4.81 4.81 0 00-3.4-1.401 4.832 4.832 0 00-3.4 1.4 4.777 4.777 0 00-1.409 3.381.64.64 0 01-.644.608.647.647 0 01-.644-.608 6.033 6.033 0 011.748-4.353 6.125 6.125 0 018.695 0 6.06 6.06 0 011.749 4.353.632.632 0 01-.397.592.647.647 0 01-.246.048z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
  <defs>
    <filter
      id="filter0_ddii_4680_6216"
      width="102.896"
      height="103"
      x="-1.896"
      y="-2"
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="5" dy="5"></feOffset>
      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"></feColorMatrix>
      <feBlend
        in2="BackgroundImageFix"
        result="effect1_dropShadow_4680_6216"
      ></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="-5" dy="-5"></feOffset>
      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 0.92549 0 0 0 0 0.937255 0 0 0 0 0.941176 0 0 0 0.25 0"></feColorMatrix>
      <feBlend
        in2="effect1_dropShadow_4680_6216"
        result="effect2_dropShadow_4680_6216"
      ></feBlend>
      <feBlend
        in="SourceGraphic"
        in2="effect2_dropShadow_4680_6216"
        result="shape"
      ></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="-1" dy="-2"></feOffset>
      <feGaussianBlur stdDeviation="13"></feGaussianBlur>
      <feComposite
        in2="hardAlpha"
        k2="-1"
        k3="1"
        operator="arithmetic"
      ></feComposite>
      <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
      <feBlend in2="shape" result="effect3_innerShadow_4680_6216"></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="1" dy="-3"></feOffset>
      <feGaussianBlur stdDeviation="13.5"></feGaussianBlur>
      <feComposite
        in2="hardAlpha"
        k2="-1"
        k3="1"
        operator="arithmetic"
      ></feComposite>
      <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
      <feBlend
        in2="effect3_innerShadow_4680_6216"
        result="effect4_innerShadow_4680_6216"
      ></feBlend>
    </filter>
    <clipPath id="clip0_4680_6216">
      <path fill="#fff" d="M0 0H100V100H0z"></path>
    </clipPath>
  </defs>
</svg>
);

export default KidMBlue;
