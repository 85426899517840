const MomL = (props) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <g clipPath="url(#clip0_4680_6380)">
        <path d="M0 0H100V100H0z"></path>
        <g>
          <g>
            <mask
              id="mask0_4680_6380"
              style={{ maskType: "alpha" }}
              width="100"
              height="100"
              x="0"
              y="0"
              maskUnits="userSpaceOnUse"
            >
              <g>
                <circle cx="50" cy="50" r="50" fill="#DDE1F0"></circle>
                <circle
                  cx="50"
                  cy="50"
                  r="49.5"
                  stroke="#000"
                  strokeOpacity="0.5"
                ></circle>
              </g>
            </mask>
            <g mask="url(#mask0_4680_6380)">
              <g>
                <g>
                  <g>
                    <path
                      fill="#3869E5"
                      d="M80.294 33.134v37.413c0 14.8-12.194 26.909-27.095 26.909h-5.646c-14.9 0-27.093-12.11-27.093-26.91V33.135a26.902 26.902 0 017.96-19.003 27.274 27.274 0 0119.133-7.906H53.2c14.901 0 27.096 12.109 27.096 26.909z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M70.049 139.879v-23.098c0-10.284-8.474-18.7-18.828-18.7h-1.685c-10.357 0-18.83 8.416-18.83 18.7v23.098h39.343z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M64.232 101.054V87.907a13.76 13.76 0 00-4.071-9.721 13.95 13.95 0 00-9.788-4.045 13.95 13.95 0 00-9.788 4.044 13.76 13.76 0 00-4.072 9.722v13.147h27.719z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M50.378 83.034a5.898 5.898 0 01-4.156-1.71 5.817 5.817 0 01-1.721-4.128v-5.868c0-1.547.62-3.032 1.722-4.126a5.91 5.91 0 018.31 0 5.802 5.802 0 011.72 4.126v5.86a5.802 5.802 0 01-1.717 4.133 5.91 5.91 0 01-4.158 1.713z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M50.377 10.475h-.002c-15.275 0-27.658 12.298-27.658 27.47v5.777c0 15.172 12.383 27.47 27.658 27.47h.002c15.276 0 27.66-12.298 27.66-27.47v-5.777c0-15.172-12.384-27.47-27.66-27.47z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M25.745 51.599a4.925 4.925 0 000-6.998 5.006 5.006 0 00-7.046 0 4.925 4.925 0 000 6.998 5.006 5.006 0 007.046 0z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M83.422 49.025c.518-2.684-1.253-5.277-3.956-5.791-2.702-.515-5.313 1.244-5.83 3.928-.519 2.685 1.252 5.278 3.955 5.792 2.702.514 5.313-1.245 5.83-3.929z"
                    ></path>
                    <path
                      fill="#56CCF2"
                      d="M32.985 53.535c0 .343-.51.62-1.14.62-.629 0-1.14-.277-1.14-.62 0-.344.511-.622 1.14-.622.63 0 1.14.278 1.14.622z"
                    ></path>
                    <path
                      fill="#56CCF2"
                      d="M36.554 53.535c0 .343-.511.62-1.14.62-.63 0-1.14-.277-1.14-.62 0-.344.51-.622 1.14-.622.629 0 1.14.278 1.14.622z"
                    ></path>
                    <path
                      fill="#56CCF2"
                      d="M34.904 55.17c0 .343-.511.62-1.141.62s-1.139-.277-1.139-.62c0-.345.511-.622 1.139-.622s1.14.277 1.14.621z"
                    ></path>
                    <path
                      fill="#56CCF2"
                      d="M38.51 55.17c0 .343-.512.62-1.142.62-.63 0-1.14-.277-1.14-.62 0-.345.51-.622 1.14-.622.63 0 1.141.277 1.141.621z"
                    ></path>
                    <path
                      fill="#56CCF2"
                      d="M40.133 53.535c0 .343-.509.62-1.139.62s-1.14-.277-1.14-.62c0-.344.51-.622 1.14-.622.63 0 1.139.278 1.139.622z"
                    ></path>
                    <path
                      fill="#56CCF2"
                      d="M62.355 53.535c0 .343-.511.62-1.141.62s-1.14-.277-1.14-.62c0-.344.51-.622 1.14-.622.63 0 1.14.278 1.14.622z"
                    ></path>
                    <path
                      fill="#56CCF2"
                      d="M65.923 53.535c0 .343-.51.62-1.139.62-.627 0-1.14-.277-1.14-.62 0-.344.51-.622 1.14-.622.63 0 1.14.278 1.14.622z"
                    ></path>
                    <path
                      fill="#56CCF2"
                      d="M64.272 55.17c0 .343-.51.62-1.139.62-.63 0-1.14-.277-1.14-.62 0-.345.51-.622 1.14-.622.63 0 1.139.277 1.139.621z"
                    ></path>
                    <path
                      fill="#56CCF2"
                      d="M67.871 55.17c0 .343-.51.62-1.14.62-.63 0-1.14-.277-1.14-.62 0-.345.51-.622 1.14-.622.63 0 1.14.277 1.14.621z"
                    ></path>
                    <path
                      fill="#56CCF2"
                      d="M69.493 53.535c0 .343-.51.62-1.14.62-.63 0-1.141-.277-1.141-.62 0-.344.51-.622 1.14-.622.63 0 1.141.278 1.141.622z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M37.368 49.549c1.27 0 2.3-1.023 2.3-2.285a2.293 2.293 0 00-2.3-2.285c-1.27 0-2.3 1.023-2.3 2.285a2.293 2.293 0 002.3 2.285z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M63.385 49.549c1.27 0 2.3-1.023 2.3-2.285a2.293 2.293 0 00-2.3-2.285c-1.27 0-2.3 1.023-2.3 2.285a2.293 2.293 0 002.3 2.285z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M46.657 51.082v5.645c.003.979.396 1.917 1.093 2.61a3.744 3.744 0 002.628 1.085 3.746 3.746 0 002.633-1.083 3.695 3.695 0 001.096-2.612v-5.645h-7.45z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M80.295 33.134v1.474a33.255 33.255 0 01-20.299 6.87 33.382 33.382 0 01-20.988-7.416 32.979 32.979 0 01-11.56-18.912 27.01 27.01 0 019.096-6.59 27.178 27.178 0 0111.01-2.335H53.2c14.9 0 27.095 12.109 27.095 26.909z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M42.159 90.9h-5.604v24.69h5.604V90.9z"
                    ></path>
                    <path
                      fill="#45CBF6"
                      d="M64.236 90.9h-5.604v24.69h5.604V90.9z"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4680_6380">
          <path fill="#fff" d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
    </svg>
);

export default MomL;
