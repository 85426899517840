import { createSlice } from "@reduxjs/toolkit";

const mostGFSlice = createSlice({
  name: "mostGF",
  initialState: [],
  reducers: {
    addRemoveMostGF(state, action) {
      if (state.includes(action.payload)) {
        return state.filter((x) => x !== action.payload);
      } else {
        state.push(action.payload);
      }
    },
    resetMostGFState(state) {
      return (state = []);
  }
  },
});

export const { addRemoveMostGF, resetMostGFState } = mostGFSlice.actions;

export default mostGFSlice.reducer;
