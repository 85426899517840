const RoundBtn = ({
  extraClass = "",
  text,
  onClick,
  type = "button",
  disabled = false,
  loading = false,
}) => {
  return (
    <button
      className={`btn custom_button ${extraClass}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {text}
      {loading && <div className="btn_loader"></div>}
    </button>
  );
};

export default RoundBtn;
