const MomBlue = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="100"
  height="100"
  fill="none"
  viewBox="0 0 100 100"
>
  <g clipPath="url(#clip0_4680_5994)">
    <path d="M0 0H100V100H0z"></path>
    <g filter="url(#filter0_ddii_4680_5994)">
      <circle cx="49.5" cy="49.5" r="42.5" fill="#fff"></circle>
      <g>
        <mask
          id="mask0_4680_5994"
          style={{ maskType: "alpha" }}
          width="85"
          height="85"
          x="7"
          y="7"
          maskUnits="userSpaceOnUse"
        >
          <g>
            <circle cx="49.5" cy="49.5" r="42.5" fill="#fff"></circle>
            <circle
              cx="49.5"
              cy="49.5"
              r="42"
              stroke="#000"
              strokeOpacity="0.5"
            ></circle>
          </g>
        </mask>
        <g mask="url(#mask0_4680_5994)">
          <g>
            <g>
              <g>
                <path
                  fill="#3869E5"
                  d="M75.25 35.164v31.8c0 12.58-10.364 22.874-23.03 22.874H47.42c-12.666 0-23.03-10.293-23.03-22.873V35.164a22.867 22.867 0 016.767-16.153 23.183 23.183 0 0116.263-6.72h4.799c12.666 0 23.03 10.293 23.03 22.873z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M66.542 125.898v-19.634c0-8.741-7.203-15.895-16.004-15.895h-1.432c-8.804 0-16.006 7.154-16.006 15.895v19.634h33.442z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M61.597 92.896V81.72c-.009-3.1-1.253-6.071-3.46-8.263a11.858 11.858 0 00-8.32-3.439 11.858 11.858 0 00-8.32 3.438 11.697 11.697 0 00-3.46 8.264v11.175h23.56z"
                ></path>
                <path
                  fill="#45CBF6"
                  d="M49.82 77.578a5.013 5.013 0 01-3.532-1.453 4.944 4.944 0 01-1.463-3.508v-4.988c0-1.316.527-2.577 1.464-3.508a5.022 5.022 0 017.063 0 4.958 4.958 0 011.463 3.508v4.98a4.933 4.933 0 01-1.46 3.513 4.997 4.997 0 01-3.534 1.456z"
                ></path>
                <path
                  fill="#45CBF6"
                  d="M49.821 15.904h-.002c-12.984 0-23.51 10.454-23.51 23.35v4.91c0 12.896 10.526 23.35 23.51 23.35h.002c12.984 0 23.51-10.454 23.51-23.35v-4.91c0-12.896-10.526-23.35-23.51-23.35z"
                ></path>
                <path
                  fill="#45CBF6"
                  d="M28.883 50.859a4.186 4.186 0 000-5.949 4.256 4.256 0 00-5.989 0 4.186 4.186 0 000 5.949 4.256 4.256 0 005.99 0z"
                ></path>
                <path
                  fill="#45CBF6"
                  d="M77.909 48.671c.44-2.281-1.065-4.485-3.362-4.922-2.297-.438-4.517 1.057-4.957 3.339-.44 2.281 1.065 4.485 3.362 4.922 2.297.438 4.517-1.057 4.957-3.339z"
                ></path>
                <path
                  fill="#56CCF2"
                  d="M35.037 52.505c0 .292-.432.528-.968.528-.535 0-.97-.236-.97-.528 0-.293.435-.528.97-.528.536 0 .968.236.968.528z"
                ></path>
                <path
                  fill="#56CCF2"
                  d="M38.071 52.505c0 .292-.434.528-.97.528-.535 0-.967-.236-.967-.528 0-.293.432-.528.968-.528.535 0 .97.236.97.528z"
                ></path>
                <path
                  fill="#56CCF2"
                  d="M36.668 53.893c0 .293-.434.529-.97.529-.535 0-.968-.236-.968-.529 0-.292.435-.528.968-.528.534 0 .97.236.97.528z"
                ></path>
                <path
                  fill="#56CCF2"
                  d="M39.732 53.893c0 .293-.434.529-.97.529-.535 0-.969-.236-.969-.529 0-.292.434-.528.97-.528.535 0 .97.236.97.528z"
                ></path>
                <path
                  fill="#56CCF2"
                  d="M41.113 52.505c0 .292-.432.528-.968.528-.535 0-.97-.236-.97-.528 0-.293.435-.528.97-.528.536 0 .968.236.968.528z"
                ></path>
                <path
                  fill="#56CCF2"
                  d="M60.002 52.505c0 .292-.434.528-.97.528-.535 0-.97-.236-.97-.528 0-.293.435-.528.97-.528.536 0 .97.236.97.528z"
                ></path>
                <path
                  fill="#56CCF2"
                  d="M63.035 52.505c0 .292-.434.528-.968.528-.533 0-.97-.236-.97-.528 0-.293.435-.528.97-.528.536 0 .968.236.968.528z"
                ></path>
                <path
                  fill="#56CCF2"
                  d="M61.63 53.893c0 .293-.432.529-.967.529-.535 0-.97-.236-.97-.529 0-.292.435-.528.97-.528.535 0 .968.236.968.528z"
                ></path>
                <path
                  fill="#56CCF2"
                  d="M64.69 53.893c0 .293-.434.529-.97.529-.535 0-.967-.236-.967-.529 0-.292.432-.528.968-.528.535 0 .97.236.97.528z"
                ></path>
                <path
                  fill="#56CCF2"
                  d="M66.07 52.505c0 .292-.434.528-.97.528-.535 0-.97-.236-.97-.528 0-.293.435-.528.97-.528.536 0 .97.236.97.528z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M38.763 49.117c1.08 0 1.955-.87 1.955-1.942a1.949 1.949 0 00-1.955-1.942c-1.08 0-1.955.87-1.955 1.942 0 1.073.875 1.942 1.955 1.942z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M60.877 49.117c1.08 0 1.956-.87 1.956-1.942a1.949 1.949 0 00-1.956-1.942c-1.08 0-1.955.87-1.955 1.942 0 1.073.875 1.942 1.955 1.942z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M46.66 50.42v4.798a3.14 3.14 0 00.928 2.218c.593.589 1.396.92 2.234.923.839-.001 1.643-.332 2.237-.92a3.14 3.14 0 00.932-2.221V50.42H46.66z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M75.25 35.164v1.253a28.267 28.267 0 01-17.253 5.839c-6.5 0-12.8-2.227-17.84-6.303a28.032 28.032 0 01-9.827-16.075 22.96 22.96 0 017.732-5.602 23.103 23.103 0 019.359-1.985h4.799c12.666 0 23.03 10.293 23.03 22.873z"
                ></path>
                <path
                  fill="#45CBF6"
                  d="M42.835 84.265H38.07v20.987h4.764V84.265z"
                ></path>
                <path
                  fill="#45CBF6"
                  d="M61.6 84.265h-4.763v20.987H61.6V84.265z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
  <defs>
    <filter
      id="filter0_ddii_4680_5994"
      width="109"
      height="109"
      x="-2"
      y="-2"
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="5" dy="5"></feOffset>
      <feGaussianBlur stdDeviation="5"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"></feColorMatrix>
      <feBlend
        in2="BackgroundImageFix"
        result="effect1_dropShadow_4680_5994"
      ></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="-5" dy="-5"></feOffset>
      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 0.92549 0 0 0 0 0.937255 0 0 0 0 0.941176 0 0 0 0.25 0"></feColorMatrix>
      <feBlend
        in2="effect1_dropShadow_4680_5994"
        result="effect2_dropShadow_4680_5994"
      ></feBlend>
      <feBlend
        in="SourceGraphic"
        in2="effect2_dropShadow_4680_5994"
        result="shape"
      ></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="-1" dy="-2"></feOffset>
      <feGaussianBlur stdDeviation="13"></feGaussianBlur>
      <feComposite
        in2="hardAlpha"
        k2="-1"
        k3="1"
        operator="arithmetic"
      ></feComposite>
      <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
      <feBlend in2="shape" result="effect3_innerShadow_4680_5994"></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="1" dy="-3"></feOffset>
      <feGaussianBlur stdDeviation="13.5"></feGaussianBlur>
      <feComposite
        in2="hardAlpha"
        k2="-1"
        k3="1"
        operator="arithmetic"
      ></feComposite>
      <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
      <feBlend
        in2="effect3_innerShadow_4680_5994"
        result="effect4_innerShadow_4680_5994"
      ></feBlend>
    </filter>
    <clipPath id="clip0_4680_5994">
      <path fill="#fff" d="M0 0H100V100H0z"></path>
    </clipPath>
  </defs>
</svg>
)

export default MomBlue;
