import React from "react";

const FeelingModal = () => {
  return (
    <div
      className="modal fade"
      id="custom_modal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content custom-modal-radius">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <h5
              className="modal-title text-center a_modal_title"
              id="exampleModalLabel"
            >
              {/* תושגרה תנבהב הרזע */}
              עזרה בהבנת הרגשות
            </h5>
          </div>
          <div className="modal-body">
            {/* 14_2 popup design */}
            <div className="disc_a14_modal mt-3">
              <p className="a11_disc_para_content">
                לפעמים אנחנו משתמשים במילה ”מרגיש/ה“ לביטוי של
                <span className="text-bold"> מחשבות.</span>
              </p>

              <p className="a11_disc_para_content">
                לדוגמה: ”אני מרגיש שאת חושדת בי“, זאת בעצם מחשבה במסווה של רגש,
                בפועל ”אני
                <span className="text-bold"> חושב </span>
                שאת חושדת/מפקפקת בי“.
              </p>
            </div>
            <div className="disc_a14_modal mt-3">
              <p className="a11_disc_para_content">
                ננסה להתחבר לרגשות שחיים בנו בזמן שהסיטואציה המדוברת קורית.
                <br />
                לדוגמה: ”אני מרגישה עצב / כעס / תסכול... “ כלומר, רגש שבאמת חי
                בנו בהקשר לסיטואציה המדוברת.
              </p>
            </div>
            {/* List Content start */}
            <div className="a11_content_list mt-3">
              <h6 className="a11_contents_list_title">
                דוגמאות לניסוח הרגשות:
              </h6>
              <ol>
                <li>אני פגוע ומבולבל.</li>
                <li>אני מרגישה חוסר וודאות.</li>
                <li>אני מרגישה עצב ובדידות / אני עצובה ובודדה.</li>
              </ol>
            </div>
            {/* List Content end */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FeelingModal);
