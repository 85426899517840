import { useState } from "react";
import IgnoredTabNeedE from "./IgnoredTabNeedE";
import { useSelector } from "react-redux";
import downArrow from "../../pages/img/downArrow.png";
import { v4 as uuidv4 } from "uuid";

const IgnoredNeedsBlock = ({ id, dataArr, isMatch, itemPerRow }) => {
  const [show, setShow] = useState(false);

  const selectedData = useSelector((state) => state.ignoredNeeds).filter(
    (a) => a.blockId === id
  );
  const selectedIndices = selectedData.map((b) => b.index);

  let keepOpen = !isMatch && selectedIndices.some((e) => e > itemPerRow - 1);
  let BlockClass = isMatch
    ? "show52"
    : keepOpen
    ? "show100"
    : show
    ? "show100"
    : "show52";

  return (
    <div className="tab_btn_all mt-4 px-1">
      <div className={BlockClass}>
        <div
          className="all_e7_btn d-flex flex-wrap justify-content-center"
          id={"element" + id}
        >
          {dataArr &&
            dataArr.map((need, i) => (
              <IgnoredTabNeedE
                key={uuidv4()}
                index={i}
                data={need}
                blockId={id}
              />
            ))}
        </div>
      </div>
      {isMatch ? (
        <BlueLine />
      ) : keepOpen ? (
        <BlackLine />
      ) : show ? (
        <BlackLineUpArrow onClick={() => setShow(!show)} />
      ) : (
        <BlueLineArrowDesign onClick={() => setShow(!show)} />
      )}
    </div>
  );
};

export default IgnoredNeedsBlock;

const BlueLine = () => {
  return (
    <div className="toggle_button_blue_simple">
      <div className="toggle_button_border_simple"></div>
    </div>
  );
};

const BlackLine = () => {
  return (
    <div className="toggle_button_black del-mt4">
      <div className="toggle_border_black"></div>
    </div>
  );
};

const BlackLineUpArrow = ({ onClick }) => {
  return (
    <div className="toggle_button_black del-mt4">
      <div className="toggle_border_black"></div>
      <div className="black_arrow" onClick={onClick}>
        <img src="assets/images/hide_arrow_black.svg" alt="" />
      </div>
    </div>
  );
};

const BlueLineArrowDesign = ({ onClick }) => {
  return (
    <div className="toggle_button_blue">
      <div className="toggle_button_border">
        <div className="shape_button" />
        <div className="shape_button" />
        <div className="shape_button" />
      </div>
      <div className="hide_arrow" onClick={onClick}>
        <img src={downArrow} alt="Blue Arrow" />
      </div>
    </div>
  );
};
