const DadBlue = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="100"
  height="100"
  fill="none"
  viewBox="0 0 100 100"
>
  <g clipPath="url(#clip0_4680_6035)">
    <path d="M0 0H100V100H0z"></path>
    <g>
      <g filter="url(#filter0_ddii_4680_6035)">
        <g>
          <circle cx="49.5" cy="49.5" r="42.5" fill="#fff"></circle>
          <g>
            <mask
              id="mask0_4680_6035"
              style={{ maskType: "alpha" }}
              width="85"
              height="85"
              x="7"
              y="7"
              maskUnits="userSpaceOnUse"
            >
              <circle
                cx="49.5"
                cy="49.5"
                r="42"
                fill="#C4C4C4"
                stroke="#000"
              ></circle>
            </mask>
            <g mask="url(#mask0_4680_6035)">
              <g>
                <g>
                  <path
                    fill="#45CBF6"
                    d="M53.825 74.824H44.88v16.33h8.946v-16.33z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M45.735 83.938h7.148c1.281 0 2.51.505 3.415 1.404a4.78 4.78 0 011.414 3.391v10.882H40.906V88.733a4.78 4.78 0 011.415-3.39 4.846 4.846 0 013.414-1.406z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M49.897 26.814h-1.51c-10.96 0-19.846 9.346-19.846 20.876v13.155c0 11.53 8.885 17.944 19.846 17.944h1.51c10.96 0 19.846-6.414 19.846-17.944V47.69c0-11.53-8.886-20.876-19.846-20.876z"
                  ></path>
                  <path
                    stroke="#3869E5"
                    strokeWidth="2"
                    d="M61 50.317c0 2.792-2.245 5.044-5 5.044s-5-2.252-5-5.044c0-2.793 2.245-5.044 5-5.044s5 2.25 5 5.044z"
                  ></path>
                  <path
                    stroke="#3869E5"
                    strokeWidth="2"
                    d="M46 50.317c0 2.792-2.245 5.044-5 5.044s-5-2.252-5-5.044c0-2.793 2.245-5.044 5-5.044s5 2.25 5 5.044z"
                  ></path>
                  <path
                    stroke="#3869E5"
                    strokeWidth="2"
                    d="M46.5 51.324h4"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M41 64.42c6 3.526 8 6.548 16.268 0H41z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M51.5 69.457H46l3 3.526 2.5-3.526z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M38.556 109.754H34.26V95.622c0-6.82 4.294-11.582 10.444-11.582v4.268c-4.247 0-6.147 3.68-6.147 7.314v14.132z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M64.54 109.754h-4.298V95.622c0-3.64-1.9-7.314-6.146-7.314V84.04c6.15 0 10.444 4.763 10.444 11.582v14.132z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M26.512 35.739c.603 5.403 1.851 15.037 1.918 15.373l3.67-13.777 10.423-6.637-1.668 5.545 6.172-3.108-3.253 4.2 16.262-6.216 6.755 6.216 2.871 13.306.754-14.902.794-13.945-24.183-8.4 5.087 4.703-13.76-3.612 5.42 3.612L32.1 17.51l10.424 1.009-17.762 4.872 1.75 12.349z"
                  ></path>
                </g>
              </g>
              <path
                fill="#3869E5"
                d="M43.205 51.941a.79.79 0 01-.79-.79 1.67 1.67 0 00-3.335 0 .791.791 0 11-1.581 0 3.253 3.253 0 013.248-3.328 3.249 3.249 0 013.249 3.327.791.791 0 01-.79.791z"
              ></path>
              <path
                fill="#3869E5"
                d="M58.3 51.941a.79.79 0 01-.79-.79 1.669 1.669 0 00-3.335 0 .792.792 0 01-1.581 0 3.25 3.25 0 015.572-2.349 3.25 3.25 0 01.925 2.348.791.791 0 01-.791.791z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
  <defs>
    <filter
      id="filter0_ddii_4680_6035"
      width="109"
      height="109"
      x="-2"
      y="-2"
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="5" dy="5"></feOffset>
      <feGaussianBlur stdDeviation="5"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"></feColorMatrix>
      <feBlend
        in2="BackgroundImageFix"
        result="effect1_dropShadow_4680_6035"
      ></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="-5" dy="-5"></feOffset>
      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 0.92549 0 0 0 0 0.937255 0 0 0 0 0.941176 0 0 0 0.25 0"></feColorMatrix>
      <feBlend
        in2="effect1_dropShadow_4680_6035"
        result="effect2_dropShadow_4680_6035"
      ></feBlend>
      <feBlend
        in="SourceGraphic"
        in2="effect2_dropShadow_4680_6035"
        result="shape"
      ></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="-1" dy="-2"></feOffset>
      <feGaussianBlur stdDeviation="13"></feGaussianBlur>
      <feComposite
        in2="hardAlpha"
        k2="-1"
        k3="1"
        operator="arithmetic"
      ></feComposite>
      <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
      <feBlend in2="shape" result="effect3_innerShadow_4680_6035"></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="1" dy="-3"></feOffset>
      <feGaussianBlur stdDeviation="13.5"></feGaussianBlur>
      <feComposite
        in2="hardAlpha"
        k2="-1"
        k3="1"
        operator="arithmetic"
      ></feComposite>
      <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
      <feBlend
        in2="effect3_innerShadow_4680_6035"
        result="effect4_innerShadow_4680_6035"
      ></feBlend>
    </filter>
    <clipPath id="clip0_4680_6035">
      <path fill="#fff" d="M0 0H100V100H0z"></path>
    </clipPath>
  </defs>
</svg>
);

export default DadBlue;
