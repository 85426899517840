const OtherMale = (props) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <g clipPath="url(#clip0_4680_5759)">
        <path d="M0 0H100V100H0z"></path>
        <g filter="url(#filter0_dd_4680_5759)">
          <ellipse
            cx="49.5"
            cy="49.5"
            fill="#fff"
            rx="42.5"
            ry="42.5"
          ></ellipse>
          <g>
            <mask
              id="mask0_4680_5759"
              style={{ maskType: "alpha" }}
              width="85"
              height="85"
              x="7"
              y="7"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#C4C4C4"
                stroke="#000"
                d="M91.5 49.5c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42z"
              ></path>
            </mask>
            <g mask="url(#mask0_4680_5759)">
              <g>
                <g>
                  <path
                    fill="#3869E5"
                    d="M53.825 74.823H44.88v16.33h8.946v-16.33z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M45.734 83.936h7.148c1.281 0 2.51.506 3.415 1.405a4.78 4.78 0 011.414 3.391v10.882H40.905V88.731a4.78 4.78 0 011.415-3.39 4.845 4.845 0 013.414-1.406z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M65.175 55.034c8.819-8.774 8.819-22.999 0-31.773-8.82-8.773-23.118-8.773-31.937 0-8.82 8.774-8.82 22.999 0 31.773 8.82 8.773 23.118 8.773 31.937 0z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M31.804 48.782a3.892 3.892 0 00-1.496-5.32c-1.892-1.06-4.29-.394-5.356 1.485a3.892 3.892 0 001.495 5.32c1.893 1.059 4.29.394 5.357-1.485z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M27.325 42.955c2.164-.186 3.766-2.08 3.578-4.229-.188-2.149-2.094-3.74-4.258-3.553-2.163.187-3.765 2.08-3.578 4.229.188 2.149 2.095 3.74 4.258 3.553z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M31.129 34.416a3.887 3.887 0 00.424-5.508 3.951 3.951 0 00-5.545-.422 3.887 3.887 0 00-.424 5.508 3.951 3.951 0 005.545.422z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M32.487 28.723c2.172 0 3.933-1.748 3.933-3.905s-1.761-3.906-3.933-3.906c-2.172 0-3.932 1.749-3.932 3.906s1.76 3.905 3.932 3.905z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M41.343 22.219a3.887 3.887 0 00-.457-5.505 3.951 3.951 0 00-5.543.455 3.887 3.887 0 00.458 5.505 3.951 3.951 0 005.542-.455z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M49.665 16.657a3.885 3.885 0 01-.538 2.203c-.395.665-.98 1.2-1.68 1.535a3.958 3.958 0 01-4.326-.605 3.88 3.88 0 01-1.094-4.2 3.907 3.907 0 011.344-1.832 3.95 3.95 0 015.91 1.422c.225.463.355.964.384 1.477z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M57.555 17.136c.05-2.157-1.67-3.945-3.84-3.995-2.172-.05-3.973 1.658-4.023 3.815-.05 2.156 1.67 3.945 3.841 3.994 2.171.05 3.972-1.658 4.022-3.814z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M64.317 22.041a3.89 3.89 0 00-1.164-5.4 3.949 3.949 0 00-5.438 1.156 3.89 3.89 0 001.164 5.401 3.949 3.949 0 005.438-1.157z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M70.822 26.151a3.9 3.9 0 00-2.42-4.973c-2.051-.71-4.293.367-5.007 2.404a3.9 3.9 0 002.42 4.973c2.051.71 4.293-.367 5.007-2.404z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M74.313 33.793a3.889 3.889 0 00-.959-5.44 3.95 3.95 0 00-5.478.95 3.889 3.889 0 00.958 5.442 3.95 3.95 0 005.478-.952z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M72.743 43.186c2.171-.067 3.876-1.869 3.809-4.025-.068-2.156-1.882-3.85-4.053-3.782-2.17.067-3.876 1.869-3.808 4.025.067 2.156 1.881 3.85 4.052 3.782z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M74.216 46.586a3.885 3.885 0 01-.538 2.203c-.395.666-.98 1.2-1.68 1.535a3.956 3.956 0 01-4.326-.604 3.901 3.901 0 01-1.189-1.934 3.88 3.88 0 01.093-2.266 3.907 3.907 0 011.345-1.831 3.949 3.949 0 015.91 1.42c.225.463.356.965.385 1.477z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M29.318 56.57c2.954-.477 4.96-3.242 4.481-6.176-.48-2.934-3.263-4.927-6.218-4.45-2.954.475-4.96 3.24-4.481 6.175.48 2.934 3.263 4.927 6.218 4.45z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M73.815 55.064a5.357 5.357 0 000-7.612 5.446 5.446 0 00-7.664 0 5.357 5.357 0 000 7.612 5.446 5.446 0 007.664 0z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M49.897 26.814h-1.51c-10.96 0-19.846 8.824-19.846 19.71v12.421c0 10.886 8.885 19.711 19.846 19.711h1.51c10.96 0 19.846-8.825 19.846-19.71V46.523c0-10.886-8.886-19.71-19.846-19.71z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M53.824 83.935l-4.473-4.443-4.474 4.443 4.474 4.443 4.473-4.443z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M49.14 33.684c6.102 0 11.048-1.973 11.048-4.408 0-2.435-4.947-4.409-11.049-4.409-6.102 0-11.05 1.974-11.05 4.409 0 2.434 4.948 4.408 11.05 4.408z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M41.421 49.227c0 .446-.133.883-.382 1.255-.25.372-.605.662-1.02.833a2.29 2.29 0 01-2.48-.49 2.254 2.254 0 01-.492-2.462c.172-.413.464-.765.838-1.013a2.286 2.286 0 012.87.28c.427.424.666.998.666 1.597z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M60.89 49.227c0 .446-.133.883-.382 1.255-.25.371-.605.661-1.02.833a2.288 2.288 0 01-2.48-.489 2.254 2.254 0 01-.493-2.462c.172-.412.463-.765.837-1.014a2.285 2.285 0 013.365 1.012c.114.274.173.568.173.865z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M41.006 56.64c5.515 9.813 10.938 10.155 16.268 0H41.006z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M38.555 109.753h-4.297V95.621c0-6.82 4.294-11.582 10.444-11.582v4.268c-4.246 0-6.147 3.68-6.147 7.314v14.132z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M64.54 109.753h-4.298V95.621c0-3.64-1.9-7.314-6.146-7.314V84.04c6.15 0 10.444 4.763 10.444 11.582v14.132z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_4680_5759"
          width="103"
          height="103"
          x="-2"
          y="-2"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="5" dy="5"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.15 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4680_5759"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-5" dy="-5"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.866667 0 0 0 0 0.882353 0 0 0 0 0.941176 0 0 0 0.6 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_4680_5759"
            result="effect2_dropShadow_4680_5759"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_4680_5759"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_4680_5759">
          <path fill="#fff" d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
    </svg>
);

export default OtherMale;
