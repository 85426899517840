import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import callApi from "../../lib/apisauce/callApi";
import { queryString } from "../../utils/queryString";

const UnderstandNeeds = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const judgementData = JSON.parse(sessionStorage.getItem("judgement"));

  useEffect(() => {
    if (judgementData?.situation_description === undefined) {
      navigate("/home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;
  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));

  let basicJudgeData;

  if (judgementData && Object.keys(judgementData).length !== 0) {
    const {
      ask_from_partner_name,
      ask_from_partner_roll,
      situation_description,
    } = judgementData;

    basicJudgeData = {
      ask_from_partner_name,
      ask_from_partner_roll,
      situation_description,
    };
  }

  const logOut = () => { //incase of expired member code
    localStorage.removeItem("UserJWT");
    [
      "UserJWT",
      "userData",
      "badFeelings",
      "blackListWords",
      "allNeeds",
      "selfConnectData",
      "request",
      "judgement",
      "goodFeelings",
      "RegUserData",
      "updateMode",
      "displayData",
    ].forEach((key) => {
      sessionStorage.removeItem(key);
    });
    navigate("/");
  };

  const saveDatatoDB = async () => {
    setLoading(true);
    let newData = { ...judgementData, status: "A" };
    try {
      const res = await callApi.postData(
        "enough-no-needs",
        queryString(newData),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: JWToken,
          },
        }
      );

      if (res.ok) {
        if (res.data.status === "success") {
          sessionStorage.removeItem("judgement");
          setLoading(false);
          navigate("/home");
        } else {
          setLoading(false);
          if (res.data.message === "תוקף הקוד פג") {
            console.log(res.data.message);
            sessionStorage.setItem("ExpireCode", "True")
            logOut();
          }
          console.log(res.data.message);
        }
      } else {
        console.log(res.originalError);
      }
    } catch (error) {
      error && console.log(error);
    }
  };

  const continuePath = (path) => {
    sessionStorage.removeItem("judgement");
    sessionStorage.setItem("judgement", JSON.stringify(basicJudgeData));
    navigate(path);
  };

  let screenWidth = window.innerWidth;

  return (
    <>
      <div className="bg-primary_e">
        <Header extraClass="border-bottom" />
        <div className="c4_content px-3 pt-100">
          <div className="container pt-5">
            <h4>
              לפעמים הכתיבה של
              <br />
              הדברים עשויה לעזור.
            </h4>
            <h4 className="my-5">
              תרצ
              {userGender === "female" ? "י " : "ה "}
              להמשיך ולבדוק
              <br />
              מה הצרכים מאחורי זה?
            </h4>
            {/* Secondary Button Design */}
          </div>
          <div className="container pt-1">
            <div className="line">
              <RoundBtn
                extraClass="mt-4 text-regular bg-secondary-button fs-18"
                text="רוצה להבין את הצרכים שלי"
                onClick={() => continuePath("/my-judgement")}
              />
            </div>

            {/* Secondary Button Design */}
            <div className="line">
              <RoundBtn
                extraClass={`mt-4 text-regular bg-secondary-button fs-18 ${
                  screenWidth < 376 ? "px-0" : ""
                }`}
                text="רוצה להבין את הצרכים של הצד השני"
                onClick={() => continuePath("/partner-judgement")}
              />
            </div>
            {/* Secondary Button Design */}
            <div className="line position-relative">
              <RoundBtn
                extraClass="mt-4 text-regular bg-secondary-button fs-18"
                text="לא, זה מספיק לי"
                onClick={saveDatatoDB}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnderstandNeeds;
