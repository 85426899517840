import { createSlice } from "@reduxjs/toolkit";

const mostAnsNeedSlice = createSlice({
  name: "mostAnsNeed",
  initialState: [],
  reducers: {
    addRemoveMostAnsNeed(state, action) {
      if (state.includes(action.payload)) {
        return state.filter((x) => x !== action.payload);
      } else {
        state.push(action.payload);
      }
    },
    resetMostAnsNeedState(state) {
      return (state = []);
  }
  },
});

export const { addRemoveMostAnsNeed, resetMostAnsNeedState } = mostAnsNeedSlice.actions;

export default mostAnsNeedSlice.reducer;
