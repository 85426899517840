const LightBulb = (props) => (
  <svg
    width={63}
    height={63}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.5 5.25v2.625M7.875 31.5H5.25m9.188-17.063-1.576-1.575m35.7 1.575 1.576-1.575M57.75 31.5h-2.625M26.25 35.438h10.5m-5.25 0v13.124m9.188-4.268c3.974-2.859 6.562-7.524 6.562-12.794 0-8.698-7.051-15.75-15.75-15.75-8.698 0-15.75 7.052-15.75 15.75 0 5.27 2.588 9.935 6.563 12.794v5.056c0 2.94 0 4.41.572 5.533a5.25 5.25 0 0 0 2.294 2.295c1.123.572 2.593.572 5.534.572h1.575c2.94 0 4.41 0 5.533-.572a5.25 5.25 0 0 0 2.294-2.294c.572-1.124.572-2.594.572-5.534v-5.056Z"
      stroke="#fff"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LightBulb;
