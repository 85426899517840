import { configureStore } from "@reduxjs/toolkit";
import goodFeelReducer from "./goodFeelSlice";
import badFeelReducer from "./badFeelSlice";
import ignoredNeedsReducer from "./ignoredNeedsSlice";
import answeredNeedsReducer from "./answeredNeedsSlice";
import deepNeedsReducer from "./deepNeedsSlice";
import mostGFReducer from "./mostGFSlice";
import mostBFReducer from "./mostBFSlice";
import mostAnsNeedReducer from "./mostAnsNeedSlice";
import mostIgNeedReducer from "./mostIgNeedSlice";

const store = configureStore({
  reducer: {
    goodFeel: goodFeelReducer,
    badFeel: badFeelReducer,
    ignoredNeeds: ignoredNeedsReducer,
    answeredNeeds: answeredNeedsReducer,
    deepNeeds : deepNeedsReducer,
    mostGF: mostGFReducer,
    mostBF: mostBFReducer,
    mostAnsNeed: mostAnsNeedReducer,
    mostIgNeed: mostIgNeedReducer,
  },
});

export default store;
