import { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import callApi from "../lib/apisauce/callApi";
import RoundBtn from "../components/RoundBtn";
import { ProgressBar } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const ExtractLogs = () => {
  const navigate = useNavigate();
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));

  useEffect(() => {
    if (JWToken === null) {
      navigate("/");
    }
    [
      "selfConnectData",
      "request",
      "judgement",
      "updateMode",
      "displayData",
      "isMyNeeds",
      "isPartnerNeeds",
      "finalSelect",
      "finalFreeText",
    ].forEach((key) => {
      sessionStorage.removeItem(key);
    });
  }, []);

  const extractDataFromDB = async (path, filename) => {
    setLoading(true);
    await callApi
      .getDatawithToken(
        path,
        {},
        {
          responseType: "blob",
          headers: {
            Authorization: JWToken,
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        }
      )
      .then((res) => {
        if (!res.ok && res.status === 400) {
          // const error = JSON.parse(await res.data.text()); //To convert Blob to Server Response.
          // console.log('error: ', error);
          setLoading(false);
          setErr(true);
          setErrMsg("המשתמש לא מורשה את הפעולה");
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${filename}.xlsx`);
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        }
      })
      .catch((res) => {
        console.log(res.originalError);
        setLoading(false);
      });
  };

  return (
    <>
      {/* LOADER */}
      {loading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#20202060",
            position: "fixed",
            zIndex: 9999999,
          }}
        >
          <ProgressBar
            height="100%"
            width="80"
            borderColor="#45cbf6"
            barColor="#45cbf6"
          />
        </div>
      )}

      {/* LOADER */}

      <Header />
      <div className="a0_main pt-5">
        <div className="a0_main_details mt-5">
          <div className="container px-4">
            <div className="header-logo mb-5">
              <img src="assets/images/logo.svg" alt="" />
            </div>
            {err && (
              <h2 className="err_msg fs-17 text-center mb-5">{errMsg}</h2>
            )}

            {/*1. Path A (Request) */}
            <RoundBtn
              extraClass={`mt-1 px-0 text-regular bg-primary-button`}
              text="להורדת לוגים של מסלול A"
              onClick={() =>
                extractDataFromDB(
                  "request-screen-excel",
                  "A להורדת לוגים של מסלול"
                )
              }
            />

            {/*2. Path C - No needs */}
            <RoundBtn
              extraClass={`mt-3 text-regular bg-primary-button`}
              text="C - ללא צרכים"
              onClick={() =>
                extractDataFromDB("enough-no-need-excel", "C - ללא צרכים")
              }
            />

            {/*3. Path C - My needs */}
            <RoundBtn
              extraClass={`mt-3 text-regular bg-primary-button`}
              text="C - הצרכים שלי"
              onClick={() =>
                extractDataFromDB("understand-my-need-excel", "C - הצרכים שלי")
              }
            />

            {/*4. Path C - Partner needs */}
            <RoundBtn
              extraClass={`mt-3 text-regular bg-primary-button`}
              text="C- צרכי הפרטנר"
              onClick={() =>
                extractDataFromDB("partner-need-excel", "C- צרכי הפרטנר")
              }
            />

            {/*5. Path E - Self Connection */}
            <RoundBtn
              extraClass={`mt-3 px-0 text-regular bg-primary-button`}
              text=" להורדת לוגים של מסלול E"
              onClick={() =>
                extractDataFromDB(
                  "self-connecting-excel",
                  "להורדת לוגים של מסלול E"
                )
              }
            />

            {/*6. Registered Users Log  */}
            <RoundBtn
              extraClass={`my-3 px-0 text-regular bg-primary-button`}
              text="הורדת אקסל של משתמשים רשומים"
              onClick={() =>
                extractDataFromDB(
                  "register-user-excel",
                  "הורדת אקסל של משתמשים רשומים"
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExtractLogs;
