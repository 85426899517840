const BrotherL = (props) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <g clipPath="url(#clip0_4680_6772)">
        <path d="M0 0H100V100H0z"></path>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <mask
                    id="mask0_4680_6772"
                    style={{ maskType: "alpha" }}
                    width="100"
                    height="100"
                    x="0"
                    y="0"
                    maskUnits="userSpaceOnUse"
                  >
                    <path
                      fill="#C4C4C4"
                      stroke="#000"
                      d="M99.5 50c0 27.34-22.057 49.5-49.262 49.5C23.033 99.5.976 77.34.976 50S23.033.5 50.238.5C77.443.5 99.5 22.66 99.5 50z"
                    ></path>
                  </mask>
                  <g mask="url(#mask0_4680_6772)">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#3869E5"
                            d="M75.198 21.03c0-10.495-14.546-8.976-25.062-8.976-10.516 0-25.337-1.518-25.337 8.976v35.011c0 10.495 20.841 19.003 31.358 19.003 10.516 0 19.041-14.55 19.041-25.044V21.03z"
                          ></path>
                          <path
                            fill="#45CBF6"
                            d="M23.346 57.385c3.505 0 6.346-2.835 6.346-6.333 0-3.497-2.841-6.332-6.346-6.332S17 47.555 17 51.052c0 3.498 2.841 6.333 6.346 6.333z"
                          ></path>
                          <path
                            fill="#45CBF6"
                            d="M76.392 57.385c3.504 0 6.346-2.835 6.346-6.333 0-3.497-2.842-6.332-6.346-6.332-3.505 0-6.346 2.835-6.346 6.332 0 3.498 2.841 6.333 6.346 6.333z"
                          ></path>
                          <path
                            fill="#3869E5"
                            d="M45.881 88.286h8.37c1.5 0 2.938.595 3.998 1.653a5.636 5.636 0 011.656 3.99v12.801H40.227V93.928c0-1.496.596-2.931 1.657-3.99a5.66 5.66 0 013.997-1.652z"
                          ></path>
                          <path
                            fill="#45CBF6"
                            d="M49.87 82.07c13.994 0 25.339-12.717 25.339-28.406 0-15.688-11.345-28.406-25.34-28.406S24.53 37.976 24.53 53.664c0 15.689 11.345 28.407 25.34 28.407z"
                          ></path>
                          <path
                            fill="#45CBF6"
                            d="M45.213 95.762h-4.985v27.775h4.985V95.762z"
                          ></path>
                          <path
                            fill="#45CBF6"
                            d="M54.922 123.534h4.984V95.758h-4.984v27.776z"
                          ></path>
                          <path
                            fill="#3869E5"
                            d="M40.731 40.077l-3.543 1.3a1.668 1.668 0 01-2.131-.98 1.658 1.658 0 01.993-2.13l3.54-1.303a1.668 1.668 0 012.133.983 1.659 1.659 0 01-.992 2.13z"
                          ></path>
                          <path
                            fill="#3869E5"
                            d="M58.272 40.076l3.54 1.301a1.67 1.67 0 002.134-.981 1.66 1.66 0 00-.985-2.128l-3.54-1.302a1.67 1.67 0 00-2.139.98 1.66 1.66 0 00.99 2.13z"
                          ></path>
                        </g>
                        <path
                          fill="#3869E5"
                          d="M41.993 50.002a.954.954 0 01-.667-.27.914.914 0 01-.276-.654 1.93 1.93 0 00-.605-1.337 2.013 2.013 0 00-1.385-.549c-.516 0-1.013.197-1.384.549a1.931 1.931 0 00-.605 1.337c0 .245-.1.48-.277.653a.954.954 0 01-1.334 0 .914.914 0 01-.276-.653c-.012-.506.079-1.01.268-1.48.19-.471.473-.9.834-1.262.362-.362.793-.65 1.27-.846a3.95 3.95 0 013.01 0c.475.196.907.484 1.268.846s.645.791.834 1.262c.19.47.281.974.269 1.48 0 .245-.1.48-.276.653a.954.954 0 01-.668.27z"
                        ></path>
                        <path
                          fill="#3869E5"
                          d="M62.407 50.002a.954.954 0 01-.668-.27.914.914 0 01-.276-.654 1.93 1.93 0 00-.605-1.337 2.013 2.013 0 00-1.385-.549c-.516 0-1.013.197-1.384.549a1.93 1.93 0 00-.605 1.337c0 .245-.1.48-.277.653a.953.953 0 01-1.334 0 .914.914 0 01-.276-.653c-.012-.506.079-1.01.268-1.48.19-.471.474-.9.835-1.262.36-.362.792-.65 1.269-.846a3.949 3.949 0 013.01 0c.476.196.907.484 1.268.846s.645.791.834 1.262c.19.47.281.974.27 1.48a.905.905 0 01-.277.654.942.942 0 01-.667.27z"
                        ></path>
                      </g>
                      <path
                        fill="#45CBF6"
                        d="M45.035 80.66h9.827v7.172c0 .487-.126.968-.373 1.417-.246.45-.607.858-1.063 1.202a5.24 5.24 0 01-1.593.803 6.33 6.33 0 01-1.88.283c-1.302 0-2.552-.39-3.473-1.084-.921-.694-1.438-1.636-1.438-2.617v-7.174l-.007-.003z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path
              fill="#3869E5"
              d="M40 59.098c6.581 11.46 13.053 11.858 19.414 0H40z"
            ></path>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4680_6772">
          <path fill="#fff" d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
    </svg>
)

export default BrotherL
