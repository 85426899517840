import { createSlice } from "@reduxjs/toolkit";

const mostBFSlice = createSlice({
  name: "mostBF",
  initialState: [],
  reducers: {
    addRemoveMostBF(state, action) {
      if (state.includes(action.payload)) {
        return state.filter((x) => x !== action.payload);
      } else {
        state.push(action.payload);
      }
    },
    resetMostBFState(state) {
      return (state = []);
    },
  },
});

export const { addRemoveMostBF, resetMostBFState } = mostBFSlice.actions;

export default mostBFSlice.reducer;
