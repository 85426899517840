import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import callApi from "../../lib/apisauce/callApi";

const Situation = () => {
  const navigate = useNavigate();
  const userRequest = JSON.parse(sessionStorage.getItem("request"));

  useEffect(() => {
    if (userRequest?.ask_from_partner_name === undefined) {
      navigate("/home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  
  let userTypedSituDesc, userSelectedDropdown;
  userTypedSituDesc =
    userRequest?.situation_description &&
    userRequest?.situation_description?.split("\n").pop().trim();
  userSelectedDropdown = userRequest?.situation_description?.split("\n")[0];

  let partnerRole = userRequest?.ask_from_partner_roll;

  const [selection, setSelection] = useState(userSelectedDropdown || "ראיתי ");
  const [desc, setDesc] = useState(userTypedSituDesc || "");
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  const optionValues = [
    "ראיתי ",
    "אני רואה ",
    "כאשר ראיתי ",
    "שמתי לב ש",
    "כאשר שמתי לב ",
    "כאשר ",
    "כאשר שמעתי ",
    "שמעתי ש",
    "שמעתי אותך אומר ",
    "שמעתי אותך אומרת ",
    "עלתה לי מחשבה ",
    "כש",
    "זכור לי ש",
    "נזכרתי ",
    `אני זוכר${userGender === "male" ? "" : "ת"} ש`,
    "טקסט חופשי",
  ];

  const [realDesc, setRealDesc] = useState("");
  const [descWithBL, setDescWithBL] = useState("");
  const [editMode, setEditMode] = useState(true);
  const [count, setCount] = useState(0);
  const [blackList, setBlackList] = useState(
    JSON.parse(sessionStorage.getItem("blackListWords")) || []
  );
  const [errMsg, setErrMsg] = useState(false);

  useEffect(() => {
    if (!blackList.length) fetchBlackListWords();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (count >= 2) setEditMode(true);
  }, [count]);

  let descId = document.getElementById("descId");

  useEffect(() => {
    setTimeout(() => {
      descId?.focus();
    }, 800);
  }, [selection]);

  useEffect(() => {
    if (desc !== "") {
      setErrMsg(false);
    }
  }, [desc]);

  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));

  const fetchBlackListWords = () => {
    callApi
      .getDatawithToken(
        "black-list-word",
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: JWToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          let words = res.data.data.map((x) => x.word);
          //console.log(words.includes("הבוקר"));
          words = words.sort((a, b) => b.length - a.length);
          sessionStorage.setItem("blackListWords", JSON.stringify(words));
          setBlackList(words);
        }
      })
      .catch((res) => {
        console.log(res.originalError);
      });
  };

  let userBLwords;
  const handleSubmit = (approve = false) => {
    if (desc === "") {
      return setErrMsg(true);
    } else {
      setErrMsg(false);
    }

    setRealDesc((d) =>
      d.length ? `${d}\n/////////////////////////\n${desc}` : `${desc}`
    );

    let UserSelection = selection;

    if (approve) {
      const newData = JSON.stringify({
        ...userRequest,
        situation_description: `${UserSelection} \n ${realDesc}`,
      });
      sessionStorage.setItem("request", newData);
      navigate("/first-stop");
    } else {
      let descArr = desc.split(" ");
      let connectedWords = descArr.filter(
        (w) =>
          w.length > 1 &&
          (w.startsWith("ו") || w.startsWith("י") || w.startsWith("ה"))
      );

      // console.log(connectedWords);

      // let userConnBLwords = connectedWords.map((x) => {
      //   console.log(blackList.filter((e) => x.includes(e)))
      //   return blackList.filter((e) => x.includes(e) && x.length === e.length+1);
      // });

      let userConnBLwords = [];
      for (let k = 0; k < connectedWords.length; k++) {
        blackList.filter(
          (e) => connectedWords[k].includes(e) && k.length === e.length + 1
        ).length > 0 && userConnBLwords.push(connectedWords[k]);
      }

      let userBLwords1 = blackList.filter((x) => {
        if ((" " + desc + " ").indexOf(" " + x + " ") > -1) return x;
      });

      let userBLwords2 = blackList.filter((x) => {
        if ((" " + desc + " ").indexOf(" " + x + " ", 1) > -1) return x;
      });

      let userBLwords3 = blackList.filter((x) => {
        if ((" " + desc + " ").lastIndexOf(" " + x + " ") > -1) return x;
      });

      let userBLwords4 = blackList.filter((x) => {
        if ((" " + desc + " ").indexOf(" " + x + ", ") > -1) return x;
      });

      let userBLwords5 = blackList.filter((x) => {
        if ((" " + desc + " ").indexOf(" " + x + ", ", 1) > -1) return x;
      });

      let userBLwords6 = blackList.filter((x) => {
        if ((" " + desc + " ").lastIndexOf(" " + x + ", ") > -1) return x;
      });

      let userBLwords7 = blackList.filter((x) => {
        if ((" " + desc + " ").indexOf(" " + x + ". ") > -1) return x;
      });

      let userBLwords8 = blackList.filter((x) => {
        if ((" " + desc + " ").indexOf(" " + x + ". ", 1) > -1) return x;
      });

      let userBLwords9 = blackList.filter((x) => {
        if ((" " + desc + " ").lastIndexOf(" " + x + ". ") > -1) return x;
      });

      userBLwords = [
        ...userBLwords1,
        ...userBLwords2,
        ...userBLwords3,
        ...userBLwords4,
        ...userBLwords5,
        ...userBLwords6,
        ...userBLwords7,
        ...userBLwords8,
        ...userBLwords9,
      ];
      let userAllBLwords = [...userBLwords, ...userConnBLwords];

      if (userAllBLwords.length) {
        setEditMode(false);
        let newDesc = desc;
        let sortedBLWords = userAllBLwords.sort((a, b) => b.length - a.length);

        for (let i = 0; i < sortedBLWords.length; i++) {
          newDesc = (" " + newDesc + " ")
            .replaceAll(
              " " + sortedBLWords[i] + " ",
              ` <span class="theme-red text-bold">${sortedBLWords[i]}</span> `
            )
            .replaceAll(
              " " + sortedBLWords[i] + ", ",
              ` <span class="theme-red text-bold">${sortedBLWords[i]}</span>, `
            )
            .replaceAll(
              " " + sortedBLWords[i] + ". ",
              ` <span class="theme-red text-bold">${sortedBLWords[i]}</span>. `
            );
        }
        setDescWithBL(newDesc);
      } else {
        const newData = JSON.stringify({
          ...userRequest,
          situation_description: realDesc.length
            ? `${UserSelection}\n${realDesc}\n/////////////////////////\n${desc}`
            : `${UserSelection}\n${desc}`,
        });
        sessionStorage.setItem("request", newData);
        if (desc.length) {
          navigate("/first-stop");
        }
      }
    }
  };

  return (
    <div className="bg-light_e">
      <Header text="תיאור הסיטואציה" />

      {/* Progress Line start */}
      <div className="progressbar pt-70 d-flex justify-content-between">
        <div className="progress-line progress_fill">
          <h6>תיאור</h6>
          <img src="assets/images/half_line.svg" alt="" />
        </div>

        <div className="progress-line">
          <h6>רגש</h6>
          <img src="assets/images/light_line.svg" alt="" />
        </div>

        <div className="progress-line">
          <h6>צורך</h6>
          <img src="assets/images/light_line.svg" alt="" />
        </div>

        <div className="progress-line">
          <h6>בקשה</h6>
          <img src="assets/images/light_line.svg" alt="" />
        </div>
      </div>
      {/* Progress Line End */}

      <div className="a11_content py-3 px-3">
        <div className="container">
          {/* Common Content start */}
          <div className="a_content_headers">
            <h5 className="a11_disc_content">
              תאר
              {userGender === "male" ? " " : "י "}
              סיטואציה שלא נעימה לך וקשורה ל{userRequest?.ask_from_partner_name}.
            </h5>
            <p className="a11_disc_para_content">
              נס
              {userGender === "male" ? "ה " : "י "}
              להשתמש בתיאור כללי, ללא רגש, פרשנות או בקשה.
              <br />
              {/* <span className="text-bold"> זוהי התחלה של המשפט.</span> */}
              <span className="text-bold">
                זה החלק הראשון בניסוח הבקשה, כלומר זו אמורה להיות רק התחלה של
                הניסוח, תיאור המתרחש בלבד.
              </span>
            </p>
          </div>
          {/* Common Content end */}

          {/* List Content start */}
          <div className="a11_content_list ">
            <h6 className="a11_contents_list_title">דוגמאות:</h6>

            {["adult_f", "adult_m"].includes(userData.family_role) &&
              userData.kids_exist === "without_children" && (
                <ListCoupleWithoutChildren />
              )}

            {["mom", "dad"].includes(userData.family_role) &&
              userData.kids_exist === "with_children" &&
              ["mom", "dad", "other_m", "other_f"].includes(partnerRole) && (
                <ListSpouseToSpouseWithChildren />
              )}

            {["mom", "dad"].includes(userData.family_role) &&
              userData.kids_exist === "with_children" &&
              ["kid_m", "kid_f"].includes(partnerRole) && (
                <ListParentAskToChild />
              )}

            {["kid_m", "kid_f"].includes(userData.family_role) &&
              userData.kids_exist === "with_children" &&
              userData.sibling === "yes" &&
              ["brother", "sister"].includes(partnerRole) && (
                <ListKidAskToSibling />
              )}

            {["kid_m", "kid_f"].includes(userData.family_role) &&
              userData.kids_exist === "with_children" &&
              ["mom", "dad"].includes(partnerRole) && <ListChildAskToParents />}
          </div>
          {/* List Content end */}

          {editMode ? (
            <>
              <div className="a11_text_dropdown mt-4">
                <h5 className="a11_disc_content">התיאור שלך:</h5>
                <div className="input-group">
                  <textarea
                    className="form-control input_shadow custom_textarea top_textarea"
                    aria-label="With textarea"
                    id="descId"
                    rows={5}
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </div>

                <select
                  className={`form-select custom-select-form form-select-sm ${
                    selection === "טקסט חופשי" ? "border_none w-10p" : ""
                  }`}
                  aria-label=".form-select-sm example"
                  onChange={(e) => setSelection(e.target.value)}
                  value={selection === "טקסט חופשי" ? "" : selection}
                >
                  <option
                    value=""
                    disabled
                    style={{ display: "none" }}
                  ></option>

                  {optionValues &&
                    optionValues.map((v, i) => (
                      <option key={v + i} value={v}>
                        {v}
                      </option>
                    ))}
                </select>
              </div>

              {errMsg && <p className="err_msg m-0 pe-1">נא להזין טקסט</p>}

              <div className="line">
                <RoundBtn
                  extraClass={`mt-5 mb-2 text-regular ${
                    desc ? "bg-primary-button" : "primary-disable"
                  }`}
                  text="להמשיך"
                  onClick={() => handleSubmit(false)}
                />
              </div>
            </>
          ) : (
            <ErrorBlackListWords
              desc={descWithBL}
              selection={selection}
              setEditMode={setEditMode}
              count={count}
              setCount={setCount}
              onClick={handleSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Situation;

const ErrorBlackListWords = ({
  desc,
  selection,
  setEditMode,
  count,
  setCount,
  onClick,
}) => {
  useEffect(() => {
    document.getElementById("displayBLstr").innerHTML = desc;
  }, []);
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userRequest = JSON.parse(sessionStorage.getItem("request"));
  let userGender = userData?.member_gender;

  return (
    <>
      <div className="dropdown_bgred pt-2">
        <div className="px-3">
          {/* Drop down Design start */}
          <div className="a11_text_dropdown">
            <h5 className="a11_disc_content a12_disc_content">התיאור שלך:</h5>
            <div className="input-group">
              <textarea
                className="form-control input_shadow custom_textarea top_textarea disabled-textarea"
                aria-label="With textarea"
                id="disabled-textarea"
                rows={5}
                defaultValue={``}
                readOnly
              />
            </div>
            <p className="text_area_desc" id="displayBLstr"></p>
            <select
              className={`form-select custom-select-form form-select-sm ${
                selection === "טקסט חופשי" ? "border_none w-10p" : ""
              }`}
              aria-label=".form-select-sm example"
            >
                <option value={selection}>{selection === "טקסט חופשי" ? "" : selection}</option>
            </select>
          </div>
          {/* Drop down Design end */}
          {count === 0 && (
            <>
              <h6 className="a12_dropdown_title">
                המילים המודגשות - אלה מילים שעלולות להתפרש בצורה שונה על ידי{" "}
                {userRequest.ask_from_partner_name}.
                {/* <span className="bright-red">{userData.name}</span>. */}
              </h6>
              <p className="a12_dropdown_disc">
                בוא
                {userGender === "male" ? " " : "י "}
                ננסה להחליף במילים אחרות ולנסח את המשפט בצורה אובייקטיבית (איך
                שמצלמה היתה תופסת). תיאור של המצב כמו שהוא, ממש כמו בדוגמאות
                מעלה.
              </p>
            </>
          )}

          {count > 0 && (
            <>
              <h6 className="a12_dropdown_title">
                אולי אפשר לדייק יותר את הניסוח.
              </h6>

              <p className="a12_dropdown_disc">
                המילים המודגשות - אלה מילים שעלולות להתפרש בצורה שונה על ידי{" "}
                {userRequest.ask_from_partner_name}.
                <br />
                אבל אפשר להמשיך ואולי לשנות בהמשך.
              </p>
            </>
          )}
        </div>
      </div>

      <div className="px-3">
        <div className="line">
          {count === 0 ? (
            <RoundBtn
              extraClass={`a12_custom_button text-regular bg-primary-button`}
              text="לנסח שוב"
              onClick={() => {
                setEditMode(true);
                setCount((c) => c + 1);
              }}
            />
          ) : (
            <div className="line">
              <RoundBtn
                extraClass={`a12_custom_button text-regular bg-primary-button`}
                text="להמשיך"
                onClick={() => onClick(true)}
              />
            </div>
          )}
        </div>

        {count === 0 && (
          <p className="a12_button_disc">אחרי 2 נסיונות, נמשיך לשלב הבא</p>
        )}

        {/* Outline black Button Design */}
        {count === 0 ? (
          <div className="line">
            <button
              className={`btn outline-black-button custom_button ${
                count && "mt-30"
              }`}
              onClick={() => onClick(true)}
            >
              אישור הניסוח הנוכחי והמשך
            </button>
          </div>
        ) : (
          <div className="line">
            <button
              className={`btn outline-black-button custom_button ${
                count && "mt-30"
              }`}
              onClick={() => {
                setEditMode(true);
                setCount((c) => c + 1);
              }}
            >
              רוצה להמשיך לשפר את הניסוח
            </button>
          </div>
        )}
      </div>
    </>
  );
};

const ListCoupleWithoutChildren = () => {
  return (
    <ol>
      <li> כאשר יש כלים בכיור</li>
      <li>שמתי לב שאין פירות במקרר</li>
      <li>בשבוע האחרון, לא דיברנו בטלפון במהלך היום</li>
      <li>שמעתי שאמרת שתגיע היום בשעה 18:00</li>
      <li>אני זוכרת שהפעם האחרונה ששכבנו היתה לפני חודש</li>
    </ol>
  );
};

const ListSpouseToSpouseWithChildren = () => {
  return (
    <ol>
      <li>ראיתי שיש צעצועים על השטיח בסלון</li>
      <li>זכור לי שיש אסיפת הורים מחר ב-19:30</li>
      <li>כאשר יש כלים בכיור</li>
      <li>אני זוכרת שהפעם האחרונה ששכבנו היתה לפני חודש</li>
      <li>
        שמתי לב שנכנסת אתמול למיטה בסביבות השעה 2 בלילה, אחרי שאני נכנסתי למיטה
        ב-23:00
      </li>
    </ol>
  );
};

const ListParentAskToChild = () => {
  return (
    <ol>
      <li>שמעתי אותך אומרת אתמול שהיום תסדרי את החדר שלך</li>
      <li>ראיתי שיש בגדים על המיטה ועל הרצפה בחדר שלך</li>
      <li>שמתי לב שאתה צופה בטלויזיה שלוש שעות ברציפות</li>
      <li> אני זוכר שיש לך מבחן במתמטיקה מחר</li>
      <li>שמעתי שהרמת את הקול על אחותך לפני רבע שעה</li>
    </ol>
  );
};

const ListChildAskToParents = () => {
  return (
    <ol>
      <li>שמעתי שאמרת אתמול שתדברי עם אבא בקשר לטיול השנתי</li>
      <li>ראיתי בקניון נעליים ממש יפות לדעתי</li>
      <li>היום בשעור תנ”ך המורה אמרה לי לצאת מהכיתה</li>
      <li>אני משחק במשחק ואפשר לקנות שם אביזרים לדמות שלי</li>
      <li>
        כשיהב לקח את השלט של הטלויזיה והעביר ערוץ, לא יכולתי לראות את התכנית שלי
      </li>
    </ol>
  );
};

const ListKidAskToSibling = () => {
  return (
    <ol>
      <li>ראיתי שהשתמשת היום במחשב שלי</li>
      <li>ראיתי שלקחת משהו מהתיק שלי</li>
      <li>כאשר אתה לוקח לי את השלט של הטלוויזיה</li>
      <li>שמתי לב שאת לובשת את החולצה שלי</li>
      <li>שמעתי שאמא אמרה שאנחנו צריכים להסתדר בינינו</li>
    </ol>
  );
};
