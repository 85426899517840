import { useDispatch, useSelector } from "react-redux";
import { addRemoveBadFeel } from "../../../redux/badFeelSlice";

const BadTabA = ({ data, index, blockId }) => {
    const dispatch = useDispatch();
    const selectedBFTabIDs = useSelector((state) =>
      state.badFeel.map((x) => x.id)
    );
  
    const saveData = (data) => {
      if (selectedBFTabIDs.length > 1) {
        if (selectedBFTabIDs.includes(data.id)) {
          dispatch(addRemoveBadFeel(data));
        } else {
          document.getElementById("max2_btn").click();
        }
      } else {
        dispatch(addRemoveBadFeel(data));
      }
    };
  
    return (
      <button
        className={`btn e7_custom_btn shadow_button mb-2 
        ${
          selectedBFTabIDs.includes(data.id) ? "bg_secondary_btn text-white" : ""
        } ${index === 0 ? "btn_text_dark" : ""}`}
        onClick={() => {
          saveData({ id: data.id, name: data.subCateName, blockId, index });
        }}
      >
        {data.subCateName}
      </button>
    );
  };


  export default BadTabA;