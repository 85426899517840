import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import callApi from "../../lib/apisauce/callApi";
import { queryString } from "../../utils/queryString";
import crown from "../img/crown.png";

const SubmitSelection = () => {
  const navigate = useNavigate();
  const selfConnData = JSON.parse(sessionStorage.getItem("selfConnectData"));

  useEffect(() => {
    if (
      selfConnData?.feelings_end === undefined ||
      selfConnData?.last_id === undefined
    ) {
      navigate("/home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  let goodFeel,
    badFeel,
    answeredNeeds,
    ignoredNeeds,
    last3,
    mostGF,
    mostBF,
    mostAnsNeed,
    mostIgNeed;

  goodFeel =
    selfConnData?.g_feelings && selfConnData?.g_feelings?.replaceAll(":", ", ");
  badFeel =
    selfConnData?.b_feelings && selfConnData?.b_feelings?.replaceAll(":", ", ");
  answeredNeeds =
    selfConnData?.g_needs && selfConnData?.g_needs?.replaceAll(":", ", ");
  ignoredNeeds =
    selfConnData?.b_needs && selfConnData?.b_needs?.replaceAll(":", ", ");
  mostGF = selfConnData?.most_g_feelings?.replaceAll(":", ", ");
  mostBF = selfConnData?.most_b_feelings?.replaceAll(":", ", ");
  mostAnsNeed = selfConnData?.most_g_needs?.replaceAll(":", ", ");
  mostIgNeed = selfConnData?.most_b_needs?.replaceAll(":", ", ");

  last3 = selfConnData?.last3;

  const [alert, setAlert] = useState(false);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));

  useEffect(() => {
    const timeout = setTimeout(() => setAlert(false), 3000);
    return () => clearTimeout(timeout);
  }, [alert]);

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  let copyGF = mostGF !== undefined && mostGF.length > 0 ? mostGF : goodFeel;
  let copyBF = mostBF !== undefined && mostBF.length > 0 ? mostBF : badFeel;
  let copyAnsNeed =
    mostAnsNeed !== undefined && mostAnsNeed.length > 0
      ? mostAnsNeed
      : answeredNeeds;
  let copyIgnNeed =
    mostIgNeed !== undefined && mostIgNeed.length > 0
      ? mostIgNeed
      : ignoredNeeds;

  const copyToClipBoard = async () => {
    let copyData = `רגשות נעימים : ${copyGF}
    \nצרכים שמקבלים מענה : ${copyAnsNeed}
    \nרגשות לא נעימים : ${copyBF}
    \nצרכים שלא מקבלים מענה : ${copyIgnNeed}`;

    try {
      await navigator.clipboard.writeText(copyData);
      setCopied(true);
      setAlert(true);
    } catch (err) {
      err && console.log(err);
    }
  };

  const actionTaken = () => {
    const allData = {
      ...selfConnData,
      does_copy: copied ? "Y" : "N",
      action: "home",
      status: "A",
    };
    let DataRemovedBlankVal = Object.fromEntries(
      Object.entries(allData).filter((value) => value[1])
    ); //Remove key with Blank value if any.
    delete DataRemovedBlankVal?.last3;
    delete DataRemovedBlankVal?.other_text;

    submitDatatoDB(DataRemovedBlankVal);
  };

  const submitDatatoDB = async (data) => {
    setLoading(true);
    try {
      const res = await callApi.postData("self-connecting", queryString(data), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          Authorization: JWToken,
        },
      });

      if (res.ok) {
        if (res.data.status === "success") {
          sessionStorage.removeItem("selfConnectData");
          setLoading(false);
          navigate("/home");
        } else {
          console.log(res.data.message);
        }
      } else {
        res.originalError && console.log(res.originalError);
      }
    } catch (error) {
      error && console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="bg-primary_e">
        <Header extraClass="border-bottom" />

        <div className="e11_content">
          <div className="container pb-4">
            <div className="e_emoji_icon mb-3">
              <img src={crown} alt="Crown" />
            </div>

            <h2 className="e11_content_head mb-4">
              {userData?.name}, תודה ששיתפת!
            </h2>
            <h5 className="e11_title_content_disc">
              הנה סיכום הרגשות והצרכים שבחרת:
            </h5>

            {mostGF && mostGF.length ? (
              <div className="e11_content_disc mb-4 mt-4">
                <p>רגשות נעימים:</p>
                <h6>{mostGF}</h6>
              </div>
            ) : goodFeel && goodFeel.length ? (
              <div className="e11_content_disc mb-4 mt-4">
                <p>רגשות נעימים:</p>
                <h6>{goodFeel}</h6>
              </div>
            ) : (
              ""
            )}

            {mostAnsNeed && mostAnsNeed.length ? (
              <div className="e11_content_disc mb-4">
                <p>צרכים שמקבלים מענה:</p>
                <h6>{mostAnsNeed}</h6>
              </div>
            ) : answeredNeeds && answeredNeeds.length ? (
              <div className="e11_content_disc mb-4">
                <p>צרכים שמקבלים מענה:</p>
                <h6>{answeredNeeds}</h6>
              </div>
            ) : (
              ""
            )}

            {mostBF && mostBF.length ? (
              <div className="e11_content_disc mb-4">
                <p>רגשות לא נעימים:</p>
                <h6>{mostBF}</h6>
              </div>
            ) : badFeel && badFeel.length ? (
              <div className="e11_content_disc mb-4">
                <p>רגשות לא נעימים:</p>
                <h6>{badFeel}</h6>
              </div>
            ) : (
              ""
            )}

            {mostIgNeed && mostIgNeed.length ? (
              <div className="e11_content_disc mb-4">
                <p>צרכים שלא מקבלים מענה :</p>
                <h6>{mostIgNeed}</h6>
              </div>
            ) : ignoredNeeds && ignoredNeeds.length ? (
              <div className="e11_content_disc mb-4">
                <p>צרכים שלא מקבלים מענה :</p>
                <h6>{ignoredNeeds}</h6>
              </div>
            ) : (
              ""
            )}

            {last3 === "Y" && (
              <div className="e11_content_disc mb-4">
                <p className="note">
                  לפעמים גילויים על עצמינו מעלים קושי וכאב.
                  <br />
                  החוויה הזאת עלולה להיות קשה מאוד.
                  <br />
                  אם יש תחושות קשות/לא נעימות כרגע, מומלץ
                  <br />
                  להתבונן בצרכים (ממקום חומל וסקרן) ולבדוק איך
                  <br />
                  ניתן לתת מענה (אפילו קטן ביותר) לצרכים אלה.{" "}
                </p>
              </div>
            )}

            {/* Button Design 1 */}
            <div className="line">
              <RoundBtn
                extraClass="text-regular bg-secondary-button mt-5"
                text="העתק"
                onClick={copyToClipBoard}
              />
              {alert && <p className="copy-text">הועתק בהצלחה </p>}
            </div>

            {/* Button Design 2 */}
            <div className="line position-relative">
              <RoundBtn
                extraClass="text-regular bg-secondary-button mt-4"
                text="חזרה לעמוד הבית"
                onClick={actionTaken}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmitSelection;
