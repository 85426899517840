import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

function SmoothScroll({ children }) {
  const { pathname } = useLocation();
  const navType = useNavigationType();

  useEffect(() => {
    if (navType !== "POP") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [pathname]);
  return <>{children}</>;
}
export default SmoothScroll;
