import GoodTab from "./GoodTabE";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import downArrow from "../../pages/img/downArrow.png";

const BlueLine = React.memo(() => {
  return (
    <div className="toggle_button_blue_simple">
      <div className="toggle_button_border_simple"></div>
    </div>
  );
});

const BlackLine = React.memo(() => {
  return (
    <div className="toggle_button_black del-mt4">
      <div className="toggle_border_black"></div>
    </div>
  );
});

const BlackLineUpArrow = React.memo(({ onClick }) => {
  return (
    <div className="toggle_button_black del-mt4">
      <div className="toggle_border_black"></div>
      <div className="black_arrow" onClick={onClick}>
        <img src="assets/images/hide_arrow_black.svg" alt="" />
      </div>
    </div>
  );
});

const BlueLineArrowDesign = React.memo(({ onClick }) => {
  return (
    <div className="toggle_button_blue">
      <div className="toggle_button_border">
        <div className="shape_button" />
        <div className="shape_button" />
        <div className="shape_button" />
      </div>
      <div className="hide_arrow" onClick={onClick}>
        <img src={downArrow} alt="Blue Arrow" />
      </div>
    </div>
  );
});

const GoodFeelBlockE = React.memo(
  ({ emotionId, dataArr, isMatch, itemPerRow }) => {
    const [show, setShow] = useState(false);
    const selectedData = useSelector((state) => state.goodFeel).filter(
      (a) => a.blockId === emotionId
    );
    const selectedIndices = selectedData.map((b) => b.index);

    let keepOpen = !isMatch && selectedIndices.some((e) => e > itemPerRow - 1);
    let BlockClass = isMatch
      ? "show52"
      : keepOpen
      ? "show100"
      : show
      ? "show100"
      : "show52";

    return (
      <div className="tab_btn_all mt-4 px-1">
        <div className={BlockClass}>
          <div
            className="all_e7_btn d-flex flex-wrap justify-content-center"
            id={"element" + emotionId}
          >
            {dataArr &&
              dataArr.map((feel, i) => (
                <GoodTab key={uuidv4()} index={i} data={feel} blockId={emotionId} />
              ))}
          </div>
        </div>

        {isMatch ? (
          <BlueLine />
        ) : keepOpen ? (
          <BlackLine />
        ) : show ? (
          <BlackLineUpArrow onClick={() => setShow(!show)} />
        ) : (
          <BlueLineArrowDesign onClick={() => setShow(!show)} />
        )}
      </div>
    );
  }
);

export default GoodFeelBlockE;
