import { useEffect } from "react";
import { useNavigate } from "react-router";
import Header from "../../components/layout/Header";

import Dad from "../../components/svg/Dad";
import DadBlue from "../../components/svg/DadBlue";
import Mom from "../../components/svg/Mom";
import MomBlue from "../../components/svg/MomBlue";
import KidM from "../../components/svg/KidM";
import KidMBlue from "../../components/svg/KidMBlue";
import KidF from "../../components/svg/KidF";
import KidFBlue from "../../components/svg/KidFBlue";
import Brother from "../../components/svg/Brother";
import BrotherBlue from "../../components/svg/BrotherBlue";
import Sister from "../../components/svg/Sister";
import SisterBlue from "../../components/svg/SisterBlue";
import OtherMale from "../../components/svg/OtherMale";
import OtherMBlue from "../../components/svg/OtherMBlue";
import OtherFemale from "../../components/svg/OtherFemale";
import OtherFBlue from "../../components/svg/OtherFBlue";

const BothKids = ({ partner = "", onClick }) => {
  return (
    <>
      <div className="profile_avtar_wraper">
        <div onClick={() => onClick("kid_m")}>
          {partner === "kid_m" ? <KidMBlue /> : <KidM />}
          <h5 className="avatar_img_title">הבן</h5>
        </div>
        <div onClick={() => onClick("kid_f")}>
          {partner === "kid_f" ? <KidFBlue /> : <KidF />}
          <h5 className="avatar_img_title">הבת</h5>
        </div>
      </div>
    </>
  );
};

const RelationWithMale = ({ partner = "", onClick }) => {
  return (
    <div className="profile_avtar_wraper">
      <div onClick={() => onClick("other_m")}>
        {partner === "other_m" ? <OtherMBlue /> : <OtherMale />}
        <h5 className="avatar_img_title">בן הזוג</h5>
      </div>
    </div>
  );
};

const RelationWithFemale = ({ partner = "", onClick }) => {
  return (
    <div className="profile_avtar_wraper">
      <div onClick={() => onClick("other_f")}>
        {partner === "other_f" ? <OtherFBlue /> : <OtherFemale />}
        <h5 className="avatar_img_title">בת הזוג</h5>
      </div>
    </div>
  );
};

const RelationWithOther = ({ partner = "", onClick }) => {
  return (
    <>
      <div className="profile_avtar_wraper">
        <div onClick={() => onClick("other_m")}>
          {partner === "other_m" ? <OtherMBlue /> : <OtherMale />}
          <h5 className="avatar_img_title">בן הזוג</h5>
        </div>
        <div onClick={() => onClick("other_f")}>
          {partner === "other_f" ? <OtherFBlue /> : <OtherFemale />}
          <h5 className="avatar_img_title">בת הזוג</h5>
        </div>
      </div>
    </>
  );
};

const MaleParent = ({ partner = "", onClick, userFamilyRole }) => {
  return (
    <>
      <div className="profile_avtar_wraper">
        <div onClick={() => onClick("dad")}>
          {partner === "dad" ? <DadBlue /> : <Dad />}
          <h5 className="avatar_img_title">
            {userFamilyRole === "adult" ? "בן הזוג" : "אבא"}
          </h5>
        </div>
      </div>
    </>
  );
};

const FemaleParent = ({ partner = "", onClick, userFamilyRole }) => {
  return (
    <>
      <div className="profile_avtar_wraper">
        <div onClick={() => onClick("mom")}>
          {partner === "mom" ? <MomBlue /> : <Mom />}
          <h5 className="avatar_img_title">
            {userFamilyRole === "adult" ? "בת הזוג" : "אמא"}
          </h5>
        </div>
      </div>
    </>
  );
};

const BothParents = ({ partner = "", onClick }) => {
  return (
    <>
      <div className="profile_avtar_wraper">
        <div onClick={() => onClick("dad")}>
          {partner === "dad" ? <DadBlue /> : <Dad />}
          <h5 className="avatar_img_title">אבא</h5>
        </div>
        <div onClick={() => onClick("mom")}>
          {partner === "mom" ? <MomBlue /> : <Mom />}
          <h5 className="avatar_img_title">אמא</h5>
        </div>
      </div>
    </>
  );
};

const BothSiblings = ({ partner = "", onClick }) => {
  return (
    <>
      <div className="profile_avtar_wraper">
        <div onClick={() => onClick("brother")}>
          {partner === "brother" ? <BrotherBlue /> : <Brother />}
          <h5 className="avatar_img_title">אח</h5>
        </div>
        <div onClick={() => onClick("sister")}>
          {partner === "sister" ? <SisterBlue /> : <Sister />}
          <h5 className="avatar_img_title">אחות</h5>
        </div>
      </div>
    </>
  );
};

const SituationAgainst = () => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let familyRole = userData?.family_role;
  let parentRelationship = userData?.parent_relationship;
  let adultRelationship = userData?.adult_relationship;
  const navigate = useNavigate();
  const screenHeight = window.innerHeight;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const userRequest = JSON.parse(sessionStorage.getItem("judgement"));
  let selectedPartner = userRequest?.ask_from_partner_roll;

  const userFamilyRole =
    familyRole === "mom" ||
    familyRole === "dad" ||
    familyRole === "adult_f" ||
    familyRole === "adult_m"
      ? "adult"
      : "child";

  const kidsExist = userData?.kids_exist;
  const siblingExist = userData?.sibling;

  const relationWith =
    parentRelationship === "male" || adultRelationship === "male"
      ? "male"
      : parentRelationship === "female" || adultRelationship === "female"
      ? "female"
      : "other";

  const handleClick = (data) => {
    const judgementData = JSON.stringify({ ask_from_partner_roll: data });
    sessionStorage.setItem("judgement", judgementData);
    navigate("/situ-partner-name");
  };

  return (
    <>
      <div className="bg-light_e">
        <Header />

        <div
          className={`a7_content ${screenHeight <= 568 ? "pt-50" : "pt-60"}`}
        >
          <div className="container pt-5">
            <div className="a7_header_content text-center">
              <h5>
                נתמקד באיזושהי סיטואציה
                <br />
                מאתגרת שקרתה בעבר,
                <br />
                שקורית עכשיו או שיש לנו
                <br />
                חשש שתקרה בעתיד.
              </h5>
            </div>
          </div>
        </div>

        <div
          className={`a8_profile-avatar ${
            screenHeight <= 568 ? "mt-4" : "mt-5"
          }`}
        >
          <div className="container pt-4">
            <div className="a7_header_content text-center">
              <h5>מול מי הסיטואציה?</h5>
            </div>
            {userFamilyRole === "adult" ? (
              <div className="row justify-content-center w-100 m-0">
                {/* showing both kids */}
                {kidsExist === "with_children" && (
                  <BothKids partner={selectedPartner} onClick={handleClick} />
                )}

                {/* showing relation with male / female / both */}

                {(familyRole === "mom" || familyRole === "adult_f") &&
                relationWith === "male" ? (
                  <MaleParent
                    partner={selectedPartner}
                    onClick={handleClick}
                    userFamilyRole={userFamilyRole}
                  />
                ) : relationWith === "male" ? (
                  <RelationWithMale
                    partner={selectedPartner}
                    onClick={handleClick}
                  />
                ) : (familyRole === "dad" || familyRole === "adult_m") &&
                  relationWith === "female" ? (
                  <FemaleParent
                    partner={selectedPartner}
                    onClick={handleClick}
                    userFamilyRole={userFamilyRole}
                  />
                ) : relationWith === "female" ? (
                  <RelationWithFemale
                    partner={selectedPartner}
                    onClick={handleClick}
                  />
                ) : relationWith === "other" ? (
                  <RelationWithOther
                    partner={selectedPartner}
                    onClick={handleClick}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              //if user is child
              <div className="row justify-content-center w-100 m-0">
                <BothParents partner={selectedPartner} onClick={handleClick} />

                {siblingExist === "Yes" && (
                  <BothSiblings
                    partner={selectedPartner}
                    onClick={handleClick}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SituationAgainst;
