const MinorShape = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={128}
    width={"100%"}
    viewBox="0 0 360 107"
    fill="none"
    {...props}
  >
    <path
      d="M486 306.5C486 475.775 348.775 613 179.5 613S-127 475.775-127 306.5 10.225 0 179.5 0 486 137.225 486 306.5Z"
      fill="#3869E5"
    />
  </svg>
);

export default MinorShape;
