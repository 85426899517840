import { useState } from "react";
import { useNavigate } from "react-router-dom";

const WhoAmISmall = () => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("userData"))
  );
  const navigate = useNavigate();
  const gender = userData?.member_gender;
  const familyRole = userData?.family_role;

  const handleClick = (val) => {
    const newUserData = { ...userData, family_role: val };
    sessionStorage.setItem("userData", JSON.stringify(newUserData));
    navigate("/query-form");
  };

  return (
    <>
      <div className="d-flex main_ami_flex">
        <div className="profile_section mt-15">
          <div className="container">
            <div className="profile-title text-center mb-5">
              <h4 className="custom-lebel-register">
                4. במשפחה שלך את
                {gender === "male" ? "ה" : ""}:
              </h4>
            </div>
            <div className="row justify-content-center">
              {userData?.kids_exist === "with_children" ? (
                <>
                  <div className="profile_avtar_wraper">
                    <div>
                      <img
                        src={`assets/images/${
                          familyRole === "kid_m"
                            ? "kid_m_click.svg"
                            : "kid_m.svg"
                        }`}
                        alt="Person"
                        className="profile_avatar_img"
                        onClick={() => handleClick("kid_m")}
                      />
                      <h5 className="avatar_img_title">הילד</h5>
                    </div>

                    <div>
                      <img
                        src={`assets/images/${
                          familyRole === "kid_f"
                            ? "kid_f_click.svg"
                            : "kid_f.svg"
                        }`}
                        alt="Person"
                        className="profile_avatar_img"
                        onClick={() => handleClick("kid_f")}
                      />
                      <h5 className="avatar_img_title">הילדה</h5>
                    </div>
                  </div>

                  <div className="profile_avtar_wraper">
                    <div>
                      <img
                        src={`assets/images/${
                          familyRole === "mom" ? "mom_click.svg" : "mom.svg"
                        }`}
                        alt="Person"
                        className="profile_avatar_img"
                        onClick={() => handleClick("mom")}
                      />
                      <h5 className="avatar_img_title">האמא</h5>
                    </div>

                    <div>
                      <img
                        src={`assets/images/${
                          familyRole === "dad" ? "dad_click.svg" : "dad.svg"
                        }`}
                        alt="Person"
                        className="profile_avatar_img"
                        onClick={() => handleClick("dad")}
                      />
                      <h5 className="avatar_img_title">האבא</h5>
                    </div>
                  </div>
                </>
              ) : (
                <div className="profile_avtar_wraper">
                  <div>
                    <img
                      src={`assets/images/${
                        familyRole === "adult_f" ? "mom_click.svg" : "mom.svg"
                      }`}
                      alt="Person"
                      className="profile_avatar_img"
                      onClick={() => handleClick("adult_f")}
                    />
                    <h5 className="avatar_img_title">בת זוג</h5>
                  </div>

                  <div>
                    <img
                      src={`assets/images/${
                        familyRole === "adult_m" ? "dad_click.svg" : "dad.svg"
                      }`}
                      alt="Person"
                      className="profile_avatar_img"
                      onClick={() => handleClick("adult_m")}
                    />
                    <h5 className="avatar_img_title">בן זוג</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="footer_ami_s">
          <div className="round-shape-footer_s">
            <img
              src="assets/images/profile-round-shape.svg"
              style={{ height: 92 }}
              alt="Round shape"
            />
          </div>
          <div className="footer_shape_design">
            <img
              src="assets/images/profile-shape.svg"
              alt="Profile Shape"
              className="footer-img w-100"
            />
            {userData?.kids_exist === "with_children" && (
              <div className="line">
                <h5 className="img-shape_profile_head">
                  * סבא וסבתא מוזמנים
                  <br />
                  להמשיך כאמא/אבא
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const WhoAmILarge = () => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("userData"))
  );
  const gender = userData?.member_gender;
  const familyRole = userData?.family_role;

  const navigate = useNavigate();
  const handleClick = (val) => {
    const newUserData = { ...userData, family_role: val };
    sessionStorage.setItem("userData", JSON.stringify(newUserData));
    navigate("/query-form");
  };

  return (
    <>
      <div style={{ backgroundColor: "#eceff0", height: "100vh" }}>
        <div className="profile_section  padding_top">
          <div className="container">
            <div className="profile-title text-center mb-5">
              <h4 className="custom-lebel-register">
                4. במשפחה שלך את
                {gender === "male" ? "ה" : ""}:
              </h4>
            </div>
            <div className="row justify-content-center">
              {userData?.kids_exist === "with_children" ? (
                <>
                  <div className="profile_avtar_wraper">
                    <div>
                      <img
                        src={`assets/images/${
                          familyRole === "kid_m"
                            ? "kid_m_click.svg"
                            : "kid_m.svg"
                        }`}
                        alt="Person"
                        className="profile_avatar_img"
                        onClick={() => handleClick("kid_m")}
                      />
                      <h5 className="avatar_img_title">הילד</h5>
                    </div>

                    <div>
                      <img
                        src={`assets/images/${
                          familyRole === "kid_f"
                            ? "kid_f_click.svg"
                            : "kid_f.svg"
                        }`}
                        alt="Person"
                        className="profile_avatar_img"
                        onClick={() => handleClick("kid_f")}
                      />
                      <h5 className="avatar_img_title">הילדה</h5>
                    </div>
                  </div>

                  <div className="profile_avtar_wraper">
                    <div>
                      <img
                        src={`assets/images/${
                          familyRole === "mom" ? "mom_click.svg" : "mom.svg"
                        }`}
                        alt="Person"
                        className="profile_avatar_img"
                        onClick={() => handleClick("mom")}
                      />
                      <h5 className="avatar_img_title">האמא</h5>
                    </div>

                    <div>
                      <img
                        src={`assets/images/${
                          familyRole === "dad" ? "dad_click.svg" : "dad.svg"
                        }`}
                        alt="Person"
                        className="profile_avatar_img"
                        onClick={() => handleClick("dad")}
                      />
                      <h5 className="avatar_img_title">האבא</h5>
                    </div>
                  </div>
                </>
              ) : (
                <div className="profile_avtar_wraper">
                  <div>
                    <img
                      src={`assets/images/${
                        familyRole === "adult_f" ? "mom_click.svg" : "mom.svg"
                      }`}
                      alt="Person"
                      className="profile_avatar_img"
                      onClick={() => handleClick("adult_f")}
                    />
                    <h5 className="avatar_img_title">בת זוג</h5>
                  </div>

                  <div>
                    <img
                      src={`assets/images/${
                        familyRole === "adult_m" ? "dad_click.svg" : "dad.svg"
                      }`}
                      alt="Person"
                      className="profile_avatar_img"
                      onClick={() => handleClick("adult_m")}
                    />
                    <h5 className="avatar_img_title">בן זוג</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="round-shape-footer">
            <img
              src="assets/images/profile-round-shape.svg"
              alt="Round shape"
            />
          </div>
          <div className="footer_shape_design">
            <img
              src="assets/images/profile-shape.svg"
              alt="Profile Shape"
              className="footer-img w-100"
            />
            {userData?.kids_exist === "with_children" && (
              <div className="line">
                <h5 className="img-shape_profile_head">
                  * סבא וסבתא מוזמנים
                  <br />
                  להמשיך כאמא/אבא
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const WhoAmI = () => {
  const screenHeight = window.innerHeight;
  return <>{screenHeight < 611 ? <WhoAmISmall /> : <WhoAmILarge />}</>;
};

export default WhoAmI;
