import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";

import MomL from "../../components/svg/MomL";
import DadL from "../../components/svg/DadL";
import OtherFemaleL from "../../components/svg/OtherFemaleL";
import OtherMaleL from "../../components/svg/OtherMaleL";
import KidFL from "../../components/svg/KidFL";
import KidML from "../../components/svg/KidML";
import BrotherL from "../../components/svg/BrotherL";
import SisterL from "../../components/svg/SisterL";

const SituPartnerName = () => {
  const judgementData = JSON.parse(sessionStorage.getItem("judgement"));
  const [name, setName] = useState(judgementData?.ask_from_partner_name || "");
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let familyRole = userData?.family_role;

  const navigate = useNavigate();

  useEffect(() => {
    if (judgementData?.ask_from_partner_roll === undefined) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const partnerRole = judgementData?.ask_from_partner_roll;

  const family = [
    { text: "הילדה", val: "kid_m" },
    { text: "הילד", val: "kid_f" },
    { text: "אבא", val: "mom" },
    { text: "אמא", val: "dad" },
    { text: "אבא", val: "adult_f" },
    { text: "אמא", val: "adult_m" },
    { text: "מבת הזוג", val: "other_f" },
    { text: "מבן הזוג", val: "other_m" },
    { text: "אח", val: "brother" },
    { text: "אחות", val: "sister" },
  ];

  const requestFrom = family.filter((x) => x.val === partnerRole)[0];
  let val = requestFrom?.val;

  let pronoun;
  if (
    ["kid_m", "dad", "adult_m", "other_m", "brother"].includes(
      judgementData?.ask_from_partner_roll
    )
  ) {
    pronoun = "לו";
  } else {
    pronoun = "לה";
  }

  let requestFromMy =
    val === "brother"
      ? "אח"
      : val === "sister"
      ? "אחות"
      : val === "kid_m"
      ? "הבן"
      : val === "kid_f"
      ? "הבת"
      : val === "dad" && (familyRole === "kid_m" || familyRole === "kid_f")
      ? "אבא"
      : val === "mom" && (familyRole === "kid_m" || familyRole === "kid_f")
      ? "אמא"
      : val === "adult_m" || val === "other_m" || val === "dad"
      ? "בן הזוג"
      : "בת הזוג";

  const handleSubmit = () => {
    const newData = JSON.stringify({
      ...judgementData,
      ask_from_partner_name: name,
    });
    sessionStorage.setItem("judgement", newData);
    if (name.length) {
      navigate("/judgement-description");
    }
  };
  return (
    <>
      <div className="bg-light_e">
        <Header />
        <div className="a7_content pt-90">
          <div className="container">
            <div className="a7_header_content text-center pt-4">
              <h5>סיטואציה מול {requestFromMy} שלי</h5>
              {(partnerRole === "mom" || partnerRole === "adult_f") && <MomL />}
              {(partnerRole === "dad" || partnerRole === "adult_m") && <DadL />}
              {partnerRole === "kid_f" && <KidFL />}
              {partnerRole === "kid_m" && <KidML />}
              {partnerRole === "brother" && <BrotherL />}
              {partnerRole === "sister" && <SisterL />}
              {partnerRole === "other_f" && <OtherFemaleL />}
              {partnerRole === "other_m" && <OtherMaleL />}
            </div>
            <div className="a7_header_content mt-4 text-center px-3">
              <h5>
                איך קוראים {pronoun}?
                <br />
                מומלץ לכתוב את שם החיבה :)
              </h5>
              {/* Input Design */}
              <div className="mb-3 mt-3 w-100">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control input_shadow custom_input"
                />
              </div>

              {/* Button Design */}
              <div className="line ">
                <RoundBtn
                  extraClass={`mt-5 mb-2 text-regular
                      ${name ? "bg-primary-button" : "primary-disable"}`}
                  text="להמשיך"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SituPartnerName;
