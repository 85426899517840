import { useDispatch, useSelector } from "react-redux";
import { addRemoveAnsweredNeeds } from "../../redux/answeredNeedsSlice";

const AnsweredNeedTabE = ({ data, index, blockId }) => {
    const dispatch = useDispatch();
    const selectedNeedIds = useSelector((state) =>
      state.answeredNeeds.map((x) => x.id)
    );
  
    const saveData = (data) => {
      dispatch(addRemoveAnsweredNeeds(data));
    };
  
    return (
      <button
        className={`btn e7_custom_btn shadow_button mb-2 
        ${
          selectedNeedIds.includes(data.id) ? "bg_secondary_btn text-white" : ""
        } ${index == 0 ? "btn_text_dark" : ""}`}
        onClick={() => {
          saveData({ id: data.id, name: data.subCateName, blockId, index });
        }}
      >
        {data.subCateName}
      </button>
    );
  };


  export default AnsweredNeedTabE;