const Heart = () => {
  return (
    <svg
      width="53"
      height="48"
      viewBox="0 0 53 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.5722 2C45.2017 2 51 10.195 51 17.84C51 33.3225 26.9356 46 26.5 46C26.0644 46 2 33.3225 2 17.84C2 10.195 7.79833 2 16.4278 2C21.3822 2 24.6217 4.5025 26.5 6.7025C28.3783 4.5025 31.6178 2 36.5722 2Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Heart;
