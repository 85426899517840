import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import thumbsUp from "../img/ThumbsUp.png";

const SecondStop = () => {
  const navigate = useNavigate();
  const userRequest = JSON.parse(sessionStorage.getItem("request"));

  useEffect(() => {
    if (userRequest?.feelings === undefined) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="bg-primary_e ">
        <Header extraClass="border-bottom" />

        <div className="a13_content padding-bottom text-center px-3">
          <div className="container pt-5">
            <h5>
              עברת גם את
              <br />
              התחנה השנייה{" "}
            </h5>
            <br />
            <img src={thumbsUp} alt="ThumbsUp" className="mb-3" />

            <Link to="/my-needs" className="line">
              <RoundBtn
                extraClass="mt-5 text-regular bg-secondary-button"
                text="להמשיך"
              />
            </Link>

            {/* Outline Button Design */}
            <Link to="/situation-feelings" className="line">
              <button className="btn bg-outline-button custom_button text-regular mt-4">
                לחזור לבחירת הרגשות
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondStop;
