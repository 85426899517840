import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import smileFace from "../img/smileFace.png";

const ThirdStop = () => {
  const navigate = useNavigate();
  const userRequest = JSON.parse(sessionStorage.getItem("request"));

  useEffect(() => {
    if (userRequest?.needs === undefined) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  
  return (
    <>
      <div className="bg-primary_e ">
        <Header extraClass="border-bottom" />

        <div className="a13_content padding-bottom text-center px-3">
          <div className="container pt-5">
            <h5>
              <span>ייאי! גם התחנה</span>
              <br />
              <span>השלישית מאחוריך!</span>
              <br />
              <br />
              <span style={{ fontSize: 20 }}> אנחנו ממש לקראת סיום </span>
            </h5>

            <img src={smileFace} alt="smile-face" className="mt-2" />

            <Link to="/guess-text" className="line">
              <RoundBtn
                extraClass="mt-5 text-regular bg-secondary-button"
                text="להמשיך"
              />
            </Link>

            {/* Outline Button Design */}
            <Link to="/my-needs" className="line">
              <button className="btn bg-outline-button custom_button text-regular mt-4">
                לחזור לבחירת הצרכים
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdStop;
