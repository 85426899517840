import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";

const ReadSummary = () => {
  const navigate = useNavigate();
  const userRequest = JSON.parse(sessionStorage.getItem("request"));

  useEffect(() => {
    if (userRequest?.final_text === undefined) navigate("/home");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const displayData = JSON.parse(sessionStorage.getItem("displayData"));

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  const handleSubmit = () => {
    sessionStorage.removeItem("request");
    sessionStorage.removeItem("displayData");
    sessionStorage.removeItem("finalSelect");
    sessionStorage.removeItem("finalFreeText");
    navigate("/home");
  };

  return (
    <>
      <div className="bg-primary_e">
        <Header />
        <div className="a24_content pt-80 px-3">
          <div className="container">
            <h5 className="a11_disc_content text-white mt-3">
              הנה הבקשה שלך :)
            </h5>
            {/* summary box design start */}
            <div className="talk-bubble tri-right round right-top">
              <div className="talktext">
                {/* <p>
                  {userRequest?.fixed_final_text.split("\n").map((itm, idx) => (
                    <span key={itm + idx}>
                      {itm}
                      <br />
                    </span>
                  ))}
                </p> */}
                <p>
                  {displayData.first_Text
                    .replace("\n", "")
                    .split("\n")
                    .map((item, i) => (
                      <span key={item + i}>
                        {item}
                        <br />
                      </span>
                    ))}
                </p>
                {displayData.draft_Select === "טקסט חופשי" ||
                displayData.draft_Select === "" ? (
                  <div>
                    {/* <img
                      src="assets/images/blue-arrow.svg"
                      width={15}
                      height={20}
                    /> */}
                    <p>{displayData.draft_Desc}</p>
                  </div>
                ) : (
                  ""
                )}
                {displayData.draft_Select !== "" &&
                displayData.draft_Select !== "טקסט חופשי" ? (
                  <div style={{ textAlign: "right" }}>
                    <p style={{ display: "inline-block" }} className="mb-0">
                      {displayData.draft_Select}
                    </p>
                    <p style={{ display: "inline-block" }}>
                      {displayData.draft_Desc}
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {displayData.last_Select === "טקסט חופשי" ? (
                  <>
                    <div>
                      {/* <img
                        src="assets/images/blue-arrow.svg"
                        width={15}
                        height={20}
                      /> */}
                    </div>
                    <p>{displayData.free_Text}</p>
                  </>
                ) : (
                  <p>{displayData.last_Select}</p>
                )}
              </div>
            </div>
            {/* summary box design end */}
            <div className="a24_disc_para">
              <h6>
                כשאומרים את הבקשה בעל פה, חשוב שהצד השני ירגיש שיש לו בחירה
                חופשית. לחץ ודרישה לא מאפשרים בחירה חופשית ומעוררים
                התנגדות/מתקפה.
              </h6>
              <h6>יעזור להגיד את הדברים ברוגע ולשחרר ציפיות ל”כן” כתגובה.</h6>
              <h6>
                זה לא פשוט, ובדרך כלל לוקח זמן לעבור לתקשורת מקרבת. בבקשה
                {userGender === "male" ? " תן " : " תני "} לעצמך להתנסות שוב
                ושוב :)
              </h6>
            </div>
            {/* Outline Button Design */}
            <div className="line position-relative">
              <button
                onClick={handleSubmit}
                className="btn bg-outline-button custom_button text-regular mt-4"
              >
                סיום
              </button>
            </div>
            <p className="a_footer_disc text-white">
              *הניסוח יישמר באופן אוטומטי בהיסטוריה
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReadSummary;
