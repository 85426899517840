import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import heartHand from "../img/heart-hand.png";

const GuessText = () => {
  const navigate = useNavigate();
  const userRequest = JSON.parse(sessionStorage.getItem("request"));

  useEffect(() => {
    if (userRequest?.needs === undefined) {
      navigate("/home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  let partnerGender;
  if (
    ["kid_m", "dad", "adult_m", "other_m", "brother"].includes(
      userRequest?.ask_from_partner_roll
    )
  ) {
    partnerGender = "male";
  } else {
    partnerGender = "female";
  }

  const screenWidth = window.innerWidth;

  return (
    <div className="bg-red-a">
      <Header />
      <div
        className={`a18_content pt-100 pb-3 ${
          screenWidth < 340 ? "px-1" : "px-3"
        }`}
      >
        <div className="container">
          <div className="a18_header_title">
            <h1>
              אבל רגעעעעע!
              <img
                src={heartHand}
                alt="heart-hand"
                style={{ marginTop: -33 }}
              />
            </h1>
            <h3>גם {userRequest?.ask_from_partner_name} בתמונה! </h3>
          </div>
          <div className="a18_disc_content">
            <p>
              גם ל{userRequest?.ask_from_partner_name} (בדיוק כמוך) עולים רגשות
              וצרכים במצב הזה.
            </p>

            <h6>
              מה לדעתך {userRequest?.ask_from_partner_name} מרגיש
              {partnerGender === "male" ? " " : "ה "}
              וצרי
              {partnerGender === "male" ? "ך" : "כה"}?
            </h6>
          </div>
          <div className="a18_disc_text">
            <h6>זאת הזדמנות לנסות לנחש, לפני שנעבור לתחנה הבאה.</h6>
            <h6>
              הניחוש שלך עשוי להגדיל את הסיכויים ש
              {userRequest?.ask_from_partner_name}{" "}
              {partnerGender === "male" ? "י" : "ת"}
              שתף פעולה ו{partnerGender === "male" ? "י" : "ת"}
              גיד “כן” לבקשה, ובעיקר זה יכול לעזור לחיבור ולקשר שלך עם{" "}
              {userRequest?.ask_from_partner_name}.
            </h6>
          </div>
          <h6 className="a18_label_disc mt-5">רוצה לנסות לנחש?</h6>
          {/* Primary Button Design */}
          <Link to="/guess-feelings-of" className="line">
            <button className="btn bg-primary-button custom_button text-regular fs-18">
              כן, אני רוצה להבין את {userRequest?.ask_from_partner_name}
            </button>
          </Link>
          {/* Outline Button Design */}
          <Link to="/drafting-request" className="line">
            <button className="btn bg-outline-button custom_button text-regular mt-3 fs-18">
              לא עכשיו, רוצה להמשיך
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GuessText;
