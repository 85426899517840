import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import callApi from "../../lib/apisauce/callApi";
import { useSelector } from "react-redux";
import RoundBtn from "../../components/RoundBtn";
import { trackPromise } from "react-promise-tracker";
import NeedModal from "../../components/feeling/NeedModal";
import AnsweredNeedsBlock from "../../components/all-tabs/AnsweredNeedsBlock";

const AnsweredNeeds = () => {
  const [needs, setNeeds] = useState(
    JSON.parse(sessionStorage.getItem("allNeeds")) || []
  );
  const navigate = useNavigate();
  const selfConnData = JSON.parse(sessionStorage.getItem("selfConnectData"));

  useEffect(() => {
    if (selfConnData?.g_feelings === undefined) {
      navigate("/home");
    }
    if (!needs.length) fetchAllNeeds();

    // Update the content attribute to set initial scale to 1.0
    var metaViewport = document.querySelector('meta[name="viewport"]');
    metaViewport.setAttribute(
      "content",
      "width=device-width, initial-scale=1, maximum-scale=1"
    );
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const resetScrollPosition = () => {
      if (document.documentElement.scrollTop > 5) {
        document.documentElement.scrollTop = 0;
      }
    };

    setTimeout(() => {
      scrollToTop();
      resetScrollPosition();
    }, 10);
  }, []);

  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));

  useEffect(() => {
    setTimeout(() => {
      compareRowCount();
    }, 100);
  }, []);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  const countRowItems = (element) => {
    const grid = Array.from(document?.querySelector(element)?.children);
    const baseOffset = grid[0].offsetTop;
    const breakIndex = grid.findIndex((item) => item.offsetTop > baseOffset);
    return breakIndex === -1 ? grid.length : breakIndex;
  };

  const compareRowCount = () => {
    needs.map((n, i) => {
      let itemPerRow = countRowItems("#element" + (i + 1));
      let arrLen = needs[i].emotionSubCat?.length;

      if (itemPerRow === arrLen) {
        setNeeds((needs) =>
          needs.map((need) =>
            need.emotionId === i + 1
              ? { ...need, isMatch: true, itemPerRow }
              : need
          )
        );
      } else {
        setNeeds((needs) =>
          needs.map((need) =>
            need.emotionId === i + 1
              ? { ...need, isMatch: false, itemPerRow }
              : need
          )
        );
      }
    });
  };

  const fetchAllNeeds = () => {
    trackPromise(
      callApi
        .getDatawithToken(
          "get-needs",
          {},
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
              Authorization: JWToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            sessionStorage.setItem("allNeeds", JSON.stringify(res.data.data));
            setNeeds(res.data.data);
          }
        })
        .catch((res) => {
          console.log(res.originalError);
        })
    );
  };

  const selectedAnsweredNeeds = useSelector((state) =>
    state.answeredNeeds.map((x) => x.name)
  );
  const { g_feelings } = selfConnData;

  const submitAnsweredNeeds = () => {
    const g_needs = selectedAnsweredNeeds.join(":");
    const newData = { ...selfConnData, g_needs };
    sessionStorage.setItem("selfConnectData", JSON.stringify(newData));
    if (selectedAnsweredNeeds.length) {
      navigate("/user-selection");
    }
  };

  return (
    <>
      <div className="bg-light_e">
        <Header />
        <div className="e3_header_content pt-60">
          <div className="container px-4">
            <h5>
              וכעת נתמקד בצרכים <u>שכן</u> מקבלים מענה בזמן שיש רגשות נעימים:
              <br />
              <span className="text-regular">
                {g_feelings?.replaceAll(":", ", ")}
              </span>
              <br />
              <br />
              בחר
              {userGender === "male" ? " " : "י "}
              את כל הצרכים (ללא הגבלה) <u>שכן</u> מקבלים מענה -{" "}
              {userGender === "male" ? "עבור " : "עברי "}
              בבקשה על כל הרשימה (גם אם זה ייקח קצת זמן) ובחר
              {userGender === "male" ? " " : "י "}
              גם צרכים יומיומיים וגם צרכים עמוקים שקיימים בך.
            </h5>

            <a
              href="#"
              className="line"
              data-bs-toggle="modal"
              data-bs-target="#custom_modal"
            >
              <button className="btn e3_custom_btn">
                {userGender === "male" ? "לחץ " : "לחצי "}
                לעזרה בהבנת הצרכים
              </button>
            </a>
          </div>
        </div>

        {needs &&
          [...needs].reverse().map((need) => (
            <AnsweredNeedsBlock
              key={need.emotionId}
              id={need.emotionId}
              dataArr={need.emotionSubCat}
              isMatch={need.isMatch}
              itemPerRow={need.itemPerRow}
            />
          ))}

        <div
          className={`container px-4 ${
            selectedAnsweredNeeds.length ? "pb-100" : ""
          }`}
        >
          <div className="line">
            <RoundBtn
              extraClass={`mt-5 mb-2 text-regular 
                  ${
                    selectedAnsweredNeeds.length
                      ? "bg-primary-button btn-popup"
                      : "primary-disable"
                  }`}
              text="להמשיך"
              onClick={submitAnsweredNeeds}
            />
          </div>
        </div>
      </div>

      {/* Modal */}
      <NeedModal />
      {/* Modal*/}
    </>
  );
};

export default AnsweredNeeds;
