import React from "react";
import RoundBtn from "../RoundBtn";
import { Link } from "react-router-dom";

const SettingModal = () => {
  return (
    <>
      <div
        className="modal fade px-3 "
        id="setting_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content" style={{ borderRadius: "7%" }}>
            <div className="modal-header border-0">
              <h5
                className="modal-title text-center a_modal_title mt-5"
                id="exampleModalLabel"
              >
                ההגדרות עודכנו בהצלחה
              </h5>
            </div>
            <div className="modal-body">
              {/* <div data-bs-dismiss="modal" aria-label="Close"> */}
              {/* <RoundBtn
                extraClass={`my-4 text-regular bg-primary-button`}
                text="חזרה לתהליך"
              /> */}
              {/* </div> */}
              <Link to="/home">
                <div data-bs-dismiss="modal" aria-label="Close">
                  <RoundBtn
                    extraClass={`mt-5 mb-4 text-regular bg-primary-button`}
                    text=" לדף הבית"
                    onClick={() => {
                      sessionStorage.removeItem("updateMode")
                    }}
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingModal;
