const KidML = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="100"
  height="100"
  fill="none"
  viewBox="0 0 100 100"
>
  <g clipPath="url(#clip0_4680_6694)">
    <path d="M0 0H100V100H0z"></path>
    <g>
      <g>
        <g>
          <mask
            id="mask0_4680_6694"
            style={{ maskType: "alpha" }}
            width="100"
            height="100"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#C4C4C4"
              stroke="#000"
              d="M98.784 50c0 27.341-22.005 49.5-49.142 49.5C22.505 99.5.5 77.34.5 50S22.505.5 49.642.5C76.779.5 98.784 22.659 98.784 50z"
            ></path>
          </mask>
          <g mask="url(#mask0_4680_6694)">
            <g>
              <g>
                <g>
                  <path
                    fill="#3869E5"
                    d="M32.125 17.262h-.002c-4.919 0-8.906 3.989-8.906 8.91V60.7c0 4.92 3.987 8.91 8.906 8.91h.002c4.919 0 8.906-3.99 8.906-8.91V26.171c0-4.92-3.987-8.91-8.906-8.91z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M73.64 21.028c0-10.495-8.504-19.003-18.995-19.003-10.491 0-18.996 8.508-18.996 19.003v35.01c0 10.496 8.505 19.003 18.996 19.003 10.49 0 18.995-8.507 18.995-19.002V21.028z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M21.913 68.315a6.332 6.332 0 006.33-6.333 6.332 6.332 0 10-12.66 0 6.332 6.332 0 006.33 6.333z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M74.83 68.315a6.332 6.332 0 006.33-6.333 6.332 6.332 0 10-12.66 0 6.332 6.332 0 006.33 6.333z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M53.845 77.563h-10.45v19.212h10.45V77.562z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M44.394 88.283h8.35a5.639 5.639 0 015.64 5.642v12.802h-19.63V93.925a5.643 5.643 0 015.64-5.642z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M48.373 82.068c13.96 0 25.278-12.718 25.278-28.407 0-15.688-11.317-28.406-25.278-28.406-13.96 0-25.278 12.718-25.278 28.406 0 15.689 11.317 28.407 25.278 28.407z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M48.13 65.84c-2.35.001-4.659-.619-6.693-1.797a3.41 3.41 0 113.445-5.883c2.183 1.278 4.674.963 5.634.243a3.408 3.408 0 114.089 5.457c-1.758 1.315-4.072 1.98-6.475 1.98z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M43.728 95.759h-4.972v27.775h4.972V95.759z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M53.413 123.533h4.973V95.757h-4.973v27.776z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M40.447 40.074l-3.534 1.3a1.66 1.66 0 01-2.126-.98 1.662 1.662 0 01.991-2.13l3.532-1.303a1.66 1.66 0 012.127.983 1.662 1.662 0 01-.99 2.13z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M56.754 40.073l3.532 1.301a1.663 1.663 0 002.129-.981 1.663 1.663 0 00-.983-2.127L57.9 36.963a1.662 1.662 0 00-2.133.98 1.664 1.664 0 00.987 2.13z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M44.485 52.341a.75.75 0 01-.752-.752 5.627 5.627 0 00-5.623-5.626 5.623 5.623 0 00-5.624 5.626.754.754 0 01-1.506 0 7.138 7.138 0 014.372-6.7 7.127 7.127 0 019.378 3.933c.351.88.524 1.82.507 2.767a.75.75 0 01-.752.752z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M64.215 52.341a.75.75 0 01-.752-.752 5.628 5.628 0 00-5.624-5.626 5.622 5.622 0 00-5.624 5.626.755.755 0 01-1.506 0 7.136 7.136 0 014.372-6.7 7.127 7.127 0 019.378 3.933c.352.88.524 1.82.508 2.767a.752.752 0 01-.752.752z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_4680_6694">
      <path fill="#fff" d="M0 0H100V100H0z"></path>
    </clipPath>
  </defs>
</svg>
);

export default KidML;
