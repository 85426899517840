import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import { addRemoveMostGF } from "../../redux/mostGFSlice";
import { addRemoveMostBF } from "../../redux/mostBFSlice";
import { addRemoveMostAnsNeed } from "../../redux/mostAnsNeedSlice";
import { addRemoveMostIgNeed } from "../../redux/mostIgNeedSlice";
import { useEffect } from "react";

const MostGoodFeel = ({ data }) => {
  const mostGF = useSelector((state) => state.mostGF);
  const dispatch = useDispatch();
  const saveData = (data) => {
    dispatch(addRemoveMostGF(data));
  };

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const resetScrollPosition = () => {
      if (document.documentElement.scrollTop > 5) {
        console.log("inside scroll");
        document.documentElement.scrollTop = 0;
      }
    };

    setTimeout(() => {
      scrollToTop();
      resetScrollPosition();
    }, 100);
  }, []);

  return (
    <button
      className={`btn e7_custom_btn ${
        mostGF.includes(data) ? "btn_e7_fill text-bold text-white" : ""
      }`}
      onClick={() => saveData(data)}
    >
      {data}
    </button>
  );
};

const MostBadFeel = ({ data }) => {
  const mostBF = useSelector((state) => state.mostBF);
  const dispatch = useDispatch();
  const saveData = (data) => {
    dispatch(addRemoveMostBF(data));
  };

  return (
    <button
      className={`btn e7_custom_btn ${
        mostBF.includes(data) ? "btn_e7_fill text-bold text-white" : ""
      }`}
      onClick={() => saveData(data)}
    >
      {data}
    </button>
  );
};

const MostAnsNeed = ({ data }) => {
  const mostAN = useSelector((state) => state.mostAnsNeed);
  const dispatch = useDispatch();
  const saveData = (data) => {
    dispatch(addRemoveMostAnsNeed(data));
  };

  return (
    <button
      className={`btn e7_custom_btn ${
        mostAN.includes(data) ? "btn_e7_fill text-bold text-white" : ""
      }`}
      onClick={() => saveData(data)}
    >
      {data}
    </button>
  );
};

const MostIgNeed = ({ data }) => {
  const mostIG = useSelector((state) => state.mostIgNeed);
  const dispatch = useDispatch();
  const saveData = (data) => {
    dispatch(addRemoveMostIgNeed(data));
  };

  return (
    <button
      className={`btn e7_custom_btn ${
        mostIG.includes(data) ? "btn_e7_fill text-bold text-white" : ""
      }`}
      onClick={() => saveData(data)}
    >
      {data}
    </button>
  );
};

const MostValueSelection = () => {
  const navigate = useNavigate();
  const selfConnData = JSON.parse(sessionStorage.getItem("selfConnectData"));

  useEffect(() => {
    if (
      (selfConnData?.b_feelings === undefined &&
        selfConnData?.b_needs === undefined) ||
      (selfConnData?.g_feelings === undefined &&
        selfConnData?.g_needs === undefined)
    ) {
      navigate("/home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // Update the content attribute to set initial scale to 1.0
    var metaViewport = document.querySelector('meta[name="viewport"]');
    metaViewport.setAttribute(
      "content",
      "width=device-width, initial-scale=1, maximum-scale=1"
    );
  }, []);

  let goodFeel, badFeel, answeredNeeds, ignoredNeeds;

  goodFeel = selfConnData?.g_feelings && selfConnData?.g_feelings?.split(":");
  badFeel = selfConnData?.b_feelings && selfConnData?.b_feelings?.split(":");
  answeredNeeds = selfConnData?.g_needs && selfConnData?.g_needs?.split(":");
  ignoredNeeds = selfConnData?.b_needs && selfConnData?.b_needs?.split(":");

  const { mostGF, mostBF, mostAnsNeed, mostIgNeed } = useSelector(
    (state) => state
  );

  const handleSubmit = () => {
    const mostData = {
      most_g_feelings: mostGF.join(":"),
      most_g_needs: mostAnsNeed.join(":"),
      most_b_feelings: mostBF.join(":"),
      most_b_needs: mostIgNeed.join(":"),
    };

    const newData = { ...selfConnData, ...mostData };
    sessionStorage.setItem("selfConnectData", JSON.stringify(newData));

    if (
      mostGF.length ||
      mostBF.length ||
      mostAnsNeed.length ||
      mostIgNeed.length
    ) {
      navigate("/selection-summary");
    }
  };

  return (
    <>
      <div className="bg-primary_e pb-4">
        <Header extraClass="border-bottom" />

        <div className="e7_content pt-80">
          <div className="container px-4">
            <h5 className="e7_header_title">סמני את מה שחי יותר כרגע</h5>
          </div>
          <div className="e7_border_bottom" />
          <div className="container px-4">
            {/* Button group 1 */}
            {goodFeel && goodFeel.length && (
              <div className="e7_content_btn mt-4">
                <h5 className="e7_header_heading">הרגשות הנעימים שבחרת:</h5>
                <div className="all_e7_btn d-flex flex-wrap">
                  {goodFeel &&
                    goodFeel.map((gf, i) => (
                      <MostGoodFeel key={uuidv4() + i} data={gf} />
                    ))}
                </div>
              </div>
            )}

            {/* Button group 2 */}
            {answeredNeeds && answeredNeeds.length && (
              <div className="e7_content_btn mt-4">
                <h5 className="e7_header_heading">
                  צרכים שכן מקבלים מענה שבחרת:
                </h5>
                <div className="all_e7_btn d-flex flex-wrap">
                  {answeredNeeds &&
                    answeredNeeds.map((ansNeed, i) => (
                      <MostAnsNeed key={i + uuidv4()} data={ansNeed} />
                    ))}
                </div>
              </div>
            )}
          </div>
          {(goodFeel || answeredNeeds) && <div className="e7_border_bottom" />}

          <div className="container px-4">
            {/* Button group 3 */}
            {badFeel && badFeel.length && (
              <div className="e7_content_btn mt-4">
                <h5 className="e7_header_heading">הרגשות הלא נעימים שבחרת:</h5>
                <div className="all_e7_btn d-flex flex-wrap">
                  {badFeel &&
                    badFeel.map((bf, i) => (
                      <MostBadFeel key={i + uuidv4() + i} data={bf} />
                    ))}
                </div>
              </div>
            )}

            {/* Button group 4 */}
            {ignoredNeeds && ignoredNeeds.length && (
              <div className="e7_content_btn mt-4">
                <h5 className="e7_header_heading">
                  צרכים שלא מקבלים מענה שבחרת:
                </h5>
                <div className="all_e7_btn d-flex flex-wrap">
                  {ignoredNeeds &&
                    ignoredNeeds.map((igNeed, i) => (
                      <MostIgNeed key={i + i + uuidv4()} data={igNeed} />
                    ))}
                </div>
              </div>
            )}
          </div>
          {(badFeel || ignoredNeeds) && <div className="e7_border_bottom" />}

          <div className="container px-4">
            {/* Button Design 1 */}
            <div className="line">
              <RoundBtn
                extraClass={`mt-5 text-regular ${
                  mostGF.length ||
                  mostBF.length ||
                  mostAnsNeed.length ||
                  mostIgNeed.length
                    ? "bg-secondary-button"
                    : "secondary-disable"
                }`}
                text="להמשיך"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MostValueSelection;
