import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import Eye from "../../components/svg/Eye";

const TryMyDeepNeeds = () => {
  const navigate = useNavigate();
  const judgementData = JSON.parse(sessionStorage.getItem("judgement"));

  useEffect(() => {
    if (judgementData?.needs === undefined || judgementData?.needs === "") {
      navigate("/home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSubmit = (bool) => {
    if (bool === false) {
      delete judgementData?.deeper_needs;
    }

    const newData = {
      ...judgementData,
      does_want_to_check_deeper_needs: bool,
    };
    sessionStorage.setItem("judgement", JSON.stringify(newData));
    if (bool) {
      navigate("/my-deep-needs");
    } else {
      navigate("/my-needs-summary");
    }
  };

  return (
    <>
      <div className="bg-primary_e">
        <Header extraClass="border-bottom" />
        <div className="c4b_5_content px-3 text-center pt-100">
          <div className="container pt-5">
            <Eye />
            <h5 className="c4b_5_title">
              רוצה לנסות לבדוק
              <br />
              אם יש לך צרכים יותר
              <br />
              עמוקים שלא מקבלים מענה
              <br />
              במקרה הזה?
            </h5>
            {/* Secondary Button Design */}
            <div>
              <RoundBtn
                extraClass="mt-4 text-regular bg-secondary-button fs-18"
                text="כן, רוצה להתעמק"
                onClick={() => handleSubmit(true)}
              />
            </div>
            {/* Secondary Button Design */}
            <div>
              <RoundBtn
                extraClass="mt-4 text-regular bg-secondary-button fs-18"
                text="לא, רוצה להמשיך"
                onClick={() => handleSubmit(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TryMyDeepNeeds;
