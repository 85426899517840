import React, { useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { InstallPromptContext } from "../feeling/InstallPromptContext";

const Header = ({ extraClass = "", text = "Mpathic Family" }) => {
  const { pathname } = useLocation();
  const deferredPrompt = useContext(InstallPromptContext);
  // const isAppInstalled = localStorage.getItem("AppInstalled");

  // window.addEventListener("appinstalled", (event) => {
  //   localStorage.setItem("AppInstalled", true);
  //   console.log("PWA already installed");
  //   // You can perform additional actions here when the PWA is already installed
  // });

  function isIOSDevice() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          localStorage.setItem("AppInstalled", true);
        } else {
          localStorage.setItem("AppInstalled", false);
        }
      });
    }
  };

  let navigate = useNavigate();

  const menuIconImg = pathname === "/home" ? "w-menu" : "menu_icon";
  const BackIconImg = pathname === "/home" ? "w-back" : "back_icon";

  const openNav = () => {
    document.getElementById("mySidenav").style.transform = "translate(0px)";
    document.getElementById("overlay").classList.remove("d-none");
    document.body.style.backgroundColor = "rgba(0,0,0,0)";
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.transform = "translateX(290px)";
    document.getElementById("overlay").classList.add("d-none");
    document.body.style.backgroundColor = "white";
  };

  const logOut = () => {
    localStorage.removeItem("UserJWT");
    [
      "UserJWT",
      "userData",
      "badFeelings",
      "blackListWords",
      "allNeeds",
      "selfConnectData",
      "request",
      "judgement",
      "goodFeelings",
      "RegUserData",
      "updateMode",
      "displayData",
    ].forEach((key) => {
      sessionStorage.removeItem(key);
    });
    navigate("/");
  };

  const clearSession = () => {
    ["selfConnectData", "request", "judgement"].forEach((key) => {
      sessionStorage.removeItem(key);
    });
  };

  const updateMode = () => {
    sessionStorage.setItem("updateMode", "on");
  };

  return (
    <>
      <div className={`e_header_home pt-3 pb-2 ${extraClass} `}>
        <div className="container">
          <div className="manu_option d-flex justify-content-between">
            <div className="manu_icon" onClick={openNav}>
              <img src={`assets/images/${menuIconImg}.svg`} alt="Menu" />
            </div>
            <div className="manu_header_title">
              <h5>{text}</h5>
            </div>
            <div
              className="manu_back_icon"
              onClick={() => {
                pathname === "/home" ? navigate() : navigate(-1);
              }}
            >
              <img src={`assets/images/${BackIconImg}.svg`} alt="Back" />
            </div>
          </div>
        </div>
      </div>

      {/* Side menu design start */}
      <div className="overlay d-none" id="overlay" />
      <div id="mySidenav" className="sidenav">
        <a className="closebtn" onClick={closeNav}>
          ×
        </a>
        <Link
          to="/home"
          onClick={() => {
            closeNav();
            clearSession();
          }}
        >
          <img src="assets/images/nav_home.svg" alt="" />
          עמוד הבית
        </Link>

        <Link
          to="/about-family"
          onClick={() => {
            updateMode();
            closeNav();
            clearSession();
          }}
        >
          <img src="assets/images/nav_setting.svg" alt="" />
          הגדרות (האפיון שלי)
        </Link>

        <Link to="/request-history">
          <img src="assets/images/nav_date.svg" alt="" />
          היסטוריה של ניסוחי בקשות
        </Link>

        <a
          onClick={() => {
            closeNav();
            logOut();
          }}
        >
          <img src="assets/images/nav_sign-out.svg" alt="" /> יציאה
        </a>

        {deferredPrompt !== null && (
          <>
            {isIOSDevice() ? (
              ""
            ) : (
              <a className="add-to">
                <div className="add-to-btn" onClick={handleInstallClick}>
                  <img
                    style={{ marginRight: 3 }}
                    src="assets/images/shortcut.svg"
                    alt=""
                  />
                  הוספת האפליקציה למסך הבית
                </div>
              </a>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Header;
