import { useState } from "react";
import ChildrenForm from "../components/signup-form/ChildrenForm";
import ParentsForm from "../components/signup-form/ParentsForm";
import UnmarriedForm from "../components/signup-form/UnmarriedForm";

const QueryForm = () => {
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem("userData"))
  );

  return (
    <>
      {(userData?.family_role === "kid_m" ||
        userData?.family_role === "kid_f") && <ChildrenForm />}

      {(userData?.family_role === "dad" || userData?.family_role === "mom") && (
        <ParentsForm />
      )}

      {(userData?.family_role === "adult_m" ||
        userData?.family_role === "adult_f") && <UnmarriedForm />}
    </>
  );
};

export default QueryForm;
