import React from "react";

const NeedModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="custom_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content custom-modal-radius">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <h5
                className="modal-title text-center a_modal_title"
                id="exampleModalLabel"
              >
                עזרה בהבנת הצרכים
              </h5>
            </div>
            <div className="modal-body">
              <div className="disc_a14_modal mt-3">
                <p className="a11_disc_para_content">
                  אנחנו רגילים לחשוב על מה ”לא בסדר” אצל האחרים כשצרכים שלנו לא
                  מקבלים מענה.
                  <br />
                  בדרך כלל אפילו לא נזהה את הצרכים שלנו בזמן שנבקר ונשפוט אנשים
                  אחרים.
                </p>
                <br />

                <p className="a11_disc_para_content">
                  <span className="text-bold">
                    אם נוכל לחבר באופן ישיר בין הרגשות לצרכים שלנו ולתקשר את זה
                    לאחרים, יהיה להם קל יותר להבין אותנו ולהגיב בצורה נעימה.
                  </span>
                </p>
              </div>
              <div className="disc_a14_modal mt-3">
                <p className="a11_disc_para_content">
                  <span className="text-bold">לדוגמה: </span>
                  אם אשה תגיד לבן זוגה “אתה חוזר ממש מאוחר מהעבודה, העבודה יותר
                  חשובה לך ממני”
                  <br />
                  - זאת בעצם סוג של ביקורת העלולה ליצור התנגדות אצל בן הזוג.
                  <br />
                  לעומת זאת, אם האשה תזהה ותגיד את הצורך שלה (למשל הצורך
                  באינטימיות ותשומת לב) יש סיכוי גבוה יותר לאמפתיה והבנה מצד בן
                  הזוג.
                </p>
              </div>

              <div className="disc_a14_modal mt-3">
                <p className="a11_disc_para_content">
                  יעזור אם
                  <span className="text-bold">
                    {" "}
                    נבטא את מה שאנחנו כן צריכים{" "}
                  </span>
                  במקום להעיר על מה שמפריע לנו (מה אנחנו לא רוצים).
                </p>
              </div>

              <div className="a11_content_list mt-3">
                <h6 className="a11_contents_list_title mb-0">
                  דוגמאות לניסוח צרכים:
                </h6>
                <ol>
                  <li>יש לי צורך באינטימיות ותשומת לב.</li>
                  <li>יש לי צורך בהתחשבות ושיתוף פעולה.</li>
                  <li>הייתי רוצה יותר חופש/עצמאות בחיים שלי.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(NeedModal);
