import { useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";

const validationSchema = Yup.object().shape({
  thoughts: Yup.string().label("Thoughts"),
});

const SelectionSummary = () => {
  const navigate = useNavigate();
  const selfConnData = JSON.parse(sessionStorage.getItem("selfConnectData"));

  useEffect(() => {
    if (
      (selfConnData?.b_feelings === undefined &&
        selfConnData?.b_needs === undefined) ||
      (selfConnData?.g_feelings === undefined &&
        selfConnData?.g_needs === undefined)
    ) {
      navigate("/home");
    }
    
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  
  let goodFeel,
    badFeel,
    answeredNeeds,
    ignoredNeeds,
    mostGF,
    mostBF,
    mostAnsNeed,
    mostIgNeed, savedThoughts;

  goodFeel =
    selfConnData?.g_feelings && selfConnData?.g_feelings?.replaceAll(":", ", ");
  badFeel =
    selfConnData?.b_feelings && selfConnData?.b_feelings?.replaceAll(":", ", ");
  answeredNeeds =
    selfConnData?.g_needs && selfConnData?.g_needs?.replaceAll(":", ", ");
  ignoredNeeds =
    selfConnData?.b_needs && selfConnData?.b_needs?.replaceAll(":", ", ");
  mostGF = selfConnData?.most_g_feelings?.replaceAll(":", ", ");
  mostBF = selfConnData?.most_b_feelings?.replaceAll(":", ", ");
  mostAnsNeed = selfConnData?.most_g_needs?.replaceAll(":", ", ");
  mostIgNeed = selfConnData?.most_b_needs?.replaceAll(":", ", ");
  savedThoughts = selfConnData?.thoughts;

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  const initialValues = {
    thoughts: savedThoughts || "",
  };

  const handleSubmit = (values) => {
    const newData = { ...selfConnData, ...values };
    sessionStorage.setItem("selfConnectData", JSON.stringify(newData));
    navigate("/verdict");
  };

  return (
    <>
      <div className="bg-light_e">
        <Header />

        <div className="e9_content pt-80 px-3">
          <div className="container">
            <h6 className="e9_head_title">סיכום:</h6>

            {mostGF && mostGF.length ? (
              <div className="e9_content_disc mt-3">
                <p>רגשות נעימים:</p>
                <h6>{mostGF}</h6>
              </div>
            ) : goodFeel && goodFeel.length ? (
              <div className="e9_content_disc mt-3">
                <p>רגשות נעימים:</p>
                <h6>{goodFeel}</h6>
              </div>
            ) : (
              ""
            )}

            {mostAnsNeed && mostAnsNeed.length ? (
              <div className="e9_content_disc mt-3">
                <p>צרכים שמקבלים מענה:</p>
                <h6>{mostAnsNeed}</h6>
              </div>
            ) : answeredNeeds && answeredNeeds.length ? (
              <div className="e9_content_disc mt-3">
                <p>צרכים שמקבלים מענה:</p>
                <h6>{answeredNeeds}</h6>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="e_border_bottom" />
        <div className="container">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              setFieldTouched,
              touched,
              values,
            }) => (
              <>
                <div className="container">
                  {mostBF && mostBF.length ? (
                    <div className="e9_content_disc mt-3">
                      <p>רגשות לא נעימים:</p>
                      <h6>{mostBF}</h6>
                    </div>
                  ) : badFeel && badFeel.length ? (
                    <div className="e9_content_disc mt-3">
                      <p>רגשות לא נעימים:</p>
                      <h6>{badFeel}</h6>
                    </div>
                  ) : (
                    ""
                  )}

                  {mostIgNeed && mostIgNeed.length ? (
                    <div className="e9_content_disc mt-3">
                      <p>צרכים שלא מקבלים מענה:</p>
                      <h6>{mostIgNeed}</h6>
                    </div>
                  ) : ignoredNeeds && ignoredNeeds.length ? (
                    <div className="e9_content_disc mt-3">
                      <p>צרכים שלא מקבלים מענה:</p>
                      <h6>{ignoredNeeds}</h6>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="e9_input_message mt-4">
                    <h6 className="e9_input_title">
                      מה עולה בך כשאת
                      {userGender === "female" ? " " : "ה "}
                      רואה את זה?
                    </h6>
                    <div className="input-group e9_input_box">
                      <textarea
                        className="form-control input_shadow custom_textarea"
                        aria-label="With textarea"
                        rows={6}
                        value={values.thoughts}
                        name="thoughts"
                        onChange={handleChange("thoughts")}
                        onBlur={() => setFieldTouched("thoughts")}
                      />
                    </div>
                    {touched.thoughts && (
                      <p style={{ color: "red" }}>{errors.thoughts}</p>
                    )}
                  </div>

                  <div className="pb-2">
                    <RoundBtn
                      extraClass={`mt-5 text-regular bg-primary-button`}
                      text="לסיים"
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default SelectionSummary;
