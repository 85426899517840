import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import downArrow from "../../pages/img/downArrow.png";

const BlackLineUpArrow = ({ onClick }) => {
  return (
    <div className="toggle_button_black del-mt4">
      <div className="toggle_border_black"></div>
      <div className="black_arrow" onClick={onClick}>
        <img src="assets/images/hide_arrow_black.svg" alt="" />
      </div>
    </div>
  );
};

const BlueLine = () => {
  return (
    <div className="toggle_button_blue toggle_button_blue_new">
      <div className="toggle_button_border_simple height_none"></div>
    </div>
  );
};

const BlueLineArrowDesign = ({ onClick }) => {
  return (
    <div className="toggle_button_blue toggle_button_blue_new">
      <div className="toggle_button_border_simple height_none"></div>
      <div className="hide_arrow" onClick={onClick}>
        <img src={downArrow} alt="Blue Arrow" />
      </div>
    </div>
  );
};

const GoodFeelBlock = ({ emotionId, dataArr, isMatch }) => {
  const [show, setShow] = useState(false);

  let BlockClass = isMatch ? "show80" : show ? "show100" : "show80";

  return (
    <div className="tab_btn_all mt-4 px-1">
      <div className={BlockClass}>
        <div
          className="all_e7_btn d-flex flex-wrap justify-content-center"
          id={"element" + emotionId}
        >
          {dataArr &&
            dataArr.map((feel, i) => (
              <Tab key={uuidv4()} index={i} data={feel} />
            ))}
        </div>
      </div>

      {isMatch ? (
        <BlueLine />
      ) : show ? (
        <BlackLineUpArrow onClick={() => setShow(!show)} />
      ) : (
        <BlueLineArrowDesign onClick={() => setShow(!show)} />
      )}
    </div>
  );
};

const Tab = ({ data, index }) => {
  return (
    <button
      className={`btn b2_custom_btn mb-2 remove_btn_bg
      ${index == 0 ? "b2_custom_btn_dark" : ""}`}
    >
      {data.subCateName}
    </button>
  );
};

const DisplayFeelNeed = ({ DisplayData, dataFor = "" }) => {
  const [goodFeel, setGoodFeel] = useState(DisplayData);

  useEffect(() => {
    setTimeout(() => {
      compareRowCount();
    }, 100);
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const resetScrollPosition = () => {
      if (document.documentElement.scrollTop > 5) {
        document.documentElement.scrollTop = 0;
      }
    };

    setTimeout(() => {
      scrollToTop();
      resetScrollPosition();
    }, 50);
  }, []);

  const countRowItems = (element) => {
    const grid = Array.from(document?.querySelector(element)?.children);
    const baseOffset = grid[0].offsetTop;
    const breakIndex = grid.findIndex((item) => item.offsetTop > baseOffset);
    return breakIndex === -1 ? grid.length : breakIndex;
  };

  const compareRowCount = () => {
    goodFeel.map((gfeel, i) => {
      let itemPerRow = countRowItems("#element" + (i + 1));
      let arrLen = goodFeel[i].emotionSubCat?.length;

      if (itemPerRow === arrLen) {
        setGoodFeel((goodFeel) =>
          goodFeel.map((gf) =>
            gf.emotionId === i + 1 ? { ...gf, isMatch: true } : gf
          )
        );
      } else {
        setGoodFeel((goodFeel) =>
          goodFeel.map((gf) =>
            gf.emotionId === i + 1 ? { ...gf, isMatch: false } : gf
          )
        );
      }
    });
  };

  return (
    <>
      {dataFor === "needs" ? (
        <>
          {goodFeel &&
            [...goodFeel].reverse().map((gf) => (
              <GoodFeelBlock
                key={gf.emotionId}
                emotionId={gf.emotionId}
                dataArr={gf.emotionSubCat}
                isMatch={gf.isMatch}
              />
            ))}
        </>
      ) : (
        <>
          {goodFeel &&
            goodFeel.map((gf) => (
              <GoodFeelBlock
                key={gf.emotionId}
                emotionId={gf.emotionId}
                dataArr={gf.emotionSubCat}
                isMatch={gf.isMatch}
              />
            ))}
        </>
      )}
    </>
  );
};

export default DisplayFeelNeed;
