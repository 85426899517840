const Eye = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={63}
    height={47}
    fill="none"
    {...props}
  >
    <path
      d="M1.465 21.693c1.581-2.899 4.833-8.094 9.835-12.554C16.294 4.686 22.99 1 31.5 1s15.206 3.686 20.2 8.139c5.002 4.46 8.255 9.656 9.835 12.554a3.74 3.74 0 0 1 0 3.614c-1.581 2.899-4.833 8.094-9.835 12.554C46.706 42.314 40.01 46 31.5 46s-15.206-3.686-20.2-8.139c-5.002-4.46-8.255-9.655-9.835-12.554a3.74 3.74 0 0 1 0-3.614zm54.71 2.316.302-.509-.302-.509c-1.582-2.671-4.241-6.517-7.973-9.844-4.323-3.855-9.826-6.803-16.702-6.803s-12.379 2.948-16.702 6.803c-3.732 3.327-6.392 7.173-7.973 9.844l-.302.509.302.509c1.582 2.671 4.241 6.517 7.973 9.844 4.323 3.855 9.826 6.802 16.702 6.802s12.379-2.948 16.702-6.802c3.732-3.327 6.392-7.173 7.973-9.844zM42.159 23.5c0 5.944-4.78 10.75-10.659 10.75S20.841 29.444 20.841 23.5s4.78-10.75 10.659-10.75 10.659 4.806 10.659 10.75zM31.5 28.906c2.974 0 5.372-2.428 5.372-5.406s-2.398-5.406-5.372-5.406-5.372 2.428-5.372 5.406 2.398 5.406 5.372 5.406z"
      fill="#fff"
      stroke="#3869e5"
      strokeWidth={2}
    />
  </svg>
);

export default Eye;
