import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import PersonFormBg from "../PersonFormBg";
import RadioBtn from "../RadioBtn";
import callApi from "../../lib/apisauce/callApi";
import { queryString } from "../../utils/queryString";
import RoundBtn from "../RoundBtn";
import SettingModal from "../feeling/SettingModal";
import { useEffect } from "react";

const validationSchema = Yup.object().shape({
  kids_over_seven: Yup.number()
    .integer()
    .required("יש לציין כמה ילדים מעל גיל 7 יש במשפחה"),
});

const ChildrenForm = () => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [sibling, setSibling] = useState(userData?.sibling || "");
  const [loading, setLoading] = useState(false);
  const [siblingErr, setSiblingErr] = useState(false);
  const navigate = useNavigate();
  const RegUserData = JSON.parse(sessionStorage.getItem("RegUserData"));
  const last_id = RegUserData?.last_id || "";

  const updateMode = sessionStorage.getItem("updateMode");
  let kidOver7 = userData?.kids_over_seven ? userData?.kids_over_seven : "";

  const initialValues = {
    kids_over_seven: kidOver7,
  };

  useEffect(() => {
    if (sibling !== "") {
      setSiblingErr(false);
    }
  }, [sibling]);

  const handleSubmit = async (values) => {
    if (sibling === "") {
      return setSiblingErr(true);
    } else {
      setSiblingErr(false);
    }

    setLoading(true);

    const userData = JSON.parse(sessionStorage.getItem("userData"));

    let newUserData =
      last_id && last_id !== ""
        ? {
            ...userData,
            ...values,
            sibling,
            status: "A",
            last_id,
          }
        : {
            ...userData,
            ...values,
            sibling,
            status: "A",
          };

    let DataRemovedBlankVal = Object.fromEntries(
      Object.entries(newUserData).filter((value) => value[1])
    ); //Remove key with Blank value if any.

    try {
      const res = await callApi.postData(
        "member_register",
        queryString(DataRemovedBlankVal),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );

      if (res?.ok) {
        if (res.data.status === "success") {
          const { userdata } = res.data;

          const {
            name,
            email,
            username,
            google_token,
            member_code,
            family_role,
            kids_exist,
            member_gender,
            sibling,
            kids_over_seven,
            parent_relationship,
            adult_relationship,
          } = userdata;

          sessionStorage.setItem(
            "userData",
            JSON.stringify({
              name,
              email,
              username,
              google_token,
              member_code,
              family_role,
              kids_exist,
              member_gender,
              sibling,
              kids_over_seven,
              parent_relationship,
              adult_relationship,
            })
          );

          sessionStorage.setItem("UserJWT", JSON.stringify(res.data.jwt));
          sessionStorage.removeItem("RegUserData");
          setLoading(false);
          if (updateMode !== undefined && updateMode === "on") {
            document.getElementById("settingsModal").click();
          } else {
            navigate("/signup-success");
          }
        } else {
          console.log(res.data.message);
        }
      } else {
        console.log(res.originalError);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <a
        href="#"
        className="line"
        data-bs-toggle="modal"
        data-bs-target="#setting_modal"
        id="settingsModal"
      ></a>
      <SettingModal />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
          values,
        }) => (
          <>
            <PersonFormBg
              submitBtn={
                <>
                  <div className="position-relative">
                    {loading && (
                      <div
                        className="btn_loader_dark"
                        style={{ right: 36, top: 10, zIndex: 9999 }}
                      ></div>
                    )}
                  </div>
                  <div className="container">
                    <RoundBtn
                      extraClass="mt-5 text-regular bg-secondary-button blank-footer-btn"
                      text="להמשיך"
                      onClick={handleSubmit}
                    />
                  </div>
                </>
              }
            >
              <div className="container px-4">
                <div className="input_radio_register my-5">
                  <label className="form-label custom-lebel-register">
                    5. יש לך אחים/אחיות?
                  </label>
                  <RadioBtn
                    extraClass="mb-3"
                    radioId="sibling1"
                    radioName="sibling"
                    value="yes"
                    checked={sibling === "yes"}
                    onChange={(e) => setSibling(e.target.value)}
                    labelText="כן"
                  />

                  <RadioBtn
                    extraClass="mb-3"
                    radioId="sibling2"
                    radioName="sibling"
                    value="no"
                    checked={sibling === "no"}
                    onChange={(e) => setSibling(e.target.value)}
                    labelText="לא"
                  />
                  {siblingErr && (
                    <p className="err_msg">יש לבחור את האפשרות המתאימה</p>
                  )}
                </div>

                <div className="mb-3 mt-3 w-100">
                  <label className="form-label custom-lebel-register">
                    6. כמה ילדים מעל גיל 7 יש במשפחה <br />
                    (כולל אותך)
                  </label>
                  <input
                    type="number"
                    className="form-control input_shadow custom_input"
                    name="kids_over_seven"
                    value={values.kids_over_seven}
                    onChange={handleChange("kids_over_seven")}
                    onBlur={() => setFieldTouched("kids_over_seven")}
                    title="Kids Over Seven"
                    required
                  />
                  {touched.kids_over_seven && (
                    <p className="err_msg">{errors.kids_over_seven}</p>
                  )}
                </div>
              </div>
            </PersonFormBg>
          </>
        )}
      </Formik>
    </>
  );
};

export default ChildrenForm;
