import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { usePromiseTracker } from "react-promise-tracker";
import { ProgressBar } from "react-loader-spinner";
import { CookiesProvider } from "react-cookie";
import { InstallPromptProvider } from "./components/feeling/InstallPromptContext";

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#20202060",
          position: "fixed",
          zIndex: 9999999,
        }}
      >
        <ProgressBar
          height="100%"
          width="80"
          borderColor="#45cbf6"
          barColor="#45cbf6"
        />
      </div>
    )
  );
};

let ClientId =
  "768133513066-ui5rbtuorts5bupqdjnegv9gsg2ai9jq.apps.googleusercontent.com";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={ClientId}>
    <Provider store={store}>
      <LoadingIndicator />
      <CookiesProvider>
        <InstallPromptProvider>
          <App />
        </InstallPromptProvider>
      </CookiesProvider>
    </Provider>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);

serviceWorkerRegistration.register();
