import { useEffect, useState } from "react";
import Header from "../../components/layout/Header";
import callApi from "../../lib/apisauce/callApi";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router-dom";

const RequestHistory = () => {
  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [text, setText] = useState("");
  const [err, setErr] = useState(false);
  const [shareBar, setShareBar] = useState(false);
  const [lastClickedId, setLastClickedId] = useState();

  useEffect(() => {
    if (!history.length) fetchRequestHistory();
  }, []);

  const logOut = () => {
    //incase of expired member code
    localStorage.removeItem("UserJWT");
    [
      "UserJWT",
      "userData",
      "badFeelings",
      "blackListWords",
      "allNeeds",
      "selfConnectData",
      "request",
      "judgement",
      "goodFeelings",
      "RegUserData",
      "updateMode",
      "displayData",
    ].forEach((key) => {
      sessionStorage.removeItem(key);
    });
    navigate("/");
  };

  const fetchRequestHistory = () => {
    trackPromise(
      callApi
        .getDatawithToken(
          "get-request-data",
          {},
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
              Authorization: JWToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.data.length) {
              setHistory(res.data.data.reverse());
              setErr(false);
            } else {
              setErr(true);
            }
          } else {
            if (res.data.message === "תוקף הקוד פג") {
              console.log(res.data.message);
              sessionStorage.setItem("ExpireCode", "True");
              logOut();
            }
            console.log(res.data.message);
          }
        })
        .catch((res) => {
          console.log(res.originalError);
        })
    );
  };

  const toggleShareBar = (id) => {
    if (lastClickedId === id) {
      setShareBar(!shareBar);
    } else {
      if (shareBar) {
        setShareBar(shareBar);
      } else {
        setShareBar(!shareBar);
      }
    }
    setLastClickedId(id);
  };

  // const toggleShareBar = () => {
  //   var x = document.getElementById("share_icon");
  //   if (x.style.display === "block") {
  //     x.style.display = "none";
  //   } else {
  //     x.style.display = "block";
  //   }
  // };

  return (
    <>
      <div className="bg-light_e">
        <Header />
        <div className="a27_content pt-60">
          <div className="conatiner">
            {history &&
              history.length > 0 &&
              history.map((h) => (
                <div className="a27_disc mt-4" key={h.id}>
                  <h5 className="px-2">
                    <img
                      src="assets/images/share-icon.svg"
                      alt=""
                      onClick={() => {
                        toggleShareBar(h.id);
                        setText(h?.fixed_final_text);
                      }}
                    />
                    בקשה מ{h.ask_from_partner_name} | {h?.created_date}
                  </h5>
                  <p
                    className="px-2"
                    style={{ textAlign: "right" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        h?.fixed_final_text &&
                        h?.fixed_final_text !== null &&
                        h?.fixed_final_text.length > 0
                          ? h?.fixed_final_text?.replaceAll("\n", "<br/>")
                          : h?.final_text?.replaceAll("\n", "<br/>"),
                    }}
                  />
                </div>
              ))}

            {err && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
              >
                <h5 className="px-2">לא נוצרו בקשות בעבר</h5>
              </div>
            )}
          </div>
        </div>

        {shareBar && (
          <div
            className="share_option"
            id="share_icon"
            style={{ display: "block" }}
          >
            <div className="share_option_icon d-flex justify-content-center">
              <a href={`https://api.whatsapp.com/send/?text=${text}`}>
                <img src="assets/images/Whatsapp.svg" alt="" />
              </a>

              <a href={`https://t.me/share/url?text==${text}`}>
                <img src="assets/images/Telegram.svg" alt="" />
              </a>

              <a
                href={`mailto:?subject=message from mpathic family webapp&body=${text}`}
              >
                <img src="assets/images/Gmail.svg" alt="" />
              </a>

              {/* <img src="assets/images/Messenger.svg" alt="" />
              <img src="assets/images/Instagram.svg" alt="" /> */}
            </div>
            <p>אפשר גם להוסיף אימוג’י או GIF מצחיקלהקללת האווירה</p>
          </div>
        )}
      </div>
    </>
  );
};

export default RequestHistory;
