const DadL = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="100"
  height="100"
  fill="none"
  viewBox="0 0 100 100"
>
  <g clipPath="url(#clip0_4680_6462)">
    <path d="M0 0H100V100H0z"></path>
    <g>
      <g>
        <g>
          <g>
            <mask
              id="mask0_4680_6462"
              style={{ maskType: "alpha" }}
              width="100"
              height="100"
              x="0"
              y="0"
              maskUnits="userSpaceOnUse"
            >
              <circle
                cx="50"
                cy="50"
                r="49.5"
                fill="#C4C4C4"
                stroke="#000"
              ></circle>
            </mask>
            <g mask="url(#mask0_4680_6462)">
              <g>
                <g>
                  <path
                    fill="#45CBF6"
                    d="M55.09 79.794H44.564v19.213H55.09V79.793z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M45.57 90.515h8.41a5.7 5.7 0 014.018 1.652 5.623 5.623 0 011.663 3.99v12.801H39.89V96.157c0-1.497.598-2.932 1.663-3.99a5.7 5.7 0 014.017-1.652z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M49.124 23h-1.776C34.453 23 24 33.996 24 47.56v15.477c0 13.565 10.453 21.111 23.348 21.111h1.776c12.895 0 23.348-7.546 23.348-21.11V47.56C72.472 33.996 62.02 23 49.125 23z"
                  ></path>
                  <path
                    stroke="#3869E5"
                    strokeWidth="2"
                    d="M63.706 50.96c0 3.382-2.719 6.111-6.058 6.111-3.34 0-6.06-2.729-6.06-6.11 0-3.382 2.72-6.111 6.06-6.111 3.339 0 6.058 2.729 6.058 6.11z"
                  ></path>
                  <path
                    stroke="#3869E5"
                    strokeWidth="2"
                    d="M46.06 50.96c0 3.382-2.72 6.111-6.06 6.111-3.34 0-6.059-2.729-6.059-6.11 0-3.382 2.72-6.111 6.06-6.111 3.339 0 6.058 2.729 6.058 6.11z"
                  ></path>
                  <path
                    stroke="#3869E5"
                    strokeWidth="2"
                    d="M46.472 52.147h4.706"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M40.001 67.553c7.059 4.148 9.412 7.703 19.139 0H40z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M52.353 73.478h-6.47l3.53 4.148 2.94-4.148z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M37.125 120.887H32.07v-16.625c0-8.023 5.052-13.626 12.288-13.626v5.021c-4.996 0-7.232 4.33-7.232 8.605v16.625z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M67.695 120.887h-5.057v-16.625c0-4.282-2.235-8.605-7.23-8.605v-5.021c7.235 0 12.287 5.603 12.287 13.626v16.625z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M22.035 34.066c.702 6.304 2.153 17.542 2.23 17.934l4.266-16.071 12.119-7.742-1.939 6.468 7.174-3.626-3.78 4.9 18.904-7.252 7.853 7.252L72.2 51.45l.877-17.385L74 17.8 45.885 8l5.914 5.487-15.996-4.214 6.301 4.214-13.573-.686 12.119 1.177L20 19.66l2.035 14.405z"
                  ></path>
                </g>
              </g>
              <path
                fill="#3869E5"
                d="M42.595 52.872a.93.93 0 01-.93-.93 1.964 1.964 0 00-3.326-1.347c-.366.354-.58.837-.597 1.346a.93.93 0 11-1.86 0 3.827 3.827 0 013.822-3.914 3.822 3.822 0 013.822 3.914.93.93 0 01-.93.931z"
              ></path>
              <path
                fill="#3869E5"
                d="M60.353 52.872a.93.93 0 01-.93-.93 1.964 1.964 0 00-3.326-1.347c-.366.354-.58.837-.597 1.346a.93.93 0 11-1.86 0 3.827 3.827 0 013.822-3.914 3.822 3.822 0 013.821 3.914.93.93 0 01-.93.931z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_4680_6462">
      <path fill="#fff" d="M0 0H100V100H0z"></path>
    </clipPath>
  </defs>
</svg>
);

export default DadL;
