import { useEffect, useState } from "react";
import ReactPlayer from "react-player/youtube";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AboutFamily from "./pages/AboutFamily";
import EnterCode from "./pages/EnterCode";
import WhoAmI from "./pages/WhoAmI";
import SignUp from "./pages/SignUp";
import NoticeMsg from "./pages/NoticeMsg";
import SignupSuccess from "./pages/SignupSuccess";
import QueryForm from "./pages/QueryForm";
import Home from "./pages/Home";
import SelfConnectText from "./pages/self-connection/SelfConnectText";
import SituationDescription from "./pages/self-connection/SituationDescription";
// import Feelings from "./pages/self-connection/Feelings";
import BadFeeling from "./pages/self-connection/BadFeeling";
import GoodFeeling from "./pages/self-connection/GoodFeeling";
import NeedsText from "./pages/self-connection/NeedsText";
import IgnoredNeeds from "./pages/self-connection/IgnoredNeeds";
import AnsweredNeeds from "./pages/self-connection/AnsweredNeeds";
import UserSelection from "./pages/self-connection/UserSelection";
import SelectionSummary from "./pages/self-connection/SelectionSummary";
import SelectionVerdict from "./pages/self-connection/SelectionVerdict";
import SubmitSelection from "./pages/self-connection/SubmitSelection";
import MostValueSelection from "./pages/self-connection/MostValueSelection";
import RequestFrom from "./pages/request/RequestFrom";
import PartnerName from "./pages/request/PartnerName";
import ProcessText from "./pages/request/ProcessText";
import Situation from "./pages/request/Situation";
import FirstStop from "./pages/request/FirstStop";
import FeelingsOnSitu from "./pages/request/FeelingsOnSitu";
import SecondStop from "./pages/request/SecondStop";
import MyNeeds from "./pages/request/MyNeeds";
import ThirdStop from "./pages/request/ThirdStop";
import GuessText from "./pages/request/GuessText";
import GuessFeelingsOf from "./pages/request/GuessFeelingsOf";
import DraftingRequest from "./pages/request/DraftingRequest";
import PointsToThink from "./pages/request/PointsToThink";
import RequestSummary from "./pages/request/RequestSummary";
import ReadSummary from "./pages/request/ReadSummary";
import RequestHistory from "./pages/request/RequestHistory";
import FeelNeedText from "./pages/feelNeed/FeelNeedText";
import FeelingsNeeds from "./pages/feelNeed/FeelingsNeeds";
import SituationAgainst from "./pages/judgement/SituationAgainst";
import SituPartnerName from "./pages/judgement/SituPartnerName";
import JudgementDesc from "./pages/judgement/JudgementDesc";
import UnderstandNeeds from "./pages/judgement/UnderstandNeeds";
import PartnerJudgement from "./pages/judgement/PartnerJudgement";
import PartnerDesire from "./pages/judgement/PartnerDesire";
import TryPartnerDeepNeeds from "./pages/judgement/TryPartnerDeepNeeds";
import PartnerNeedsSummary from "./pages/judgement/PartnerNeedsSummary";
import PartnerNeedSitu from "./pages/judgement/PartnerNeedSitu";
import PartnerNeedSubmit from "./pages/judgement/PartnerNeedSubmit";
import PartnerFirstNeed from "./pages/judgement/PartnerFirstNeed";
import PartnerSecondNeed from "./pages/judgement/PartnerSecondNeed";
import PartnerDeepNeeds from "./pages/judgement/PartnerDeepNeeds";
import MyJudgement from "./pages/judgement/MyJudgement";
import MyDesire from "./pages/judgement/MyDesire";
import MyFirstNeed from "./pages/judgement/MyFirstNeed";
import MySecondNeed from "./pages/judgement/MySecondNeed";
import TryMyDeepNeeds from "./pages/judgement/TryMyDeepNeeds";
import MyDeepNeeds from "./pages/judgement/MyDeepNeeds";
import MyNeedsSummary from "./pages/judgement/MyNeedsSummary";
import MyNeedSitu from "./pages/judgement/MyNeedSitu";
import MyNeedSubmit from "./pages/judgement/MyNeedSubmit";
import SmoothScroll from "./components/SmoothScroll";
import Registration from "./pages/Registration";
import SaveCookies from "./pages/SaveCookies";
import Login from "./pages/Login";
import ExtractLogs from "./pages/ExtractLogs";

function App() {
  const [play, setPlay] = useState(false);
  const notMobile = useMediaQuery({
    query: "(min-width: 460px)",
  });

  let youtubeURL =
    "https://www.youtube.com/watch?v=Mxa26woUW_4&ab_channel=tatangert";

  useEffect(() => {
    var myModalEl = document.getElementById("youtube_modal");
    myModalEl?.addEventListener("hidden.bs.modal", function (event) {
      setPlay(false);
    });
    myModalEl?.addEventListener("shown.bs.modal", function (event) {
      setPlay(true);
    });
  }, [play]);

  const pathArr = window.location.href.split("/");
  const path = pathArr.includes("a")
    ? "/a/"
    : pathArr.includes("b")
    ? "/b/"
    : "/c/";

  return (
    <>
      {notMobile ? (
        <NoticeMsg />
      ) : (
        <>
          <BrowserRouter basename={path}>
            <SmoothScroll>
              <Routes>
                <Route path="/" element={<SignUp />} />
                <Route path="/enter-code" element={<EnterCode />} />
                <Route path="/about-family" element={<AboutFamily />} />
                <Route path="/i-am" element={<WhoAmI />} />
                <Route path="/query-form" element={<QueryForm />} />
                <Route path="/signup-success" element={<SignupSuccess />} />

                <Route path="/home" element={<Home />} />
                <Route path="/self-connect" element={<SelfConnectText />} />
                <Route
                  path="/situ-description"
                  element={<SituationDescription />}
                />
                {/* <Route path="/feelings" element={<Feelings />} /> */}
                <Route path="/bad-feeling" element={<BadFeeling />} />
                <Route path="/good-feeling" element={<GoodFeeling />} />

                <Route path="/needs-text" element={<NeedsText />} />
                <Route path="/ignored-needs" element={<IgnoredNeeds />} />
                <Route path="/answered-needs" element={<AnsweredNeeds />} />
                <Route path="/user-selection" element={<UserSelection />} />
                <Route
                  path="/select-most-valued"
                  element={<MostValueSelection />}
                />
                <Route
                  path="/selection-summary"
                  element={<SelectionSummary />}
                />
                <Route path="/verdict" element={<SelectionVerdict />} />
                <Route path="/submit-selection" element={<SubmitSelection />} />
                <Route path="/request-from" element={<RequestFrom />} />
                <Route path="/partner-name" element={<PartnerName />} />
                <Route path="/process" element={<ProcessText />} />
                <Route path="/situation" element={<Situation />} />
                <Route path="/first-stop" element={<FirstStop />} />
                <Route
                  path="/situation-feelings"
                  element={<FeelingsOnSitu />}
                />
                <Route path="/second-stop" element={<SecondStop />} />
                <Route path="/my-needs" element={<MyNeeds />} />
                <Route path="/third-stop" element={<ThirdStop />} />
                <Route path="/guess-text" element={<GuessText />} />
                <Route
                  path="/guess-feelings-of"
                  element={<GuessFeelingsOf />}
                />
                <Route path="/drafting-request" element={<DraftingRequest />} />
                <Route path="/points-to-think" element={<PointsToThink />} />
                <Route path="/request-summary" element={<RequestSummary />} />
                <Route path="/read-summary" element={<ReadSummary />} />
                <Route path="/request-history" element={<RequestHistory />} />
                <Route path="/feel-need-text" element={<FeelNeedText />} />
                <Route path="/all-feelings-needs" element={<FeelingsNeeds />} />
                <Route
                  path="/situation-against"
                  element={<SituationAgainst />}
                />
                <Route
                  path="/situ-partner-name"
                  element={<SituPartnerName />}
                />
                <Route
                  path="/judgement-description"
                  element={<JudgementDesc />}
                />
                <Route path="/understand-needs" element={<UnderstandNeeds />} />
                <Route path="/my-judgement" element={<MyJudgement />} />
                <Route
                  path="/partner-judgement"
                  element={<PartnerJudgement />}
                />
                <Route path="/my-desire" element={<MyDesire />} />
                <Route path="/partner-desire" element={<PartnerDesire />} />
                <Route path="/my-first-need" element={<MyFirstNeed />} />
                <Route
                  path="/partner-first-need"
                  element={<PartnerFirstNeed />}
                />
                <Route path="/my-second-need" element={<MySecondNeed />} />
                <Route
                  path="/partner-second-need"
                  element={<PartnerSecondNeed />}
                />
                <Route path="/try-my-deep-needs" element={<TryMyDeepNeeds />} />
                <Route
                  path="/try-partner-deep-needs"
                  element={<TryPartnerDeepNeeds />}
                />
                <Route path="/my-deep-needs" element={<MyDeepNeeds />} />
                <Route
                  path="/partner-deep-needs"
                  element={<PartnerDeepNeeds />}
                />
                <Route path="/my-needs-summary" element={<MyNeedsSummary />} />
                <Route
                  path="/partner-needs-summary"
                  element={<PartnerNeedsSummary />}
                />
                <Route path="/my-need-situation" element={<MyNeedSitu />} />
                <Route
                  path="/partner-need-situ"
                  element={<PartnerNeedSitu />}
                />
                <Route path="/my-need-submit" element={<MyNeedSubmit />} />
                <Route
                  path="/partner-need-submit"
                  element={<PartnerNeedSubmit />}
                />
                <Route path="/registration" element={<Registration />} />
                <Route path="/login" element={<Login />} />
                <Route path="/save-cookies" element={<SaveCookies />} />
                <Route path="/extract-logs" element={<ExtractLogs />} />
                <Route path="*" element={<SignUp />} />
              </Routes>
            </SmoothScroll>
          </BrowserRouter>
        </>
      )}

      {/* Modal for YouTube Mpathic Video */}
      <div
        className="modal fade"
        id="youtube_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content-yt">
            <div className="modal-header py-1" style={{ border: 0 }}>
              <button
                type="button"
                className="btn-close btn-close-yt"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setPlay(false);
                }}
              />
              <h5
                className="modal-title text-center a_modal_title"
                id="exampleModalLabel"
              >
                Onboarding
              </h5>
            </div>
            <div className="modal-body px-0 pb-1">
              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player"
                  url={youtubeURL}
                  width="100%"
                  height="100%"
                  controls
                  playing={play}
                  // stopOnUnmount={true}
                  style={{ border: 0 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  Modal for YouTube Mpathic Video */}
    </>
  );
}

export default App;
