const MaxSelectionModal = ({ num = 2 }) => {
  return (
    <>
      <div
        className="modal fade"
        id="max_selection_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
        data-bs-backdrop="true"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered d-flex justify-content-center">
          <div className="modal-content-yt" style={{ width: "80%" }}>
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close btn-close-yt"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              {/* popup design */}
              <div className="disc_a14_modal mt-1 mb-3">
                <p className="modal-title text-center a_modal_title">
                  {" "}
                  כעת ניתן לבחור {num} בלבד
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MaxSelectionModal;
