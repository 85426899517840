import { Link } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import smileFace from "../img/smileFace.png";

const SelfConnectText = () => {
  return (
    <>
      <div className="bg-primary_e">
        <Header extraClass="border-bottom" />

        <div className="e_content">
          <div className="container">
            <h4 className="e_content_disc mb-3">
              חיבור לעצמינו זו דרך מופלאה לחוות את החיים במלואם.
            </h4>
            <div className="e_emoji_icon mb-3">
              <img src="assets/images/smile_face.svg" alt="smile face" />
            </div>
            <h4 className="e_content_disc mb-3">
              עם כל המורכבות שבדבר, חיבור עצמי עוזר לנקות את רעשי הרקע ולזהות את
              מה שחי בנו באמת.
            </h4>

            <Link to="/situ-description" className="line">
              <RoundBtn
                extraClass="mt-5 text-regular bg-secondary-button"
                text="להמשיך"
              />
            </Link>

          </div>
        </div>
      </div>
    </>
  );
};

export default SelfConnectText;
