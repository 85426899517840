import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FeelingModal from "../../components/feeling/FeelingModal";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import DisplayFeelNeed from "../../components/feeling/DisplayFeelNeed";
import callApi from "../../lib/apisauce/callApi";
import { trackPromise } from "react-promise-tracker";
import NeedModal from "../../components/feeling/NeedModal";

const FeelingsNeeds = () => {
  const location = useLocation();
  const data = location?.state?.data;
  const text = location?.state?.text;

  const [activeGFBtn, setActiveGFBtn] = useState(data === "good_feel");
  const [activeBFBtn, setActiveBFBtn] = useState(data === "bad_feel");
  const [activeNeed, setActiveNeed] = useState(data === "need");
  const [headText, setHeadText] = useState(text);

  const [goodFeel, setGoodFeel] = useState(
    JSON.parse(sessionStorage.getItem("goodFeelings")) || []
  );
  const [badFeel, setBadFeel] = useState(
    JSON.parse(sessionStorage.getItem("badFeelings")) || []
  );
  const [needs, setNeeds] = useState(
    JSON.parse(sessionStorage.getItem("allNeeds")) || []
  );

  useEffect(() => {
    if (!needs.length) fetchAllNeeds();
    if (!badFeel.length) fetchBadFeelings();
    if (!goodFeel.length) fetchGoodFeelings();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));

  const fetchAllNeeds = () => {
    trackPromise(
      callApi
        .getDatawithToken(
          "get-needs",
          {},
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
              Authorization: JWToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            sessionStorage.setItem("allNeeds", JSON.stringify(res.data.data));
            setNeeds(res.data.data);
          }
        })
        .catch((res) => {
          console.log(res.originalError);
        })
    );
  };

  const fetchBadFeelings = () => {
    trackPromise(
      callApi
        .getDatawithToken(
          "get-bad",
          {},
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
              Authorization: JWToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            setBadFeel(res.data.data);
            sessionStorage.setItem(
              "badFeelings",
              JSON.stringify(res.data.data)
            );
          }
        })
        .catch((res) => console.log(res.originalError))
    );
  };

  const fetchGoodFeelings = () => {
    trackPromise(
      callApi
        .getDatawithToken(
          "get-good",
          {},
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
              Authorization: JWToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            setGoodFeel(res.data.data);
            sessionStorage.setItem(
              "goodFeelings",
              JSON.stringify(res.data.data)
            );
          }
        })
        .catch((res) => console.log(res.originalError))
    );
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  return (
    <>
      <div className="bg-light_e pb-10">
        <Header text={headText} />

        <ul
          className="nav nav-pills mt-4 mb-3 justify-content-center pt-70 nav-fix"
        >
          <li className="nav-item">
            <button
              className={`nav-link custom_tab_button ${
                !activeGFBtn ? "active" : ""
              }`}
              type="button"
              onClick={() => {
                setActiveGFBtn(true);
                setActiveBFBtn(false);
                setActiveNeed(false);
                setHeadText("רגשות נעימים");
              }}
            >
              <img
                src={`assets/images/${
                  !activeGFBtn ? "good_feel_white.svg" : "good_feel_blue.svg"
                }`}
                alt="Smile"
              />{" "}
              רגשות <br />
              נעימים
            </button>
          </li>

          <li className="nav-item">
            <button
              className={`nav-link custom_tab_button ${
                !activeBFBtn ? "active" : ""
              }`}
              type="button"
              onClick={() => {
                setActiveBFBtn(true);
                setActiveGFBtn(false);
                setActiveNeed(false);
                setHeadText("רגשות לא נעימים");
              }}
            >
              <img
                src={`assets/images/${
                  !activeBFBtn ? "bad_feel_white.svg" : "bad_feel_blue.svg"
                }`}
                alt="Sad"
              />{" "}
              רגשות לא נעימים
            </button>
          </li>

          <li className="nav-item">
            <button
              className={`nav-link custom_tab_button ${
                !activeNeed ? "active" : ""
              }`}
              type="button"
              onClick={() => {
                setActiveNeed(true);
                setActiveGFBtn(false);
                setActiveBFBtn(false);
                setHeadText("צרכים");
              }}
            >
              <img
                src={`assets/images/${
                  !activeNeed ? "neutral_white.svg" : "neutral_blue.svg"
                }`}
                alt="Neutral"
              />{" "}
              <p className="mt-2"> צרכים</p>
            </button>
          </li>
        </ul>

        <div className="e3_header_content e30_header_content">
          <div className="container px-3 pt-4">
            <a
              href="#"
              className="line"
              data-bs-toggle="modal"
              data-bs-target="#custom_modal"
            >
              {activeNeed ? (
                <button className="btn e3_custom_btn">עזרה בהבנת הצרכים</button>
              ) : (
                <button className="btn e3_custom_btn">
                  {userGender === "male" ? "לחץ " : "לחצי "}
                  לעזרה בהבנת הרגשות
                </button>
              )}
            </a>
          </div>
        </div>

        {activeGFBtn && <DisplayFeelNeed DisplayData={goodFeel} />}
        {activeBFBtn && <DisplayFeelNeed DisplayData={badFeel} />}
        {activeNeed && <DisplayFeelNeed DisplayData={needs} dataFor="needs" />}

        <div className="container px-3 pt-4">
          <Link to="/home">
            <RoundBtn
              extraClass={`mt-5 mb-2 text-regular bg-primary-button`}
              text="לדף הבית"
            />
          </Link>
        </div>
      </div>

      {/* {/ Modal Popup /} */}
      {activeNeed ? <NeedModal /> : <FeelingModal />}
      {/* {/ Modal Popup /} */}
    </>
  );
};

export default FeelingsNeeds;