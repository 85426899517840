import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Header from "../../components/layout/Header";
import callApi from "../../lib/apisauce/callApi";
import RoundBtn from "../../components/RoundBtn";
import FeelingModal from "../../components/feeling/FeelingModal";
import MaxSelectionModal from "../../components/feeling/MaxSelectionModal";
import BadFeelBlockA from "../../components/all-tabs/path-a/BadFeelBlockA";

const FeelingsOnSitu = () => {
  const userRequest = JSON.parse(sessionStorage.getItem("request"));
  const partnerRole = userRequest?.ask_from_partner_roll;
  const navigate = useNavigate();
  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));
  const [badFeel, setBadFeel] = useState(
    JSON.parse(sessionStorage.getItem("badFeelings")) || []
  );

  useEffect(() => {
    if (userRequest?.situation_description === undefined) {
      navigate("/home");
    }

    if (!badFeel.length) fetchBadFeelings();

    // Update the content attribute to set initial scale to 1.0
    var metaViewport = document.querySelector('meta[name="viewport"]');
    metaViewport.setAttribute(
      "content",
      "width=device-width, initial-scale=1, maximum-scale=1"
    );
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const resetScrollPosition = () => {
      if (document.documentElement.scrollTop > 5) {
        console.log("inside scroll");
        document.documentElement.scrollTop = 0;
      }
    };

    setTimeout(() => {
      scrollToTop();
      resetScrollPosition();
    }, 100);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      compareRowCount();
    }, 100);
  }, []);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  const countRowItems = (element) => {
    const grid = Array.from(document?.querySelector(element)?.children);
    const baseOffset = grid[0].offsetTop;
    const breakIndex = grid.findIndex((item) => item.offsetTop > baseOffset);
    return breakIndex === -1 ? grid.length : breakIndex;
  };

  const compareRowCount = () => {
    badFeel.map((bfeel, i) => {
      let itemPerRow = countRowItems("#element" + (i + 1));
      let arrLen = badFeel[i].emotionSubCat?.length;

      if (itemPerRow === arrLen) {
        setBadFeel((badFeel) =>
          badFeel.map((bf) =>
            bf.emotionId === i + 1 ? { ...bf, isMatch: true, itemPerRow } : bf
          )
        );
      } else {
        setBadFeel((badFeel) =>
          badFeel.map((bf) =>
            bf.emotionId === i + 1 ? { ...bf, isMatch: false, itemPerRow } : bf
          )
        );
      }
    });
  };

  const fetchBadFeelings = () => {
    callApi
      .getDatawithToken(
        "get-bad",
        {},
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: JWToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          sessionStorage.setItem("badFeelings", JSON.stringify(res.data.data));
          setBadFeel(res.data.data);
        }
      })
      .catch((res) => {
        console.log(res.originalError);
      });
  };

  let selectedBadFeels = useSelector((state) =>
    state.badFeel.map((x) => x.name)
  );

  const submitBadFeelings = () => {
    const feelings = selectedBadFeels.join(":");
    const newData = { ...userRequest, feelings };
    sessionStorage.setItem("request", JSON.stringify(newData));
    if (selectedBadFeels.length) {
      navigate("/second-stop");
    }
  };

  return (
    <>
      <div className="bg-light_e pb-10">
        <Header text="הרגשות שלי" />

        {/* Progress Line start */}
        <div className="progressbar pt-70 d-flex justify-content-between">
          <div className="progress-line progress_fill">
            <h6>תיאור</h6>
            <img src="assets/images/full_line.svg" alt="" />
          </div>

          <div className="progress-line progress_fill">
            <h6>רגש</h6>
            <img src="assets/images/half_line.svg" alt="" />
          </div>

          <div className="progress-line">
            <h6>צורך</h6>
            <img src="assets/images/light_line.svg" alt="" />
          </div>

          <div className="progress-line">
            <h6>בקשה</h6>
            <img src="assets/images/light_line.svg" alt="" />
          </div>
        </div>
        {/* Progress Line End */}

        <div className="container mt-4 px-4">
          <div className="a_content_headers">
            <h5 className="a11_disc_content">
              בחר
              {userGender === "male" ? " " : "י "} 2-1 רגשות לא נעימים שעולים בך
              כאשר :
            </h5>

            <p className="a11_disc_para_content">
              "
              {userRequest?.situation_description.split("\n")[0] === "טקסט חופשי"
                ? ""
                : userRequest?.situation_description.split("\n")[0]}
              {userRequest?.situation_description.split("\n").pop()}"
            </p>
          </div>
          <a
            href="#"
            className="line"
            data-bs-toggle="modal"
            data-bs-target="#custom_modal"
          >
            <button className="btn e3_custom_btn">
              {userGender === "male" ? "לחץ " : "לחצי "}
              לעזרה בהבנת הרגשות
            </button>
          </a>
        </div>

        {["kid_f", "kid_m", "brother", "sister"].includes(partnerRole) && (
          <p className="child_note mt-3">
            *בזמן בחירת הרגשות, יש לקחת בחשבון את גיל הילד/ה.
          </p>
        )}

        {badFeel &&
          badFeel.map((bf) => (
            <BadFeelBlockA
              key={bf.emotionId}
              id={bf.emotionId}
              dataArr={bf.emotionSubCat}
              isMatch={bf.isMatch}
              itemPerRow={bf.itemPerRow}
            />
          ))}

        {/* hidden btn for popup for max selection */}
        <a
          className="line"
          data-bs-toggle="modal"
          data-bs-target="#max_selection_modal"
          id="max2_btn"
        ></a>
        {/* hidden btn for popup for max selection */}

        <div
          className={`container px-4 ${
            selectedBadFeels.length ? "pb-100" : ""
          }`}
        >
          <div className="pb-2">
            <RoundBtn
              extraClass={`mt-4  text-regular 
                  ${
                    selectedBadFeels.length
                      ? "bg-primary-button btn-popup"
                      : "primary-disable"
                  }`}
              text="להמשיך"
              onClick={submitBadFeelings}
            />
          </div>
        </div>
      </div>

      {/* Modal Popup */}
      <FeelingModal />
      {/* Modal Popup */}

      {/* Modal for max 2 selection */}
      <MaxSelectionModal />
      {/* Modal for max 2 selection*/}
    </>
  );
};

export default FeelingsOnSitu;
