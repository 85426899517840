import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import callApi from "../../lib/apisauce/callApi";
import { queryString } from "../../utils/queryString";
import { needInstances } from "../../utils/needInstances";
import { badfeelingInstances } from "../../utils/badfeelingInstances";

const RequestSummary = () => {
  const userRequest = JSON.parse(sessionStorage.getItem("request"));
  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  const navigate = useNavigate();
  useEffect(() => {
    if (userRequest?.request_to_partner === undefined) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  let partnerGender;
  if (
    ["kid_m", "dad", "adult_m", "other_m", "brother"].includes(
      userRequest?.ask_from_partner_roll
    )
  ) {
    partnerGender = "male";
  } else {
    partnerGender = "female";
  }

  let userfinalSelect, userFinalFreeText;
  userfinalSelect = sessionStorage.getItem("finalSelect");
  userFinalFreeText = sessionStorage.getItem("finalFreeText");

  const [finalSelect, setFinalSelect] = useState(
    userfinalSelect ||
      `האם תוכל${partnerGender === "male" ? "" : "י"} לעזור לי בזה?`
  );

  const [freeTextInput, setFreeTextInput] = useState(false);
  const [freeText, setFreeText] = useState(userFinalFreeText || "");

  const [contentEditable, setContentEditable] = useState(false);
  let oldDraftingSelect;
  let situDescDrop = userRequest?.situation_description?.split("\n")[0];
  let situDescLast = userRequest?.situation_description?.split("\n").pop();
  oldDraftingSelect = userRequest?.request_to_partner?.split("\n")[0];
  let oldDraftingDesc = userRequest?.request_to_partner?.split("\n").pop();
  let Feel1 = userRequest?.feelings?.split(":")[0];
  let Feel2 = userRequest?.feelings?.split(":")[1];
  let Need1 = userRequest?.needs?.split(":")[0];
  let Need2 = userRequest?.needs?.split(":")[1];

  const [draftingSelect, setDraftingSelect] = useState(
    oldDraftingSelect || "מאוד יעזור לי אם "
  );

  // console.log(draftingSelect);

  const [draftingDesc, setDraftingDesc] = useState(oldDraftingDesc);

  let fixedFinalText, text1;
  fixedFinalText = userRequest?.fixed_final_text;
  text1 = userRequest?.text1;

  const need1Ins = needInstances?.find((need) => need.name === Need1?.trim());
  const need2Ins = needInstances?.find((need) => need.name === Need2?.trim());

  const n2Ins =
    userGender === "male"
      ? need2Ins?.male_second_instance
      : need2Ins?.female_second_instance;

  Need1 =
    need1Ins?.first_instance_unisex === undefined
      ? Need1
      : need1Ins?.first_instance_unisex;
  Need2 = n2Ins === undefined ? Need2 : n2Ins;

  const feel1Ins = badfeelingInstances?.find(
    (feel) => feel?.name === Feel1?.trim()
  );

  const f1Ins =
    userGender === "male"
      ? feel1Ins?.male_first_instance
      : feel1Ins?.female_first_instance;

  const feel2Ins = badfeelingInstances?.find(
    (feel) => feel?.name === Feel2?.trim()
  );
  const f2Ins =
    userGender === "male"
      ? feel2Ins?.male_second_instance
      : feel2Ins?.female_second_instance;

  Feel1 = f1Ins === undefined ? Feel1 : f1Ins;
  Feel2 = f2Ins === undefined ? Feel2 : f2Ins;

  let finalText = `${userRequest?.ask_from_partner_name}, ${
    situDescDrop === "טקסט חופשי" ? "" : situDescDrop
  }${situDescLast}.\n\n${Feel1} ${
    Feel2 === undefined ? "" : " " + Feel2
  } כי אני ממש זקו${userGender === "male" ? "ק" : "קה"} ${Need1}${
    Need2 === undefined ? "" : " " + Need2
  }.`;

  // console.log({ finalText });

  const onLoadDesc = text1 && text1.length > 0 ? text1 : finalText;

  const [oldDesc, setOldDesc] = useState(onLoadDesc);
  const [editedText, setEditedText] = useState(text1 || "");

  const [editedDraftingText, setEditedDraftingText] = useState("");

  const [freeDraftingTextInput, setFreeDraftingTextInput] = useState(false);
  const [freeDraftingText, setFreeDraftingText] = useState("");

  let share__way, savedAction;
  share__way = userRequest?.share_way;
  savedAction = userRequest?.action;
  const [shareWay, setShareWay] = useState(share__way || "");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [action, setAction] = useState(savedAction || "");

  const draftingOptionValues = [
    "מאוד יעזור לי אם ",
    "מאוד יעזור לי ש",
    "הייתי רוצה ש",
    "בא לי ש",
    `אני זקוק${userGender === "male" ? "" : "ה"} לעזרה ממך - ש`,
    "אני רוצה לבקש ממך ש",
    "חשוב לי לבקש ממך ש",
    "מקווה שתוכל לעזור לי ו",
    "מקווה שתוכלי לעזור לי ו",
    "יש לי בקשה ",
    "טקסט חופשי",
  ];

  useEffect(() => {
    if (finalSelect === "טקסט חופשי") {
      // setTimeout(() => {
      //   descId?.focus();
      // }, 100);
      setFreeTextInput(true);
    } else {
      setFreeTextInput(false);
      setFreeText("");
    }
  }, [finalSelect]);

  useEffect(() => {
    if (draftingSelect === "טקסט חופשי") {
      setFreeDraftingTextInput(true);
    } else {
      setFreeDraftingTextInput(false);
      setFreeDraftingText("");
    }
  }, [draftingSelect]);

  useEffect(() => {
    if (freeTextInput) {
      setTimeout(() => {
        document.getElementById("finalDesc").focus();
      }, 1000);
    }
  }, [freeTextInput]);

  useEffect(() => {
    const sessData = JSON.parse(sessionStorage.getItem("request"));
    let updatedData = { ...sessData, share_way: shareWay };
    sessionStorage.setItem("request", JSON.stringify(updatedData));
  }, [shareWay]);

  useEffect(() => {
    const sessData = JSON.parse(sessionStorage.getItem("request"));
    let updatedData = { ...sessData, action };
    sessionStorage.setItem("request", JSON.stringify(updatedData));
  }, [action]);

  useEffect(() => {
    if (contentEditable) {
      document.getElementById("oldDescTextarea").focus();
    }
  }, [contentEditable]);

  const toggleShareBar = () => {
    var x = document.getElementById("share_icon");

    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }

    if (x.style.display === "block") {
      setTimeout(function () {
        x.style.display = "none";
      }, 3000);
    }
  };

  const optionValues = [
    `האם תוכל${partnerGender === "male" ? "" : "י"} לעזור לי בזה?`,
    `תרגיש${partnerGender === "male" ? "" : "י"} בנוח לעזור לי?`,
    `תוכל${partnerGender === "male" ? "" : "י"} לעזור לי בבקשה?`,
    `תסכי${partnerGender === "male" ? "ם" : "מי"} לעזור לי בזה?`,
    `זה יכול לעבוד מבחינתך?`,
    `זה אפשרי מבחינתך?`,
    `זה סבבה מבחינתך?`,
    `זור${partnerGender === "male" ? "ם" : "מת"} איתי :) ?`,
    `מרגיש לך בנוח עם הבקשה הזאת?`,
    `תוכל${partnerGender === "male" ? "" : "י"} להגיד לי האם ${
      partnerGender === "male" ? "תוכל" : "תוכלי"
    } לעזור לי בזה?`,
    `בבקשה תגיד${partnerGender === "male" ? "" : "י"} לי האם ${
      partnerGender === "male" ? "תוכל" : "תוכלי"
    } לעזור לי בזה?`,
    `אם זה לא מתאים, בבקשה תגיד${
      partnerGender === "male" ? "" : "י"
    } מה כן יכול להתאים כדי לעזור לי :)`,
    `טקסט חופשי`,
  ];

  let userSelect =
    finalSelect === "טקסט חופשי" && freeText.length > 0
      ? freeText
      : finalSelect === "טקסט חופשי" && freeText.length === 0
      ? ""
      : finalSelect;

  let draftSelectVal = draftingSelect === "טקסט חופשי" ? "" : draftingSelect;

  //let final_text = `${userSelect}\n${finalDesc}`;

  let request_to_partner = `${draftSelectVal}\n${
    editedDraftingText.length ? editedDraftingText : draftingDesc
  }`;

  let final_text = `${finalText}\n${userRequest?.request_to_partner?.replace(
    "\n",
    " "
  )}\n${userSelect}`;

  let lastSelect = userSelect.includes("?")
    ? userSelect.replace("?", "") + "?"
    : userSelect;

  let fixed_final_text = `${
    editedText ? editedText : finalText
  }\n${request_to_partner?.replace("\n", " ")}.\n${lastSelect}`;

  const displayData = {
    first_Text: editedText && editedText.length > 0 ? editedText : finalText,
    draft_Select: draftingSelect,
    draft_Desc: request_to_partner.split("\n").pop(),
    last_Select: finalSelect,
    free_Text: freeText,
  };

  const handleSubmit = () => {
    const newData = {
      ...userRequest,
      final_text,
      fixed_final_text,
      text1: editedText && editedText.length > 0 ? editedText : finalText,
      status: "A",
      action:
        action.length > 0
          ? action.includes("verbal")
            ? action
            : action + ":verbal"
          : "verbal",
      share_way: shareWay.length > 0 ? shareWay : " ",
    };

    submitDatatoDB(newData);

    const updatedData = {
      ...userRequest,
      final_text,
      fixed_final_text,
      text1: editedText && editedText.length > 0 ? editedText : finalText,
      request_to_partner,
      status: "A",
      action:
        action.length > 0
          ? action.includes("verbal")
            ? action
            : action + ":verbal"
          : "verbal",
      share_way: shareWay,
    };

    sessionStorage.setItem("displayData", JSON.stringify(displayData));
    sessionStorage.setItem("request", JSON.stringify(updatedData));
    navigate("/read-summary");
  };

  const saveEditedChanges = () => {
    setContentEditable(false);
    //setDraftingSelect(draftingSelect);

    const updatedData = {
      ...userRequest,
      final_text,
      fixed_final_text,
      text1: editedText && editedText.length > 0 ? editedText : finalText,
      request_to_partner,
      status: "A",
      action: action,
      share_way: shareWay,
    };

    sessionStorage.setItem("request", JSON.stringify(updatedData));
    //delete updatedData?.text1;
    submitDatatoDB({
      ...updatedData,
      action: action.length > 0 ? action : " ",
      share_way: shareWay.length > 0 ? shareWay : " ",
    });
  };

  const updateShareChanges = (sharedOn) => {
    let shareWayFinal =
      shareWay !== ""
        ? shareWay.includes(sharedOn)
          ? shareWay
          : shareWay + ":" + sharedOn
        : sharedOn;

    const updatedData = {
      ...userRequest,
      final_text,
      fixed_final_text,
      text1: editedText && editedText.length > 0 ? editedText : finalText,
      request_to_partner,
      status: "A",
      action: action,
      share_way: shareWay.length > 0 ? shareWay + ":" + sharedOn : sharedOn,
      share_way: shareWayFinal,
    };

    sessionStorage.setItem("request", JSON.stringify(updatedData));

    console.log({ sharedOn });
    console.log(updatedData);

    if (sharedOn === "whatsapp") {
      window.location.href = `https://api.whatsapp.com/send/?text=${updatedData.fixed_final_text}`;
    } else if (sharedOn === "telegram") {
      window.location.href = `tg://msg?text=${updatedData.fixed_final_text}`;
    } else {
      window.location.href = `mailto:?subject=message from mpathic family webapp&body=${updatedData.fixed_final_text}`;
    }
    submitDatatoDB(updatedData, true);
  };

  const submitDatatoDB = async (data, socialShare = false) => {
    socialShare ? setLoading1(true) : setLoading(true);
    delete data?.text1;
    try {
      const res = await callApi.postData("request-screen", queryString(data), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          Authorization: JWToken,
        },
      });

      if (res.ok) {
        if (res.data.status === "success") {
          socialShare ? setLoading1(false) : setLoading(false);
        } else {
          socialShare ? setLoading1(false) : setLoading(false);
          console.log(res.data.message);
        }
      } else {
        console.log(res.originalError);
      }
    } catch (error) {
      error && console.log(error);
    }
    socialShare ? setLoading1(false) : setLoading(false);
  };

  let textt = editedText && editedText.length > 0 ? editedText : finalText;

  let sharingText = `${textt} ${
    displayData.draft_Select === "טקסט חופשי" ? "" : displayData.draft_Select
  }${displayData.draft_Desc}. ${
    displayData.last_Select === "טקסט חופשי" ? "" : displayData.last_Select
  }${displayData.free_Text.length > 0 ? displayData.free_Text : ""}`;

  return (
    <>
      <div className="bg-light_e">
        <Header text="ניסוח הבקשה מוכן" />
        {/* Progress Line start */}
        <div className="progressbar pt-70 d-flex justify-content-between">
          <div className="progress-line progress_fill">
            <h6>תיאור</h6>
            <img src="assets/images/full_line.svg" alt="" />
          </div>
          <div className="progress-line progress_fill">
            <h6>רגש</h6>
            <img src="assets/images/full_line.svg" alt="" />
          </div>
          <div className="progress-line progress_fill">
            <h6>צורך</h6>
            <img src="assets/images/full_line.svg" alt="" />
          </div>
          <div className="progress-line progress_fill">
            <h6>בקשה</h6>
            <img src="assets/images/full_line.svg" alt="" />
          </div>
        </div>
        {/* Progress Line End */}
        <div className="px-3">
          <div className="container">
            {/* summary box design start */}
            <h5 className="a11_disc_content mt-4">הנה הבקשה שלך :)</h5>
            <div className="talk-bubble tri-right round right-top">
              <div className="talktext">
                <div id="content-editable">
                  {!contentEditable ? (
                    editedText.length > 0 || editedDraftingText.length > 0 ? (
                      <>
                        <p>
                          {editedText.length > 0
                            ? editedText.split("\n").map((item, i) => (
                                <span key={item + i}>
                                  {item}
                                  <br />
                                </span>
                              ))
                            : oldDesc.split("\n").map((itm, idx) => (
                                <span key={itm + idx}>
                                  {itm}
                                  <br />
                                </span>
                              ))}
                        </p>

                        <select
                          className={`form-select a22_custom-select-form custom-select-form form-select-sm disabled-textarea ${
                            draftingSelect === "טקסט חופשי"
                              ? "border_none w-10p"
                              : ""
                          }`}
                          onChange={(e) => setDraftingSelect(e.target.value)}
                          value={
                            draftingSelect === "טקסט חופשי"
                              ? ""
                              : draftingSelect
                          }
                        >
                          <option
                            value=""
                            disabled
                            style={{ display: "none" }}
                          ></option>
                          {draftingOptionValues &&
                            draftingOptionValues.map((dv, i) => (
                              <option key={dv + i} value={dv}>
                                {dv}
                              </option>
                            ))}
                        </select>

                        <p>
                          {editedDraftingText.length > 0
                            ? editedDraftingText
                            : draftingDesc}
                        </p>
                      </>
                    ) : (
                      // This block will display on load only, not at/after edit.
                      <>
                        <p>
                          {text1 && text1.length > 0
                            ? text1.split("\n").map((itm, idx) => (
                                <span key={itm + idx}>
                                  {itm}
                                  <br />
                                </span>
                              ))
                            : oldDesc.split("\n").map((itm, idx) => (
                                <span key={itm + idx}>
                                  {itm}
                                  <br />
                                </span>
                              ))}
                        </p>

                        <div className="position-relative">
                          <select
                            className={`form-select a22_custom-select-form custom-select-form form-select-sm disabled-textarea ${
                              draftingSelect === "טקסט חופשי"
                                ? "border_none w-10p"
                                : ""
                            }`}
                            onChange={(e) => setDraftingSelect(e.target.value)}
                            value={
                              draftingSelect === "טקסט חופשי"
                                ? ""
                                : draftingSelect
                            }
                          >
                            <option
                              value=""
                              disabled
                              style={{ display: "none" }}
                            ></option>
                            {draftingOptionValues &&
                              draftingOptionValues.map((dv, i) => (
                                <option key={dv + i} value={dv}>
                                  {dv}
                                </option>
                              ))}
                          </select>
                        </div>

                        <p>
                          {editedDraftingText.length > 0
                            ? editedDraftingText
                            : draftingDesc}
                        </p>
                      </>
                    )
                  ) : (
                    // This block is on edit/ typing box.
                    <>
                      <textarea
                        rows="6"
                        className="request_textarea"
                        defaultValue={editedText ? editedText : oldDesc}
                        onChange={(e) => setEditedText(e.target.value)}
                        id="oldDescTextarea"
                      />
                      <div className="position-relative">
                        <select
                          className={`form-select a22_custom-select-form custom-select-form form-select-sm disabled-textarea ${
                            draftingSelect === "טקסט חופשי"
                              ? "border_none w-10p"
                              : ""
                          }`}
                          onChange={(e) => setDraftingSelect(e.target.value)}
                          value={
                            draftingSelect === "טקסט חופשי"
                              ? ""
                              : draftingSelect
                          }
                        >
                          <option
                            value=""
                            disabled
                            style={{ display: "none" }}
                          ></option>
                          {draftingOptionValues &&
                            draftingOptionValues.map((dv, i) => (
                              <option
                                key={dv + i}
                                value={dv}
                                selected={draftingSelect === dv}
                              >
                                {draftingSelect === dv ? draftingSelect : dv}
                              </option>
                            ))}
                        </select>
                      </div>

                      <textarea
                        rows="2"
                        className="request_textarea"
                        defaultValue={
                          editedDraftingText.length > 0
                            ? editedDraftingText
                            : draftingDesc
                        }
                        onChange={(e) => setEditedDraftingText(e.target.value)}
                      />
                    </>
                  )}
                </div>

                <div
                  className={`position-relative ${
                    contentEditable ? "" : "mt-3"
                  }`}
                >
                  <select
                    className={`form-select a22_custom-select-form custom-select-form form-select-sm ${
                      finalSelect === "טקסט חופשי" ? "border_none w-10p" : ""
                    }`}
                    aria-label=".form-select-sm example"
                    onChange={(e) => {
                      setFinalSelect(e.target.value);
                      sessionStorage.setItem("finalSelect", e.target.value);
                      if (e.target.value !== "טקסט חופשי") {
                        sessionStorage.removeItem("finalFreeText");
                      }
                    }}
                    value={finalSelect === "טקסט חופשי" ? "" : finalSelect}
                  >
                    {optionValues &&
                      optionValues.map((v, i) => (
                        <option key={v + i} value={v}>
                          {v}
                        </option>
                      ))}
                  </select>

                  {finalSelect === "טקסט חופשי" ? (
                    <textarea
                      rows="2"
                      className="request_textarea"
                      value={freeText}
                      onChange={(e) => {
                        setFreeText(e.target.value);
                        sessionStorage.setItem("finalFreeText", e.target.value);
                      }}
                      id="finalDesc"
                      disabled={finalSelect === "טקסט חופשי" ? false : true}
                    />
                  ) : (
                    ""
                  )}

                  {/* {freeTextInput && (
                    <input
                      type="text"
                      id="free_text_input"
                      className="textarea_input_situation_3 px-0"
                      value={freeText}
                      onChange={(e) => setFreeText(e.target.value)}
                    />
                  )} */}
                </div>
              </div>
            </div>
            {/* summary box design end */}
          </div>
        </div>

        {/* Button group design start */}
        <div className="a22_button_group mt-5 px-3">
          <div className="container">
            {/* Primary Button Design */}
            <div className="position-relative">
              {loading1 && (
                <div className="btn_loader" style={{ left: 25, top: 20 }}></div>
              )}
            </div>
            <div className="line">
              <button
                onClick={() => {
                  toggleShareBar();
                  setAction((preVal) =>
                    preVal !== ""
                      ? preVal.includes("sharetext")
                        ? preVal
                        : preVal + ":sharetext"
                      : "sharetext"
                  );
                }}
                className="btn custom_button mt-5 mb-2 text-regular bg-primary-button d-flex align-items-center justify-content-evenly"
              >
                <img
                  src="assets/images/share_icon.svg"
                  alt=""
                  className="button_icon_option"
                />{" "}
                לשתף כהודעה
              </button>
            </div>

            {/* Outline Button Design */}
            {contentEditable ? (
              <div className="line">
                <button
                  onClick={saveEditedChanges}
                  className="btn custom_button bg-secondary-button-2 text-regular mt-3 d-flex align-items-center justify-content-evenly"
                >
                  <img
                    src="assets/images/update.svg"
                    alt="Update"
                    className="button_icon_option"
                  />{" "}
                  שמירת שינויים בניסוח
                </button>
              </div>
            ) : (
              <>
                <div className="position-relative">
                  {loading && (
                    <div
                      className="btn_loader_dark"
                      style={{ left: 25, top: 28 }}
                    ></div>
                  )}
                </div>
                <div className="line">
                  <button
                    onClick={() => {
                      setContentEditable(true);
                    }}
                    className="btn bg-blue-outline-button custom_button outline-bg-focus text-regular mt-3 d-flex align-items-center justify-content-evenly"
                  >
                    <img
                      src="assets/images/edit_icon.svg"
                      alt=""
                      className="button_icon_option"
                    />{" "}
                    תיקון ניסוח ידני
                  </button>
                </div>
              </>
            )}

            {/* Outline Button Design */}

            <div className="line">
              <button
                className="btn bg-blue-outline-button custom_button text-regular mt-3 d-flex align-items-center justify-content-evenly"
                onClick={handleSubmit}
              >
                <img
                  src="assets/images/voice_icon.svg"
                  alt=""
                  className="button_icon_option"
                />
                רוצה להגיד בעל פה
              </button>
            </div>

            <p className="a_footer_disc mt-3">
              *הניסוח יישמר באופן אוטומטי
              <Link to="/request-history">
                <u> בהיסטוריה</u>
              </Link>
            </p>
          </div>
        </div>
        {/* Button group design end */}

        <div className="share_option" id="share_icon">
          <div className="share_option_icon d-flex justify-content-center">
            <a
            // href={`https://api.whatsapp.com/send/?text=${sharingText}`}
            >
              <img
                src="assets/images/Whatsapp.svg"
                alt=""
                onClick={() => {
                  toggleShareBar();
                  setShareWay((preVal) =>
                    preVal !== ""
                      ? preVal.includes("whatsapp")
                        ? preVal
                        : preVal + ":whatsapp"
                      : "whatsapp"
                  );

                  updateShareChanges("whatsapp");
                }}
              />
            </a>

            {/* <a href={`https://t.me/share/url?text=${sharingText}`}> */}
            <a
            // href={`tg://msg?text=${sharingText}`}
            >
              <img
                src="assets/images/Telegram.svg"
                alt=""
                onClick={() => {
                  toggleShareBar();
                  setShareWay((preVal) =>
                    preVal !== ""
                      ? preVal.includes("telegram")
                        ? preVal
                        : preVal + ":telegram"
                      : "telegram"
                  );
                  updateShareChanges("telegram");
                }}
              />
            </a>

            <a
            // href={`mailto:?subject=message from mpathic family webapp&body=${sharingText}`}
            >
              <img
                src="assets/images/Gmail.svg"
                alt=""
                onClick={() => {
                  toggleShareBar();
                  setShareWay((preVal) =>
                    preVal !== ""
                      ? preVal.includes("gmail")
                        ? preVal
                        : preVal + ":gmail"
                      : "gmail"
                  );
                  updateShareChanges("gmail");
                }}
              />
            </a>

            {/* <FacebookShareButton
              url={"https://mpathic-family.vercel.app/"}
              quote={"Mpathic-Family"}
            >
              <img src="assets/images/Messenger.svg" alt="" />
            </FacebookShareButton>
            
            <img src="assets/images/Instagram.svg" alt="" /> */}
          </div>
          <p>אפשר גם להוסיף אימוג’י או GIF מצחיק להקללת האווירה</p>
        </div>
      </div>
    </>
  );
};

export default RequestSummary;
