import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import GoodFeelBlock from "../all-tabs/GoodFeelBlockE";
import callApi from "../../lib/apisauce/callApi";
import RoundBtn from "../RoundBtn";

const GoodFeelings = () => {
  const navigate = useNavigate();
  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));
  const [goodFeel, setGoodFeel] = useState(
    JSON.parse(sessionStorage.getItem("goodFeelings")) || []
  );

  useEffect(() => {
    if (!goodFeel.length) fetchGoodFeelings();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      compareRowCount();
    }, 100);
  }, []);

  const countRowItems = (element) => {
    const grid = Array.from(document?.querySelector(element)?.children);
    const baseOffset = grid[0].offsetTop;
    const breakIndex = grid.findIndex((item) => item.offsetTop > baseOffset);
    return breakIndex === -1 ? grid.length : breakIndex;
  };

  const compareRowCount = () => {
    goodFeel.map((gfeel, i) => {
      let itemPerRow = countRowItems("#element" + (i + 1));
      let arrLen = goodFeel[i].emotionSubCat?.length;

      if (itemPerRow === arrLen) {
        setGoodFeel((goodFeel) =>
          goodFeel.map((gf) =>
            gf.emotionId === i + 1 ? { ...gf, isMatch: true, itemPerRow } : gf
          )
        );
      } else {
        setGoodFeel((goodFeel) =>
          goodFeel.map((gf) =>
            gf.emotionId === i + 1 ? { ...gf, isMatch: false, itemPerRow } : gf
          )
        );
      }
    });
  };

  const fetchGoodFeelings = () => {
    trackPromise(
      callApi
        .getDatawithToken(
          "get-good",
          {},
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
              Authorization: JWToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            setGoodFeel(res.data.data);
            sessionStorage.setItem(
              "goodFeelings",
              JSON.stringify(res.data.data)
            );
          }
        })
        .catch((res) => console.log(res.originalError))
    );
  };

  let selectedGoodFeels = useSelector((state) =>
    state.goodFeel.map((x) => x.name)
  );

  let selectedBadFeels = useSelector((state) =>
    state.badFeel.map((x) => x.name)
  );

  const selfConnData = JSON.parse(sessionStorage.getItem("selfConnectData"));

  const submitGoodFeelings = () => {
    let g_feelings = selectedGoodFeels?.join(":");
    let b_feelings = selectedBadFeels?.join(":");
    let newData = { ...selfConnData, g_feelings, b_feelings };

    // let newData = (g_feelings && b_feelings) ? { ...selfConnData, g_feelings, b_feelings } :
    // g_feelings ? { ...selfConnData, g_feelings } : { ...selfConnData, b_feelings };

    sessionStorage.setItem("selfConnectData", JSON.stringify(newData));
    if (selectedGoodFeels.length || selectedBadFeels.length) {
      navigate("/needs-text");
    }
  };

  return (
    <>
      {goodFeel &&
        goodFeel.map((gf) => (
          <GoodFeelBlock
            key={gf.emotionId}
            emotionId={gf.emotionId}
            dataArr={gf.emotionSubCat}
            isMatch={gf.isMatch}
            itemPerRow={gf.itemPerRow}
          />
        ))}

      <div
        className={`container px-4 ${
          selectedGoodFeels.length || selectedBadFeels.length ? "pb-100" : ""
        }`}
      >
        <div className="pb-2">
          <RoundBtn
            extraClass={`mt-4  text-regular 
                  ${
                    selectedGoodFeels.length || selectedBadFeels.length
                      ? "bg-primary-button btn-popup"
                      : "primary-disable"
                  }`}
            text="להמשיך"
            onClick={submitGoodFeelings}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(GoodFeelings);
