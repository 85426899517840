import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import RadioBtn from "../components/RadioBtn";
import RoundBtn from "../components/RoundBtn";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("יש להזין את שמך")
    .max(45, "שם ארוך מדי (יותר מ45 תווים)"),
});

const AboutFamily = () => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let savedName, gendr, chidrn;
  savedName = userData?.name !== "" ? userData?.name : "";
  gendr = userData?.member_gender !== "" ? userData?.member_gender : "";
  chidrn = userData?.kids_exist !== "" ? userData?.kids_exist : "";
  const [gender, setGender] = useState(gendr || "");
  const [children, setChildren] = useState(chidrn || "");
  const [genderErr, setGenderErr] = useState(false);
  const [childErr, setChildErr] = useState(false);

  const navigate = useNavigate();
  const updateMode = sessionStorage.getItem("updateMode");

  const initialValues = {
    name: savedName,
  };

  useEffect(() => {
    if (gender !== "") {
      setGenderErr(false);
    }
  }, [gender]);

  useEffect(() => {
    if (children !== "") {
      setChildErr(false);
    }
  }, [children]);

  const handleSubmit = (values) => {
    if (gender === "") {
      setGenderErr(true);
    } else {
      setGenderErr(false);
    }
    if (children === "") {
      setChildErr(true);
    } else {
      setChildErr(false);
    }

    if (gender === "" || children === "") return;

    const newUserData = {
      ...userData,
      ...values,
      member_gender: gender,
      kids_exist: children,
    };
    if (newUserData?.kids_exist === "without_children") {
      delete newUserData?.kids_over_seven; //for update case only.
      delete newUserData?.sibling; //for update case only.
    }
    sessionStorage.setItem("userData", JSON.stringify(newUserData));
    navigate("/i-am");
  };

  return (
    <>
      <div className="bg-light_e pb-2">
        {/* Header Section Start */}
        <div className="header_register">
          <img
            src="assets/images/header-shape.svg"
            className="w-100"
            alt="header-shape"
          />

          {updateMode !== undefined && updateMode === "on" ? (
            <h5 className="img-shape_content">
              פה ניתן לעדכן את הפרטים
              <br />
              שלך ושל משפחתך :)
              <br />
              <span className="small">מקסימום 6 שאלות </span>
            </h5>
          ) : (
            <h5 className="img-shape_content">
              לפני הכל, נשמח להכיר קצת
              <br />
              וללמוד טיפה על המשפחה
              <br />
              שלך :) <br />
              <span className="small">מקסימום 6 שאלות </span>
            </h5>
          )}
        </div>
        {/* Header Section End */}

        {/* Input Section Start */}
        <div className="register-input">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              setFieldTouched,
              touched,
              values,
            }) => (
              <>
                <div className="container">
                  <div className="mb-3 mt-3 w-100">
                    <label className="form-label custom-lebel-register">
                      1. איך קוראים לך?
                    </label>
                    <input
                      type="text"
                      className="form-control input_shadow custom_input"
                      name="name"
                      onChange={handleChange("name")}
                      onBlur={() => setFieldTouched("name")}
                      title="Name"
                      value={values.name}
                      required
                    />
                    {touched.name && <p className="err_msg">{errors.name}</p>}
                  </div>

                  {/* Input Radio 1 */}
                  <div className="input_radio_register mt-5">
                    <label className="form-label custom-lebel-register">
                      2. איך לפנות אליך?
                    </label>
                    <RadioBtn
                      extraClass="mb-3"
                      radioId="gender1"
                      radioName="gender"
                      value="female"
                      checked={gender === "female"}
                      onChange={(e) => setGender(e.target.value)}
                      labelText="בלשון נקבה"
                    />
                    <RadioBtn
                      radioId="gender2"
                      radioName="gender"
                      value="male"
                      checked={gender === "male"}
                      onChange={(e) => setGender(e.target.value)}
                      labelText="בלשון זכר"
                    />
                    {genderErr && (
                      <p className="err_msg">יש לבחור את האפשרות המתאימה</p>
                    )}
                  </div>

                  {/* Input Radio 2 */}
                  <div className="input_radio_register mt-5">
                    <label className="form-label custom-lebel-register">
                      3. מה נכון יותר להגיד בנוגע למשפחה שלך?
                    </label>
                    <RadioBtn
                      extraClass="mb-3"
                      radioId="children1"
                      radioName="children"
                      value="with_children"
                      checked={children === "with_children"}
                      onChange={(e) => setChildren(e.target.value)}
                      labelText=" יש ילדים במשפחה"
                    />
                    <RadioBtn
                      radioName="children"
                      radioId="children2"
                      value="without_children"
                      checked={children === "without_children"}
                      onChange={(e) => setChildren(e.target.value)}
                      labelText="זוגיות ללא ילדים"
                    />
                    {childErr && (
                      <p className="err_msg">יש לבחור את האפשרות המתאימה</p>
                    )}
                  </div>

                  {/* Button Design */}
                  <div className="line">
                    <RoundBtn
                      extraClass="mt-5 text-regular bg-secondary-button"
                      text="להמשיך"
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
      {/* Input Section End */}
    </>
  );
};

export default AboutFamily;
