import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";

const JudgementDesc = () => {
  const judgementData = JSON.parse(sessionStorage.getItem("judgement"));
  const [desc, setDesc] = useState(judgementData?.situation_description || "");
  const navigate = useNavigate();

  useEffect(() => {
    if (
      judgementData?.ask_from_partner_name === undefined ||
      judgementData?.ask_from_partner_name === ""
    ) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  const handleSubmit = () => {
    const newData = { ...judgementData, situation_description: desc };
    sessionStorage.setItem("judgement", JSON.stringify(newData));
    if (desc.length) {
      navigate("/understand-needs");
    }
  };

  return (
    <>
      <div className="bg-light_e">
        <Header />

        <div className="c3-content px-3 pt-100 pb-2">
          <div className="container">
            <p>
              בבקשה
              {userGender === "male" ? " כתוב " : " כתבי "}
              את כל הדברים שאת
              {userGender === "male" ? "ה " : " "}
              חושב
              {userGender === "male" ? " " : "ת "}
              ומרגיש
              {userGender === "male" ? " " : "ה "}
              סביב הסיטואציה.
              <br />
              {userGender === "male" ? "כתוב " : "כתבי "}
              בצורה חופשית, ביקורתית, שיפוטית וללא צנזורה (זה רק בשבילך).
              <br />
              אפשר להשתמש בכל מילה בעולם.
            </p>
            <h4>הכל מתקבל כאן בהבנה וללא שיפוט :)</h4>
            {/* Textarea Input Design */}
            <div className="input-group">
              <textarea
                className="form-control input_shadow custom_textarea"
                aria-label="With textarea"
                rows={12}
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>

            <div className="line">
              <RoundBtn
                extraClass={`mt-5 mb-2 text-regular 
                  ${desc.length ? "bg-primary-button" : "primary-disable"}
                `}
                text="להמשיך"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JudgementDesc;
