import { useState } from "react";
import { useNavigate } from "react-router-dom";

import callApi from "../../lib/apisauce/callApi";
import PersonFormBg from "../PersonFormBg";
import RadioBtn from "../RadioBtn";
import { queryString } from "../../utils/queryString";
import RoundBtn from "../RoundBtn";
import SettingModal from "../feeling/SettingModal";
import { useEffect } from "react";

const UnmarriedForm = () => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [relation, setRelation] = useState(userData?.adult_relationship || "");
  const [loading, setLoading] = useState(false);
  const [relationErr, setRelationErr] = useState(false);

  const navigate = useNavigate();

  const updateMode = sessionStorage.getItem("updateMode");
  const gender = userData?.member_gender;
  const RegUserData = JSON.parse(sessionStorage.getItem("RegUserData"));
  const last_id = RegUserData?.last_id || "";

  useEffect(() => {
    if (relation !== "") {
      setRelationErr(false);
    }
  }, [relation]);

  //handleSubmit fn save all user data from session storage to Database.
  const handleSubmit = async () => {
    if (relation === "") {
      return setRelationErr(true);
    } else {
      setRelationErr(false);
    }

    setLoading(true);
    let newUserData =
      last_id && last_id !== ""
        ? {
            ...userData,
            adult_relationship: relation,
            status: "A",
            last_id,
          }
        : {
            ...userData,
            adult_relationship: relation,
            status: "A",
          };

    delete newUserData?.parent_relationship; //for update case only.
    let DataRemovedBlankVal = Object.fromEntries(
      Object.entries(newUserData).filter((value) => value[1])
    ); //Remove key with Blank value if any.

    try {
      const res = await callApi.postData(
        "member_register",
        queryString(DataRemovedBlankVal),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );

      if (res?.ok) {
        if (res.data.status === "success") {
          const { userdata } = res.data;
          const {
            name,
            email,
            username,
            google_token,
            member_code,
            family_role,
            kids_exist,
            member_gender,
            sibling,
            kids_over_seven,
            parent_relationship,
            adult_relationship,
          } = userdata;

          sessionStorage.setItem(
            "userData",
            JSON.stringify({
              name,
              email,
              username,
              google_token,
              member_code,
              family_role,
              kids_exist,
              member_gender,
              sibling,
              kids_over_seven,
              parent_relationship,
              adult_relationship,
            })
          );

          sessionStorage.setItem("UserJWT", JSON.stringify(res.data.jwt));
          sessionStorage.removeItem("RegUserData");
          setLoading(false);
          if (updateMode !== undefined && updateMode === "on") {
            document.getElementById("settingsModal").click();
          } else {
            navigate("/signup-success");
          }
        } else {
          console.log(res.data.message);
        }
      } else {
        console.log(res.originalError);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <a
        href="#"
        className="line"
        data-bs-toggle="modal"
        data-bs-target="#setting_modal"
        id="settingsModal"
      ></a>
      <SettingModal />

      <PersonFormBg
        submitBtn={
          <>
            <div className="position-relative">
              {loading && (
                <div
                  className="btn_loader_dark"
                  style={{ right: 36, top: 10, zIndex: 9999 }}
                ></div>
              )}
            </div>
            <div className="container">
              <RoundBtn
                extraClass="mt-5 text-regular bg-secondary-button blank-footer-btn"
                text="להמשיך"
                onClick={handleSubmit}
              />
            </div>
          </>
        }
      >
        <div className="container px-4">
          {/* Input Radio 1 */}
          <div className="input_radio_register my-5">
            <label className="form-label custom-lebel-register">
              5. אני נמצא
              {gender === "male" ? " " : "ת "}
              בזוגיות עם:
            </label>
            <RadioBtn
              extraClass="mb-3"
              radioId="relation1"
              radioName="relation"
              value="female"
              checked={relation === "female"}
              onChange={(e) => setRelation(e.target.value)}
              labelText="בת זוג"
            />

            <RadioBtn
              extraClass="mb-3"
              radioId="relation2"
              radioName="relation"
              value="male"
              checked={relation === "male"}
              onChange={(e) => setRelation(e.target.value)}
              labelText="בן זוג"
            />

            <RadioBtn
              extraClass="mb-3"
              radioId="relation3"
              radioName="relation"
              value="other"
              checked={relation === "other"}
              onChange={(e) => setRelation(e.target.value)}
              labelText="אחר"
            />
            {relationErr && (
              <p className="err_msg">יש לבחור את האפשרות המתאימה</p>
            )}
          </div>
        </div>
      </PersonFormBg>
    </>
  );
};

export default UnmarriedForm;
