import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import { v4 as uuidv4 } from "uuid";
import RoundBtn from "../../components/RoundBtn";

const UserSelection = () => {
  const navigate = useNavigate();
  const selfConnData = JSON.parse(sessionStorage.getItem("selfConnectData"));
  useEffect(() => {
    if (
      (selfConnData?.b_feelings === undefined &&
        selfConnData?.b_needs === undefined) ||
      (selfConnData?.g_feelings === undefined &&
        selfConnData?.g_needs === undefined)
    ) {
      navigate("/home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  let goodFeel, badFeel, answeredNeeds, ignoredNeeds;

  goodFeel = selfConnData?.g_feelings && selfConnData?.g_feelings?.split(":");
  badFeel = selfConnData?.b_feelings && selfConnData?.b_feelings?.split(":");
  answeredNeeds = selfConnData?.g_needs && selfConnData?.g_needs?.split(":");
  ignoredNeeds = selfConnData?.b_needs && selfConnData?.b_needs?.split(":");

  const handleCheckMost = (wantsTocheck) => {
    const newData = {
      ...selfConnData,
      does_want_to_check_most_feelings: wantsTocheck,
    };
    sessionStorage.setItem("selfConnectData", JSON.stringify(newData));
    wantsTocheck === "Y"
      ? navigate("/select-most-valued")
      : navigate("/selection-summary");
  };

  return (
    <>
      <div className="bg-primary_e pb-4">
        <Header extraClass="border-bottom" />

        <div className="e7_content pt-80">
          <div className="container">
            <h5 className="e7_header_title">סיכום :)</h5>
          </div>
          <div className="e7_border_bottom" />
          <div className="container px-4">
            {/* Button group 1 */}
            {goodFeel && goodFeel.length && (
              <div className="e7_content_btn mt-4">
                <h5 className="e7_header_heading">הרגשות הנעימים שבחרת:</h5>
                <div className="all_e7_btn d-flex flex-wrap">
                  {goodFeel &&
                    goodFeel.map((gf, i) => (
                      <button
                        key={uuidv4() + i}
                        className="btn e7_custom_btn bg_light_btn"
                      >
                        {gf}
                      </button>
                    ))}
                </div>
              </div>
            )}

            {/* Button group 2 */}
            {answeredNeeds && answeredNeeds.length && (
              <div className="e7_content_btn mt-4">
                <h5 className="e7_header_heading">
                  צרכים שכן מקבלים מענה שבחרת:
                </h5>
                <div className="all_e7_btn d-flex flex-wrap">
                  {answeredNeeds &&
                    answeredNeeds.map((ansNeed, i) => (
                      <button
                        key={i + uuidv4()}
                        className="btn e7_custom_btn bg_light_btn"
                      >
                        {ansNeed}
                      </button>
                    ))}
                </div>
              </div>
            )}
          </div>
          {(goodFeel || answeredNeeds) && <div className="e7_border_bottom" />}

          <div className="container px-4">
            {/* Button group 3 */}
            {badFeel && badFeel.length && (
              <div className="e7_content_btn mt-4">
                <h5 className="e7_header_heading">הרגשות הלא נעימים שבחרת:</h5>
                <div className="all_e7_btn d-flex flex-wrap">
                  {badFeel &&
                    badFeel.map((bf, i) => (
                      <button
                        key={i + uuidv4() + i}
                        className="btn e7_custom_btn bg_light_btn"
                      >
                        {bf}
                      </button>
                    ))}
                </div>
              </div>
            )}

            {/* Button group 4 */}
            {ignoredNeeds && ignoredNeeds.length && (
              <div className="e7_content_btn mt-4">
                <h5 className="e7_header_heading">
                  צרכים שלא מקבלים מענה שבחרת:
                </h5>
                <div className="all_e7_btn d-flex flex-wrap">
                  {ignoredNeeds &&
                    ignoredNeeds.map((ingNeed, i) => (
                      <button
                        key={i + i + uuidv4()}
                        className="btn e7_custom_btn bg_light_btn"
                      >
                        {ingNeed}
                      </button>
                    ))}
                </div>
              </div>
            )}
          </div>
          {(badFeel || ignoredNeeds) && <div className="e7_border_bottom" />}

          <div className="container px-4">
            {/* Button group 5 */}
            <div className="e7_content_btn mt-5">
              <h5 className="e7_header_title">
                רוצה לבחור את אלה שהכי מורגשים עכשיו?
              </h5>
            </div>
            {/* Button Design 1 */}
            <div className="line">
              <RoundBtn
                extraClass="mt-3 text-regular bg-secondary-button"
                text="כן"
                onClick={() => handleCheckMost("Y")}
              />
            </div>
            {/* Button Design 2 */}
            <div className="line">
              <RoundBtn
                extraClass="mt-4 text-regular bg-secondary-button"
                text="לא, רוצה להמשיך"
                onClick={() => handleCheckMost("N")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSelection;
