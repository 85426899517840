const OtherFBlue = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    fill="none"
    viewBox="0 0 100 100"
  >
    <g clipPath="url(#clip0_4680_6072)">
      <path d="M0 0H100V100H0z"></path>
      <g>
        <g filter="url(#filter0_ddii_4680_6072)">
          <ellipse
            cx="49.311"
            cy="49.499"
            fill="#fff"
            rx="42.311"
            ry="42.499"
          ></ellipse>
          <g>
            <mask
              id="mask0_4680_6072"
              style={{ maskType: "alpha" }}
              width="85"
              height="85"
              x="7"
              y="7"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#C4C4C4"
                stroke="#000"
                d="M91.5 49.5c0 23.198-18.722 42-41.812 42-23.09 0-41.811-18.802-41.811-42 0-23.197 18.722-41.998 41.811-41.998 23.09 0 41.812 18.801 41.812 41.999z"
              ></path>
            </mask>
            <g mask="url(#mask0_4680_6072)">
              <g>
                <g>
                  <path
                    fill="#45CBF6"
                    d="M53.618 70.233H44.71v20.918h8.907V70.233z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M45.562 79.7h7.117c1.275 0 2.498.504 3.4 1.404a4.79 4.79 0 011.407 3.39v10.882H40.755V84.495a4.79 4.79 0 011.408-3.391 4.813 4.813 0 013.4-1.405z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M64.916 55.031c8.78-8.773 8.78-22.998 0-31.771-8.78-8.774-23.015-8.774-31.795 0-8.78 8.773-8.78 22.998 0 31.771 8.78 8.774 23.015 8.774 31.795 0z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M31.695 48.78a3.9 3.9 0 00-1.49-5.32 3.92 3.92 0 00-5.332 1.485 3.9 3.9 0 001.489 5.32 3.92 3.92 0 005.333-1.485z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M27.235 42.953a3.908 3.908 0 003.562-4.229 3.912 3.912 0 00-4.239-3.553 3.908 3.908 0 00-3.561 4.229 3.912 3.912 0 004.238 3.553z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M31.021 34.415a3.9 3.9 0 00.423-5.508 3.922 3.922 0 00-5.52-.421 3.9 3.9 0 00-.423 5.507 3.921 3.921 0 005.52.422z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M32.375 28.722a3.91 3.91 0 003.915-3.905 3.91 3.91 0 00-3.915-3.906 3.91 3.91 0 00-3.915 3.906 3.91 3.91 0 003.915 3.905z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M41.19 22.218a3.9 3.9 0 00-.456-5.505 3.921 3.921 0 00-5.517.455 3.899 3.899 0 00.455 5.505 3.921 3.921 0 005.518-.455z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M49.476 16.656a3.897 3.897 0 01-2.209 3.737 3.923 3.923 0 01-4.306-.604 3.904 3.904 0 01-1.09-4.2 3.921 3.921 0 017.223-.41c.223.463.353.964.382 1.477z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M57.331 17.135a3.91 3.91 0 00-3.824-3.995 3.91 3.91 0 00-4.004 3.815 3.91 3.91 0 003.824 3.994 3.91 3.91 0 004.004-3.814z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M64.063 22.04a3.9 3.9 0 00-1.16-5.401 3.92 3.92 0 00-5.414 1.156 3.9 3.9 0 001.16 5.401 3.92 3.92 0 005.414-1.157z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M70.538 26.15a3.903 3.903 0 00-2.41-4.973 3.917 3.917 0 00-4.984 2.404 3.903 3.903 0 002.41 4.973 3.917 3.917 0 004.984-2.404z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M74.014 33.792a3.9 3.9 0 00-.954-5.44 3.92 3.92 0 00-5.454.95 3.9 3.9 0 00.954 5.442 3.92 3.92 0 005.454-.952z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M72.451 43.184a3.91 3.91 0 003.792-4.025 3.911 3.911 0 00-4.035-3.782 3.91 3.91 0 00-3.791 4.025 3.911 3.911 0 004.034 3.782z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M73.918 46.584a3.898 3.898 0 01-2.209 3.738 3.923 3.923 0 01-4.306-.604 3.904 3.904 0 01-1.091-4.2 3.906 3.906 0 013.471-2.613 3.928 3.928 0 012.832.985 3.91 3.91 0 011.303 2.694z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M24.788 62.748c4.851-.786 8.146-5.346 7.358-10.186-.787-4.84-5.358-8.127-10.21-7.341-4.852.786-8.146 5.346-7.359 10.186.788 4.84 5.36 8.127 10.21 7.34z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M27.525 49.53c4.33-.702 7.27-4.772 6.568-9.09-.703-4.32-4.783-7.253-9.113-6.552-4.33.7-7.27 4.77-6.567 9.09.703 4.32 4.783 7.253 9.112 6.551z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M81.043 60.263a8.863 8.863 0 000-12.555c-3.476-3.468-9.11-3.468-12.586 0a8.863 8.863 0 000 12.555c3.475 3.467 9.11 3.467 12.586 0z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M77.73 47.31a7.91 7.91 0 000-11.205 7.956 7.956 0 00-11.232 0 7.91 7.91 0 000 11.206 7.956 7.956 0 0011.232 0z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M49.706 26.811h-1.503c-10.912 0-19.758 7.73-19.758 17.266v10.88c0 9.536 8.846 17.266 19.758 17.266h1.503c10.912 0 19.758-7.73 19.758-17.266v-10.88c0-9.535-8.846-17.265-19.758-17.265z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M53.617 79.698l-4.454-4.443-4.453 4.443 4.453 4.443 4.454-4.443z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M45.096 44.232c9.19-2.563 15.358-9.346 13.777-15.152-1.58-5.805-10.313-8.433-19.503-5.87-9.19 2.562-15.359 9.346-13.778 15.15 1.582 5.806 10.313 8.434 19.504 5.872z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M41.27 49.225a2.255 2.255 0 01-1.398 2.089c-.413.17-.618-3.965-1.308.129a2.265 2.265 0 01-1.78-1.776 2.253 2.253 0 01.965-2.318 2.267 2.267 0 013.52 1.877z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M60.651 49.225a2.254 2.254 0 01-1.396 2.088c-.414.172-.567-3.964-1.309.13a2.266 2.266 0 01-1.78-1.774 2.254 2.254 0 01.963-2.32 2.268 2.268 0 013.35 1.013c.113.273.172.567.172.863z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M40.855 58.1c5.49 12.464 10.89 12.898 16.196 0H40.855z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M38.416 105.515h-4.278V91.384c0-6.82 4.275-11.582 10.398-11.582v4.268c-4.228 0-6.12 3.68-6.12 7.314v14.131z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M64.284 105.515h-4.278V91.384c0-3.64-1.892-7.314-6.12-7.314v-4.268c6.123 0 10.398 4.762 10.398 11.582v14.131z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_ddii_4680_6072"
        width="102.623"
        height="103"
        x="-2"
        y="-2"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="5" dy="5"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4680_6072"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-5" dy="-5"></feOffset>
        <feGaussianBlur stdDeviation="2"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0.92549 0 0 0 0 0.937255 0 0 0 0 0.941176 0 0 0 0.25 0"></feColorMatrix>
        <feBlend
          in2="effect1_dropShadow_4680_6072"
          result="effect2_dropShadow_4680_6072"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_4680_6072"
          result="shape"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="-1" dy="-2"></feOffset>
        <feGaussianBlur stdDeviation="13"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
        <feBlend in2="shape" result="effect3_innerShadow_4680_6072"></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dx="1" dy="-3"></feOffset>
        <feGaussianBlur stdDeviation="13.5"></feGaussianBlur>
        <feComposite
          in2="hardAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
        ></feComposite>
        <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
        <feBlend
          in2="effect3_innerShadow_4680_6072"
          result="effect4_innerShadow_4680_6072"
        ></feBlend>
      </filter>
      <clipPath id="clip0_4680_6072">
        <path fill="#fff" d="M0 0H100V100H0z"></path>
      </clipPath>
    </defs>
  </svg>
  )
}

export default OtherFBlue
