export const needInstances = [
  {
    id: 1,
    name: "הבנה",
    need_category_id: 1,
    first_instance_unisex: "להבנה",
    female_second_instance: "ושיבינו אותי",
    male_second_instance: "ושיבינו אותי",
  },
  {
    id: 2,
    name: "אמפתיה",
    need_category_id: 1,
    first_instance_unisex: "לאמפתיה",
    female_second_instance: "ואני זקוקה לאמפתיה",
    male_second_instance: "ואני זקוק לאמפתיה",
  },
  {
    id: 3,
    name: "קבלה",
    need_category_id: 1,
    first_instance_unisex: "שיקבלו אותי",
    female_second_instance: "ושיקבלו אותי",
    male_second_instance: "לכך שיקבלו אותי",
  },
  {
    id: 4,
    name: "הכלה",
    need_category_id: 1,
    first_instance_unisex: "להכלה",
    female_second_instance: "ויש לי צורך ביותר הכלה",
    male_second_instance: "ויש לי צורך ביותר הכלה",
  },
  {
    id: 5,
    name: "חמלה",
    need_category_id: 1,
    first_instance_unisex: "לחמלה",
    female_second_instance: "ויש לי צורך ביותר חמלה",
    male_second_instance: "ויש לי צורך ביותר חמלה",
  },
  {
    id: 6,
    name: "ניראות",
    need_category_id: 2,
    first_instance_unisex: "לכך שייראו אותי",
    female_second_instance: "ושיראו אותי",
    male_second_instance: "ושיראו אותי",
  },
  {
    id: 7,
    name: "הערכה",
    need_category_id: 2,
    first_instance_unisex: "להערכה",
    female_second_instance: "ושיעריכו אותי",
    male_second_instance: "ושיעריכו אותי",
  },
  {
    id: 8,
    name: "תשומת לב",
    need_category_id: 2,
    first_instance_unisex: "לתשומת לב",
    female_second_instance: "ולתשומת לב",
    male_second_instance: "ולתשומת לב",
  },
  {
    id: 9,
    name: "כבוד",
    need_category_id: 2,
    first_instance_unisex: "לכבוד",
    female_second_instance: "ושיכבדו אותי",
    male_second_instance: "ושיכבדו אותי",
  },
  {
    id: 10,
    name: "התחשבות",
    need_category_id: 2,
    first_instance_unisex: "להתחשבות",
    female_second_instance: "ושיתחשבו בי",
    male_second_instance: "ושיתחשבו בי",
  },
  {
    id: 11,
    name: "אכפתיות",
    need_category_id: 2,
    first_instance_unisex: "לאכפתיות",
    female_second_instance: "ושיהיה אכפת ממני",
    male_second_instance: "ושיהיה אכפת ממני",
  },
  {
    id: 12,
    name: "הכרה",
    need_category_id: 2,
    first_instance_unisex: "לכך שיכירו בי",
    female_second_instance: "ושיכירו בי",
    male_second_instance: "ושיכירו בי",
  },
  {
    id: 13,
    name: "קבלה",
    need_category_id: 2,
    first_instance_unisex: "לכך שיקבלו אותי",
    female_second_instance: "ושיקבלו אותי",
    male_second_instance: "ושיקבלו אותי",
  },
  {
    id: 14,
    name: "התייחסות",
    need_category_id: 2,
    first_instance_unisex: "ליחס",
    female_second_instance: "ולקבל יחס",
    male_second_instance: "ולקבל יחס",
  },
  {
    id: 15,
    name: "רגישות",
    need_category_id: 3,
    first_instance_unisex: "לרגישות",
    female_second_instance: "ויש לי צורך ביותר רגישות",
    male_second_instance: "ויש לי צורך ביותר רגישות",
  },
  {
    id: 16,
    name: "עדינות",
    need_category_id: 3,
    first_instance_unisex: "לעדינות",
    female_second_instance: "ויש לי צורך ביותר עדינות",
    male_second_instance: "ויש לי צורך ביותר עדינות",
  },
  {
    id: 17,
    name: "רכות",
    need_category_id: 3,
    first_instance_unisex: "לרכות",
    female_second_instance: "ויש לי צורך ביותר רכות",
    male_second_instance: "ויש לי צורך ביותר רכות",
  },
  {
    id: 18,
    name: "קירבה",
    need_category_id: 3,
    first_instance_unisex: "לקירבה",
    female_second_instance: "ויש לי צורך ביותר קירבה",
    male_second_instance: "ויש לי צורך ביותר קירבה",
  },
  {
    id: 19,
    name: "חמימות",
    need_category_id: 3,
    first_instance_unisex: "לחמימות",
    female_second_instance: "ויש לי צורך ביותר חמימות",
    male_second_instance: "ויש לי צורך ביותר חמימות",
  },
  {
    id: 20,
    name: "עזרה",
    need_category_id: 4,
    first_instance_unisex: "לעזרה",
    female_second_instance: "ויש לי צורך בעזרה",
    male_second_instance: "ויש לי צורך בעזרה",
  },
  {
    id: 21,
    name: "התחשבות",
    need_category_id: 4,
    first_instance_unisex: "להתחשבות",
    female_second_instance: "ושיתחשבו בי",
    male_second_instance: "ושיתחשבו בי",
  },
  {
    id: 22,
    name: "שיתוף פעולה",
    need_category_id: 4,
    first_instance_unisex: "לשיתוף פעולה",
    female_second_instance: "ויש לי צורך בשיתוף פעולה",
    male_second_instance: "ויש לי צורך בשיתוף פעולה",
  },
  {
    id: 23,
    name: "תמיכה",
    need_category_id: 4,
    first_instance_unisex: "לתמיכה",
    female_second_instance: "ויש לי צורך בתמיכה",
    male_second_instance: "ויש לי צורך בתמיכה",
  },
  {
    id: 24,
    name: "תקשורת",
    need_category_id: 5,
    first_instance_unisex: "ליותר תקשורת",
    female_second_instance: "ותהיה תקשורת",
    male_second_instance: "ותהיה תקשורת",
  },
  {
    id: 25,
    name: "יכולת ביטוי",
    need_category_id: 5,
    first_instance_unisex: "ליכולת להתבטא",
    female_second_instance: "ורוצה שתהיה לי יכולת להתבטא",
    male_second_instance: "ורוצה שתהיה לי יכולת להתבטא",
  },
  {
    id: 26,
    name: "הקשבה",
    need_category_id: 5,
    first_instance_unisex: "להקשבה",
    female_second_instance: "ושיקשיבו לי",
    male_second_instance: "ושיקשיבו לי",
  },
  {
    id: 27,
    name: "כנות",
    need_category_id: 5,
    first_instance_unisex: "לכנות",
    female_second_instance: "ושתהיה כנות",
    male_second_instance: "ושתהיה כנות",
  },
  {
    id: 28,
    name: "קירבה",
    need_category_id: 5,
    first_instance_unisex: "לקירבה",
    female_second_instance: "ולהרגיש יותר קירבה",
    male_second_instance: "ולהרגיש יותר קירבה",
  },
  {
    id: 29,
    name: "אמת",
    need_category_id: 5,
    first_instance_unisex: "לדעת את האמת",
    female_second_instance: "ולדעת את האמת",
    male_second_instance: "ולדעת את האמת",
  },
  {
    id: 30,
    name: "אינטימיות",
    need_category_id: 5,
    first_instance_unisex: "לאינטימיות",
    female_second_instance: "ושתהיה אינטימיות",
    male_second_instance: "ושתהיה אינטימיות",
  },
  {
    id: 31,
    name: "מגע",
    need_category_id: 5,
    first_instance_unisex: "למגע",
    female_second_instance: "ושיהיה יותר מגע",
    male_second_instance: "ושיהיה יותר מגע",
  },
  {
    id: 32,
    name: "יושרה",
    need_category_id: 5,
    first_instance_unisex: "ליושרה",
    female_second_instance: "ואני זקוקה ליושרה",
    male_second_instance: "ואני זקוק ליושרה",
  },
  {
    id: 33,
    name: "אהבה",
    need_category_id: 6,
    first_instance_unisex: "לאהבה",
    female_second_instance: "ואני צריכה אהבה",
    male_second_instance: "ואני צריך אהבה",
  },
  {
    id: 34,
    name: "קרבה",
    need_category_id: 6,
    first_instance_unisex: "לקרבה",
    female_second_instance: "ויש לי צורך בקרבה",
    male_second_instance: "ויש לי צורך בקרבה",
  },
  {
    id: 35,
    name: "אינטימיות",
    need_category_id: 6,
    first_instance_unisex: "לאינטימיות",
    female_second_instance: "ושתהיה אינטימיות",
    male_second_instance: "ושתהיה אינטימיות",
  },
  {
    id: 36,
    name: "חום",
    need_category_id: 6,
    first_instance_unisex: "לחום",
    female_second_instance: "ויש לי צורך בחום",
    male_second_instance: "ויש לי צורך בחום",
  },
  {
    id: 37,
    name: "חיבה",
    need_category_id: 6,
    first_instance_unisex: "לחיבה",
    female_second_instance: "ויש לי צורך בחיבה",
    male_second_instance: "ויש לי צורך בחיבה",
  },
  {
    id: 38,
    name: "אכפתיות",
    need_category_id: 6,
    first_instance_unisex: "לאכפתיות",
    female_second_instance: "ושיהיה אכפת ממני",
    male_second_instance: "ושיהיה אכפת ממני",
  },
  {
    id: 39,
    name: "חיבור (אנושי)",
    need_category_id: 7,
    first_instance_unisex: "לחיבור",
    female_second_instance: "ולחוות חיבור בינינו",
    male_second_instance: "ולחוות חיבור בינינו",
  },
  {
    id: 40,
    name: "חברות",
    need_category_id: 7,
    first_instance_unisex: "לכך שתהיה חברות בינינו",
    female_second_instance: "ושתהיה חברות בינינו",
    male_second_instance: "ושתהיה חברות בינינו",
  },
  {
    id: 41,
    name: "אכפתיות",
    need_category_id: 7,
    first_instance_unisex: "לאכפתיות",
    female_second_instance: "ושיהיה אכפת ממני",
    male_second_instance: "ושיהיה אכפת ממני",
  },
  {
    id: 42,
    name: "קשר",
    need_category_id: 7,
    first_instance_unisex: "לקשר אמיתי בינינו ",
    female_second_instance: "ושיהיה בינינו קשר אמיתי",
    male_second_instance: "ושיהיה בינינו קשר אמיתי",
  },
  {
    id: 43,
    name: "חיבור לעצמי",
    need_category_id: 7,
    first_instance_unisex: "לחיבור לעצמי",
    female_second_instance: "ורוצה להיות מחוברת לעצמי",
    male_second_instance: "ורוצה להיות מחובר לעצמי",
  },
  {
    id: 44,
    name: "ידידות",
    need_category_id: 7,
    first_instance_unisex: "לידידות בינינו",
    female_second_instance: "ושתהיה ידידות בינינו",
    male_second_instance: "ושתהיה ידידות בינינו",
  },
  {
    id: 45,
    name: "נתינה",
    need_category_id: 7,
    first_instance_unisex: "לכך שתהיה לי יכולת לתת",
    female_second_instance: "ולהיות מסוגלת לתת",
    male_second_instance: "ולהיות מסוגל לתת",
  },
  {
    id: 46,
    name: "הדדיות",
    need_category_id: 7,
    first_instance_unisex: "להדדיות",
    female_second_instance: "ושתהיה הדדיות",
    male_second_instance: "ושתהיה הדדיות",
  },
  {
    id: 47,
    name: "בטחון",
    need_category_id: 8,
    first_instance_unisex: "לבטחון",
    female_second_instance: "ואני צריכה בטחון",
    male_second_instance: "ואני צריך בטחון ",
  },
  {
    id: 48,
    name: "בריאות",
    need_category_id: 8,
    first_instance_unisex: "ורוצה לשמור על הבריאות",
    female_second_instance: "ורוצה לשמור על הבריאות",
    male_second_instance: "ורוצה לשמור על הבריאות",
  },
  {
    id: 49,
    name: "מנוחה",
    need_category_id: 8,
    first_instance_unisex: "למנוחה",
    female_second_instance: "ואני צריכה לנוח",
    male_second_instance: "ואני צריך לנוח",
  },
  {
    id: 50,
    name: "הגנה",
    need_category_id: 8,
    first_instance_unisex: "להגנה",
    female_second_instance: "ואני צריכה הגנה",
    male_second_instance: "ואני צריך הגנה",
  },
  {
    id: 51,
    name: "כוח",
    need_category_id: 8,
    first_instance_unisex: "לכוח",
    female_second_instance: "ושיהיה לי יותר כוח",
    male_second_instance: "ושיהיה לי יותר כוח",
  },
  {
    id: 52,
    name: "נוחות",
    need_category_id: 8,
    first_instance_unisex: "לנוחות",
    female_second_instance: "ורוצה שיהיה לי נוח",
    male_second_instance: "ורוצה שיהיה לי נוח",
  },
  {
    id: 53,
    name: "הימנעות מכאב פיזי",
    need_category_id: 8,
    first_instance_unisex: "להימנעות מכאב פיזי",
    female_second_instance: "ולהימנע מכאב פיזי",
    male_second_instance: "ולהימנע מכאב פיזי",
  },
  {
    id: 54,
    name: "תזונה בריאה",
    need_category_id: 8,
    first_instance_unisex: "לתזונה בריאה",
    female_second_instance: "ואני צריכה לאכול בריא",
    male_second_instance: "ואני צריך לאכול בריא",
  },
  {
    id: 55,
    name: "להגן",
    need_category_id: 8,
    first_instance_unisex: "ליכולת להגן עליך",
    female_second_instance: "ושאני אוכל להגן עליך",
    male_second_instance: "ושאני אוכל להגן עליך",
  },
  {
    id: 56,
    name: "מגע",
    need_category_id: 8,
    first_instance_unisex: "למגע",
    female_second_instance: "ושיהיה יותר מגע",
    male_second_instance: "ושיהיה יותר מגע",
  },
  {
    id: 57,
    name: "בטחון רגשי",
    need_category_id: 9,
    first_instance_unisex: "לבטחון רגשי",
    female_second_instance: "ואני צריכה בטחון רגשי",
    male_second_instance: "ואני צריך בטחון רגשי",
  },
  {
    id: 58,
    name: "אמון",
    need_category_id: 9,
    first_instance_unisex: "לאמון",
    female_second_instance: "ולתת ולקבל אמון",
    male_second_instance: "ולתת ולקבל אמון",
  },
  {
    id: 59,
    name: "לקיחת אחריות",
    need_category_id: 9,
    first_instance_unisex: "לכך שתהיה לקיחת אחריות ",
    female_second_instance: "ושתהיה לקיחת אחריות",
    male_second_instance: "ושתהיה לקיחת אחריות",
  },
  {
    id: 60,
    name: "בהירות",
    need_category_id: 9,
    first_instance_unisex: "לבהירות",
    female_second_instance: "ואני צריכה בהירות",
    male_second_instance: "ואני צריך בהירות",
  },
  {
    id: 61,
    name: "וודאות",
    need_category_id: 9,
    first_instance_unisex: "לודאות",
    female_second_instance: "ושתהיה ודאות",
    male_second_instance: "ושתהיה ודאות",
  },
  {
    id: 62,
    name: "יציבות",
    need_category_id: 9,
    first_instance_unisex: "ליציבות",
    female_second_instance: "ושתהיה יציבות",
    male_second_instance: "ושתהיה יציבות",
  },
  {
    id: 63,
    name: "יכולת לסמוך",
    need_category_id: 9,
    first_instance_unisex: "ליכולת לסמוך עליך",
    female_second_instance: "ולדעת לסמוך עליך",
    male_second_instance: "ולדעת לסמוך עליך",
  },
  {
    id: 64,
    name: "יושרה",
    need_category_id: 9,
    first_instance_unisex: "ליושרה",
    female_second_instance: "ואני זקוקה ליושרה",
    male_second_instance: "ואני זקוק ליושרה",
  },
  {
    id: 65,
    name: "עדינות",
    need_category_id: 9,
    first_instance_unisex: "לעדינות",
    female_second_instance: "ויש לי צורך ביותר עדינות",
    male_second_instance: "ויש לי צורך ביותר עדינות",
  },
  {
    id: 66,
    name: "הימנעות מכאב נפשי",
    need_category_id: 9,
    first_instance_unisex: "להימנעות מכאב נפשי",
    female_second_instance: "ולהימנע מכאב נפשי",
    male_second_instance: "ולהימנע מכאב נפשי",
  },
  {
    id: 67,
    name: "שקט נפשי",
    need_category_id: 9,
    first_instance_unisex: "לשקט נפשי",
    female_second_instance: "ושיהיה לי שקט נפשי",
    male_second_instance: "ושיהיה לי שקט נפשי",
  },
  {
    id: 68,
    name: "יעילות",
    need_category_id: 10,
    first_instance_unisex: "ליעילות",
    female_second_instance: "ויש לי צורך ביעילות",
    male_second_instance: "ויש לי צורך ביעילות",
  },
  {
    id: 69,
    name: "קצב מותאם",
    need_category_id: 10,
    first_instance_unisex: "לקצב מותאם",
    female_second_instance: "ושיהיה קצב מותאם בשבילי",
    male_second_instance: "ושיהיה קצב מותאם בשבילי",
  },
  {
    id: 70,
    name: "תפקודיות",
    need_category_id: 10,
    first_instance_unisex: "לתיפקודיות",
    female_second_instance: "ושדברים יתפקדו",
    male_second_instance: "ושדברים יתפקדו",
  },
  {
    id: 71,
    name: "עקביות",
    need_category_id: 10,
    first_instance_unisex: "לעקביות",
    female_second_instance: "ושתהיה עקביות",
    male_second_instance: "ושתהיה עקביות",
  },
  {
    id: 72,
    name: "כוח",
    need_category_id: 10,
    first_instance_unisex: "לכוח",
    female_second_instance: "ושיהיה לי יותר כוח",
    male_second_instance: "ושיהיה לי יותר כוח",
  },
  {
    id: 73,
    name: "מיקוד",
    need_category_id: 10,
    first_instance_unisex: "למיקוד",
    female_second_instance: "ואני צריכה מיקוד",
    male_second_instance: "ואני צריך מיקוד",
  },
  {
    id: 74,
    name: "ריכוז",
    need_category_id: 10,
    first_instance_unisex: "לריכוז",
    female_second_instance: "ואני צריכה ריכוז",
    male_second_instance: "ואני צריך ריכוז",
  },
  {
    id: 75,
    name: "הצלחה",
    need_category_id: 10,
    first_instance_unisex: "להצלחה",
    female_second_instance: "ושתהיה הצלחה",
    male_second_instance: "ושתהיה הצלחה",
  },
  {
    id: 76,
    name: "המשכיות",
    need_category_id: 10,
    first_instance_unisex: "להמשכיות",
    female_second_instance: "ושתהיה המשכיות",
    male_second_instance: "ושתהיה המשכיות",
  },
  {
    id: 77,
    name: "התקדמות",
    need_category_id: 10,
    first_instance_unisex: "להתקדמות",
    female_second_instance: "ושתהיה התקדמות",
    male_second_instance: "ושתהיה התקדמות",
  },
  {
    id: 78,
    name: "שייכות",
    need_category_id: 11,
    first_instance_unisex: "לחווית שייכות",
    female_second_instance: "ולהרגיש שייכת",
    male_second_instance: "ולהרגיש שייך",
  },
  {
    id: 79,
    name: "השתתפות",
    need_category_id: 11,
    first_instance_unisex: "להשתתפות",
    female_second_instance: "ושתהיה השתתפות",
    male_second_instance: "ושתהיה השתתפות",
  },
  {
    id: 80,
    name: "שיתוף פעולה",
    need_category_id: 11,
    first_instance_unisex: "לשיתוף פעולה",
    female_second_instance: "ויש לי צורך בשיתוף פעולה",
    male_second_instance: "ויש לי צורך בשיתוף פעולה",
  },
  {
    id: 81,
    name: "שותפות",
    need_category_id: 11,
    first_instance_unisex: "לשותפות",
    female_second_instance: "ושתהיה שותפות",
    male_second_instance: "ושתהיה שותפות",
  },
  {
    id: 82,
    name: "הדדיות",
    need_category_id: 11,
    first_instance_unisex: "להדדיות",
    female_second_instance: "ושתהיה הדדיות",
    male_second_instance: "ושתהיה הדדיות",
  },
  {
    id: 83,
    name: "קהילה",
    need_category_id: 11,
    first_instance_unisex: "לקהילה",
    female_second_instance: "ואני רוצה להיות חלק מקהילה",
    male_second_instance: "ואני רוצה להיות חלק מקהילה",
  },
  {
    id: 84,
    name: "חופש",
    need_category_id: 12,
    first_instance_unisex: "לחופש",
    female_second_instance: "ואני צריכה חופש",
    male_second_instance: "ואני צריך חופש",
  },
  {
    id: 85,
    name: "עצמאות",
    need_category_id: 12,
    first_instance_unisex: "לעצמאות",
    female_second_instance: "ויש לי צורך בעצמאות",
    male_second_instance: "ויש לי צורך בעצמאות",
  },
  {
    id: 86,
    name: "מרחב/ספייס",
    need_category_id: 12,
    first_instance_unisex: "ליותר מרחב בשבילי (ספייס)",
    female_second_instance: "ואני זקוקה ליותר מרחב בשבילי (ספייס)",
    male_second_instance: "ואני זקוק ליותר מרחב בשבילי (ספייס)",
  },
  {
    id: 87,
    name: "בחירה",
    need_category_id: 12,
    first_instance_unisex: "ליכולת בחירה",
    female_second_instance: "ושתהיה לי אפשרות בחירה",
    male_second_instance: "ושתהיה לי אפשרות בחירה",
  },
  {
    id: 88,
    name: "התחדשות",
    need_category_id: 12,
    first_instance_unisex: "להתחדשות",
    female_second_instance: "ושתהיה התחדשות",
    male_second_instance: "ושתהיה התחדשות",
  },
  {
    id: 89,
    name: "גיוון",
    need_category_id: 12,
    first_instance_unisex: "לגיוון",
    female_second_instance: "ויש לי צורך בגיוון",
    male_second_instance: "ויש לי צורך בגיוון",
  },
  {
    id: 90,
    name: "ביטוי חופשי",
    need_category_id: 12,
    first_instance_unisex: "לביטוי יותר חופשי",
    female_second_instance: "ולהתבטא יותר בחופשיות",
    male_second_instance: "ולהתבטא יותר בחופשיות",
  },
  {
    id: 91,
    name: "יצירתיות",
    need_category_id: 12,
    first_instance_unisex: "ליצירתיות",
    female_second_instance: "ויש לי צורך ביצירתיות",
    male_second_instance: "ויש לי צורך ביצירתיות",
  },
  {
    id: 92,
    name: "ספונטניות",
    need_category_id: 12,
    first_instance_unisex: "לספונטניות",
    female_second_instance: "ויש לי צורך בספונטניות",
    male_second_instance: "ויש לי צורך בספונטניות",
  },
  {
    id: 93,
    name: "רוגע",
    need_category_id: 13,
    first_instance_unisex: "לרוגע",
    female_second_instance: "ולרוגע",
    male_second_instance: "ולרוגע",
  },
  {
    id: 94,
    name: "סדר",
    need_category_id: 13,
    first_instance_unisex: "לסדר",
    female_second_instance: "ויש לי צורך בסדר",
    male_second_instance: "ויש לי צורך בסדר",
  },
  {
    id: 95,
    name: "שקט פנימי",
    need_category_id: 13,
    first_instance_unisex: "לשקט פנימי",
    female_second_instance: "ואני צריכה שקט פנימי",
    male_second_instance: "ואני צריך שקט פנימי",
  },
  {
    id: 96,
    name: "יופי ואסתטיקה",
    need_category_id: 13,
    first_instance_unisex: "ליופי ואסתטיקה",
    female_second_instance: "ושיהיה יפה ואסתטי",
    male_second_instance: "ושיהיה יפה ואסתטי",
  },
  {
    id: 97,
    name: "שלום",
    need_category_id: 13,
    first_instance_unisex: "לשלום",
    female_second_instance: "ויש לי צורך בשלום",
    male_second_instance: "ויש לי צורך בשלום",
  },
  {
    id: 98,
    name: "שיוויון",
    need_category_id: 13,
    first_instance_unisex: "לשיוויון",
    female_second_instance: "ושיהיה שיוויון",
    male_second_instance: "ושיהיה שיוויון",
  },
  {
    id: 99,
    name: "ניקיון",
    need_category_id: 13,
    first_instance_unisex: "לניקיון",
    female_second_instance: "ושיהיה נקי",
    male_second_instance: "ושיהיה נקי",
  },
  {
    id: 100,
    name: "עקביות",
    need_category_id: 13,
    first_instance_unisex: "לעקביות",
    female_second_instance: "ושתהיה עקביות",
    male_second_instance: "ושתהיה עקביות",
  },
  {
    id: 101,
    name: "הרמוניה",
    need_category_id: 13,
    first_instance_unisex: "להרמוניה",
    female_second_instance: "ושתהיה הרמוניה",
    male_second_instance: "ושתהיה הרמוניה",
  },
  {
    id: 102,
    name: "הנאה",
    need_category_id: 14,
    first_instance_unisex: "ליותר הנאה",
    female_second_instance: "ושאוכל להינות יותר",
    male_second_instance: "ושאוכל להינות יותר",
  },
  {
    id: 103,
    name: "קלילות",
    need_category_id: 14,
    first_instance_unisex: "ליותר קלילות",
    female_second_instance: "ויש לי צורך ביותר קלילות",
    male_second_instance: "ויש לי צורך ביותר קלילות",
  },
  {
    id: 104,
    name: "חגיגה",
    need_category_id: 14,
    first_instance_unisex: "לאפשרות לחגוג",
    female_second_instance: "ושתהיה אפשרות לחגוג",
    male_second_instance: "ושתהיה אפשרות לחגוג",
  },
  {
    id: 105,
    name: "צחוק",
    need_category_id: 14,
    first_instance_unisex: "ליותר צחוק",
    female_second_instance: "ויש לי צורך בצחוק",
    male_second_instance: "ויש לי צורך בצחוק",
  },
  {
    id: 106,
    name: "הומור",
    need_category_id: 14,
    first_instance_unisex: "להומור",
    female_second_instance: "ושיהיה יותר הומור",
    male_second_instance: "ושיהיה יותר הומור",
  },
  {
    id: 107,
    name: "שימחה",
    need_category_id: 14,
    first_instance_unisex: "לשימחה",
    female_second_instance: "ושתהיה יותר שימחה",
    male_second_instance: "ושתהיה יותר שימחה",
  },
  {
    id: 108,
    name: "משחק",
    need_category_id: 14,
    first_instance_unisex: "ליכולת לשחק יותר",
    female_second_instance: "ושתהיה הזדמנות למשחק",
    male_second_instance: "ושתהיה הזדמנות למשחק",
  },
  {
    id: 109,
    name: "משמעות",
    need_category_id: 15,
    first_instance_unisex: "למשמעות",
    female_second_instance: "ושתהיה משמעות בחיי",
    male_second_instance: "ושתהיה משמעות בחיי",
  },
  {
    id: 110,
    name: "יכולת לתרום",
    need_category_id: 15,
    first_instance_unisex: "ליכולת לתרום",
    female_second_instance: "ושתהיה לי יכולת לתרום",
    male_second_instance: "ושתהיה לי יכולת לתרום",
  },
  {
    id: 111,
    name: "השראה",
    need_category_id: 15,
    first_instance_unisex: "להשראה",
    female_second_instance: "ויש לי צורך בהשראה",
    male_second_instance: "ויש לי צורך בהשראה",
  },
  {
    id: 112,
    name: "הכרת תודה",
    need_category_id: 15,
    first_instance_unisex: "להכרת תודה",
    female_second_instance: "ושתהיה הכרת תודה",
    male_second_instance: "ושתהיה הכרת תודה",
  },
  {
    id: 113,
    name: "התפתחות",
    need_category_id: 15,
    first_instance_unisex: "להתפתחות",
    female_second_instance: "ואני רוצה שתהיה התפתחות",
    male_second_instance: "ואני רוצה שתהיה התפתחות",
  },
  {
    id: 114,
    name: "מודעות",
    need_category_id: 15,
    first_instance_unisex: "ליותר מודעות",
    female_second_instance: "ושתהיה יותר מודעות",
    male_second_instance: "ושתהיה יותר מודעות",
  },
  {
    id: 115,
    name: "למידה",
    need_category_id: 15,
    first_instance_unisex: "ללמידה",
    female_second_instance: "ואני רוצה ללמוד",
    male_second_instance: "ואני רוצה ללמוד",
  },
  {
    id: 116,
    name: "אתגר (מאתגר)",
    need_category_id: 15,
    first_instance_unisex: "לאיזשהו אתגר",
    female_second_instance: "ושיהיה לי איזשהו אתגר",
    male_second_instance: "ושיהיה לי איזשהו אתגר",
  },
  {
    id: 117,
    name: "יצירתיות",
    need_category_id: 15,
    first_instance_unisex: "ליצירתיות",
    female_second_instance: "ויש לי צורך ביצירתיות",
    male_second_instance: "ויש לי צורך ביצירתיות",
  },
  {
    id: 118,
    name: "אחריות (שלי)",
    need_category_id: 15,
    first_instance_unisex: "ללקיחת אחריות על עצמי",
    female_second_instance: "ויש לי צורך לקחת על עצמי אחריות",
    male_second_instance: "ויש לי צורך לקחת על עצמי אחריות",
  },
  {
    id: 119,
    name: "ייעוד",
    need_category_id: 15,
    first_instance_unisex: "לאיזשהו ייעוד",
    female_second_instance: "ולהגשמה עצמית (הגשמת החלומות שלי)",
    male_second_instance: "ולהגשמה עצמית (הגשמת החלומות שלי)",
  },
  {
    id: 120,
    name: "הגשמה עצמית",
    need_category_id: 16,
    first_instance_unisex: "להגשמה עצמית (הגשמת החלומות שלי)",
    female_second_instance: "ולהגשים את עצמי / את החלומות שלי",
    male_second_instance: "ולהגשים את עצמי / את החלומות שלי",
  },
  {
    id: 121,
    name: "המשכיות",
    need_category_id: 16,
    first_instance_unisex: "להמשכיות",
    female_second_instance: "ושתהיה המשכיות",
    male_second_instance: "ושתהיה המשכיות",
  },
  {
    id: 122,
    name: "מיצוי החיים",
    need_category_id: 16,
    first_instance_unisex: "ליכולת למצות את החיים",
    female_second_instance: "ורוצה למצות את החיים",
    male_second_instance: "ורוצה למצות את החיים",
  },
  {
    id: 123,
    name: "גיוון",
    need_category_id: 16,
    first_instance_unisex: "לגיוון",
    female_second_instance: "ויש לי צורך בגיוון",
    male_second_instance: "ויש לי צורך בגיוון",
  },
  {
    id: 124,
    name: "אתגר (מאתגר)",
    need_category_id: 16,
    first_instance_unisex: "לאיזשהו אתגר",
    female_second_instance: "ושיהיה לי איזשהו אתגר",
    male_second_instance: "ושיהיה לי איזשהו אתגר",
  },
  {
    id: 125,
    name: "צמיחה",
    need_category_id: 16,
    first_instance_unisex: "לצמיחה",
    female_second_instance: "ואני רוצה לצמוח",
    male_second_instance: "ואני רוצה לצמוח",
  },
  {
    id: 126,
    name: "למידה",
    need_category_id: 16,
    first_instance_unisex: "ללמידה",
    female_second_instance: "ואני רוצה ללמוד",
    male_second_instance: "ואני רוצה ללמוד",
  },
  {
    id: 127,
    name: "מימוש עצמי",
    need_category_id: 16,
    first_instance_unisex: "למימוש עצמי",
    female_second_instance: "ושאוכל לממש את עצמי",
    male_second_instance: "ושאוכל לממש את עצמי",
  },
  {
    id: 128,
    name: "יצירתיות",
    need_category_id: 16,
    first_instance_unisex: "ליצירתיות",
    female_second_instance: "ויש לי צורך ביצירתיות",
    male_second_instance: "ויש לי צורך ביצירתיות",
  },
  {
    id: 129,
    name: "התחדשות",
    need_category_id: 16,
    first_instance_unisex: "להתחדשות",
    female_second_instance: "ואני רוצה התחדשות",
    male_second_instance: "ואני רוצה התחדשות",
  },
  {
    id: 130,
    name: "ייעוד",
    need_category_id: 16,
    first_instance_unisex: "לחיות על פי הייעוד שלי",
    female_second_instance: "ולחיות על פי הייעוד שלי",
    male_second_instance: "ולחיות על פי הייעוד שלי",
  },
  {
    id: 131,
    name: "דאגה עצמית",
    need_category_id: 17,
    first_instance_unisex: "ורוצה לדאוג לעצמי",
    female_second_instance: "ואני רוצה לדאוג לעצמי",
    male_second_instance: "ואני רוצה לדאוג לעצמי",
  },
  {
    id: 132,
    name: "קבלה עצמית",
    need_category_id: 17,
    first_instance_unisex: "ורוצה לקבל את עצמי",
    female_second_instance: "ואני רוצה לקבל את עצמי",
    male_second_instance: "ואני רוצה לקבל את עצמי",
  },
  {
    id: 133,
    name: "ערך עצמי",
    need_category_id: 17,
    first_instance_unisex: "ורוצה להעריך את עצמי",
    female_second_instance: "ואני רוצה להעריך את עצמי",
    male_second_instance: "ואני רוצה להעריך את עצמי",
  },
  {
    id: 134,
    name: "חיבור לחיים",
    need_category_id: 18,
    first_instance_unisex: "לחיבור לחיים האלה",
    female_second_instance: "ולחיבור לחיים",
    male_second_instance: "ולחיבור לחיים",
  },
  {
    id: 135,
    name: "תקווה",
    need_category_id: 18,
    first_instance_unisex: "לתקווה",
    female_second_instance: "ויש לי צורך בתקווה",
    male_second_instance: "ויש לי צורך בתקווה",
  },
  {
    id: 136,
    name: "אמונה",
    need_category_id: 18,
    first_instance_unisex: "לאמונה",
    female_second_instance: "ויש לי צורך באמונה",
    male_second_instance: "ויש לי צורך באמונה",
  },
  {
    id: 137,
    name: "מודעות",
    need_category_id: 18,
    first_instance_unisex: "למודעות",
    female_second_instance: "ושתהיה מודעות",
    male_second_instance: "ושתהיה מודעות",
  },
  {
    id: 138,
    name: "השראה",
    need_category_id: 18,
    first_instance_unisex: "להשראה",
    female_second_instance: "ויש לי צורך בהשראה",
    male_second_instance: "ויש לי צורך בהשראה",
  },
  {
    id: 139,
    name: "רוחניות",
    need_category_id: 18,
    first_instance_unisex: "לפן רוחני בחיי",
    female_second_instance: "ורוצה יותר רוחניות בחיי",
    male_second_instance: "ורוצה יותר רוחניות בחיי",
  },
  {
    id: 140,
    name: "אחדות",
    need_category_id: 18,
    first_instance_unisex: "לאחדות",
    female_second_instance: "ואני רוצה לחוות אחדות",
    male_second_instance: "ואני רוצה לחוות אחדות",
  },
  {
    id: 141,
    name: "להתאבל(אֵבֶל)",
    need_category_id: 18,
    first_instance_unisex: "לתקופת אבל",
    female_second_instance: "ויש לי צורך להתאבל",
    male_second_instance: "ויש לי צורך להתאבל",
  },
  {
    id: 142,
    name: "ריפוי",
    need_category_id: 18,
    first_instance_unisex: "לריפוי",
    female_second_instance: "ויש לי צורך בריפוי",
    male_second_instance: "ויש לי צורך בריפוי",
  },
  {
    id: 143,
    name: "שלום",
    need_category_id: 18,
    first_instance_unisex: "לשלום",
    female_second_instance: "ויש לי צורך בשלום",
    male_second_instance: "ויש לי צורך בשלום",
  },
  {
    id: 144,
    name: "התקדמות",
    need_category_id: 18,
    first_instance_unisex: "להתקדמות",
    female_second_instance: "ושתהיה התקדמות",
    male_second_instance: "ושתהיה התקדמות",
  },
  {
    id: 145,
    name: "ביטוי עצמי",
    need_category_id: 19,
    first_instance_unisex: "לביטוי עצמי",
    female_second_instance: "ויש לי צורך בביטוי עצמי",
    male_second_instance: "ויש לי צורך בביטוי עצמי",
  },
  {
    id: 146,
    name: "נוכחות",
    need_category_id: 19,
    first_instance_unisex: "ורוצה להיות בנוכחות",
    female_second_instance: "ואני רוצה להיות בנוכחות",
    male_second_instance: "ואני רוצה להיות בנוכחות",
  },
  {
    id: 147,
    name: "אותנטיות",
    need_category_id: 19,
    first_instance_unisex: "לאותנטיות",
    female_second_instance: "ויש לי צורך באותנטיות",
    male_second_instance: "ויש לי צורך באותנטיות",
  },
  {
    id: 148,
    name: "לתרום",
    need_category_id: 19,
    first_instance_unisex: "ליכולת לתרום",
    female_second_instance: "ושתהיה לי יכולת לתרום",
    male_second_instance: "ושתהיה לי יכולת לתרום",
  },
  {
    id: 149,
    name: "לתת",
    need_category_id: 19,
    first_instance_unisex: "ליכולת לתת",
    female_second_instance: "ושתהיה לי יכולת לתת",
    male_second_instance: "ושתהיה לי יכולת לתת",
  },
  {
    id: 150,
    name: "להכיר תודה",
    need_category_id: 19,
    first_instance_unisex: "להכיר תודה",
    female_second_instance: "ושאוכל להכיר תודה",
    male_second_instance: "ושאוכל להכיר תודה",
  },
  {
    id: 151,
    name: "יצירתיות",
    need_category_id: 19,
    first_instance_unisex: "ליצירתיות",
    female_second_instance: "ויש לי צורך ביצירתיות",
    male_second_instance: "ויש לי צורך ביצירתיות",
  },
  {
    id: 152,
    name: "גיוון",
    need_category_id: 19,
    first_instance_unisex: "לגיוון",
    female_second_instance: "ויש לי צורך בגיוון",
    male_second_instance: "ויש לי צורך בגיוון",
  },
  {
    id: 153,
    name: "שינוי",
    need_category_id: 19,
    first_instance_unisex: "לשינוי",
    female_second_instance: "ויש לי צורך בשינוי",
    male_second_instance: "ויש לי צורך בשינוי",
  },
];
