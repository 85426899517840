const OtherFemaleL = (props) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <g clipPath="url(#clip0_4680_6549)">
        <path d="M0 0H100V100H0z"></path>
        <g>
          <g>
            <mask
              id="mask0_4680_6549"
              style={{ maskType: "alpha" }}
              width="101"
              height="100"
              x="0"
              y="0"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#C4C4C4"
                stroke="#000"
                d="M99.5 50c0 27.338-22.162 49.5-49.5 49.5S.5 77.338.5 50 22.662.5 50 .5 99.5 22.662 99.5 50z"
              ></path>
            </mask>
            <g mask="url(#mask0_4680_6549)">
              <g>
                <g>
                  <path
                    fill="#45CBF6"
                    d="M55.089 74.397H44.563v24.609H55.09v-24.61z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M45.57 85.532h8.41a5.7 5.7 0 014.017 1.653 5.623 5.623 0 011.663 3.99v12.801H39.89V91.174c0-1.496.598-2.931 1.663-3.99a5.7 5.7 0 014.017-1.652z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M68.443 56.511c10.375-10.322 10.375-27.057 0-37.38-10.376-10.322-27.198-10.322-37.573 0-10.376 10.323-10.376 27.058 0 37.38 10.375 10.322 27.197 10.322 37.573 0z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M29.182 49.156a4.578 4.578 0 00-1.76-6.26c-2.226-1.245-5.047-.463-6.301 1.748a4.578 4.578 0 001.76 6.26c2.225 1.245 5.047.462 6.3-1.748z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M23.913 42.3c2.546-.22 4.43-2.447 4.21-4.975-.222-2.528-2.465-4.4-5.01-4.18-2.546.22-4.43 2.447-4.21 4.975.222 2.528 2.465 4.4 5.01 4.18z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M28.387 32.255a4.573 4.573 0 00.5-6.48 4.649 4.649 0 00-6.524-.496 4.573 4.573 0 00-.5 6.48 4.648 4.648 0 006.524.495z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M29.987 25.558c2.555 0 4.626-2.057 4.626-4.595s-2.071-4.595-4.626-4.595-4.627 2.057-4.627 4.595 2.072 4.595 4.627 4.595z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M40.404 17.905a4.573 4.573 0 00-.539-6.476 4.648 4.648 0 00-6.52.534 4.573 4.573 0 00.538 6.476 4.648 4.648 0 006.52-.534z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M50.195 11.36a4.57 4.57 0 01-.634 2.593 4.615 4.615 0 01-1.976 1.805 4.655 4.655 0 01-5.09-.71 4.59 4.59 0 01-1.398-2.277c-.23-.88-.19-1.808.11-2.665A4.596 4.596 0 0142.79 7.95a4.646 4.646 0 014.31-.67 4.634 4.634 0 011.556.91 4.597 4.597 0 011.539 3.17z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M59.478 11.925c.059-2.537-1.964-4.641-4.519-4.7-2.554-.058-4.673 1.95-4.731 4.488-.06 2.537 1.964 4.641 4.518 4.7 2.555.058 4.673-1.951 4.732-4.488z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M67.433 17.696a4.576 4.576 0 00-1.37-6.354c-2.145-1.379-5.01-.77-6.398 1.361a4.576 4.576 0 001.37 6.354c2.145 1.379 5.01.77 6.398-1.361z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M75.085 22.53c.84-2.396-.435-5.015-2.848-5.85s-5.05.432-5.89 2.828c-.84 2.397.434 5.016 2.847 5.85 2.413.835 5.05-.43 5.89-2.827z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M79.192 31.521a4.575 4.575 0 00-1.127-6.4c-2.091-1.46-4.977-.958-6.445 1.119a4.575 4.575 0 001.127 6.4c2.09 1.46 4.976.958 6.445-1.119z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M77.346 42.572c2.554-.079 4.56-2.199 4.48-4.735-.079-2.537-2.213-4.53-4.767-4.45-2.554.078-4.56 2.199-4.48 4.735.079 2.536 2.213 4.529 4.767 4.45z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M79.079 46.573a4.57 4.57 0 01-.634 2.591 4.615 4.615 0 01-1.976 1.806 4.655 4.655 0 01-5.09-.71 4.59 4.59 0 01-1.398-2.276 4.565 4.565 0 01.11-2.665 4.597 4.597 0 011.581-2.155 4.645 4.645 0 016.954 1.672c.264.544.418 1.134.453 1.737z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M21.02 65.59c5.734-.925 9.627-6.29 8.696-11.984-.93-5.695-6.332-9.561-12.066-8.637-5.733.924-9.626 6.29-8.695 11.984.93 5.694 6.332 9.56 12.065 8.636z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M24.255 50.037c5.117-.825 8.591-5.613 7.76-10.695-.83-5.082-5.65-8.532-10.767-7.708-5.117.825-8.591 5.614-7.76 10.695.83 5.082 5.65 8.533 10.767 7.708z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M87.498 62.665a10.395 10.395 0 000-14.771c-4.107-4.08-10.766-4.08-14.873 0a10.395 10.395 0 000 14.771c4.107 4.08 10.766 4.08 14.873 0z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M83.583 47.427a9.277 9.277 0 000-13.182c-3.666-3.64-9.608-3.64-13.273 0a9.277 9.277 0 000 13.182c3.665 3.64 9.607 3.64 13.273 0z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M50.468 23.31H48.69c-12.895 0-23.348 9.094-23.348 20.312v12.801c0 11.218 10.453 20.313 23.348 20.313h1.776c12.895 0 23.348-9.095 23.348-20.313v-12.8c0-11.22-10.453-20.313-23.347-20.313z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M55.088 85.53l-5.263-5.227-5.263 5.227 5.263 5.227 5.263-5.227z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M45.019 43.805c10.86-3.015 18.15-10.996 16.28-17.826-1.868-6.83-12.187-9.922-23.047-6.907-10.86 3.015-18.15 10.996-16.281 17.826 1.869 6.83 12.187 9.922 23.048 6.907z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M40.496 49.679c0 .525-.156 1.04-.45 1.477a2.671 2.671 0 01-1.2.98c-.49.2-.73-4.666-1.546.15a2.681 2.681 0 01-1.37-.727 2.64 2.64 0 01-.58-2.897c.202-.485.545-.9.986-1.192a2.689 2.689 0 013.377.331c.502.498.783 1.173.783 1.878z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M63.402 49.679c0 .525-.157 1.04-.45 1.476a2.671 2.671 0 01-1.2.98c-.49.201-.67-4.664-1.547.152a2.682 2.682 0 01-1.37-.727 2.64 2.64 0 01-.58-2.896c.202-.485.545-.9.985-1.193a2.688 2.688 0 013.958 1.191c.134.322.204.668.204 1.017z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M40.008 60.12c6.488 14.663 12.868 15.174 19.139 0h-19.14z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M37.125 115.903H32.07V99.277c0-8.023 5.052-13.626 12.288-13.626v5.022c-4.996 0-7.232 4.329-7.232 8.604v16.626z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M67.695 115.903h-5.056V99.277c0-4.281-2.236-8.604-7.232-8.604v-5.022c7.236 0 12.288 5.603 12.288 13.626v16.626z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4680_6549">
          <path fill="#fff" d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
    </svg>
);

export default OtherFemaleL;
