import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import callApi from "../../lib/apisauce/callApi";
import { queryString } from "../../utils/queryString";
import LightBulb from "../../components/svg/LightBulb";
import { useDispatch } from "react-redux";
import { resetNeedState } from "../../redux/answeredNeedsSlice";
import { resetBadFeelState } from "../../redux/badFeelSlice";
import { resetGoodFeelState } from "../../redux/goodFeelSlice";
import { needInstances } from "../../utils/needInstances";
import { badfeelingInstances } from "../../utils/badfeelingInstances";

const PointsToThink = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const resetScrollPosition = () => {
      if (document.documentElement.scrollTop > 5) {
        document.documentElement.scrollTop = 0;
      }
    };

    setTimeout(() => {
      scrollToTop();
      resetScrollPosition();
    }, 100); // Adjust the delay as needed
  }, []);

  const userRequest = JSON.parse(sessionStorage.getItem("request"));

  useEffect(() => {
    if (userRequest?.request_to_partner === undefined) {
      navigate("/home");
    }
  }, []);

  let partnerGender;
  if (
    ["kid_m", "dad", "adult_m", "other_m", "brother"].includes(
      userRequest?.ask_from_partner_roll
    )
  ) {
    partnerGender = "male";
  } else {
    partnerGender = "female";
  }
  let lastId;
  lastId = userRequest?.last_id;
  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;
  let situDescDrop = userRequest?.situation_description?.split("\n")[0];
  let situDescLast = userRequest?.situation_description?.split("\n").pop();
  let requestToPartnerDrop = userRequest?.request_to_partner?.split("\n")[0];
  let requestToPartnerLast = userRequest?.request_to_partner?.split("\n").pop();

  let alldropDesc, oldDesc;
  alldropDesc = userRequest?.request_to_partner?.split("\n");
  if (alldropDesc && alldropDesc !== undefined && alldropDesc?.length > 0) {
    alldropDesc?.shift();
    oldDesc = alldropDesc?.join("");
  }

  let Feel1 = userRequest?.feelings?.split(":")[0];
  let Feel2 = userRequest?.feelings?.split(":")[1];
  let Need1 = userRequest?.needs?.split(":")[0];
  let Need2 = userRequest?.needs?.split(":")[1];

  const need1Ins = needInstances?.find((need) => need.name === Need1?.trim());
  const need2Ins = needInstances?.find((need) => need.name === Need2?.trim());

  const n2Ins =
    userGender === "male"
      ? need2Ins?.male_second_instance
      : need2Ins?.female_second_instance;

  Need1 =
    need1Ins?.first_instance_unisex === undefined
      ? Need1
      : need1Ins?.first_instance_unisex;
  Need2 = n2Ins === undefined ? Need2 : n2Ins;

  const feel1Ins = badfeelingInstances?.find(
    (feel) => feel?.name === Feel1?.trim()
  );
  const f1Ins =
    userGender === "male"
      ? feel1Ins?.male_first_instance
      : feel1Ins?.female_first_instance;

  const feel2Ins = badfeelingInstances?.find(
    (feel) => feel?.name === Feel2?.trim()
  );
  const f2Ins =
    userGender === "male"
      ? feel2Ins?.male_second_instance
      : feel2Ins?.female_second_instance;

  Feel1 = f1Ins === undefined ? Feel1 : f1Ins;
  Feel2 = f2Ins === undefined ? Feel2 : f2Ins;

  let finalText = `${userRequest?.ask_from_partner_name}, ${
    situDescDrop === "טקסט חופשי" ? "" : situDescDrop
  }${situDescLast}.\n\n${Feel1} ${
    Feel2 === undefined ? "" : " " + Feel2
  } כי אני ממש זקו${userGender === "male" ? "ק" : "קה"} ${Need1}${
    Need2 === undefined ? "" : " " + Need2
  }.\n${
    requestToPartnerDrop === "טקסט חופשי" ? "" : requestToPartnerDrop
  }${requestToPartnerLast}.\nהאם תוכל${
    partnerGender === "male" ? "" : "י"
  } לעזור לי בזה?`;

  const dispatch = useDispatch();
  const situDropDesc = userRequest?.situation_description?.split("\n");
  situDropDesc?.shift();

  const logOut = () => {
    //incase of expired member code
    localStorage.removeItem("UserJWT");
    [
      "UserJWT",
      "userData",
      "badFeelings",
      "blackListWords",
      "allNeeds",
      "selfConnectData",
      "request",
      "judgement",
      "goodFeelings",
      "RegUserData",
      "updateMode",
      "displayData",
    ].forEach((key) => {
      sessionStorage.removeItem(key);
    });
    navigate("/");
  };

  const submitDatatoDB = async () => {
    if (lastId && lastId !== "") {
      navigate("/request-summary"); //simply navigate to next page
    } else {
      setLoading(true);
      let newData = {
        ...userRequest,
        request_to_partner: `${
          requestToPartnerDrop === "טקסט חופשי" ? "" : requestToPartnerDrop
        } ${
          oldDesc !== undefined && oldDesc.length > 0
            ? oldDesc
            : requestToPartnerLast
        }`,
        situation_description: `${
          situDescDrop === "טקסט חופשי" ? "" : situDescDrop
        } ${situDropDesc}`,

        final_text: finalText,
        id_needed: true,
        status: "A",
      };

      try {
        const res = await callApi.postData(
          "request-screen",
          queryString(newData),
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
              Authorization: JWToken,
            },
          }
        );

        if (res.ok) {
          if (res.data.status === "success") {
            sessionStorage.setItem(
              "request",
              JSON.stringify({
                ...userRequest,
                last_id: res.data.inserted_id,
              })
            );
            dispatch(resetNeedState());
            dispatch(resetBadFeelState()); //reseting Redux State for Needs, Good & Bad Feelings.
            dispatch(resetGoodFeelState());

            setLoading(false);
            navigate("/request-summary");
          } else {
            setLoading(false);
            if (res.data.message === "תוקף הקוד פג") {
              console.log(res.data.message);
              sessionStorage.setItem("ExpireCode", "True");
              logOut();
            }
            console.log(res.data.message);
          }
        } else {
          console.log(res.originalError);
        }
      } catch (error) {
        error && console.log(error);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-primary_e">
        <Header extraClass="border-bottom" />
        <div className="a21_content pb-1 text-center px-3 pt-70">
          <div className="container">
            <div className="a21_header_img mt-4 mb-3">
              <LightBulb />
            </div>
            <div className="a21_content_disc">
              <h5>נקודות למחשבה</h5>
              <ul>
                <li>
                  אם {userRequest?.ask_from_partner_name}
                  {partnerGender === "male" ? " י" : " ת"}
                  גיד “לא” לבקשה שלך - זה יתן אפשרות להבין ממנ
                  {partnerGender === "female" ? "ה " : "ו "}
                  מה
                  <span className="text-bold"> כן </span>
                  {partnerGender === "female" ? " היא " : " הוא "}
                  {partnerGender === "male" ? "יכול " : "יכולה "}
                  לעשות (מתוך בחירה ושמירה על הצרכים של שני הצדדים).
                </li>

                <li>
                  בהרבה מצבים אנשים אומרים “כן” (כשמבקשים מהם משהו) מתוך
                  <span className="text-bold"> פחד, אשמה, בושה או כניעה </span>
                  (או גם וגם), בהמשך זה עלול להביא לחוסר כנות במערכות היחסים.
                </li>
                <li>
                  כשאנחנו מוכנים לקבל “לא” כתשובה, זה מאפשר
                  <span className="text-bold"> בחירה חופשית </span>
                  לצד השני ותומך במתן תשובה כנה שתואמת את הצרכים שלו. זה מאוד
                  תורם לחיבור ולאמון שנוצר במערכת היחסים.
                </li>
              </ul>
            </div>

            <div className="line position-relative">
              <RoundBtn
                extraClass="mt-4 text-regular bg-secondary-button"
                text="להמשיך"
                onClick={submitDatatoDB}
                loading={loading}
              />
            </div>

            {/* Outline Button Design */}
            <Link to="/drafting-request" className="line">
              <button className="btn bg-outline-button custom_button text-regular mt-3 fs-18">
                לחזור לניסוח הבקשה
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PointsToThink;
