import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import callApi from "../../lib/apisauce/callApi";
import { resetNeedState } from "../../redux/answeredNeedsSlice";
import { resetBadFeelState } from "../../redux/badFeelSlice";
import { resetGoodFeelState } from "../../redux/goodFeelSlice";
import { queryString } from "../../utils/queryString";
import { resetDeepNeedState } from "../../redux/deepNeedsSlice";
import { resetIGNeedState } from "../../redux/ignoredNeedsSlice";
import { resetMostAnsNeedState } from "../../redux/mostAnsNeedSlice";
import { resetMostIgNeedState } from "../../redux/mostIgNeedSlice";
import { resetMostGFState } from "../../redux/mostGFSlice";
import { resetMostBFState } from "../../redux/mostBFSlice";

const SelectionVerdict = () => {
  const navigate = useNavigate();
  const selfConnData = JSON.parse(sessionStorage.getItem("selfConnectData"));

  useEffect(() => {
    if (selfConnData?.thoughts === undefined) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [otherText, setOtherText] = useState("");
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fe1, setFe1] = useState(false);
  const [fe2, setFe2] = useState(false);
  const [fe3, setFe3] = useState(false);
  const [fe4, setFe4] = useState(false);
  const [fe5, setFe5] = useState(false);
  const [fe6, setFe6] = useState(false);

  useEffect(() => {
    if (!fe6) {
      setOtherText("");
      setErr(false);
    }
  }, [fe6]);

  useEffect(() => {
    let feelings_end = selfConnData?.feelings_end;
    if (feelings_end?.includes("הקלה")) setFe1(true);
    if (feelings_end?.includes("בהירות")) setFe2(true);
    if (feelings_end?.includes("שחרור")) setFe3(true);
    if (feelings_end?.includes("עצב")) setFe4(true);
    if (feelings_end?.includes("כאב גדול")) setFe5(true);
    if (selfConnData?.other_text && selfConnData?.other_text !== "") {
      setFe6(true);
      setOtherText(selfConnData?.other_text);
    }
  }, []);

  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));
  const dispatch = useDispatch();

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  const logOut = () => { //incase of expired member code
    localStorage.removeItem("UserJWT");
    [
      "UserJWT",
      "userData",
      "badFeelings",
      "blackListWords",
      "allNeeds",
      "selfConnectData",
      "request",
      "judgement",
      "goodFeelings",
      "RegUserData",
      "updateMode",
      "displayData",
    ].forEach((key) => {
      sessionStorage.removeItem(key);
    });
    navigate("/");
  };

  const handleSubmit = () => {
    if (fe1 || fe2 || fe3 || fe4 || fe5 || fe6) {
      if (fe6 && otherText === "") {
        return setErr(true);
      }
      let fe6Text = fe6 && otherText !== "" ? ":" + otherText : "";
      let str = `${fe1 ? "הקלה" : ""}${fe2 ? ":בהירות" : ""}${
        fe3 ? ":שחרור" : ""
      }${fe4 ? ":עצב" : ""}${fe5 ? ":כאב גדול" : ""}${fe6Text}`;

      let feelings_end = str.startsWith(":") ? str.replace(":", "") : str;
      const selfConnData = JSON.parse(
        sessionStorage.getItem("selfConnectData")
      );
      const newData = {
        ...selfConnData,
        feelings_end,
        does_copy: false ? "Y" : "N",
        action: "exit",
        status: "A",
        last3: fe4 || fe5 || fe6 ? "Y" : "N",
        other_text: otherText,
      };
      sessionStorage.setItem("selfConnectData", JSON.stringify(newData));
      delete newData?.last3;
      delete newData?.other_text;

      let DataRemovedBlankVal = Object.fromEntries(
        Object.entries(newData).filter((value) => value[1])
      ); //Remove key with Blank value if any.

      submitDatatoDB(DataRemovedBlankVal); //submit data if user exit at next page without clicking any button.
    }
  };

  const submitDatatoDB = async (data) => {
    setLoading(true);
    try {
      const res = await callApi.postData("self-connecting", queryString(data), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          Authorization: JWToken,
        },
      });

      if (res.ok) {
        if (res.data.status === "success") {
          const selfConnData = JSON.parse(
            sessionStorage.getItem("selfConnectData")
          );
          const newData = { ...selfConnData, last_id: res.data.lastId };
          sessionStorage.setItem("selfConnectData", JSON.stringify(newData));

          dispatch(resetNeedState());
          dispatch(resetBadFeelState()); //reseting Redux State for Needs, Good & Bad Feelings.
          dispatch(resetGoodFeelState());
          dispatch(resetDeepNeedState());
          dispatch(resetIGNeedState());
          dispatch(resetMostAnsNeedState());
          dispatch(resetMostIgNeedState());
          dispatch(resetMostBFState());
          dispatch(resetMostGFState());
          setLoading(false);
          navigate("/submit-selection");
        } else {
          if (res.data.message === "תוקף הקוד פג") {
            console.log(res.data.message);
            sessionStorage.setItem("ExpireCode", "True")
            logOut();
          }
          console.log(res.data.message);
        }
      } else {
        console.log(res?.originalError);
      }
    } catch (error) {
      error && console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="bg-light_e">
        <Header />
        <div className="pt-50">
          <div className="e10_content_header">
            <img
              src="assets/images/e10_header_shape.svg"
              alt="Shape"
              className="w-100"
            />
            <div className="e10_header_disc">
              <div className="container px-4">
                <h5>
                  מקווים שהתהליך
                  <br />
                  הזה תרם לך.
                </h5>
                <h6>
                  איך את
                  {userGender === "female" ? " " : "ה "}
                  מרגיש
                  {userGender === "male" ? " " : "ה "}
                  עכשיו, אחרי שניסינו לזהות את הרגשות והצרכים שחיים בך?
                </h6>
              </div>
            </div>

            {/* Input Radio */}
            <div className="input_radio_register mt-3">
              <div className="container pb-3">
                <div className={`form-check mb-3 px-3`}>
                  <input
                    type="radio"
                    className="radio_checked form-check-radio"
                    id="feelingsEnd1"
                    name="fe1"
                    checked={fe1}
                    onClick={() => setFe1(!fe1)}
                  />
                  <label
                    className="form-check-label fs-18"
                    htmlFor="feelingsEnd1"
                  >
                    הקלה{" "}
                  </label>
                </div>

                <div className={`form-check mb-3 px-3`}>
                  <input
                    type="radio"
                    className="radio_checked form-check-radio"
                    id="feelingsEnd2"
                    name="fe2"
                    checked={fe2}
                    onClick={() => setFe2(!fe2)}
                  />
                  <label
                    className="form-check-label fs-18"
                    htmlFor="feelingsEnd2"
                  >
                    בהירות{" "}
                  </label>
                </div>

                <div className={`form-check mb-3 px-3`}>
                  <input
                    type="radio"
                    className="radio_checked form-check-radio"
                    id="feelingsEnd3"
                    name="fe3"
                    checked={fe3}
                    onClick={() => setFe3(!fe3)}
                  />
                  <label
                    className="form-check-label fs-18"
                    htmlFor="feelingsEnd3"
                  >
                    שחרור{" "}
                  </label>
                </div>

                <div className={`form-check mb-3 px-3`}>
                  <input
                    type="radio"
                    className="radio_checked form-check-radio"
                    id="feelingsEnd4"
                    name="fe4"
                    checked={fe4}
                    onClick={() => setFe4(!fe4)}
                  />
                  <label
                    className="form-check-label fs-18"
                    htmlFor="feelingsEnd4"
                  >
                    עצב{" "}
                  </label>
                </div>

                <div className={`form-check mb-3 px-3`}>
                  <input
                    type="radio"
                    className="radio_checked form-check-radio"
                    id="feelingsEnd5"
                    name="fe5"
                    checked={fe5}
                    onClick={() => setFe5(!fe5)}
                  />
                  <label
                    className="form-check-label fs-18"
                    htmlFor="feelingsEnd5"
                  >
                    כאב גדול{" "}
                  </label>
                </div>

                {/* Radio 6 */}
                <div className="form-check custom_label_check d-flex mb-3 px-3">
                  <input
                    type="radio"
                    id="feelingsEnd6"
                    className="radio_checked form-check-radio"
                    name="feelingsEnd6"
                    checked={fe6}
                    onClick={() => setFe6(!fe6)}
                  />
                  <label
                    className="form-check-label fs-18"
                    htmlFor="feelingsEnd6"
                  >
                    אחר:{" "}
                    <input
                      className="custom_input custom_input_height input_shadow px-2"
                      required={fe6 ? "required" : ""}
                      type="text"
                      name="feelingsEnd"
                      value={otherText}
                      onChange={(e) => {
                        setOtherText(e.target.value);
                        setErr(false);
                      }}
                      autoComplete="off"
                      readOnly={!fe6}
                    />
                    {err && (
                      <p className="err_msg mt-1 me-5 mb-0">נא להזין טקסט</p>
                    )}
                  </label>
                </div>

                <div className="line px-3 position-relative">
                  <RoundBtn
                    extraClass={`mt-5 text-regular
                    ${
                      fe1 || fe2 || fe3 || fe4 || fe5 || fe6
                        ? "bg-secondary-button"
                        : "secondary-disable"
                    }
                  `}
                    text="סיום"
                    onClick={handleSubmit}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectionVerdict;
