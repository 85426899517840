import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";

const PartnerJudgement = () => {
  const judgementData = JSON.parse(sessionStorage.getItem("judgement"));
  const j1 = judgementData?.partner_judgments?.split(":")[0];
  const j2 = judgementData?.partner_judgments?.split(":")[1];

  const [judgement1, setJudgement1] = useState(j1 || "");
  const [judgement2, setJudgement2] = useState(j2 || "");

  const navigate = useNavigate();

  useEffect(() => {
    if (
      judgementData?.situation_description === undefined ||
      judgementData?.situation_description === ""
    ) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const name = judgementData?.ask_from_partner_name;

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  let partnerGender;
  if (
    ["kid_m", "dad", "adult_m", "other_m", "brother"].includes(
      judgementData?.ask_from_partner_roll
    )
  ) {
    partnerGender = "male";
  } else {
    partnerGender = "female";
  }

  let partner_judgments =
    judgement1.length && judgement2.length
      ? `${judgement1}:${judgement2}`
      : judgement1.length
      ? judgement1
      : judgement2;

  const handleSubmit = () => {
    const newData = {
      ...judgementData,
      partner_judgments,
    };

    sessionStorage.setItem("judgement", JSON.stringify(newData));
    if (partner_judgments.length) {
      navigate("/partner-desire");
    }
  };

  return (
    <>
      <div className="bg-light_e">
        <Header />
        <div className="c4b_1_content pt-100 px-3">
          <div className="container">
            <h5 className="c4b_1_header_title">
              {userGender === "male" ? "כתוב " : "כתבי "}
              בקצרה את השיפוטים שיש לדעתך ל{name} כלפיך.
            </h5>
            <div className="list_content_c4b mt-4">
              <h6>לדוגמה:</h6>
              <ul className="list_content_ulli">
                <li>
                  {partnerGender === "female" ? "היא " : "הוא "}
                  חושב
                  {partnerGender === "male" ? " " : "ת "}
                  שאני לא מתחשבת {partnerGender === "female" ? "בה" : "בו"}{" "}
                </li>
                <li>
                  {partnerGender === "female" ? "היא " : "הוא "}
                  חושב
                  {partnerGender === "male" ? " " : "ת "}
                  שאני
                  {userGender === "male" ? " מתעלם  " : " מתעלמת "}
                  ממנ
                  {partnerGender === "male" ? "ו" : "ה"}
                </li>
                <li>
                  {partnerGender === "female" ? "היא " : "הוא "}
                  חושב
                  {partnerGender === "male" ? " " : "ת "}
                  שאני מוזר
                  {userGender === "male" ? " " : "ה "}
                </li>
                <li>
                  {partnerGender === "female" ? "היא " : "הוא "}
                  חושב
                  {partnerGender === "male" ? " " : "ת "}
                  שאני טיפש
                  {userGender === "male" ? "" : "ה"}
                </li>
                <li>
                  {partnerGender === "female" ? "היא " : "הוא "}
                  חושב
                  {partnerGender === "male" ? " " : "ת "}
                  שאני “לא בסדר”
                </li>
              </ul>
              <h5 className="input_label_c4b_title mt-4">
                נסתפק באחד או שני שיפוטים
              </h5>
              <div className="input_c4b_1">
                <h5>
                  {name} חוש
                  {partnerGender === "male" ? "ב " : "בת "}
                  שאני...
                </h5>

                <div className="mb-3  w-100">
                  <input
                    type="text"
                    className="form-control input_shadow custom_input"
                    value={judgement1}
                    onChange={(e) => setJudgement1(e.target.value)}
                  />
                </div>
              </div>
              <div className="input_c4b_1">
                <h5>
                  {name} חוש
                  {partnerGender === "male" ? "ב " : "בת "}
                  שאני...
                </h5>

                <div className="mb-3  w-100">
                  <input
                    type="text"
                    className="form-control input_shadow custom_input"
                    value={judgement2}
                    onChange={(e) => setJudgement2(e.target.value)}
                  />
                </div>
              </div>

              <div className="pb-2">
                <RoundBtn
                  extraClass={`mt-5 text-regular 
                  ${
                    judgement1.length || judgement2.length
                      ? "bg-primary-button"
                      : "primary-disable"
                  }
                `}
                  text="להמשיך"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerJudgement;
