import { createSlice } from "@reduxjs/toolkit";

const ignoredNeedsSlice = createSlice({
  name: "ignoredNeeds",
  initialState: [],
  reducers: {
    addRemoveIgnoredNeeds(state, action) {
      if (state.map(x => x.name).includes(action.payload.name) && state.map(x => x.blockId).includes(action.payload.blockId)) {
        return state.filter((x) => x.name !== action.payload.name);
      } else {
        state.push(action.payload);
      }
    },
    resetIGNeedState(state) {
      return (state = []);
  }
  },
});

export const { addRemoveIgnoredNeeds, resetIGNeedState } = ignoredNeedsSlice.actions;
export default ignoredNeedsSlice.reducer;
