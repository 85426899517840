import { useEffect, useState } from "react";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import callApi from "../../lib/apisauce/callApi";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { addRemoveDeepNeeds } from "../../redux/deepNeedsSlice";
import NeedModal from "../../components/feeling/NeedModal";
import MaxSelectionModal from "../../components/feeling/MaxSelectionModal";
import { trackPromise } from "react-promise-tracker";

const BlueLine = () => {
  return (
    <div className="toggle_button_blue_simple">
      <div className="toggle_button_border_simple"></div>
    </div>
  );
};

const BlackLine = () => {
  return (
    <div className="toggle_button_black del-mt4">
      <div className="toggle_border_black"></div>
    </div>
  );
};

const BlackLineUpArrow = ({ onClick }) => {
  return (
    <div className="toggle_button_black del-mt4">
      <div className="toggle_border_black"></div>
      <div className="black_arrow" onClick={onClick}>
        <img src="assets/images/hide_arrow_black.svg" alt="" />
      </div>
    </div>
  );
};

const BlueLineArrowDesign = ({ onClick }) => {
  return (
    <div className="toggle_button_blue">
      <div className="toggle_button_border">
        <div className="shape_button" />
        <div className="shape_button" />
        <div className="shape_button" />
      </div>
      <div className="hide_arrow" onClick={onClick}>
        <img src="assets/images/hide_arrow.svg" alt="Blue Arrow" />
      </div>
    </div>
  );
};

const NeedsBlock = ({ id, dataArr, isMatch, itemPerRow }) => {
  const [show, setShow] = useState(false);
  const selectedData = useSelector((state) => state.deepNeeds).filter(
    (c) => c.blockId === id
  );
  const selectedIndexes = selectedData.map((d) => d.index);

  let keepOpen = !isMatch && selectedIndexes.some((f) => f > itemPerRow - 1);
  let BlockClass = isMatch
    ? "show52"
    : keepOpen
    ? "show100"
    : show
    ? "show100"
    : "show52";

  return (
    <div className="tab_btn_all mt-4 px-1">
      <div className={BlockClass}>
        <div
          className="all_e7_btn d-flex flex-wrap justify-content-center"
          id={"element" + id}
        >
          {dataArr &&
            dataArr.map((need, i) => (
              <TabNeed key={uuidv4()} index={i} data={need} blockId={id} />
            ))}
        </div>
      </div>
      {isMatch ? (
        <BlueLine />
      ) : keepOpen ? (
        <BlackLine />
      ) : show ? (
        <BlackLineUpArrow onClick={() => setShow(!show)} />
      ) : (
        <BlueLineArrowDesign onClick={() => setShow(!show)} />
      )}
    </div>
  );
};

const TabNeed = ({ data, index, blockId }) => {
  const dispatch = useDispatch();
  const selectedNeedIDs = useSelector((state) =>
    state.deepNeeds.map((x) => x.id)
  );

  const saveData = (data) => {
    if (selectedNeedIDs.length > 2) {
      if (selectedNeedIDs.includes(data.id)) {
        dispatch(addRemoveDeepNeeds(data));
      } else {
        document.getElementById("max2_btn").click();
      }
    } else {
      dispatch(addRemoveDeepNeeds(data));
    }
  };

  return (
    <button
      className={`btn e7_custom_btn shadow_button mb-2 
          ${
            selectedNeedIDs.includes(data.id)
              ? "bg_secondary_btn text-white"
              : ""
          } ${index === 0 ? "btn_text_dark" : ""}`}
      onClick={() => {
        saveData({ id: data.id, name: data.subCateName, blockId, index });
      }}
    >
      {data.subCateName}
    </button>
  );
};

const PartnerDeepNeeds = () => {
  const [needs, setNeeds] = useState(
    JSON.parse(sessionStorage.getItem("allNeeds")).reverse() || []
  );
  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));
  const navigate = useNavigate();

  useEffect(() => {
    if (
      judgementData?.does_want_to_check_partner_deeper_needs === undefined ||
      judgementData?.does_want_to_check_partner_deeper_needs !== true
    ) {
      navigate("/home");
    }
    if (!needs.length) fetchAllNeeds();

    // Update the content attribute to set initial scale to 1.0
    var metaViewport = document.querySelector('meta[name="viewport"]');
    metaViewport.setAttribute(
      "content",
      "width=device-width, initial-scale=1, maximum-scale=1"
    );
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const resetScrollPosition = () => {
      if (document.documentElement.scrollTop > 5) {
        document.documentElement.scrollTop = 0;
      }
    };

    setTimeout(() => {
      scrollToTop();
      resetScrollPosition();
    }, 100);
  }, []);

  const judgementData = JSON.parse(sessionStorage.getItem("judgement"));
  const name = judgementData?.ask_from_partner_name;
  const desire1 = judgementData?.partner_desires.split(":")[0];

  const twoNeeds = judgementData?.partner_needs.replace(":", ", ");

  const fetchAllNeeds = () => {
    trackPromise(
      callApi
        .getDatawithToken(
          "get-needs",
          {},
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
              Authorization: JWToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            sessionStorage.setItem(
              "allNeeds",
              JSON.stringify(res.data.data)
            );
            setNeeds(res.data.data);
          }
        })
        .catch((res) => {
          console.log(res.originalError);
        })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      compareRowCount();
    }, 100);
  }, []);

  const countRowItems = (element) => {
    const grid = Array.from(document?.querySelector(element)?.children);
    const baseOffset = grid[0].offsetTop;
    const breakIndex = grid.findIndex((item) => item.offsetTop > baseOffset);
    return breakIndex === -1 ? grid.length : breakIndex;
  };

  const compareRowCount = () => {
    needs.reverse().map((n, i) => {
      let itemPerRow = countRowItems("#element" + (i + 1));
      let arrLen = needs[i].emotionSubCat?.length;

      if (itemPerRow === arrLen) {
        setNeeds((needs) =>
          needs.map((need) =>
            need.emotionId === i + 1
              ? { ...need, isMatch: true, itemPerRow }
              : need
          )
        );
      } else {
        setNeeds((needs) =>
          needs.map((need) =>
            need.emotionId === i + 1
              ? { ...need, isMatch: false, itemPerRow }
              : need
          )
        );
      }
    });
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  let partnerGender;
  if (
    ["kid_m", "dad", "adult_m", "other_m", "brother"].includes(
      judgementData?.ask_from_partner_roll
    )
  ) {
    partnerGender = "male";
  } else {
    partnerGender = "female";
  }

  const selectedNeeds = useSelector((state) =>
    state.deepNeeds.map((x) => x.name)
  );

  const submitNeeds = () => {
    const partner_deeper_needs = selectedNeeds.join(":");
    const newData = {
      ...judgementData,
      partner_deeper_needs,
    };
    sessionStorage.setItem("judgement", JSON.stringify(newData));
    if (selectedNeeds.length) {
      navigate("/partner-needs-summary");
    }
  };

  return (
    <>
      <div className="bg-light_e">
        <Header />

        <div className="container pt-90 px-4">
          <div className="c4b_8_details px-3">
            <h6>אם הצרכים האלה של {name} יתמלאו</h6>
            {/* <p>(צורך ,בבקשה, בחר)</p> */}
            <p>({twoNeeds})</p>
            <h6>
              מה עוד לדעתך זה יתן ל{partnerGender === "male" ? "ו" : "ה"}
              ?<br />
              כלומר לאיזה צרכים יותר עמוקים זה ייתן מענה?
            </h6>
          </div>
          <a
            href="#"
            className="line"
            data-bs-toggle="modal"
            data-bs-target="#custom_modal"
          >
            <button className="btn e3_custom_btn">
              {userGender === "male" ? "לחץ " : "לחצי "}
              לעזרה בהבנת הצרכים
            </button>
          </a>
          <div className="c4b_8_details px-3">
            <h6>
              בחר
              {userGender === "male" ? " " : "י "}
              עד 3 צרכים.
            </h6>
          </div>
        </div>

        {needs &&
          needs.map((need) => (
            <NeedsBlock
              key={need.emotionId}
              id={need.emotionId}
              dataArr={need.emotionSubCat}
              isMatch={need.isMatch}
              itemPerRow={need.itemPerRow}
            />
          ))}

        {/* hidden btn for popup for max selection */}
        <a
          className="line"
          data-bs-toggle="modal"
          data-bs-target="#max_selection_modal"
          id="max2_btn"
        ></a>
        {/* hidden btn for popup for max selection */}

        <div
          className={`container px-4 ${selectedNeeds.length ? "pb-100" : ""}`}
        >
          <div className="pb-2">
            <RoundBtn
              extraClass={`mt-4 text-regular 
                  ${
                    selectedNeeds.length
                      ? "bg-primary-button btn-popup"
                      : "primary-disable"
                  }`}
              text="להמשיך"
              onClick={submitNeeds}
            />
          </div>
        </div>
      </div>

      {/* Modal */}
      <NeedModal />
      {/* Modal*/}

      {/* Modal for max 3 selection */}
      <MaxSelectionModal num={3} />
      {/* Modal for max 3 selection*/}
    </>
  );
};

export default PartnerDeepNeeds;
