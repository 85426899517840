import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";

const PartnerDesire = () => {
  const judgementData = JSON.parse(sessionStorage.getItem("judgement"));
  const d1 = judgementData?.partner_desires?.split(":")[0];
  const d2 = judgementData?.partner_desires?.split(":")[1];

  const [desire1, setDesire1] = useState(d1 || "");
  const [desire2, setDesire2] = useState(d2 || "");
  const navigate = useNavigate();
  
  useEffect(() => {
    if (
      judgementData?.partner_judgments === undefined ||
      judgementData?.partner_judgments === ""
    ) {
      navigate("/home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const name = judgementData?.ask_from_partner_name;
  const [judgement1, judgement2] = judgementData?.partner_judgments.split(":");

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  let partnerGender;
  if (
    ["kid_m", "dad", "adult_m", "other_m", "brother"].includes(
      judgementData?.ask_from_partner_roll
    )
  ) {
    partnerGender = "male";
  } else {
    partnerGender = "female";
  }

  let partner_desires =
    desire1.length && desire2.length
      ? `${desire1}:${desire2}`
      : desire1.length
      ? desire1
      : desire2;

  const handleSubmit = () => {
    const newData = {
      ...judgementData,
      partner_desires,
    };
    sessionStorage.setItem("judgement", JSON.stringify(newData));
    if (partner_desires.length) {
      navigate("/partner-first-need");
    }
  };
  return (
    <>
      <div className="bg-light_e">
        <Header />
        <div className="c4b_2_content pt-100 pb-4">
          <div className="container px-4">
            <h5 className="c4b_1_header_title">
              ועכשיו {userGender === "male" ? "כתוב" : "כתבי"} איך לדעתך {name}
              <br />
              {partnerGender === "male" ? "היה" : "הייתה"} רוצה ש
              {userGender === "male" ? "תהיה " : "תהיי "}
              או ש{userGender === "male" ? "תתנהג" : "תתנהגי"}.
            </h5>
            <div className="mt-4">
              <h5 className="c4b_1_header_title">לדוגמה:</h5>
            </div>
            <div className="small_list_content_c4b mb-4">
              <h6>שיפוט</h6>
              <p>אני שתלטן</p>
            </div>
            <div className="small_list_content_c4b">
              <h6>{name} רוצה שאני...</h6>
              <p>אתן ל{partnerGender === "male" ? "ו" : "ה"} יותר חופש</p>
            </div>
          </div>

          <div className="input_box_details my-4"></div>
          <div className="container px-4">
            <div className="input_box_content_details">
              <h6>שיפוט</h6>
              <p>אני {judgement1}</p>
            </div>
            <div className="input_box_2_details">
              <h6>{name} רוצה שאני...</h6>
              <div className="mb-3  w-100">
                <input
                  type="text"
                  className="form-control input_shadow custom_input"
                  value={desire1}
                  onChange={(e) => setDesire1(e.target.value)}
                />
              </div>
            </div>
          </div>

          {judgement2 !== undefined && judgement2.length ? (
            <>
              <div className="input_box_details my-4"></div>
              <div className="container px-4">
                <div className="input_box_content_details">
                  <h6>שיפוט</h6>
                  <p>אני {judgement2}</p>
                </div>
                <div className="input_box_2_details">
                  <h6>{name} רוצה שאני...</h6>
                  <div className="w-100">
                    <input
                      type="text"
                      className="form-control input_shadow custom_input"
                      value={desire2}
                      onChange={(e) => setDesire2(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {/* Primary Disable Button Design */}
          <div className="container px-4">
            <div className="pb-2">
              <RoundBtn
                extraClass={`mt-5 text-regular 
                  ${
                    desire1.length || desire2.length
                      ? "bg-primary-button"
                      : "primary-disable"
                  }
                `}
                text="להמשיך"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerDesire;
