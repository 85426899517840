import { createSlice } from "@reduxjs/toolkit";

const mostIgNeedSlice = createSlice({
  name: "mostIgNeed",
  initialState: [],
  reducers: {
    addRemoveMostIgNeed(state, action) {
      if (state.includes(action.payload)) {
        return state.filter((x) => x !== action.payload);
      } else {
        state.push(action.payload);
      }
    },
    resetMostIgNeedState(state) {
      return (state = []);
    },
  },
});

export const { addRemoveMostIgNeed, resetMostIgNeedState } =
  mostIgNeedSlice.actions;

export default mostIgNeedSlice.reducer;
