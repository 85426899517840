import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";

const DraftingRequest = () => {
  const userRequest = JSON.parse(sessionStorage.getItem("request"));
  let alldropDesc, oldDesc;
  alldropDesc = userRequest?.request_to_partner?.split("\n");
  if (alldropDesc && alldropDesc !== undefined && alldropDesc?.length > 0) {
    alldropDesc?.shift();
    oldDesc = alldropDesc?.join("");
  }

  const savedDesc = userRequest?.request_to_partner?.split("\n").pop();
  const userSelectedDropdown = userRequest?.request_to_partner?.split("\n")[0];

  const [select, setSelect] = useState(
    userSelectedDropdown || "מאוד יעזור לי אם "
  );
  const [desc, setDesc] = useState(savedDesc || "");
  const [freeTextInput, setFreeTextInput] = useState(false);
  const [freeText, setFreeText] = useState("");
  const [errMsg, setErrMsg] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (userRequest?.needs === undefined) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  const optionValues = [
    "מאוד יעזור לי אם ",
    "מאוד יעזור לי ש",
    "הייתי רוצה ש",
    "בא לי ש",
    `אני זקוק${userGender === "male" ? "" : "ה"} לעזרה ממך - ש`,
    "אני רוצה לבקש ממך ש",
    "חשוב לי לבקש ממך ש",
    "מקווה שתוכל לעזור לי ו",
    "מקווה שתוכלי לעזור לי ו",
    "יש לי בקשה ",
    "טקסט חופשי",
  ];

  useEffect(() => {
    if (select === "טקסט חופשי") {
      setFreeTextInput(true);
    } else {
      setFreeTextInput(false);
      setFreeText("");
    }
  }, [select]);

  let descId = document.getElementById("descId");

  useEffect(() => {
    if (select === "טקסט חופשי") {
      setFreeTextInput(true);
    } else {
      setFreeTextInput(false);
      setFreeText("");
    }
    setTimeout(() => {
      descId?.focus();
    }, 800);
  }, [select]);

  useEffect(() => {
    if (desc !== "") {
      setErrMsg(false);
    }
  }, [desc]);

  let partnerGender;
  if (
    ["kid_m", "dad", "adult_m", "other_m", "brother"].includes(
      userRequest?.ask_from_partner_roll
    )
  ) {
    partnerGender = "male";
  } else {
    partnerGender = "female";
  }

  //let userSelect = select === "טקסט חופשי" ? "" : select;
  let userSelect = select;

  const handleSubmit = () => {
    if (desc === "") {
      return setErrMsg(true);
    } else {
      setErrMsg(false);
    }

    const updatedDesc =
      oldDesc && oldDesc !== undefined && oldDesc.length > 0
        ? `${oldDesc}\n/////////////////////////\n${desc}`
        : desc;

    const newData = JSON.stringify({
      ...userRequest,
      request_to_partner: `${userSelect}\n${updatedDesc}`,
    });
    sessionStorage.setItem("request", newData);
    if (desc.length) {
      navigate("/points-to-think");
    }
  };

  return (
    <>
      <div className="bg-light_e">
        <Header text="ניסוח בקשה" />

        {/* Progress Line start */}
        <div className="progressbar pt-70 d-flex justify-content-between">
          <div className="progress-line progress_fill">
            <h6>תיאור</h6>
            <img src="assets/images/full_line.svg" alt="" />
          </div>

          <div className="progress-line progress_fill">
            <h6>רגש</h6>
            <img src="assets/images/full_line.svg" alt="" />
          </div>

          <div className="progress-line progress_fill">
            <h6>צורך</h6>
            <img src="assets/images/full_line.svg" alt="" />
          </div>

          <div className="progress-line progress_fill">
            <h6>בקשה</h6>
            <img src="assets/images/half_line.svg" alt="" />
          </div>
        </div>
        {/* Progress Line End */}

        <div className="a11_content mt-4 px-3 pb-3">
          <div className="container">
            {/* Common Content start */}
            <div className="a_content_headers">
              <a
                href="#"
                className="line"
                data-bs-toggle="modal"
                data-bs-target="#custom_modal"
              >
                <button className="btn e3_custom_btn">
                  {userGender === "male" ? "לחץ " : "לחצי "}
                  לתזכורת של השלבים הקודמים
                </button>
              </a>

              <h5 className="a11_disc_content">
                ועכשיו, סוף סוף :) בוא
                {userGender === "male" ? " " : "י "}
                ננסח את הבקשה עצמה מ{userRequest?.ask_from_partner_name}.
              </h5>

              <div className="a11_content_list">
                <ul>
                  <li>
                    {userGender === "male" ? "כתוב " : "כתבי "}
                    מה היית רוצה ש{userRequest?.ask_from_partner_name}{" "}
                    <span className="text-bold">
                      {partnerGender === "male" ? "י" : "ת"}
                      עשה
                    </span>
                    <br />
                    (ולא משהו
                    <span className="text-bold">
                      {" "}
                      ש{partnerGender === "male" ? "י" : "ת"}
                      פסיק לעשות
                    </span>
                    )
                  </li>
                  <li>
                    נס
                    {userGender === "male" ? "ח " : "חי "}
                    כך שהבקשה תהיה
                    <span className="text-bold"> מעשית וברורה </span>
                    <br />
                    (משהו פשוט ומובן לביצוע).
                  </li>
                  <li>
                    ציי
                    {userGender === "male" ? "ן " : "ני "}
                    מועד מדוייק ותחום בזמן, ככל האפשר, לביצוע הבקשה. ניסוח בזמן
                    הווה מעלה את הסיכוי לביצוע הבקשה.
                  </li>
                </ul>
              </div>
            </div>

            {/* List Content start */}
            <div className="a11_content_list mt-4">
              <h6 className="a11_contents_list_title">דוגמאות לניסוחים:</h6>
              <ol>
                <li>צחצח את השיניים היום לפני שתלך לישון.</li>
                <li> חזרי הביתה היום עד השעה עשר בלילה.</li>
              </ol>
            </div>
            {/* List Content end */}

            <div className="a11_text_dropdown mt-4">
              <h5 className="a11_disc_content">
                ניסוח הבקשה (הפנייה ל{userRequest?.ask_from_partner_name}
                ):
              </h5>
              <div className="input-group">
                <textarea
                  className="form-control input_shadow custom_textarea top_textarea"
                  // aria-label="With textarea"
                  id="descId"
                  rows={5}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </div>
              <div className="d-flex avtar_name_drop">
                <div className="user_name_drop position-unset">
                  <span>{userRequest?.ask_from_partner_name}</span>,
                </div>
                <select
                  className={`form-select custom-select-form form-select-sm position-unset  ${
                    select === "טקסט חופשי" ? "border_none w-10p" : ""
                  }`}
                  aria-label=".form-select-sm example"
                  onChange={(e) => setSelect(e.target.value)}
                  value={select === "טקסט חופשי" ? "" : select}
                >
                  <option
                    value=""
                    disabled
                    style={{ display: "none" }}
                  ></option>
                  {optionValues &&
                    optionValues.map((v, i) => (
                      <option key={v + i} value={v}>
                        {v}
                      </option>
                    ))}
                </select>

              </div>
            </div>
            {errMsg && <p className="err_msg m-0 pe-1">נא להכניס טקסט</p>}

            <div className="line">
              <RoundBtn
                extraClass={`mt-5 mb-2 text-regular ${
                  desc ? "bg-primary-button" : "primary-disable"
                }`}
                text="להמשיך"
                onClick={() => handleSubmit(false)}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="custom_modal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content custom-modal-radius">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <h5
                className="modal-title text-center a_modal_title"
                id="exampleModalLabel"
              >
                תזכורת של השלבים הקודמים
              </h5>
            </div>
            <div className="modal-body">
              {/* 14_2 popup design */}
              <div className="disc_a14_modal mt-3">
                <p className="a11_disc_para_content">
                  תיאור של הסיטואציה:
                  <br />
                  <span className="text-bold">
                    "
                    {userRequest?.situation_description.split("\n")[0] ===
                    "טקסט חופשי"
                      ? ""
                      : userRequest?.situation_description.split("\n")[0]}
                    {userRequest?.situation_description.split("\n").pop()}"
                  </span>
                  <br />
                  <br />
                  רגשות שצפים בי בעקבות זה:
                  <br />
                  <span className="text-bold">
                    {userRequest?.feelings.replaceAll(":", ", ")}
                  </span>
                  <br />
                  <br />
                  צרכים שלי שלא מקבלים מענה במצב זה:
                  <br />
                  <span className="text-bold">
                    {userRequest?.needs.replaceAll(":", ", ")}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal*/}
    </>
  );
};

export default DraftingRequest;
