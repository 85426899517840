import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import callApi from "../../lib/apisauce/callApi";
import { queryString } from "../../utils/queryString";
import Heart from "../../components/svg/Heart";
import { useDispatch } from "react-redux";
import { resetDeepNeedState } from "../../redux/deepNeedsSlice";
import { resetIGNeedState } from "../../redux/ignoredNeedsSlice";
import { resetNeedState } from "../../redux/answeredNeedsSlice";

const PartnerNeedSubmit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const judgementData = JSON.parse(sessionStorage.getItem("judgement"));
  useEffect(() => {
    if (judgementData?.does_want_to_check_partner_deeper_needs === undefined) {
      navigate("/home");
    }
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  
  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));
  const isPartnerNeeds = sessionStorage.getItem("isPartnerNeeds");
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  let basicJudgeData, alreadySubmitted;
  alreadySubmitted = judgementData?.alreadySubmit;

  if (judgementData && Object.keys(judgementData).length !== 0) {
    const {
      ask_from_partner_name,
      ask_from_partner_roll,
      situation_description,
    } = judgementData;

    basicJudgeData = {
      ask_from_partner_name,
      ask_from_partner_roll,
      situation_description,
      alreadySubmit: true,
    };
  }

  const logOut = () => {
    //incase of expired member code
    localStorage.removeItem("UserJWT");
    [
      "UserJWT",
      "userData",
      "badFeelings",
      "blackListWords",
      "allNeeds",
      "selfConnectData",
      "request",
      "judgement",
      "goodFeelings",
      "RegUserData",
      "updateMode",
      "displayData",
    ].forEach((key) => {
      sessionStorage.removeItem(key);
    });
    navigate("/");
  };

  const submitData = (bool) => {
    let booll = bool;
    if (judgementData?.alreadySubmit && bool === false) {
      booll = "came from other part";
    } else {
      booll = bool;
    }

    const newData = {
      ...judgementData,
      does_want_to_check_self_needs: booll,
      status: "A",
    };
    delete newData?.alreadySubmit;
    if (bool) {
      saveNContinue(newData);
      //call api, save data, redirect to same process for self.
    } else {
      if (isPartnerNeeds && isPartnerNeeds === "submitted") {
        sessionStorage.removeItem("isPartnerNeeds");
        navigate("/home", { replace: true });
      } else {
        saveNGoToHome(newData); //if user wants to finish, submit data to DB and redirect to homepage.
      }
    }
  };

  const saveNContinue = async (data) => {
    setLoading2(true);
    try {
      const res = await callApi.postData("partner-needs", queryString(data), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          Authorization: JWToken,
        },
      });

      if (res.ok) {
        if (res.data.status === "success") {
          sessionStorage.removeItem("judgement");
          dispatch(resetNeedState());
          dispatch(resetIGNeedState());
          dispatch(resetDeepNeedState());
          sessionStorage.setItem("judgement", JSON.stringify(basicJudgeData));
          sessionStorage.setItem("isPartnerNeeds", "submitted");
          setLoading2(false);
          navigate("/my-judgement");
        } else {
          setLoading2(false);
          if (res.data.message === "תוקף הקוד פג") {
            console.log(res.data.message);
            sessionStorage.setItem("ExpireCode", "True");
            logOut();
          }
          console.log(res.data.message);
        }
      } else {
        console.log(res.originalError);
      }
    } catch (error) {
      error && console.log(error);
    }
  };

  const saveNGoToHome = async (data) => {
    setLoading(true);
    try {
      const res = await callApi.postData("partner-needs", queryString(data), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          Authorization: JWToken,
        },
      });

      if (res.ok) {
        if (res.data.status === "success") {
          sessionStorage.removeItem("judgement");
          dispatch(resetNeedState());
          dispatch(resetIGNeedState());
          dispatch(resetDeepNeedState());
          setLoading(false);
          navigate("/home", { replace: true });
        } else {
          setLoading(false);
          if (res.data.message === "תוקף הקוד פג") {
            console.log(res.data.message);
            sessionStorage.setItem("ExpireCode", "True");
            logOut();
          }
          console.log(res.data.message);
        }
      } else {
        console.log(res.originalError);
      }
    } catch (error) {
      error && console.log(error);
    }
  };

  return (
    <>
      <div className="bg-primary_e">
        <Header extraClass="border-bottom" />
        <div className="c4b_9_content text-center px-3 pt-100">
          <div className="container pt-5">
            <h5 className="c4b_9_title">תודה ששיתפת! </h5>
            {/* <img src="assets/images/heart.svg" alt="Heart" className="my-3" /> */}
            <div className="my-3">
              <Heart />
            </div>

            <h5 className="c4b_9_title">
              {userGender === "male" ? "חזור " : "חזרי "}
              שוב בהזדמנות :)
              <br />
              יחד ננסה להבין עוד
              <br />
              צרכים של אנשים
              <br />
              חשובים בחייך
            </h5>
            {/* Outline Button Design */}

            <div className="position-relative">
              {loading && (
                <div
                  className="btn_loader"
                  style={{ right: 25, top: 60 }}
                ></div>
              )}
            </div>
            <div className="line">
              <button
                className="btn bg-outline-button custom_button text-regular mt-5 fs-18"
                onClick={() => submitData(false)}
              >
                רוצה לסיים
              </button>
            </div>

            <div className="position-relative">
              {loading2 && (
                <div
                  className="btn_loader_dark"
                  style={{ right: 15, top: 68 }}
                ></div>
              )}
            </div>
            {!alreadySubmitted && (
              <div className="line">
                <RoundBtn
                  extraClass="mt-5 text-regular bg-secondary-button fs-18 px-0"
                  text="רוצה להבין את הצרכים שלי"
                  onClick={() => submitData(true)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerNeedSubmit;
