import { useDispatch, useSelector } from "react-redux";
import { addRemoveIgnoredNeeds } from "../../redux/ignoredNeedsSlice";

const IgnoredTabNeedE = ({ data, index, blockId }) => {
  const dispatch = useDispatch();
  const selectedNeedTabIDs = useSelector((state) =>
    state.ignoredNeeds.map((x) => x.id)
  );

  const saveData = (data) => {
    dispatch(addRemoveIgnoredNeeds(data));
  };

  return (
    <button
      className={`btn e7_custom_btn shadow_button mb-2 
        ${
          selectedNeedTabIDs.includes(data.id)
            ? "bg_secondary_btn text-white"
            : ""
        } ${index == 0 ? "btn_text_dark" : ""}`}
      onClick={() => {
        saveData({ id: data.id, name: data.subCateName, blockId, index });
      }}
    >
      {data.subCateName}
    </button>
  );
};

export default IgnoredTabNeedE;
