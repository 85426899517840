import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import key from "../img/key.png";

const NeedsText = () => {
  const navigate = useNavigate();
  const selfConnData = JSON.parse(sessionStorage.getItem("selfConnectData"));

  useEffect(() => {
    if (selfConnData?.b_feelings === undefined) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="bg-primary_e">
        <Header extraClass="border-bottom" />

        <div className="e_content">
          <div className="container">
            <h4 className="e_content_disc mb-3">
              מאחורי הרגשות שלנו <br /> עומדים צרכים אנושיים.
            </h4>
            <div className="e_emoji_icon mb-3">
              <img src={key} alt="Key" />
            </div>
            <h4 className="e_content_disc mb-3">
              מאחורי רגש נעים עומד
              <br />
              צורך שקיבל מענה,
              <br />
              ומאחורי רגש לא נעים,
              <br />
              עומד צורך שלא קיבל מענה.
              <br />
              <br />
              ננסה כעת לראות את הצרכים
              <br />
              מאחורי הרגשות שלנו.
            </h4>

            {/* Button Design */}
            {selfConnData?.b_feelings && selfConnData?.b_feelings !== "" ? (
              <Link to="/ignored-needs" className="line">
                <RoundBtn
                  extraClass="mt-5 text-regular bg-secondary-button"
                  text="להמשיך"
                />
              </Link>
            ) : (
              <Link to="/answered-needs" className="line">
                <RoundBtn
                  extraClass="mt-5 text-regular bg-secondary-button"
                  text="להמשיך"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NeedsText;
