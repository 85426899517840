const OtherMaleL = (props) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <g clipPath="url(#clip0_4680_6598)">
        <path d="M0 0H100V100H0z"></path>
        <g>
          <g>
            <mask
              id="mask0_4680_6598"
              style={{ maskType: "alpha" }}
              width="101"
              height="100"
              x="0"
              y="0"
              maskUnits="userSpaceOnUse"
            >
              <path
                fill="#C4C4C4"
                stroke="#000"
                d="M99.5 50c0 27.338-22.162 49.5-49.5 49.5S.5 77.338.5 50 22.662.5 50 .5 99.5 22.662 99.5 50z"
              ></path>
            </mask>
            <g mask="url(#mask0_4680_6598)">
              <g>
                <g>
                  <path
                    fill="#3869E5"
                    d="M55.09 79.792H44.564v19.212H55.09V79.792z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M45.57 90.515h8.41a5.7 5.7 0 014.018 1.652 5.623 5.623 0 011.663 3.99v12.801H39.89V96.157c0-1.497.598-2.932 1.663-3.99a5.7 5.7 0 014.018-1.652z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M68.443 56.511c10.375-10.322 10.375-27.057 0-37.38-10.376-10.322-27.198-10.322-37.573 0-10.376 10.323-10.376 27.058 0 37.38 10.375 10.322 27.197 10.322 37.573 0z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M29.183 49.156a4.578 4.578 0 00-1.76-6.26c-2.226-1.245-5.047-.463-6.301 1.748a4.578 4.578 0 001.76 6.26c2.225 1.245 5.047.462 6.3-1.748z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M23.913 42.3c2.546-.22 4.43-2.447 4.21-4.975-.222-2.528-2.465-4.4-5.01-4.18-2.546.22-4.43 2.447-4.21 4.975.222 2.528 2.465 4.4 5.01 4.18z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M28.387 32.255a4.573 4.573 0 00.5-6.48 4.649 4.649 0 00-6.524-.496 4.573 4.573 0 00-.5 6.48 4.648 4.648 0 006.524.495z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M29.987 25.558c2.555 0 4.626-2.057 4.626-4.595s-2.071-4.595-4.626-4.595-4.627 2.057-4.627 4.595 2.072 4.595 4.627 4.595z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M40.404 17.905a4.573 4.573 0 00-.538-6.476 4.648 4.648 0 00-6.52.534 4.573 4.573 0 00.538 6.476 4.648 4.648 0 006.52-.534z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M50.196 11.36a4.57 4.57 0 01-.634 2.593 4.615 4.615 0 01-1.976 1.805 4.655 4.655 0 01-5.09-.71 4.59 4.59 0 01-1.398-2.277c-.23-.88-.19-1.808.11-2.665a4.597 4.597 0 011.583-2.155 4.646 4.646 0 014.31-.67 4.634 4.634 0 011.556.91 4.57 4.57 0 011.538 3.17z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M59.478 11.925c.059-2.537-1.964-4.641-4.519-4.7-2.554-.058-4.673 1.95-4.731 4.488-.06 2.537 1.964 4.641 4.518 4.7 2.555.058 4.673-1.951 4.732-4.488z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M67.434 17.696a4.576 4.576 0 00-1.37-6.354c-2.145-1.379-5.01-.77-6.398 1.361a4.576 4.576 0 001.37 6.354c2.145 1.379 5.01.77 6.398-1.361z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M75.086 22.53c.84-2.396-.435-5.015-2.848-5.85s-5.05.432-5.89 2.828c-.84 2.397.434 5.016 2.847 5.85 2.413.835 5.05-.43 5.89-2.827z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M79.193 31.521a4.575 4.575 0 00-1.127-6.4c-2.091-1.46-4.977-.958-6.445 1.119a4.575 4.575 0 001.127 6.4c2.09 1.46 4.976.958 6.445-1.119z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M77.346 42.572c2.554-.079 4.56-2.199 4.48-4.735-.079-2.537-2.213-4.53-4.767-4.45-2.554.078-4.56 2.199-4.48 4.735.079 2.536 2.213 4.529 4.767 4.45z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M79.079 46.573a4.57 4.57 0 01-.634 2.591 4.615 4.615 0 01-1.976 1.806 4.655 4.655 0 01-5.09-.71 4.59 4.59 0 01-1.398-2.276 4.565 4.565 0 01.11-2.665 4.597 4.597 0 011.581-2.155 4.645 4.645 0 016.954 1.672c.264.544.418 1.134.453 1.737z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M26.257 58.317c3.476-.56 5.836-3.813 5.272-7.265-.564-3.453-3.84-5.797-7.315-5.237-3.476.56-5.837 3.814-5.272 7.266.564 3.452 3.839 5.797 7.315 5.236z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M78.607 56.546a6.302 6.302 0 000-8.956 6.407 6.407 0 00-9.017 0 6.302 6.302 0 000 8.956 6.407 6.407 0 009.017 0z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M50.468 23.31H48.69c-12.895 0-23.348 10.382-23.348 23.189v14.613c0 12.807 10.453 23.19 23.348 23.19h1.776c12.895 0 23.348-10.383 23.348-23.19V46.5c0-12.807-10.453-23.19-23.347-23.19z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M55.088 90.512l-5.263-5.227-5.263 5.227 5.263 5.228 5.263-5.228z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M49.577 31.394c7.18 0 13-2.322 13-5.187 0-2.864-5.82-5.187-13-5.187-7.179 0-12.999 2.323-12.999 5.187 0 2.864 5.82 5.187 13 5.187z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M40.497 49.679c0 .525-.156 1.04-.45 1.477a2.672 2.672 0 01-1.2.98 2.693 2.693 0 01-2.917-.577 2.64 2.64 0 01-.58-2.897c.203-.485.547-.9.987-1.192a2.689 2.689 0 013.377.331c.502.498.783 1.173.783 1.878z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M63.402 49.679c0 .525-.157 1.04-.45 1.476a2.671 2.671 0 01-1.2.98 2.692 2.692 0 01-2.917-.575 2.64 2.64 0 01-.58-2.896c.202-.485.545-.9.985-1.193a2.688 2.688 0 013.958 1.191c.134.322.204.668.204 1.017z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M40.008 58.401c6.488 11.544 12.868 11.946 19.139 0h-19.14z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M37.125 120.886H32.07v-16.625c0-8.023 5.052-13.626 12.288-13.626v5.021c-4.996 0-7.232 4.33-7.232 8.605v16.625z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M67.695 120.886H62.64v-16.625c0-4.282-2.236-8.605-7.232-8.605v-5.021c7.236 0 12.287 5.603 12.287 13.626v16.625z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4680_6598">
          <path fill="#fff" d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
    </svg>
);

export default OtherMaleL;
