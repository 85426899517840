import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import MinorShape from "../../components/svg/MinorShape";

const MyNeedsSummary = () => {
  const navigate = useNavigate();
  const judgementData = JSON.parse(sessionStorage.getItem("judgement"));

  useEffect(() => {
    if (judgementData?.does_want_to_check_deeper_needs === undefined) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const needsStr = judgementData?.needs?.replace(":", ", ");
  let deepNeeds;
  deepNeeds = judgementData?.deeper_needs?.replaceAll(":", ", ");
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  return (
    <>
      <div className="bg-light_e pb-0">
        <Header />
        <div className="flex_c4b7">
          <div className="c4b_7_content pt-90 px-3">
            <div className="container">
              <div className="c4b_8_details">
                <h6>אלה הצרכים שלך בסיטואציה</h6>
                <p>{needsStr}</p>
              </div>

              {deepNeeds && deepNeeds?.length > 0 && (
                <div className="c4b_8_details mt-4">
                  <h6>ואלה צרכים נוספים, עמוקים יותר</h6>
                  <p>{deepNeeds}</p>
                </div>
              )}
            </div>
          </div>
          <div className="c4b_7_bg_img customCircleEvent">
            <div className="svg-img">
              {/* <img src="assets/images/minor-shape.svg" /> */}
              <MinorShape />
            </div>
            <div className="c4b_7_bg_content px-3 bg-blue-prm">
              <h3>כל הצרכים הם אנושיים וחשובים מאוד!</h3>
              <p className="c4a7_content_para">
                חלק מהצרכים יכולים להיראות “מנוגדים”
                <br />
                כביכול, גם זה מאוד אנושי.
                <br />
                הרי אנחנו יצורים מאוד מורכבים
                <br />
                (ומדהימים).
                <br />
                כל צורך וצורך ראוי לתשומת לב ולחקירה.
              </p>
              <h5 className="mt-4">
                וסחטיין עליך! על התהליך שאת
                {userGender === "female" ? "" : "ה"}
                <br />
                עושה למען עצמך והמשפחה שלך!
              </h5>
              {/* Secondary Button Design */}
              <Link to="/my-need-situation" className="line">
                <RoundBtn
                  extraClass="mt-5 text-regular bg-secondary-button"
                  text="להמשיך"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyNeedsSummary;
