import { createSlice } from "@reduxjs/toolkit";

const answeredNeedsSlice = createSlice({
  name: "answeredNeeds",
  initialState: [],
  reducers: {
    addRemoveAnsweredNeeds(state, action) {
      if (state.map(x => x.name).includes(action.payload.name) && state.map(x => x.blockId).includes(action.payload.blockId)) {
        return state.filter((x) => x.name !== action.payload.name);
      } else {
        if (action.payload.maxLimit) {
          //do nothing (for Section A - allow only 2 selection for Needs)
        } else {
          state.push(action.payload);
        }
      }
    },
    resetNeedState(state) {
        return (state = []);
    }
  },
});

export const { addRemoveAnsweredNeeds, resetNeedState } = answeredNeedsSlice.actions;
export default answeredNeedsSlice.reducer;
