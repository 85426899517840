const BrotherBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <g clipPath="url(#clip0_4680_6293)">
        <path d="M0 0H100V100H0z"></path>
        <g filter="url(#filter0_ddii_4680_6293)">
          <g>
            <ellipse
              cx="49.298"
              cy="49.5"
              fill="#fff"
              rx="42.298"
              ry="42.5"
            ></ellipse>
            <g>
              <mask
                id="mask0_4680_6293"
                style={{ maskType: "alpha" }}
                width="85"
                height="85"
                x="7"
                y="7"
                maskUnits="userSpaceOnUse"
              >
                <path
                  fill="#C4C4C4"
                  stroke="#000"
                  d="M91.5 49.5c0 23.198-18.716 42-41.799 42-23.082 0-41.798-18.802-41.798-42s18.716-42 41.798-42c23.083 0 41.798 18.802 41.798 42z"
                ></path>
              </mask>
              <g mask="url(#mask0_4680_6293)">
                <g>
                  <g>
                    <g>
                      <path
                        fill="#3869E5"
                        d="M71.468 24.874c0-8.92-12.364-7.63-21.303-7.63-8.938 0-21.536-1.29-21.536 7.63v29.76c0 8.92 17.715 16.152 26.654 16.152s16.185-7.232 16.185-16.152v-29.76z"
                      ></path>
                      <path
                        fill="#45CBF6"
                        d="M27.394 55.776a5.388 5.388 0 005.394-5.383 5.388 5.388 0 00-5.394-5.382A5.388 5.388 0 0022 50.394a5.388 5.388 0 005.394 5.382z"
                      ></path>
                      <path
                        fill="#45CBF6"
                        d="M72.483 55.776a5.388 5.388 0 005.394-5.383 5.388 5.388 0 00-5.394-5.382 5.388 5.388 0 00-5.394 5.383 5.388 5.388 0 005.394 5.382z"
                      ></path>
                      <path
                        fill="#3869E5"
                        d="M46.549 82.042h7.114a4.81 4.81 0 013.399 1.405 4.79 4.79 0 011.407 3.39V97.72H41.743V86.838a4.79 4.79 0 011.408-3.391 4.81 4.81 0 013.398-1.405z"
                      ></path>
                      <path
                        fill="#45CBF6"
                        d="M49.94 76.759c11.895 0 21.538-10.81 21.538-24.146 0-13.335-9.643-24.145-21.539-24.145-11.895 0-21.539 10.81-21.539 24.145 0 13.335 9.644 24.146 21.54 24.146z"
                      ></path>
                      <path
                        fill="#45CBF6"
                        d="M45.981 88.395h-4.237v23.61h4.237v-23.61z"
                      ></path>
                      <path
                        fill="#45CBF6"
                        d="M54.234 112.003h4.237v-23.61h-4.237v23.61z"
                      ></path>
                      <path
                        fill="#3869E5"
                        d="M42.171 41.063l-3.01 1.106a1.418 1.418 0 01-1.813-.834 1.41 1.41 0 01.845-1.81l3.01-1.107a1.418 1.418 0 011.811.835 1.41 1.41 0 01-.843 1.81z"
                      ></path>
                      <path
                        fill="#3869E5"
                        d="M57.08 41.063l3.01 1.106a1.42 1.42 0 001.814-.834 1.411 1.411 0 00-.838-1.809l-3.009-1.107a1.42 1.42 0 00-1.817.834 1.411 1.411 0 00.84 1.81z"
                      ></path>
                    </g>
                    <path
                      fill="#3869E5"
                      d="M43.244 49.501a.81.81 0 01-.567-.23.777.777 0 01-.235-.555 1.64 1.64 0 00-.514-1.137 1.711 1.711 0 00-1.177-.466c-.44 0-.861.167-1.177.466-.315.3-.5.707-.514 1.137a.777.777 0 01-.235.555.81.81 0 01-1.134 0 .777.777 0 01-.235-.555c-.01-.43.067-.858.228-1.258s.402-.765.71-1.073a3.3 3.3 0 011.078-.719 3.358 3.358 0 012.558 0 3.3 3.3 0 011.079.72c.307.307.548.671.709 1.072.16.4.239.828.228 1.258a.777.777 0 01-.235.555.81.81 0 01-.567.23z"
                    ></path>
                    <path
                      fill="#3869E5"
                      d="M60.596 49.501a.81.81 0 01-.567-.23.777.777 0 01-.235-.555 1.641 1.641 0 00-.514-1.137 1.711 1.711 0 00-1.177-.466c-.44 0-.861.167-1.177.466-.316.3-.5.707-.515 1.137a.777.777 0 01-.234.555.81.81 0 01-1.135 0 .777.777 0 01-.235-.555c-.01-.43.068-.858.229-1.258.16-.4.402-.765.709-1.073a3.3 3.3 0 011.079-.719 3.358 3.358 0 012.558 0 3.3 3.3 0 011.078.72c.307.307.548.671.71 1.072.16.4.238.828.228 1.258a.77.77 0 01-.235.556.801.801 0 01-.567.229z"
                    ></path>
                  </g>
                  <path
                    fill="#45CBF6"
                    d="M45.83 75.56h8.353v6.097c0 .413-.107.822-.317 1.204-.21.382-.516.729-.904 1.021a4.453 4.453 0 01-1.354.683c-.506.159-1.049.24-1.597.24-1.108 0-2.17-.33-2.953-.92-.783-.59-1.222-1.391-1.222-2.226v-6.097l-.006-.002z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
        <path
          fill="#3869E5"
          d="M41 57.233c5.594 9.741 11.095 10.08 16.502 0H41z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_ddii_4680_6293"
          width="102.596"
          height="103"
          x="-2"
          y="-2"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="5" dy="5"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4680_6293"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-5" dy="-5"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.92549 0 0 0 0 0.937255 0 0 0 0 0.941176 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_4680_6293"
            result="effect2_dropShadow_4680_6293"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_4680_6293"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="-1" dy="-2"></feOffset>
          <feGaussianBlur stdDeviation="13"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
          <feBlend in2="shape" result="effect3_innerShadow_4680_6293"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1" dy="-3"></feOffset>
          <feGaussianBlur stdDeviation="13.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="effect3_innerShadow_4680_6293"
            result="effect4_innerShadow_4680_6293"
          ></feBlend>
        </filter>
        <clipPath id="clip0_4680_6293">
          <path fill="#fff" d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default BrotherBlue
