import { createSlice } from "@reduxjs/toolkit";

const badFeelSlice = createSlice({
  name: "badFeel",
  initialState: [],
  reducers: {
    addRemoveBadFeel(state, action) {
      if (
        state.map((x) => x.name).includes(action.payload.name) &&
        state.map((x) => x.blockId).includes(action.payload.blockId)
      ) {
        return state.filter((x) => x.name !== action.payload.name);
      } else {
        if (action.payload.maxLimit) {
          //do nothing (for Section A - allow only 2 selection for Feelings)
        } else {
          state.push(action.payload);
        }
      }
    },
    resetBadFeelState(state) {
      return (state = []);
    },
  },
});

export const { addRemoveBadFeel, resetBadFeelState } = badFeelSlice.actions;
export default badFeelSlice.reducer;
