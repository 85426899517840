const PersonFormBg = ({ children, submitBtn }) => {
  return (
    <>
      <div className="blank-wrapper">
        <div className="header_child">
          <img src="assets/images/child_header_shape.svg" alt="Top Shape" />
          {children}
        </div>

        <div className="blank-footer line">
          <img src="assets/images/blank_footer_shape.svg" alt="Bottom Shape" />
          {submitBtn}
        </div>
      </div>
    </>
  );
};

export default PersonFormBg;
