import { createSlice } from "@reduxjs/toolkit";

const goodFeelSlice = createSlice({
  name: "goodfeel",
  initialState: [],
  reducers: {
    addRemoveGoodFeel(state, action) {
      if (state.map(x => x.name).includes(action.payload.name) && state.map(x => x.blockId).includes(action.payload.blockId)) {
        return state.filter((x) => x.name !== action.payload.name);
      } else {
        state.push(action.payload);
      }
    },
    resetGoodFeelState(state) {
      return (state = []);
    },
  },
});

export const { addRemoveGoodFeel, resetGoodFeelState } = goodFeelSlice.actions;

export default goodFeelSlice.reducer;
