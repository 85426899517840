import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import BadFeelBlock from "../all-tabs/BadFeelBlockE";
import callApi from "../../lib/apisauce/callApi";
import RoundBtn from "../RoundBtn";

const BadFeelings = () => {
  const navigate = useNavigate();
  const JWToken = JSON.parse(sessionStorage.getItem("UserJWT"));
  const [badFeel, setBadFeel] = useState(
    JSON.parse(sessionStorage.getItem("badFeelings")) || []
  );

  useEffect(() => {
    if (!badFeel.length) fetchBadFeelings();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      compareRowCount();
    }, 100);
  }, []);

  const countRowItems = (element) => {
    const grid = Array.from(document?.querySelector(element)?.children);
    const baseOffset = grid[0].offsetTop;
    const breakIndex = grid.findIndex((item) => item.offsetTop > baseOffset);
    return breakIndex === -1 ? grid.length : breakIndex;
  };

  const compareRowCount = () => {
    badFeel.map((bfeel, i) => {
      let itemPerRow = countRowItems("#element" + (i + 1));
      let arrLen = badFeel[i].emotionSubCat?.length;

      if (itemPerRow === arrLen) {
        setBadFeel((badFeel) =>
          badFeel.map((bf) =>
            bf.emotionId === i + 1 ? { ...bf, isMatch: true, itemPerRow } : bf
          )
        );
      } else {
        setBadFeel((badFeel) =>
          badFeel.map((bf) =>
            bf.emotionId === i + 1 ? { ...bf, isMatch: false, itemPerRow } : bf
          )
        );
      }
    });
  };

  const fetchBadFeelings = () => {
    trackPromise(
      callApi
        .getDatawithToken(
          "get-bad",
          {},
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
              Authorization: JWToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            setBadFeel(res.data.data);
            sessionStorage.setItem(
              "badFeelings",
              JSON.stringify(res.data.data)
            );
          }
        })
        .catch((res) => console.log(res.originalError))
    );
  };

  let selectedBadFeels = useSelector((state) =>
    state.badFeel.map((x) => x.name)
  );
  let selectedGoodFeels = useSelector((state) =>
    state.goodFeel.map((x) => x.name)
  );
  const selfConnData = JSON.parse(sessionStorage.getItem("selfConnectData"));

  const submitBadFeelings = () => {
    const b_feelings = selectedBadFeels?.join(":");
    const g_feelings = selectedGoodFeels?.join(":");
    const newData = { ...selfConnData, b_feelings, g_feelings };
    sessionStorage.setItem("selfConnectData", JSON.stringify(newData));

    if (selectedGoodFeels.length || selectedBadFeels.length) {
      navigate("/good-feeling");
    }
  };

  return (
    <>
      {badFeel &&
        badFeel.map((bf) => (
          <BadFeelBlock
            key={bf.emotionId}
            id={bf.emotionId}
            dataArr={bf.emotionSubCat}
            isMatch={bf.isMatch}
            itemPerRow={bf.itemPerRow}
          />
        ))}

      <div
        className={`container px-4 ${
          selectedGoodFeels.length || selectedBadFeels.length ? "pb-100" : ""
        }`}
      >
        <div className="pb-2">
          <RoundBtn
            extraClass={`mt-4 text-regular 
                  ${
                    selectedGoodFeels.length || selectedBadFeels.length
                      ? "bg-primary-button btn-popup"
                      : "primary-disable"
                  }`}
            text="להמשיך"
            onClick={submitBadFeelings}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(BadFeelings);
