const KidM = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="100"
  height="100"
  fill="none"
  viewBox="0 0 100 100"
>
  <g clipPath="url(#clip0_4680_5853)">
    <path d="M0 0H100V100H0z"></path>
    <g filter="url(#filter0_dd_4680_5853)">
      <g>
        <ellipse
          cx="49.805"
          cy="49.5"
          fill="#fff"
          rx="42.196"
          ry="42.5"
        ></ellipse>
        <g>
          <mask
            id="mask0_4680_5853"
            style={{ maskType: "alpha" }}
            width="85"
            height="85"
            x="7"
            y="7"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#C4C4C4"
              stroke="#000"
              d="M90.891 49.5c0 23.2-18.671 42-41.696 42C26.172 91.5 7.5 72.7 7.5 49.5s18.671-42 41.696-42c23.024 0 41.695 18.8 41.695 42z"
            ></path>
          </mask>
          <g mask="url(#mask0_4680_5853)">
            <g>
              <g>
                <g>
                  <path
                    fill="#3869E5"
                    d="M36.06 21.673h-.001a7.572 7.572 0 00-7.57 7.573v29.35a7.572 7.572 0 007.57 7.573h.001c4.181 0 7.57-3.391 7.57-7.573v-29.35a7.572 7.572 0 00-7.57-7.573z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M71.349 24.874c0-8.92-7.23-16.152-16.146-16.152-8.918 0-16.146 7.231-16.146 16.152v29.76c0 8.92 7.228 16.151 16.146 16.151 8.917 0 16.146-7.231 16.146-16.152v-29.76z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M27.38 65.067A5.382 5.382 0 1022 59.684a5.382 5.382 0 005.38 5.383z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M72.36 65.067c2.972 0 5.381-2.41 5.381-5.382a5.382 5.382 0 10-10.761 0 5.382 5.382 0 005.38 5.382z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M54.523 72.928H45.64v16.33h8.882v-16.33z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M46.49 82.041h7.097a4.793 4.793 0 014.794 4.796v10.881H41.695V86.837a4.797 4.797 0 014.794-4.796z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M49.871 76.758c11.867 0 21.487-10.81 21.487-24.146 0-13.335-9.62-24.145-21.487-24.145-11.866 0-21.486 10.81-21.486 24.145 0 13.336 9.62 24.146 21.486 24.146z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M49.665 62.965c-1.998 0-3.96-.527-5.689-1.528a2.898 2.898 0 112.929-5.001c1.855 1.086 3.972.818 4.788.207a2.897 2.897 0 113.476 4.638c-1.494 1.118-3.461 1.684-5.504 1.684z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M45.923 88.394h-4.227v23.61h4.227v-23.61z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M54.155 112.003h4.227v-23.61h-4.227v23.61z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M43.135 41.062l-3.004 1.106a1.411 1.411 0 01-1.807-.834 1.413 1.413 0 01.842-1.81l3.002-1.107a1.412 1.412 0 011.808.835 1.413 1.413 0 01-.84 1.81z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M56.996 41.062L60 42.168a1.413 1.413 0 001.809-.834 1.414 1.414 0 00-.836-1.809l-3.002-1.107a1.413 1.413 0 00-1.813.834 1.415 1.415 0 00.84 1.81z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M46.568 51.49a.64.64 0 01-.64-.64 4.783 4.783 0 00-4.78-4.782 4.78 4.78 0 00-4.78 4.782.642.642 0 01-1.28 0 6.064 6.064 0 016.06-6.165 6.058 6.058 0 015.628 3.814c.298.747.445 1.547.431 2.351a.64.64 0 01-.64.64z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M63.337 51.49a.64.64 0 01-.64-.64 4.783 4.783 0 00-4.78-4.782 4.78 4.78 0 00-4.78 4.782.641.641 0 01-1.28 0 6.064 6.064 0 016.06-6.165 6.058 6.058 0 015.628 3.814c.298.747.445 1.547.431 2.351a.64.64 0 01-.639.64z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
  <defs>
    <filter
      id="filter0_dd_4680_5853"
      width="102.391"
      height="103"
      x="-1.391"
      y="-2"
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="-5" dy="-5"></feOffset>
      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 0.866667 0 0 0 0 0.882353 0 0 0 0 0.941176 0 0 0 0.6 0"></feColorMatrix>
      <feBlend
        in2="BackgroundImageFix"
        result="effect1_dropShadow_4680_5853"
      ></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="5" dy="5"></feOffset>
      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.15 0"></feColorMatrix>
      <feBlend
        in2="effect1_dropShadow_4680_5853"
        result="effect2_dropShadow_4680_5853"
      ></feBlend>
      <feBlend
        in="SourceGraphic"
        in2="effect2_dropShadow_4680_5853"
        result="shape"
      ></feBlend>
    </filter>
    <clipPath id="clip0_4680_5853">
      <path fill="#fff" d="M0 0H100V100H0z"></path>
    </clipPath>
  </defs>
</svg>
);

export default KidM;
