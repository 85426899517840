const KidFBlue = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="100"
  height="100"
  fill="none"
  viewBox="0 0 100 100"
>
  <g clipPath="url(#clip0_4680_6166)">
    <path d="M0 0H100V100H0z"></path>
    <g filter="url(#filter0_ddii_4680_6166)">
      <circle cx="49.5" cy="49.5" r="42.5" fill="#fff"></circle>
      <g>
        <mask
          id="mask0_4680_6166"
          style={{ maskType: "alpha" }}
          width="85"
          height="85"
          x="7"
          y="7"
          maskUnits="userSpaceOnUse"
        >
          <g>
            <circle cx="49.5" cy="49.5" r="42.5" fill="#DDE1F0"></circle>
            <mask
              style={{ maskType: "alpha" }}
              width="85"
              height="85"
              x="7"
              y="7"
              maskUnits="userSpaceOnUse"
            >
              <g>
                <circle
                  cx="49.5"
                  cy="49.5"
                  r="42.5"
                  fill="#DDE1F0"
                ></circle>
                <circle
                  cx="49.5"
                  cy="49.5"
                  r="42"
                  stroke="#000"
                  strokeOpacity="0.5"
                ></circle>
              </g>
            </mask>
          </g>
        </mask>
        <g mask="url(#mask0_4680_6166)">
          <g>
            <g>
              <g>
                <path
                  fill="#3869E5"
                  d="M42.238 78.76H55.04c.79 0 1.549.312 2.108.867a2.95 2.95 0 01.872 2.092v14.544H39.258V81.718c0-.785.314-1.537.873-2.092a2.99 2.99 0 012.107-.866z"
                ></path>
                <path
                  fill="#45CBF6"
                  d="M42.9 78.57h1.372a.278.278 0 01.276.274v17.23h-1.923v-17.23a.274.274 0 01.275-.275z"
                ></path>
                <path
                  fill="#45CBF6"
                  d="M53.05 78.57h1.371a.277.277 0 01.277.274v17.23h-1.924v-17.23a.274.274 0 01.275-.275z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M42.634 84.808h-3.388v12.421h3.388V84.808z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M54.722 97.228h3.3V84.806h-3.3v12.422z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M44.55 72.647h8.234v7.922a4.064 4.064 0 01-1.203 2.892 4.117 4.117 0 01-2.91 1.2 4.13 4.13 0 01-2.91-1.197 4.074 4.074 0 01-1.206-2.891V72.65l-.005-.004z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M31.411 62.912a3.452 3.452 0 000-4.906 3.51 3.51 0 00-4.939 0 3.452 3.452 0 000 4.906 3.51 3.51 0 004.94 0z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M68.814 63.908c1.915-.232 3.277-1.962 3.042-3.864-.234-1.901-1.976-3.254-3.89-3.021-1.915.233-3.277 1.963-3.043 3.864.235 1.901 1.977 3.254 3.891 3.021z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M24.24 29.685c5.463 0 9.891-4.398 9.891-9.824s-4.428-9.825-9.892-9.825c-5.463 0-9.891 4.399-9.891 9.825 0 5.425 4.428 9.824 9.891 9.824z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M30.388 32.872l-2.034-2.018a4.88 4.88 0 01-1.433-3.451 4.88 4.88 0 011.433-3.452 4.947 4.947 0 013.477-1.425c1.302 0 2.552.512 3.476 1.425l2.031 2.018a4.88 4.88 0 011.434 3.451 4.88 4.88 0 01-1.434 3.452 4.944 4.944 0 01-3.475 1.425 4.944 4.944 0 01-3.475-1.425z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M73.35 29.685c5.464 0 9.893-4.398 9.893-9.824s-4.43-9.825-9.892-9.825c-5.463 0-9.892 4.399-9.892 9.825 0 5.425 4.429 9.824 9.892 9.824z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M67.201 32.872l2.033-2.018a4.88 4.88 0 001.434-3.451 4.88 4.88 0 00-1.434-3.452 4.947 4.947 0 00-3.476-1.425 4.947 4.947 0 00-3.476 1.425l-2.032 2.018a4.876 4.876 0 00-1.435 3.451c0 1.294.516 2.535 1.435 3.452a4.944 4.944 0 003.476 1.425 4.944 4.944 0 003.475-1.425z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M65.728 59.627c9.437-9.39 9.437-24.613 0-34.002-9.438-9.389-24.74-9.389-34.177 0-9.438 9.39-9.438 24.612 0 34.002 9.437 9.389 24.739 9.389 34.177 0z"
                ></path>
                <path
                  fill="#45CBF6"
                  d="M68.046 47.777c0-10.63-8.677-19.248-19.38-19.248-10.702 0-19.379 8.618-19.379 19.248v9.615c0 10.63 8.677 19.247 19.38 19.247 10.702 0 19.379-8.617 19.379-19.247v-9.615z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M69.627 48.167c0-12-10.03-21.82-22.093-21.18a21.05 21.05 0 00-14.076 6.477 20.77 20.77 0 00-5.754 14.308c9.255-2.14 16.587-6.055 20.962-12.694 4.166 7.564 11.38 11.67 20.961 13.089z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M36.868 58.012c0 .241-.355.437-.8.437-.445 0-.797-.196-.797-.437s.354-.435.797-.435c.443 0 .8.196.8.435z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M39.368 58.012c0 .241-.354.437-.799.437-.445 0-.8-.196-.8-.437s.355-.435.8-.435c.445 0 .8.196.8.435z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M38.212 59.16c0 .24-.355.436-.8.436-.445 0-.796-.202-.796-.437 0-.234.355-.436.798-.436.443 0 .798.195.798.436z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M40.739 59.16c0 .24-.355.436-.8.436-.445 0-.797-.195-.797-.437 0-.24.354-.436.797-.436.444 0 .8.195.8.436z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M41.869 58.012c0 .241-.355.437-.798.437-.443 0-.8-.196-.8-.437s.355-.435.8-.435c.445 0 .798.196.798.435z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M57.445 58.012c0 .241-.355.437-.8.437-.445 0-.797-.196-.797-.437s.354-.435.797-.435c.444 0 .8.196.8.435z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M59.948 58.012c0 .241-.355.437-.8.437-.445 0-.797-.196-.797-.437s.354-.435.797-.435c.444 0 .8.196.8.435z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M58.789 59.16c0 .24-.355.436-.798.436-.443 0-.8-.195-.8-.437 0-.24.355-.436.8-.436.445 0 .798.195.798.436z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M61.316 59.16c0 .24-.355.436-.8.436-.444 0-.797-.195-.797-.437 0-.24.354-.436.797-.436.444 0 .8.195.8.436z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M62.448 58.012c0 .241-.355.437-.8.437-.445 0-.8-.196-.8-.437s.355-.435.8-.435c.445 0 .8.196.8.435z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M52.433 61.39c.34-2.066-1.07-4.015-3.151-4.353-2.08-.337-4.042 1.064-4.382 3.13-.34 2.066 1.07 4.015 3.15 4.352 2.081.338 4.043-1.063 4.383-3.13z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M42.033 54.48a.793.793 0 01-.559-.23.783.783 0 01-.231-.555 1.651 1.651 0 00-.507-1.136 1.674 1.674 0 00-2.32 0c-.312.299-.494.706-.508 1.136a.783.783 0 01-.231.556.793.793 0 01-1.35-.556 3.206 3.206 0 01.924-2.33 3.25 3.25 0 012.325-.972 3.27 3.27 0 012.324.972 3.224 3.224 0 01.924 2.33.783.783 0 01-.231.556.793.793 0 01-.56.23z"
                ></path>
                <path
                  fill="#3869E5"
                  d="M59.139 54.48a.793.793 0 01-.56-.23.783.783 0 01-.23-.555 1.652 1.652 0 00-.508-1.136 1.674 1.674 0 00-2.32 0c-.311.299-.493.706-.507 1.136a.783.783 0 01-.232.556.793.793 0 01-1.35-.556 3.208 3.208 0 01.925-2.33 3.25 3.25 0 012.324-.972 3.269 3.269 0 012.324.972 3.225 3.225 0 01.924 2.33.777.777 0 01-.23.556.79.79 0 01-.56.23z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
  <defs>
    <filter
      id="filter0_ddii_4680_6166"
      width="103"
      height="103"
      x="-2"
      y="-2"
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="5" dy="5"></feOffset>
      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"></feColorMatrix>
      <feBlend
        in2="BackgroundImageFix"
        result="effect1_dropShadow_4680_6166"
      ></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="-5" dy="-5"></feOffset>
      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 0.92549 0 0 0 0 0.937255 0 0 0 0 0.941176 0 0 0 0.25 0"></feColorMatrix>
      <feBlend
        in2="effect1_dropShadow_4680_6166"
        result="effect2_dropShadow_4680_6166"
      ></feBlend>
      <feBlend
        in="SourceGraphic"
        in2="effect2_dropShadow_4680_6166"
        result="shape"
      ></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="-1" dy="-2"></feOffset>
      <feGaussianBlur stdDeviation="13"></feGaussianBlur>
      <feComposite
        in2="hardAlpha"
        k2="-1"
        k3="1"
        operator="arithmetic"
      ></feComposite>
      <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
      <feBlend in2="shape" result="effect3_innerShadow_4680_6166"></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="1" dy="-3"></feOffset>
      <feGaussianBlur stdDeviation="13.5"></feGaussianBlur>
      <feComposite
        in2="hardAlpha"
        k2="-1"
        k3="1"
        operator="arithmetic"
      ></feComposite>
      <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.5 0"></feColorMatrix>
      <feBlend
        in2="effect3_innerShadow_4680_6166"
        result="effect4_innerShadow_4680_6166"
      ></feBlend>
    </filter>
    <clipPath id="clip0_4680_6166">
      <path fill="#fff" d="M0 0H100V100H0z"></path>
    </clipPath>
  </defs>
</svg>
)

export default KidFBlue
