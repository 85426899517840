const SisterL = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="100"
  height="100"
  fill="none"
  viewBox="0 0 100 100"
>
  <g clipPath="url(#clip0_4680_6737)">
    <path d="M0 0H100V100H0z"></path>
    <g>
      <g filter="url(#filter0_d_4680_6737)">
        <g>
          <mask
            id="mask0_4680_6737"
            style={{ maskType: "alpha" }}
            width="100"
            height="100"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
          >
            <g>
              <circle cx="50" cy="50" r="50" fill="#DDE1F0"></circle>
              <mask
                style={{ maskType: "alpha" }}
                width="100"
                height="100"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
              >
                <g>
                  <circle cx="50" cy="50" r="50" fill="#DDE1F0"></circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="49.5"
                    stroke="#000"
                    strokeOpacity="0.5"
                  ></circle>
                </g>
              </mask>
            </g>
          </mask>
          <g mask="url(#mask0_4680_6737)">
            <g>
              <g>
                <g>
                  <g fill="#3869E5">
                    <path d="M65.102 23.649c-13.969 2.036-10.93 13.39-8.137 27.325 1.25 6.18-3.366 13.846 1.76 17.482 5.125 3.637 19.603 2.97 25.803 2.074-2.834-6.833-4.455-11.34-7.183-30.047-2.727-18.706-13.17-10.404-12.243-16.834zM32.305 23.649c13.97 2.036 10.93 13.39 8.137 27.325-1.25 6.18 3.365 13.846-1.76 17.482-5.125 3.637-19.603 2.97-25.802 2.075 2.833-6.834 4.454-11.342 7.182-30.048 2.727-18.706 13.17-10.404 12.243-16.834z"></path>
                  </g>
                  <path
                    fill="#3869E5"
                    d="M41.456 84.425h15.062c.93 0 1.821.367 2.479 1.02a3.47 3.47 0 011.027 2.462v17.109H37.95V87.905c0-.923.37-1.808 1.028-2.461a3.518 3.518 0 012.478-1.02z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M41.922 91.538h-3.985v14.614h3.985V91.538z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M56.144 106.15h3.881V91.537h-3.88v14.613z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M44.176 77.231h9.687v9.322a4.782 4.782 0 01-1.415 3.402 4.844 4.844 0 01-3.423 1.411 4.86 4.86 0 01-3.425-1.408 4.793 4.793 0 01-1.418-3.401v-9.321l-.006-.005z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M69.09 61.914c11.104-11.046 11.104-28.956 0-40.002-11.102-11.046-29.104-11.046-40.208 0-11.103 11.047-11.103 28.956 0 40.002 11.104 11.046 29.106 11.046 40.209 0z"
                  ></path>
                  <path
                    fill="#45CBF6"
                    d="M71.818 47.974c0-12.506-10.207-22.644-22.799-22.644-12.592 0-22.8 10.138-22.8 22.644v11.312c0 12.506 10.208 22.644 22.8 22.644s22.8-10.138 22.8-22.644V47.974z"
                  ></path>
                  <path
                    fill="#3869E5"
                    d="M73.679 48.432c0-14.117-11.8-25.67-25.993-24.917a24.765 24.765 0 00-16.56 7.62 24.436 24.436 0 00-6.769 16.833c7.171-1.818 5.758-2.446 24.662-2.446s18.43 1.065 24.66 2.91z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        fill="#3869E5"
        d="M55.557 52.942c.247 0 .483.097.658.27a.921.921 0 01.272.653c.017.506.23.986.597 1.337a1.97 1.97 0 002.73 0c.366-.351.58-.83.596-1.337a.92.92 0 01.273-.653.933.933 0 011.315 0 .921.921 0 01.272.653 3.774 3.774 0 01-1.087 2.742 3.824 3.824 0 01-2.734 1.144 3.845 3.845 0 01-2.735-1.144 3.795 3.795 0 01-1.087-2.742c0-.245.098-.48.272-.653a.934.934 0 01.658-.27z"
      ></path>
      <path
        fill="#3869E5"
        d="M35.433 52.942c.247 0 .483.097.658.27a.92.92 0 01.272.653c.017.506.23.986.597 1.337a1.97 1.97 0 002.73 0c.366-.351.58-.83.596-1.337 0-.245.098-.48.273-.653a.934.934 0 011.315 0 .921.921 0 01.272.653 3.773 3.773 0 01-1.087 2.742 3.824 3.824 0 01-2.734 1.144 3.845 3.845 0 01-2.735-1.144 3.796 3.796 0 01-1.087-2.742.917.917 0 01.574-.854.934.934 0 01.356-.07z"
      ></path>
      <path
        fill="#3869E5"
        d="M38.823 63.53c6.488 14.556 12.869 15.063 19.139 0H38.823z"
      ></path>
    </g>
  </g>
  <defs>
    <filter
      id="filter0_d_4680_6737"
      width="91.647"
      height="106.372"
      x="7.88"
      y="8.628"
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="5" dy="5"></feOffset>
      <feGaussianBlur stdDeviation="5"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.15 0"></feColorMatrix>
      <feBlend
        in2="BackgroundImageFix"
        result="effect1_dropShadow_4680_6737"
      ></feBlend>
      <feBlend
        in="SourceGraphic"
        in2="effect1_dropShadow_4680_6737"
        result="shape"
      ></feBlend>
    </filter>
    <clipPath id="clip0_4680_6737">
      <path fill="#fff" d="M0 0H100V100H0z"></path>
    </clipPath>
  </defs>
</svg>
);

export default SisterL;
