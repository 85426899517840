import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import MinorShape from "../../components/svg/MinorShape";

const PartnerNeedsSummary = () => {
  const navigate = useNavigate();
  const judgementData = JSON.parse(sessionStorage.getItem("judgement"));

  useEffect(() => {
    if (judgementData?.does_want_to_check_partner_deeper_needs === undefined) {
      navigate("/home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const name = judgementData?.ask_from_partner_name;
  const needsStr = judgementData?.partner_needs?.replace(":", ", ");
  let deepNeeds;
  deepNeeds = judgementData?.partner_deeper_needs?.replaceAll(":", ", ");
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let userGender = userData?.member_gender;

  return (
    <>
      <div className="bg-light_e pb-0">
        <Header />
        <div className="flex_c4b7">
          <div className="c4b_7_content pt-90 px-3">
            <div className="container">
              <div className="c4b_8_details">
                <h6>אלו הצרכים של {name} בסיטואציה:</h6>
                <p>{needsStr}</p>
              </div>

              {deepNeeds && deepNeeds?.length > 0 && (
                <div className="c4b_8_details mt-4">
                  <h6>ואלה צרכים נוספים, עמוקים יותר:</h6>
                  <p>{deepNeeds}</p>
                </div>
              )}
            </div>
          </div>
          <div className="c4b_7_bg_img customCircleEvent">
            <div className="svg-img">
              {/* <img src="assets/images/minor-shape.png" /> */}
              <MinorShape />
            </div>

            <div className="c4b_7_bg_content px-3 bg-blue-prm">
              <h4>כל הצרכים הם אנושיים וחשובים מאוד!</h4>
              <h5>ולאף אחד מאיתנו לא נעים כשהצרכים שלו לא מקבלים מענה.</h5>
              <p className="px-3">
                * אם עולים לך כרגע רגשות אשמה, צער או רגש לא נעים אחר, בבקשה תנס
                {userGender === "male" ? "ה " : "י "}
                להתייחס לזה בחמלה.
                <br />
                הכוונה בתהליך הזה היא לפתח סקרנות כלפי {name}, להתעמק ולהכיר
                מזווית קצת אחרת.
                <br />
                אם תרצ
                {userGender === "male" ? "ה " : "י "}
                לחקור את הרגשות שעלו, אפשר להיעזר במסלול חיבור עצמי (אחד
                המסלולים בעמוד הבית) או בכל דרך שנעימה לך.
              </p>
              {/* Secondary Button Design */}
              <Link to="/partner-need-situ" className="line">
                <RoundBtn
                  extraClass="mt-5 text-regular bg-secondary-button"
                  text="להמשיך"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerNeedsSummary;
