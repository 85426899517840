import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";

import Dad from "../../components/svg/Dad";
import DadL from "../../components/svg/DadL";
import DadBlue from "../../components/svg/DadBlue";
import Mom from "../../components/svg/Mom";
import MomL from "../../components/svg/MomL";
import MomBlue from "../../components/svg/MomBlue";
import KidM from "../../components/svg/KidM";
import KidML from "../../components/svg/KidML";
import KidMBlue from "../../components/svg/KidMBlue";
import KidF from "../../components/svg/KidF";
import KidFL from "../../components/svg/KidFL";
import KidFBlue from "../../components/svg/KidFBlue";
import Brother from "../../components/svg/Brother";
import BrotherBlue from "../../components/svg/BrotherBlue";
import Sister from "../../components/svg/Sister";
import SisterBlue from "../../components/svg/SisterBlue";
import OtherMale from "../../components/svg/OtherMale";
import OtherMBlue from "../../components/svg/OtherMBlue";
import OtherFemale from "../../components/svg/OtherFemale";
import OtherFBlue from "../../components/svg/OtherFBlue";

const RequestFrom = () => {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  let familyRole = userData?.family_role;
 
  let parentRelationship = userData?.parent_relationship;
  let adultRelationship = userData?.adult_relationship;
  let userGender = userData?.member_gender;
  const navigate = useNavigate();

  const userRequest = JSON.parse(sessionStorage.getItem("request"));
  let selectedPartner = userRequest?.ask_from_partner_roll;

  let userFamilyRole =
    familyRole === "mom" ||
    familyRole === "dad" ||
    familyRole === "adult_f" ||
    familyRole === "adult_m"
      ? "adult"
      : "child";

  let kidsExist = userData?.kids_exist;
  let siblingExist = userData?.sibling;

  let relationWith =
    parentRelationship === "male" || adultRelationship === "male"
      ? "male"
      : parentRelationship === "female" || adultRelationship === "female"
      ? "female"
      : parentRelationship === "other" || adultRelationship === "other"
      ? "other"
      : "no_relation";

  const handleClick = (data) => {
    const requestData = JSON.stringify({ ask_from_partner_roll: data });
    sessionStorage.setItem("request", requestData);
    navigate("/partner-name");
  };

  return (
    <>
      <div className="bg-light_e">
        <Header />

        <div className="a7_content pt-80">
          <div className="container">
            <div className="a7_header_content text-center">
              {(familyRole === "mom" || familyRole === "adult_f") && <MomL />}
              {(familyRole === "dad" || familyRole === "adult_m") && <DadL />}
              {familyRole === "kid_f" && <KidFL />}
              {familyRole === "kid_m" && <KidML />}
              <h5 className="mt-1">{userData.name}</h5>
              <h5 className="mb-35">
                ממי תרצ
                {userGender === "male" ? "ה " : "י "}
                לבקש בקשה?
              </h5>
            </div>
          </div>
        </div>

        <div className="a8_profile-avatar">
          <div className="container">
            {userFamilyRole === "adult" ? (
              <div
                className={`row justify-content-center w-100 m-0 ${
                  relationWith === "no_relation" ||
                  (kidsExist === "without_children" && relationWith === "other")
                    ? "pt-50"
                    : ""
                }`}
              >
                {/* showing both kids */}
                {kidsExist === "with_children" && (
                  <BothKids partner={selectedPartner} onClick={handleClick} />
                )}

                {(familyRole === "mom" || familyRole === "adult_f") &&
                relationWith === "male" ? (
                  <MaleParent
                    text="מבן הזוג"
                    partner={selectedPartner}
                    onClick={handleClick}
                  />
                ) : relationWith === "male" ? (
                  <RelationWithMale
                    partner={selectedPartner}
                    onClick={handleClick}
                  />
                ) : (familyRole === "dad" || familyRole === "adult_m") &&
                  relationWith === "female" ? (
                  <FemaleParent
                    text="מבת הזוג"
                    partner={selectedPartner}
                    onClick={handleClick}
                  />
                ) : relationWith === "female" ? (
                  <RelationWithFemale
                    partner={selectedPartner}
                    onClick={handleClick}
                  />
                ) : relationWith === "other" ? (
                  <RelationWithOther
                    partner={selectedPartner}
                    onClick={handleClick}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              //if user is child
              <div
                className={`row justify-content-center w-100 m-0 ${
                  siblingExist !== "Yes" ? "pt-50" : ""
                } `}
              >
                <BothParents partner={selectedPartner} onClick={handleClick} />

                {siblingExist === "Yes" && (
                  <BothSiblings
                    partner={selectedPartner}
                    onClick={handleClick}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestFrom;

const BothKids = ({ partner = "", onClick }) => {
  return (
    <>
      <div className="profile_avtar_wraper">
        <div onClick={() => onClick("kid_m")}>
          {partner === "kid_m" ? <KidMBlue /> : <KidM />}
          <h5 className="avatar_img_title">מהבן</h5>
        </div>
        <div onClick={() => onClick("kid_f")}>
          {partner === "kid_f" ? <KidFBlue /> : <KidF />}
          <h5 className="avatar_img_title">מהבת</h5>
        </div>
      </div>
    </>
  );
};

const RelationWithMale = ({ partner = "", onClick }) => {
  return (
    <div className="profile_avtar_wraper">
      <div onClick={() => onClick("other_m")}>
        {partner === "other_m" ? <OtherMBlue /> : <OtherMale />}
        <h5 className="avatar_img_title">מבן הזוג</h5>
      </div>
    </div>
  );
};

const RelationWithFemale = ({ partner = "", onClick }) => {
  return (
    <div className="profile_avtar_wraper">
      <div onClick={() => onClick("other_f")}>
        {partner === "other_f" ? <OtherFBlue /> : <OtherFemale />}
        <h5 className="avatar_img_title">מבת הזוג</h5>
      </div>
    </div>
  );
};

const RelationWithOther = ({ partner = "", onClick }) => {
  return (
    <>
      <div className="profile_avtar_wraper">
        <div onClick={() => onClick("other_m")}>
          {partner === "other_m" ? <OtherMBlue /> : <OtherMale />}
          <h5 className="avatar_img_title">מבן הזוג</h5>
        </div>
        <div onClick={() => onClick("other_f")}>
          {partner === "other_f" ? <OtherFBlue /> : <OtherFemale />}
          <h5 className="avatar_img_title">מבת הזוג</h5>
        </div>
      </div>
    </>
  );
};

const BothParents = ({ partner = "", onClick }) => {
  return (
    <>
      <div className="profile_avtar_wraper">
        <div onClick={() => onClick("dad")}>
          {partner === "dad" ? <DadBlue /> : <Dad />}
          <h5 className="avatar_img_title">מהאבא</h5>
        </div>
        <div onClick={() => onClick("mom")}>
          {partner === "mom" ? <MomBlue /> : <Mom />}
          <h5 className="avatar_img_title">מהאמא</h5>
        </div>
      </div>
    </>
  );
};

const MaleParent = ({ text = "מהאבא", partner = "", onClick }) => {
  return (
    <>
      <div className="profile_avtar_wraper">
        <div onClick={() => onClick("dad")}>
          {partner === "dad" ? <DadBlue /> : <Dad />}
          <h5 className="avatar_img_title">{text}</h5>
        </div>
      </div>
    </>
  );
};

const FemaleParent = ({ text = "מהאמא", partner = "", onClick }) => {
  return (
    <>
      <div className="profile_avtar_wraper">
        <div onClick={() => onClick("mom")}>
          {partner === "mom" ? <MomBlue /> : <Mom />}
          <h5 className="avatar_img_title">{text}</h5>
        </div>
      </div>
    </>
  );
};

const BothSiblings = ({ partner = "", onClick }) => {
  return (
    <>
      <div className="profile_avtar_wraper">
        <div onClick={() => onClick("brother")}>
          {partner === "brother" ? <BrotherBlue /> : <Brother />}
          <h5 className="avatar_img_title">מהאח</h5>
        </div>
        <div onClick={() => onClick("sister")}>
          {partner === "sister" ? <SisterBlue /> : <Sister />}
          <h5 className="avatar_img_title">מהאחות</h5>
        </div>
      </div>
    </>
  );
};
