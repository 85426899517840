import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";

import MomL from "../../components/svg/MomL";
import DadL from "../../components/svg/DadL";
import OtherFemaleL from "../../components/svg/OtherFemaleL";
import OtherMaleL from "../../components/svg/OtherMaleL";
import KidFL from "../../components/svg/KidFL";
import KidML from "../../components/svg/KidML";
import BrotherL from "../../components/svg/BrotherL";
import SisterL from "../../components/svg/SisterL";

const PartnerName = () => {
  const userRequest = JSON.parse(sessionStorage.getItem("request"));
  const [name, setName] = useState(userRequest?.ask_from_partner_name || "");
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const familyRole = userData?.family_role;
  const navigate = useNavigate();
  const [err, setErr] = useState(false);

  useEffect(() => {
    if (userRequest?.ask_from_partner_roll === undefined) {
      navigate("/home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (err) setErr(false);
  }, [name]);

  const partnerRole = userRequest?.ask_from_partner_roll;

  let pronoun;
  if (["kid_m", "dad", "adult_m", "other_m", "brother"].includes(partnerRole)) {
    pronoun = "לו";
  } else {
    pronoun = "לה";
  }

  let requestFromMy =
    partnerRole === "brother"
      ? "אח"
      : partnerRole === "sister"
      ? "אחות"
      : partnerRole === "kid_m"
      ? "בן"
      : partnerRole === "kid_f"
      ? "בת"
      : partnerRole === "dad" ||
        partnerRole === "adult_m" ||
        partnerRole === "other_m"
      ? "בן הזוג"
      : "בת הזוג";

  const handleSubmit = () => {
    if (name.length > 45) {
      return setErr(true);
    } else {
      setErr(false);
    }

    const newData = JSON.stringify({
      ...userRequest,
      ask_from_partner_name: name,
    });
    sessionStorage.setItem("request", newData);
    if (name.length) {
      navigate("/process");
    }
  };

  return (
    <>
      <div className="bg-light_e">
        <Header />
        <div className="a7_content pt-80">
          <div className="container">
            <div className="a7_header_content text-center pt-4">
              {partnerRole === "other_m" ? (
                <h5>בקשה מבן הזוג</h5>
              ) : partnerRole === "other_f" ? (
                <h5>בקשת מבת הזוג</h5>
              ) : partnerRole === "mom" &&
                (familyRole === "kid_f" || familyRole === "kid_m") ? (
                <h5>בקשה מאמא</h5>
              ) : partnerRole === "dad" &&
                (familyRole === "kid_f" || familyRole === "kid_m") ? (
                <h5>בקשה מאבא</h5>
              ) : partnerRole === "dad" && familyRole === "mom" ? (
                <h5>בקשה מבן הזוג שלי</h5>
              ) : partnerRole === "mom" && familyRole === "dad" ? (
                <h5>בקשה מבת הזוג שלי</h5>
              ) : (
                <h5>
                  בקשה מה
                  {requestFromMy} שלי
                </h5>
              )}

              {/* <img src={requestFrom.img} alt="Request from" /> */}
              {(partnerRole === "mom" || partnerRole === "adult_f") && <MomL />}
              {(partnerRole === "dad" || partnerRole === "adult_m") && <DadL />}
              {partnerRole === "kid_f" && <KidFL />}
              {partnerRole === "kid_m" && <KidML />}
              {partnerRole === "brother" && <BrotherL />}
              {partnerRole === "sister" && <SisterL />}
              {partnerRole === "other_f" && <OtherFemaleL />}
              {partnerRole === "other_m" && <OtherMaleL />}
              <h5 className="pt-5">
                איך קוראים {pronoun}?
                <br />
                מומלץ לכתוב את שם החיבה :)
              </h5>
            </div>
            <div className="a9_input_content mt-4 text-center px-3">
              {/* Input Design */}
              <div className="mb-3 mt-3 w-100">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control input_shadow custom_input"
                />
                {err && (
                  <p className="err_msg pe-1" style={{ textAlign: "right" }}>
                    שם ארוך מדי (יותר מ45 תווים)
                  </p>
                )}
              </div>

              {/* Button Design */}
              <div className="line ">
                <RoundBtn
                  extraClass={`mt-5 mb-2 text-regular
                      ${name ? "bg-primary-button" : "primary-disable"}`}
                  text="להמשיך"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerName;
