const OtherFemale = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="100"
  height="100"
  fill="none"
  viewBox="0 0 100 100"
>
  <g clipPath="url(#clip0_4680_5678)">
    <path d="M0 0H100V100H0z"></path>
    <g filter="url(#filter0_dd_4680_5678)">
      <ellipse
        cx="49.5"
        cy="49.5"
        fill="#fff"
        rx="42.5"
        ry="42.5"
      ></ellipse>
      <g>
        <mask
          id="mask0_4680_5678"
          style={{ maskType: "alpha" }}
          width="85"
          height="85"
          x="7"
          y="7"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#C4C4C4"
            stroke="#000"
            d="M91.5 49.5c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42z"
          ></path>
        </mask>
        <g mask="url(#mask0_4680_5678)">
          <g>
            <g>
              <path
                fill="#45CBF6"
                d="M53.826 70.236H44.88v20.918h8.946V70.236z"
              ></path>
              <path
                fill="#3869E5"
                d="M45.735 79.701h7.148c1.281 0 2.51.505 3.415 1.405a4.78 4.78 0 011.414 3.391v10.881H40.906V84.497a4.78 4.78 0 011.415-3.391 4.846 4.846 0 013.414-1.405z"
              ></path>
              <path
                fill="#3869E5"
                d="M65.176 55.034c8.819-8.774 8.819-22.999 0-31.773-8.82-8.773-23.118-8.773-31.937 0-8.82 8.774-8.82 22.999 0 31.773 8.82 8.773 23.118 8.773 31.937 0z"
              ></path>
              <path
                fill="#3869E5"
                d="M31.806 48.782a3.892 3.892 0 00-1.496-5.32c-1.892-1.06-4.29-.394-5.356 1.485a3.892 3.892 0 001.495 5.32c1.893 1.059 4.29.394 5.357-1.485z"
              ></path>
              <path
                fill="#3869E5"
                d="M27.326 42.955c2.164-.186 3.766-2.08 3.578-4.229-.188-2.149-2.094-3.74-4.258-3.553-2.163.187-3.765 2.08-3.578 4.229.188 2.149 2.095 3.74 4.258 3.553z"
              ></path>
              <path
                fill="#3869E5"
                d="M31.129 34.416a3.887 3.887 0 00.424-5.508 3.951 3.951 0 00-5.545-.422 3.887 3.887 0 00-.424 5.508 3.951 3.951 0 005.545.422z"
              ></path>
              <path
                fill="#3869E5"
                d="M32.49 28.723c2.17 0 3.931-1.748 3.931-3.905s-1.76-3.906-3.932-3.906c-2.172 0-3.932 1.749-3.932 3.906s1.76 3.905 3.932 3.905z"
              ></path>
              <path
                fill="#3869E5"
                d="M41.343 22.219a3.887 3.887 0 00-.457-5.505 3.951 3.951 0 00-5.543.455 3.887 3.887 0 00.458 5.505 3.951 3.951 0 005.542-.455z"
              ></path>
              <path
                fill="#3869E5"
                d="M49.666 16.657a3.885 3.885 0 01-.538 2.203c-.395.665-.98 1.2-1.68 1.535a3.957 3.957 0 01-4.326-.605 3.881 3.881 0 01-1.095-4.2 3.908 3.908 0 011.345-1.832 3.95 3.95 0 015.91 1.422c.225.463.355.964.384 1.477z"
              ></path>
              <path
                fill="#3869E5"
                d="M57.557 17.136c.05-2.157-1.67-3.945-3.84-3.995-2.172-.05-3.973 1.658-4.023 3.815-.05 2.156 1.67 3.945 3.84 3.994 2.172.05 3.973-1.658 4.023-3.814z"
              ></path>
              <path
                fill="#3869E5"
                d="M64.318 22.041a3.89 3.89 0 00-1.164-5.4 3.949 3.949 0 00-5.438 1.156 3.89 3.89 0 001.164 5.401 3.949 3.949 0 005.438-1.157z"
              ></path>
              <path
                fill="#3869E5"
                d="M70.822 26.151a3.9 3.9 0 00-2.42-4.973c-2.051-.71-4.293.367-5.007 2.404a3.9 3.9 0 002.42 4.973c2.051.71 4.293-.367 5.007-2.404z"
              ></path>
              <path
                fill="#3869E5"
                d="M74.314 33.793a3.889 3.889 0 00-.959-5.44 3.95 3.95 0 00-5.478.95 3.889 3.889 0 00.958 5.442 3.95 3.95 0 005.479-.952z"
              ></path>
              <path
                fill="#3869E5"
                d="M72.744 43.186c2.171-.067 3.876-1.869 3.809-4.025-.068-2.156-1.882-3.85-4.053-3.782-2.17.067-3.876 1.869-3.808 4.025.067 2.156 1.881 3.85 4.052 3.782z"
              ></path>
              <path
                fill="#3869E5"
                d="M74.216 46.586a3.885 3.885 0 01-.538 2.203c-.395.666-.98 1.2-1.68 1.535a3.956 3.956 0 01-4.326-.604 3.901 3.901 0 01-1.189-1.934 3.88 3.88 0 01.093-2.266 3.907 3.907 0 011.345-1.831 3.949 3.949 0 015.91 1.42c.225.463.356.965.385 1.477z"
              ></path>
              <path
                fill="#3869E5"
                d="M24.868 62.75c4.873-.785 8.182-5.346 7.391-10.186-.79-4.84-5.382-8.127-10.256-7.341-4.873.786-8.182 5.346-7.391 10.186.791 4.84 5.383 8.127 10.256 7.341z"
              ></path>
              <path
                fill="#3869E5"
                d="M27.619 49.53c4.349-.7 7.302-4.77 6.596-9.09-.706-4.32-4.804-7.252-9.153-6.551-4.349.7-7.302 4.77-6.596 9.09.706 4.32 4.804 7.253 9.153 6.552z"
              ></path>
              <path
                fill="#3869E5"
                d="M81.373 60.265a8.836 8.836 0 000-12.556c-3.491-3.467-9.15-3.467-12.642 0a8.836 8.836 0 000 12.556c3.491 3.467 9.151 3.467 12.642 0z"
              ></path>
              <path
                fill="#3869E5"
                d="M78.046 47.313a7.885 7.885 0 000-11.206c-3.115-3.094-8.166-3.094-11.282 0a7.885 7.885 0 000 11.206c3.116 3.094 8.167 3.094 11.282 0z"
              ></path>
              <path
                fill="#45CBF6"
                d="M49.897 26.814h-1.51c-10.96 0-19.846 7.73-19.846 17.265v10.88c0 9.536 8.885 17.266 19.846 17.266h1.51c10.96 0 19.846-7.73 19.846-17.265V44.08c0-9.536-8.886-17.266-19.846-17.266z"
              ></path>
              <path
                fill="#45CBF6"
                d="M53.825 79.7l-4.473-4.443-4.474 4.443 4.474 4.443 4.473-4.443z"
              ></path>
              <path
                fill="#3869E5"
                d="M45.267 44.233c9.23-2.563 15.427-9.346 13.839-15.152-1.589-5.805-10.36-8.433-19.59-5.87-9.232 2.562-15.428 9.346-13.84 15.15 1.589 5.806 10.36 8.435 19.59 5.872z"
              ></path>
              <path
                fill="#3869E5"
                d="M41.423 49.227c0 .446-.133.883-.382 1.255a2.27 2.27 0 01-1.02.833c-.416.17-.622-3.965-1.315.129a2.279 2.279 0 01-1.165-.619 2.254 2.254 0 01-.493-2.462c.173-.413.465-.765.839-1.013a2.286 2.286 0 012.87.28c.427.424.666.998.666 1.597z"
              ></path>
              <path
                fill="#3869E5"
                d="M60.892 49.227c0 .446-.133.883-.383 1.255-.25.371-.604.661-1.02.833-.415.17-.57-3.965-1.314.129a2.279 2.279 0 01-1.165-.618 2.254 2.254 0 01-.493-2.462c.172-.412.463-.765.837-1.014a2.285 2.285 0 013.365 1.012c.114.274.173.568.173.865z"
              ></path>
              <path
                fill="#3869E5"
                d="M41.007 58.102c5.515 12.464 10.938 12.898 16.268 0H41.007z"
              ></path>
              <path
                fill="#3869E5"
                d="M38.557 105.517H34.26V91.386c0-6.82 4.294-11.582 10.444-11.582v4.268c-4.246 0-6.147 3.68-6.147 7.314v14.131z"
              ></path>
              <path
                fill="#3869E5"
                d="M64.54 105.517h-4.297V91.386c0-3.64-1.9-7.314-6.146-7.314v-4.268c6.15 0 10.444 4.762 10.444 11.582v14.131z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
  <defs>
    <filter
      id="filter0_dd_4680_5678"
      width="103"
      height="103"
      x="-2"
      y="-2"
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="5" dy="5"></feOffset>
      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.764706 0 0 0 0 0.952941 0 0 0 0.15 0"></feColorMatrix>
      <feBlend
        in2="BackgroundImageFix"
        result="effect1_dropShadow_4680_5678"
      ></feBlend>
      <feColorMatrix
        in="SourceAlpha"
        result="hardAlpha"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      ></feColorMatrix>
      <feOffset dx="-5" dy="-5"></feOffset>
      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
      <feComposite in2="hardAlpha" operator="out"></feComposite>
      <feColorMatrix values="0 0 0 0 0.866667 0 0 0 0 0.884052 0 0 0 0 0.941176 0 0 0 0.6 0"></feColorMatrix>
      <feBlend
        in2="effect1_dropShadow_4680_5678"
        result="effect2_dropShadow_4680_5678"
      ></feBlend>
      <feBlend
        in="SourceGraphic"
        in2="effect2_dropShadow_4680_5678"
        result="shape"
      ></feBlend>
    </filter>
    <clipPath id="clip0_4680_5678">
      <path fill="#fff" d="M0 0H100V100H0z"></path>
    </clipPath>
  </defs>
</svg>
);

export default OtherFemale;
