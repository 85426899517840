import { createSlice } from "@reduxjs/toolkit";

const deepNeedsSlice = createSlice({
  name: "deepNeeds",
  initialState: [],
  reducers: {
    addRemoveDeepNeeds(state, action) {
      if (state.map(x => x.name).includes(action.payload.name) && state.map(x => x.blockId).includes(action.payload.blockId)) {
        return state.filter((x) => x.name !== action.payload.name);
      } else {
        if (action.payload.maxLimit) {
          //do nothing (for Section A - allow only 2 selection for Needs)
        } else {
          state.push(action.payload);
        }
      }
    },
    resetDeepNeedState(state) {
        return (state = []);
    }
  },
});

export const { addRemoveDeepNeeds, resetDeepNeedState } = deepNeedsSlice.actions;
export default deepNeedsSlice.reducer;
