import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/layout/Header";
import RoundBtn from "../../components/RoundBtn";
import Clap from "../../components/svg/Clap";

const FirstStop = () => {
  const navigate = useNavigate();
  const userRequest = JSON.parse(sessionStorage.getItem("request"));

  useEffect(() => {
    if (userRequest?.situation_description === undefined) {
      navigate("/home");
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="bg-primary_e">
      <Header extraClass="border-bottom" />

      <div className="a13_content padding-bottom text-center px-3">
        <div className="container">
          <h5>התחנה הראשונה מאחוריך</h5>
          <p>איזה כיף!</p>
          <p>לפעמים זאת יכולה להיות תחנה מאתגרת.</p>
          <div className="my-4">
            <Clap />
          </div>
          <p className="small_disc_a">
            לפני שנעבור הלאה, אם במה שכתבת קיים רגש או דעה, אפשר לחזור ולנסח קצת
            אחרת
          </p>

          <Link to="/situation-feelings" className="line">
            <RoundBtn
              extraClass="mt-5 text-regular bg-secondary-button"
              text="להמשיך"
            />
          </Link>

          {/* Outline Button Design */}
          <Link to="/situation" className="line">
            <button className="btn bg-outline-button custom_button text-regular mt-3">
              לחזור לניסוח הסיטואציה
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FirstStop;
